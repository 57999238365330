import { Dialog } from '@rio-cloud/rio-uikit';
import moment from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';

import releaseNotesDE from './releaseNotesDE';
import releaseNotesEN from './releaseNotesEN';

const dateProps = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

type ReleaseI = Record<string, { content: JSX.Element[]; date: string }>;

const getReleases = (releaseNotes: ReleaseI) => {
    const releases: JSX.Element[] = [];
    Object.entries(releaseNotes).forEach(([version, note]) => {
        const notePoints = note.content.map((point: JSX.Element, i: number) => (
            <li style={{ listStyle: 'none' }} key={`${version}-${releaseNotes}-note-${i}`}>
                {point}
            </li>
        ));

        releases.push(
            <div className={'form-group'} key={`${version}-${releaseNotes}-note`}>
                <h5>
                    {`v ${version}`}
                    <span className="pull-right text-muted">
                        <FormattedDate value={moment(note.date, moment.ISO_8601).toDate()} {...(dateProps as any)} />
                    </span>
                </h5>
                <ul>{notePoints}</ul>
            </div>
        );
    });

    return releases;
};

function ChangelogDialog({
    toggleChangeDialog,
    show,
    locale,
    germanReleaseNotes = releaseNotesDE,
    englishReleaseNotes = releaseNotesEN,
}: {
    toggleChangeDialog: () => void;
    show: boolean;
    locale: string;
    germanReleaseNotes?: ReleaseI;
    englishReleaseNotes?: ReleaseI;
}) {
    const header = <FormattedMessage id="releaseNotes.whatsNew" defaultMessage="Changelog" />;

    const releaseNotes = locale.includes('de') ? germanReleaseNotes : englishReleaseNotes;
    const body = <div>{getReleases(releaseNotes)}</div>;

    const footer = (
        <button data-component="CloseButton" type="button" className="btn btn-default" onClick={toggleChangeDialog}>
            <FormattedMessage id="close" defaultMessage="Close" />
        </button>
    );
    return (
        <Dialog show={show} title={header} body={body} onHide={toggleChangeDialog} useOverflow={true} footer={footer} />
    );
}

export default ChangelogDialog;
