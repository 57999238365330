import { axisBottom, AxisScale } from 'd3-axis';
import { ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';

import { DateRange } from '../../types';
import configureGetCreateTimeInterval from '../../utils/timeInterval';
import { GraphContext } from './GraphDataProvider';
import { calculateDateRange, DataEntry } from './utils';

const DEFAULT_INTERVAL_TYPE = configureGetCreateTimeInterval({
    days: calculateDateRange('day'),
    weeks: calculateDateRange('week'),
    months: calculateDateRange('month'),
    quarters: calculateDateRange('month'),
    years: calculateDateRange('year'),
});

const DEFAULT_TIME_DIFF = configureGetCreateTimeInterval<moment.unitOfTime.Diff>({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'month',
    years: 'year',
});

export default function XAxis({
    dateRange,
    selectedElement,
    timeIntervalType = DEFAULT_INTERVAL_TYPE,
    tickFormatter = () => () => '',
    timeDiff = DEFAULT_TIME_DIFF,
}: {
    dateRange: DateRange;
    selectedElement?: DataEntry | Date;
    timeIntervalType?: (d: DateRange) => (start: Date, end: Date) => Date[];
    timeDiff?: (d: DateRange) => moment.unitOfTime.Diff;
    tickFormatter: (d: DateRange) => (date: Date) => string;
}) {
    const {
        xScale,
        dimensions: { margin, height, widthWithoutMargin },
    } = useContext(GraphContext);
    const axisRef = useRef(null);
    const axisLine = useRef(null);

    const x: ScaleTime<number, Date> = xScale;

    const interval = (timeIntervalType || DEFAULT_INTERVAL_TYPE)(dateRange);

    useEffect(() => {
        if (axisRef && axisRef.current) {
            const el = select(axisRef.current);
            const xAxis = axisBottom<Date>(x as AxisScale<Date>)
                .tickValues(interval(x.domain()[0], x.domain()[1]))
                .tickFormat(tickFormatter(dateRange));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            el.call(xAxis);
            el.select('.domain').remove();
            el.selectAll<any, Date>('.tick').attr('class', d =>
                selectedElement && moment(d).diff(selectedElement, (timeDiff || DEFAULT_TIME_DIFF)(dateRange)) === 0
                    ? 'tick active'
                    : 'tick'
            );
            el.selectAll('.tick text')
                .attr('x', 4)
                .attr('dy', 10);
        }

        if (axisLine.current) {
            select(axisLine.current)
                .attr('x0', 0)
                .attr('x1', widthWithoutMargin)
                .attr('y0', 0);
        }
    }, [axisRef, height, margin, x]);

    return (
        <g transform={`translate(0 ${height})`}>
            <g ref={axisRef} className="axis xAxis" textAnchor="start" />
            <line ref={axisLine} className="axisLine" />
        </g>
    );
}
