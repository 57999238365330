import { ErrorState } from '@rio-cloud/rio-uikit';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { configureReporting } from '../setup/errorReporting';

const { captureException } = configureReporting(window, process.env);

interface State {
    hasError: boolean;
}
export default class ErrorBoundary extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        captureException(error, info);
    }

    render(): React.ReactNode {
        return this.state.hasError ? (
            <ErrorState headline={<FormattedMessage id="error.default" />} />
        ) : (
            this.props.children
        );
    }
}
