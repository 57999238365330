import { Notification } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import { getLanguageData } from '../lang/selectors';

export function* errorNotification(errorKey) {
    const languageData = yield select(getLanguageData);
    const errorMessage = _.get(languageData, errorKey, 'Unknown Error');
    yield call([Notification, Notification.error], errorMessage);
}

export function* infoNotification(errorKey) {
    const languageData = yield select(getLanguageData);
    const infoMessage = _.get(languageData, errorKey, 'Unknown Info');
    yield call([Notification, Notification.info], infoMessage);
}
