import { useForm } from 'react-hook-form';

import { Driver, Vehicle, VehicleGroup } from '../../../api';
import { useAppDispatch } from '../../../setup/store';
import { createReportConfiguration } from '../reducer';
import { ReportForm } from '../types';
import Form from './Form';
import { handleFormData } from './handleFormData';

type Props = {
    vehicles: Vehicle[];
    drivers: Driver[];
    groups: VehicleGroup[];
    report: Omit<ReportForm, 'id'>;
    onCloseDialog: () => void;
    setIsSubmitting: (s: boolean) => void;
};

const CreateReportForm = ({ vehicles, groups, drivers, report, onCloseDialog, setIsSubmitting }: Props) => {
    const dispatch = useAppDispatch();
    const formApi = useForm<ReportForm>({
        defaultValues: report,
    });

    const onSubmit = (data: ReportForm) => {
        setIsSubmitting(true);
        const handledData = handleFormData(data, groups, drivers, vehicles);
        dispatch(createReportConfiguration(handledData)).then(res => {
            setIsSubmitting(false);
            if (res.meta.requestStatus !== 'rejected') {
                onCloseDialog();
            }
        });
    };

    return (
        <form onSubmit={formApi.handleSubmit(onSubmit)}>
            <Form formApi={formApi} vehicles={vehicles} groups={groups} drivers={drivers} />
        </form>
    );
};

export default CreateReportForm;
