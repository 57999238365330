import { Notification } from '@rio-cloud/rio-uikit';
import { Component, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const FADE_AWAY_IN_SECONDS = 7 * 1000;
const DELAY_RENDER_TOOLTIPS_IN_SECONDS = 2000;
const KEY = 'showFeedback';
const FALSE = '0';

const isFeedbackDismissed = feedbackToggle => feedbackToggle === FALSE;
const dismissFeedback = () => {
    sessionStorage.setItem(KEY, FALSE);
};

function Feedback({ intl }) {
    const [shouldShowFeedback, setShouldShowFeedback] = useState(sessionStorage.getItem(KEY));

    const hideFeedback = () => {
        dismissFeedback();
        setShouldShowFeedback(FALSE);
    };

    useEffect(() => {
        const renderFeedback = () => {
            const link = intl.formatMessage({ id: 'beta.feedback.link' });

            if (!/^https:\/\/www.surveymonkey/.test(link)) {
                return;
            }

            Notification.info(
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={link} target="_blank" className="text-white text-decoration-underline">
                    <FormattedMessage id="beta.feedback.content" />
                </a>,
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={link} target="_blank" className="text-white text-decoration-underline">
                    <FormattedMessage id="beta.feedback.title" />
                </a>,
                FADE_AWAY_IN_SECONDS,
                hideFeedback
            );
        };

        if (!isFeedbackDismissed(shouldShowFeedback)) {
            dismissFeedback();
            setTimeout(renderFeedback, DELAY_RENDER_TOOLTIPS_IN_SECONDS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldShowFeedback]);

    return null;
}

const FeedbackWithIntl = injectIntl(Feedback);

export default class SwallowError extends Component {
    componentDidCatch() {}
    render() {
        return <FeedbackWithIntl />;
    }
}
