import { array, number, string, type, TypeOf } from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

import { createId, createSignal } from '../helpers/transformer';
import { Entity } from '../models/types';
import { decodeJson } from './decoder';

const RawRoute = type({
    longitude: number,
    latitude: number,
    occurred_at: DateFromISOString,
});

const RouteApiResponse = type({
    historic_positions: array(RawRoute),
    asset_id: string,
});

type ApiResponse = TypeOf<typeof RouteApiResponse>;

export function routeDecoder(raw: ApiResponse, dateRange: { start: Date; end: Date }): Entity {
    const decoded = decodeJson(RouteApiResponse, raw);
    return {
        id: createId(raw),
        signals: {
            start: createSignal(dateRange.start),
            end: createSignal(dateRange.end),
            points: createSignal(
                decoded.historic_positions.map(({ longitude, latitude }) => ({ lat: latitude, lng: longitude }))
            ),
            vehicleIds: createSignal([decoded.asset_id]),
        },
    };
}
