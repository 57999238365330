import { exact } from 'io-ts';
import _ from 'lodash';

import {
    fetchDeleteWithJSONResponse,
    fetchGetWithJSONResponse,
    fetchPatchWithJSONResponse,
    fetchPostWithJSONResponse,
} from './api';
import { decodeReportConfigurations, decodeSingleReportConfiguration } from './decoder/decodeReportConfiguration';
import { encodeJson } from './encoder/encoder';
import { encodeReportConfiguration } from './encoder/encodeReportConfiguration';
import {
    EditReportConfigurationRequest,
    ReportConfiguration,
    ReportConfigurationEditableFields,
} from './models/reportConfiguration';

export function changeCaseResponse(response: any, changeCase: (k: string) => string): any {
    const isObject = _.isObject(response);
    const isArray = _.isArray(response);
    if (isObject || isArray) {
        const newObj = _.mapKeys(response, (v, k) => {
            return changeCase(k);
        });
        return _.mapValues(newObj, v => {
            if (_.isArray(v)) {
                return v.map(vv => changeCaseResponse(vv, changeCase));
            }

            if (_.isObject(v) && !_.isDate(v)) {
                return changeCaseResponse(v, changeCase);
            }

            return v;
        });
    }
    return response;
}

export async function fetchReportConfigurations(baseUrl: string, authToken: string) {
    const responses = await fetchGetWithJSONResponse(baseUrl, 'configurations', authToken);

    return decodeReportConfigurations(changeCaseResponse(responses, _.camelCase));
}

export async function postReportConfiguration(
    baseUrl: string,
    authToken: string,
    configurationPayload: Omit<ReportConfiguration, 'id'>
) {
    const encodedReportPayload = encodeReportConfiguration(configurationPayload);

    const response = await fetchPostWithJSONResponse(
        baseUrl,
        'configurations',
        changeCaseResponse(encodedReportPayload, _.snakeCase),
        authToken
    );

    return decodeSingleReportConfiguration(changeCaseResponse(response, _.camelCase));
}

export async function patchReportConfiguration(
    baseUrl: string,
    authToken: string,
    configurationPayload: EditReportConfigurationRequest
) {
    const encodedReportPayload = encodeJson(exact(ReportConfigurationEditableFields), configurationPayload);

    return await fetchPatchWithJSONResponse(
        baseUrl,
        'configurations',
        configurationPayload.id,
        changeCaseResponse(encodedReportPayload, _.snakeCase),
        authToken
    );
}

export async function deleteConfiguration(baseUrl: string, authToken: string, reportId: string) {
    return await fetchDeleteWithJSONResponse(baseUrl, 'configurations', reportId, authToken);
}
