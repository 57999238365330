import _ from 'lodash';
import React, { ReactElement } from 'react';

import usePerformData from '../../data/hooks/usePerformData';
import { Loadable } from '../../data/loadable';
import { DateRange } from '../../types';
import { CODRIVER_REQUEST_ATTRIBUTES } from './queries';
import { createId } from './reducer';
import { DRIVER, VEHICLE } from './selectors';
import { CoDriverDownloadPayload, Status } from './types';

interface AutoSuggestSuggestion {
    [name: string]: any;
}

const DataLoader: React.FC<{
    dateRange: DateRange;
    vehicles: string[];
    drivers: string[];
    selection: AutoSuggestSuggestion;
    startDownload: (payload: CoDriverDownloadPayload) => void;
    status: Record<string, Status>;
}> = ({ dateRange, vehicles = [], drivers = [], children, selection, startDownload = _.noop, status = {} }) => {
    const isDriver = selection.type === DRIVER;

    const loadableEntities = usePerformData(CODRIVER_REQUEST_ATTRIBUTES, {
        variables: {
            ...dateRange,
            ...(isDriver
                ? { driverIds: drivers, segmentBy: 'vehicle' }
                : { vehicleIds: vehicles, segmentBy: 'driver' }),
        },
    });

    const mappedEntities = Loadable.map(loadableEntities, data =>
        data.map((row: any) => ({
            ...row,
            download: {
                onClick: () =>
                    startDownload({
                        type: selection.type,
                        driver: row.drivers[0],
                        vehicle: row.vehicles[0],
                        start: dateRange.start,
                        end: dateRange.end,
                        isTotalSegment: false,
                    }),
                status:
                    status[
                        createId(
                            row.vehicles.length > 0 ? [row.vehicles[0].vehicleId] : [],
                            row.drivers.length > 0 ? [row.drivers[0].driverId] : ['unknown'],
                            dateRange.start,
                            dateRange.end
                        )
                    ],
            },
        }))
    );

    const shouldShowAllDriversButton = Loadable.cata(
        loadableEntities,
        data => selection.type === VEHICLE && data.length > 1,
        () => false,
        () => false,
        () => false
    );
    return React.cloneElement(children as ReactElement, { mappedEntities, shouldShowAllDriversButton });
};

export default DataLoader;
