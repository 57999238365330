import { compose } from 'redux';

import { KILOMETERS } from '../constants/units';
import { formatOneDigitNumber, plainAverage, withUnit } from '../utils/formatters';
import createColumn from './createColumn';

export const distanceSignal = {
    unit: KILOMETERS,
    displayedUnit: KILOMETERS,
    format: compose(withUnit(KILOMETERS), formatOneDigitNumber),
    average: plainAverage,
};

export const mileage = createColumn({
    key: 'mileage',
    dataField: 'mileage',
    labelId: 'mileage',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const coastingCcDistance = createColumn({
    key: 'coastingCcDistance',
    dataField: 'coastingCcDistance',
    labelId: 'coastingCcDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const kickdownDistance = createColumn({
    key: 'kickdownDistance',
    dataField: 'kickdownDistance',
    labelId: 'kickdownDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const accelerationPedalDistanceWithCc = createColumn({
    key: 'accelerationPedalDistanceWithCc',
    dataField: 'accelerationPedalDistanceWithCc',
    labelId: 'accelerationPedalDistanceWithCc',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const serviceBrakeDistance = createColumn({
    key: 'serviceBrakeDistance',
    dataField: 'serviceBrakeDistance',
    labelId: 'serviceBrakeDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const motorBrakeDistance = createColumn({
    key: 'motorBrakeDistance',
    dataField: 'motorBrakeDistance',
    labelId: 'motorBrakeDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const cruiseControlDistanceInPotential = createColumn({
    key: 'cruiseControlDistanceInPotential',
    dataField: 'cruiseControlDistanceInPotential',
    labelId: 'cruiseControlDistanceInPotential',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const noCruiseControlDistanceInPotential = createColumn({
    key: 'noCruiseControlDistanceInPotential',
    dataField: 'noCruiseControlDistanceInPotential',
    labelId: 'noCruiseControlDistanceInPotential',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});
export const cruiseControlPotentialDistance = createColumn({
    key: 'cruiseControlPotentialDistance',
    dataField: 'cruiseControlPotentialDistance',
    labelId: 'cruiseControlPotentialDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});
export const overspeedDistance = createColumn({
    key: 'overspeedDistance',
    dataField: 'overspeedDistance',
    labelId: 'overspeedDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const activeCoastingDistance = createColumn({
    key: 'activeCoastingDistance',
    dataField: 'activeCoastingDistance',
    labelId: 'activeCoastingDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const retarderDistance = createColumn({
    key: 'retarderDistance',
    dataField: 'retarderDistance',
    labelId: 'retarderDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});
