import merge from 'lodash/fp/merge';

import { localConfig } from './env/env.local';
import { localAuthConfig } from './env/env.localauth';
import { localProdConfig } from './env/env.localprod';
import { productionConfig } from './env/env.production';
import { stagingConfig } from './env/env.staging';
import { testConfig } from './env/env.test';
import { testStageConfig } from './env/env.test.stage';

const determineFeatureToggles = window => {
    const param = (regex, defaultValue = null) => {
        let result = defaultValue;
        decodeURI(window.location.href).replace(regex, (_, it) => {
            result = it;
        });
        return result;
    };

    const detectToggle = (transform, name, defaultValue = null) => {
        // Note that IE11 throws a SyntaxError when using 'u' here
        // eslint-disable-next-line require-unicode-regexp
        let value = param(new RegExp(`${name}=([^&]+)`));

        if (value) {
            try {
                window.localStorage.setItem(name, value);
            } catch (_) {
                // Intentionally left blank
            }
        } else {
            try {
                value = window.localStorage.getItem(name);
            } catch (_) {
                // Intentionally left blank
            }
        }

        return value ? transform(value) : defaultValue;
    };

    const BooleanToggle = value => {
        if (value === 'true' || value === '1') {
            return true;
        }
        return false;
    };

    // ... add your custom feature toggles here
    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        developer: detectToggle(String, 'ft_developer'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
        printOnLambda: detectToggle(BooleanToggle, 'ft_printOnLambda'),
        persistSettings: detectToggle(BooleanToggle, 'ft_persistSettings', true),
    };
};

const ALL_SCOPES_WILDCARD = ' ';

const getRuntimeConfig = (window, env = {}) => {
    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location.origin}`,
        id: 'env.stub',
        login: {
            // TODO: We can't request the current clientId till we figure out which is our
            //       by which domain the api can be requested. SEE: TBMTWOPA-500
            clientId: '6770a314-a990-480b-ab39-17fdba2f2c8a',
            oauthScope: [ALL_SCOPES_WILDCARD],
            mockLocale: 'en-GB',
            preventRedirect: false,
            redirectUri: `${window.location.origin}/redirect.html`,
            silentRedirectUri: `${window.location.origin}/redirect.html`,
        },
        sentryToken: 'https://7a8d711e3232442d854c4a61784bcd75@o117480.ingest.sentry.io/1403991',
    };

    let config = localConfig;

    if (env.isRunningOnProd) {
        config = productionConfig;
    }

    if (env.isRunningOnStaging) {
        config = stagingConfig;
    }

    if (env.isRunningOnTestStage) {
        config = testStageConfig;
    }

    if (env.featureToggles.enforcedEnv === 'localauth') {
        config = localAuthConfig;
    }

    if (env.featureToggles.enforcedEnv === 'localprod') {
        config = localProdConfig;
    }

    if (env.featureToggles.enforcedEnv === 'production') {
        config = productionConfig;
    }

    if (env.isTestEnv) {
        config = testConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

export const configureEnv = (window, processEnv) => {
    const {
        location: { host },
    } = window;
    const { NODE_ENV } = processEnv;

    const isProdEnv = host === 'perform.rio.cloud' || host === 'perform3.rio.cloud';
    const isStagingEnv = host === 'staging.perform.rio.cloud' || host === 'staging.perform3.rio.cloud';
    const isTestEnv = NODE_ENV === 'test' || NODE_ENV === 'cov';
    const isLocalEnv = !isProdEnv && !isStagingEnv && !isTestEnv;

    const shouldSendMetrics = isProdEnv || isStagingEnv;

    const isProdBuild = NODE_ENV === 'production';
    const isRunningOnProd = host === 'perform.rio.cloud' || host === 'perform3.rio.cloud';
    const isRunningOnStaging = host === 'staging.perform.rio.cloud' || host === 'staging.perform3.rio.cloud';
    const isRunningOnTestStage = host === 'test.perform.rio.cloud';
    const runsInSandbox = isProdBuild && !isRunningOnStaging && !isRunningOnProd;

    // "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about
    // accessing the console when the developer tools are not visible
    // so we will be extra defensive here, just to be safe
    const console = typeof window['console'] !== 'undefined' ? window.console : null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const shouldRestrictLog = NODE_ENV === 'production';
    const shouldUseConsoleLogger = false; //NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = determineFeatureToggles(window);

    const env = {
        featureToggles,
        hasConsole,
        isLocalEnv,
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        isRunningOnTestStage,
        isTestEnv,
        runsInSandbox,
        runtimeConfig: {},
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, process.env);

if (!env.hasConsole && window) {
    // I'm still looking at you IE <o.O>
    const noop = () => {};

    const fakeConsole = {
        debug: noop,
        info: noop,
        isFakeConsole: true,
        log: noop,
        warn: noop,
    };

    window.console = fakeConsole;
}

if (env.featureToggles.tracing) {
    // eslint-disable-next-line no-console
    console.log(env);
}
