import _ from 'lodash';
import always from 'lodash/fp/always';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createId } from '../api';
import { requestPerformanceData } from '../data/actions';
import { Loadable } from '../data/loadable';
import { entitiesForRequest } from '../data/selectors';

const DEFAULT_RETURN_VALUE = Loadable.createNotRequested();
const WAITING_RETURN_VALUE = Loadable.createWaiting();

const isQueryFinished = loadable => Loadable.cata(loadable, always(true), always(true), always(false), always(false));

const stateSelector = state => state;

const useQuery = (
    query,
    { variables = {}, selector = entitiesForRequest, endPoint = requestPerformanceData, debounced = true }
) => {
    const state = useSelector(stateSelector);
    const dispatch = useDispatch();
    const [isProcessingTriggered, setIsProcessingTriggered] = useState(false);
    const [requestInProcess, setRequestInProcess] = useState(null);
    const compiledQuery = query(variables);
    const compiledQueryId = createId(compiledQuery);
    const requestInProcessId = requestInProcess ? requestInProcess.compiledQueryId : '';

    const hasFinishedRequest = requestInProcess ? isQueryFinished(selector(state, requestInProcessId)) : false;

    const debouncedSetRequestInProcess = useRef(_.debounce(nextRequest => setRequestInProcess(nextRequest), 1000))
        .current;
    const result = selector(state, compiledQueryId);

    const currentRequestStatus = result || DEFAULT_RETURN_VALUE;
    const isCurrentRequestNotRequested = Loadable.isNotRequested(currentRequestStatus);

    function compiledQueryChanged() {
        if (isCurrentRequestNotRequested) {
            if (debounced) {
                if (!requestInProcess) {
                    setIsProcessingTriggered(true);
                    debouncedSetRequestInProcess({ compiledQuery, compiledQueryId, selector, endPoint });
                }
            } else {
                dispatch(endPoint(compiledQuery));
            }
        }
    }
    function requestInProcessChanged() {
        if (requestInProcess) {
            dispatch(requestInProcess.endPoint(requestInProcess.compiledQuery));
        }
    }
    function hasFinishedRequestChanged() {
        if (hasFinishedRequest) {
            if (compiledQueryId !== requestInProcessId && isCurrentRequestNotRequested) {
                debouncedSetRequestInProcess({ compiledQuery, compiledQueryId, selector, endPoint });
            } else {
                setIsProcessingTriggered(false);
                setRequestInProcess(null);
            }
        }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(compiledQueryChanged, [compiledQueryId]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(requestInProcessChanged, [requestInProcessId]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(hasFinishedRequestChanged, [hasFinishedRequest]);

    if (!_.isFunction(query)) {
        // eslint-disable-next-line no-console
        console.warn('[useQuery]: Expects that query is a function');
    }
    if (result && !Loadable.isLoadable(result)) {
        // eslint-disable-next-line no-console
        console.warn('[useQuery]: Expects that the returned value is of the type: Loadable');
    }

    if (isCurrentRequestNotRequested && isProcessingTriggered) {
        return WAITING_RETURN_VALUE;
    }

    return currentRequestStatus;
};

export default useQuery;
