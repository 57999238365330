import { difference, isEmpty } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';
import { Vehicle } from '../../types';

export function getEssentialsWarning(row: unknown, performVehicleIds?: string[]): JSX.Element | null {
    if (isEmpty(performVehicleIds)) {
        return null;
    }

    const vehicles: Vehicle[] = getSignal(row, 'vehicles', []);
    const vehicleIds = vehicles.map(vehicle => vehicle.vehicleId);
    const essentialsVehicleIds = difference(vehicleIds, performVehicleIds!);
    return !isEmpty(essentialsVehicleIds) ? <EssentialsWarning key="containsEssentialsVehicleWarning" /> : null;
}

function EssentialsWarning(): JSX.Element {
    return (
        <React.Fragment>
            <dt data-test="essential-warning">
                <FormattedMessage id="containsEssentialsVehicleWarning" />
            </dt>
            <dd>
                <FormattedMessage id="containsEssentialsWarningVehicleDetail" />
            </dd>
        </React.Fragment>
    );
}

export default EssentialsWarning;
