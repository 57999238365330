import { uniq } from 'lodash';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Driver, ReportTimeframe, ReportType, Vehicle, VehicleGroup } from '../../../api';
import { getAvailableLocales } from '../../lang/selectors';
import { REPORT_FORM } from '../CreateReportDialog';
import { ReportForm } from '../types';
import FormGroup from './components/FormGroup';
import Input, { InputStatus } from './components/Input';
import Select from './components/Select';
import Switch from './components/Switch';
import TreeSelect from './components/TreeSelect';

type Props = {
    formApi: UseFormReturn<ReportForm>;
    vehicles: Vehicle[];
    drivers: Driver[];
    groups: VehicleGroup[];
    isEditing?: boolean;
};

const Form = ({ formApi, vehicles, drivers, groups, isEditing = false }: Props) => {
    const intl = useIntl();
    const {
        register,
        control,
        setValue,
        formState: { errors },
    } = formApi;
    const availableLocales: string[] = uniq(Object.values(useSelector(getAvailableLocales)));

    const reportType = useWatch({ control, name: 'reportType' });
    useEffect(() => {
        setValue('entities', { items: [], groups: [] });
    }, [reportType, setValue]);

    return (
        <>
            <fieldset>
                <FormGroup label={<FormattedMessage id="reports.name" />} htmlFor="name">
                    <Input
                        type="text"
                        register={register('name', { required: true, minLength: 5, maxLength: 200 })}
                        status={errors?.name ? InputStatus.error : InputStatus.success}
                        errorMessage={<FormattedMessage id="reports.name.error" />}
                        data-test="create-form-name"
                    />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.type" />} htmlFor="report-type">
                    <Select
                        name="reportType"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        options={[
                            { id: ReportType.driver_details, label: intl.formatMessage({ id: 'driver' }) },
                            { id: ReportType.asset_details, label: intl.formatMessage({ id: 'vehicle' }) },
                        ]}
                        disabled={isEditing}
                    />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.interval" />} htmlFor="interval">
                    <Select
                        name="timeframe"
                        options={[
                            {
                                id: ReportTimeframe.monthly,
                                label: intl.formatMessage({ id: 'reports.intervalTypes.MONTHLY' }),
                            },
                            {
                                id: ReportTimeframe.weekly,
                                label: intl.formatMessage({ id: 'reports.intervalTypes.WEEKLY' }),
                            },
                        ]}
                        control={control}
                        disabled={isEditing}
                    />
                </FormGroup>

                {isEditing && (
                    <>
                        <FormGroup label={<FormattedMessage id="reports.timezone" />} htmlFor="report-timezone">
                            <Select
                                name="timezone"
                                disabled={true}
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                options={[{ id: 'Europe/Berlin', label: 'Europe/Berlin' }]}
                            />
                        </FormGroup>

                        <FormGroup label={<FormattedMessage id="reports.language" />} htmlFor="languageCode">
                            <Select
                                name="languageCode"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                options={availableLocales.map(locale => ({
                                    label: intl.formatMessage({ id: `reports.locale.${locale}` }),
                                    id: locale,
                                }))}
                                disabled={isEditing}
                            />
                        </FormGroup>

                        <FormGroup label={<FormattedMessage id="reports.informViaEmail" />} htmlFor="informViaEmail">
                            <Switch name="informViaEmail" control={control} />
                        </FormGroup>
                    </>
                )}

                {reportType === ReportType.driver_details && (
                    <FormGroup label={<FormattedMessage id="drivers" />} htmlFor="drivers">
                        <TreeSelect
                            name="entities"
                            status={'entities' in errors ? InputStatus.error : InputStatus.success}
                            errorMessage={<FormattedMessage id="reports.drivers.error" />}
                            control={control}
                            rules={{
                                required: true,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                validate: value => isEditing || value?.groups.length > 0 || value?.items.length > 0,
                            }}
                            groups={groups}
                            items={drivers}
                            searchPlaceholder={intl.formatMessage({
                                id: 'intl-msg:asset-tree.search.placeholder.driver',
                            })}
                            disabled={isEditing}
                        />
                    </FormGroup>
                )}

                {reportType === ReportType.asset_details && (
                    <FormGroup label={<FormattedMessage id="vehicles" />} htmlFor="vehicles">
                        <TreeSelect
                            name="entities"
                            status={'entities' in errors ? InputStatus.error : InputStatus.success}
                            errorMessage={<FormattedMessage id="reports.vehicles.error" />}
                            control={control}
                            rules={{
                                required: true,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                validate: value => isEditing || value?.groups.length > 0 || value?.items.length > 0,
                            }}
                            groups={groups}
                            items={vehicles}
                            searchPlaceholder={intl.formatMessage({
                                id: 'intl-msg:asset-tree.search.placeholder.vehicles',
                            })}
                            disabled={isEditing}
                        />
                    </FormGroup>
                )}
            </fieldset>
            <input type="submit" data-test="submit" id={REPORT_FORM} hidden />
        </>
    );
};

export default Form;
