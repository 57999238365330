import { FormattedMessage } from 'react-intl';

import FormattedDateTime from './FormattedDateTime';

export default function OperatingPeriod({ start, end }: { start: Date | string; end: Date | string }): JSX.Element {
    return (
        <div className="bg-lightest text-size-14 text-color-dark text-normal padding-10 padding-left-20 padding-right-20 padding-bottom-10 border-style-solid border-top-1 border-left-0 border-right-0 border-color-light border-width-1">
            <FormattedMessage id="tripStartEnd" />:{' '}
            <span className="text-color-darker">
                <FormattedDateTime value={start} />
            </span>{' '}
            <span style={{ textTransform: 'lowercase' }}>
                <FormattedMessage id="dateRange.to" />{' '}
            </span>
            <span className="text-color-darker">
                <FormattedDateTime value={end} />
            </span>
        </div>
    );
}
