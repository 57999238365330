import { getAccuracyWarning } from '../../components/warnings/AccuracyWarning';
import { getBelowMileageWarning } from '../../components/warnings/BelowMileageWarning';
import { getBusOrVanWarning } from '../../components/warnings/BusOrVanWarning';
import { getEssentialsWarning } from '../../components/warnings/EssentialsWarning';
import { getTbm2AndTbm3Warning } from '../../components/warnings/Tbm2AndTbm3Warning';

export const decorateWithWarnings = (entity: any, performVehicleIds?: string[]) => ({
    ...entity,
    warnings: [
        getAccuracyWarning(entity),
        getTbm2AndTbm3Warning(entity),
        getBelowMileageWarning(entity),
        getBusOrVanWarning(entity),
        getEssentialsWarning(entity, performVehicleIds),
    ].filter(Boolean),
    ...(entity.children
        ? {
              children: entity.children.map((entity: any) => decorateWithWarnings(entity, performVehicleIds)),
          }
        : {}),
});
