import { SimpleButtonDropdown } from '@rio-cloud/rio-uikit';
import { MenuItemProps } from '@rio-cloud/rio-uikit/lib/types';
import { connect } from 'react-redux';

import { DownloadStatePart } from '../../setup/types';
import { isDownloading } from './selectors';

// eslint-disable-next-line import/no-unused-modules
export const DownloadMenu = ({
    options = [],
    isDownloading,
    disabled = false,
}: {
    options: MenuItemProps[];
    isDownloading: boolean;
    disabled?: boolean;
}): JSX.Element => {
    if (isDownloading) {
        return <button className="btn btn-default btn-icon-only btn-loading" />;
    }
    return (
        <SimpleButtonDropdown
            disabled={disabled}
            items={options}
            title={<span data-test="toolbar-download" className="rioglyph rioglyph-download" aria-hidden="true" />}
            iconOnly
        />
    );
};

export default connect((state: DownloadStatePart, { owner }: { owner: string }) => ({
    isDownloading: isDownloading(state, owner),
}))(DownloadMenu);
