import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Column } from '../../columns/createColumn';
import Card from '../../components/Card';
import { SettingStatePart } from '../../setup/types';
import { getColumn } from '../../utils/columns';
import { getRatingCSSColor } from '../../utils/rating';
import { includeCruiseControlInRating } from '../settings/reducer';
import { EntityType } from './types';

const withCCRating = getColumn('totalRating');
const withoutCCRating = getColumn('totalRatingWithoutCC');

export const Total = ({ entity, column }: { entity: EntityType; column?: Column }): JSX.Element => {
    const value = column!.valueExtractor(entity);
    const { color } = getRatingCSSColor(value);
    return (
        <Card
            title={<FormattedMessage id={column!.labelId} />}
            score={
                <span className={cn('text-size-12 text-bold', color)}>
                    <span className="text-size-200pct"> {column!.formatter(value)} </span>
                </span>
            }
        />
    );
};

export const mapStateToProps = (state: SettingStatePart) => ({
    column: includeCruiseControlInRating(state) ? withCCRating : withoutCCRating,
});

export default connect(mapStateToProps)(Total);
