import PropTypes from 'prop-types';

const STATUS = Object.freeze({
    WAITING: 'WAITING',
    DONE: 'DONE',
    FAILED: 'FAILED',
    UNKNOWN: 'UNKNOWN',
});

type SuccessLoadable<T> = {
    type: 'DONE';
    data: T;
};

type RestLoadables = {
    type: 'WAITING' | 'UNKNOWN';
};

type FailedLoadable = {
    type: 'FAILED';
};

export type LoadableType<T> = SuccessLoadable<T> | FailedLoadable | RestLoadables;

const createWaiting = <T>(): LoadableType<T> => ({ type: 'WAITING' });
const createDone = <T>(data: T): LoadableType<T> => ({ type: 'DONE', data });
const createFailed = <T>(): LoadableType<T> => ({ type: 'FAILED' });
const createNotRequested = <T>(): LoadableType<T> => ({ type: 'UNKNOWN' });

function map<T1, T2>(status: LoadableType<T1>, fn: (l: T1) => T2) {
    return status.type === 'DONE' ? createDone(fn(status.data)) : status;
}

const flatten = <T>(status: LoadableType<T>): LoadableType<any> => {
    switch (status.type) {
        case 'DONE': {
            return Loadable.isLoadable(status.data)
                ? Loadable.flatten((status.data as unknown) as SuccessLoadable<any>)
                : status;
        }
        case 'WAITING':
        case 'FAILED':
        case 'UNKNOWN':
        default: {
            return status;
        }
    }
};

function combine<CombinedResult, T1, T2, T3, T4>(
    fn: (t1: T1, t2: T2, t3: T3, t4: T4) => CombinedResult,
    t1: LoadableType<T1>,
    t2: LoadableType<T2>,
    t3: LoadableType<T3>,
    t4: LoadableType<T4>
): LoadableType<CombinedResult>;

function combine<CombinedResult, T1, T2, T3>(
    fn: (t1: T1, t2: T2, t3: T3) => CombinedResult,
    t1: LoadableType<T1>,
    t2: LoadableType<T2>,
    t3: LoadableType<T3>
): LoadableType<CombinedResult>;

function combine<CombinedResult, T1, T2>(
    fn: (t1: T1, t2: T2) => CombinedResult,
    t1: LoadableType<T1>,
    t2: LoadableType<T2>
): LoadableType<CombinedResult>;

function combine<CombinedResult, T1, T2>(
    fn: (...rest: any[]) => CombinedResult,
    ...rest: [LoadableType<T1>, LoadableType<T2>]
): LoadableType<CombinedResult> {
    const result = rest.reduce((previous, current: LoadableType<any>) => {
        const result = map(previous, previousData => map(current, currentData => [...previousData, currentData]));
        return flatten(result);
    }, Loadable.createDone([] as any));
    return map(flatten(result), r => fn(...r));
}

export const Loadable = {
    isLoadable: <A>(status: any) => status && Object.keys(STATUS).includes(status.type),
    isNotRequested: <A>(status: LoadableType<A>) => status.type === STATUS.UNKNOWN,
    createWaiting,
    createDone,
    createFailed,
    createNotRequested,
    isEqual: <A, B>(a: LoadableType<A>, b: LoadableType<B>) => {
        const failure = Symbol('failure');
        const waiting = Symbol('waiting');
        const unknown = Symbol('unknown');
        return (
            Loadable.cata(
                a,
                d => d,
                () => failure,
                () => waiting,
                () => unknown
            ) ===
            Loadable.cata(
                b || Loadable.createNotRequested(),
                d => d,
                () => failure,
                () => waiting,
                () => unknown
            )
        );
    },
    //TODO: REVERSE PARAMS
    map,
    /* FIXME: combine should combine: LoadableType.creatFailed() and LoadableType.createNotRequested() to LoadableType.creatFailed();
     * Right now it combines to LoadableType.createNotRequested()
     */
    combine,

    flatten,
    cata: <SuccessInput, SuccessReturn, FailureReturn, WaitingReturn, UnknownReturn>(
        status: LoadableType<SuccessInput>,
        successFn: (data: SuccessInput) => SuccessReturn,
        failureFn: () => FailureReturn,
        waitingFn: () => WaitingReturn,
        unknownFn: () => UnknownReturn
    ) => {
        switch (status.type) {
            case 'FAILED':
                return failureFn();
            case 'WAITING':
                return waitingFn();
            case 'UNKNOWN':
                return unknownFn();
            case 'DONE': {
                return successFn(status.data);
            }
        }
    },
};

export const LoadableEntityPropType = PropTypes.shape({
    type: PropTypes.oneOf(Object.values(STATUS)),
    data: PropTypes.any,
});
