import { get } from 'lodash';
import React from 'react';

import { Vehicle } from '../api/models/types';
import { VehicleType } from '../types';
import { extractVehicleName } from '../utils/stringFormatters';
import Label from './Label';

export const SingleVehicleLabel: React.FC<{ vehicle: Vehicle }> = ({ vehicle }) => {
    const name = extractVehicleName(vehicle);

    switch (vehicle.type) {
        case VehicleType.TRUCK:
            return (
                <Label id="truck" icon="rioglyph-truck">
                    {name}
                </Label>
            );
        case VehicleType.VAN:
            return (
                <Label id="truck" icon="rioglyph-van">
                    {name}
                </Label>
            );
        case VehicleType.BUS:
            return (
                <Label id="truck" icon="rioglyph-bus">
                    {name}
                </Label>
            );
        default:
            return <Label id="unknown" icon="" />;
    }
};

const VehicleLabel: React.FC<{ vehicles: Vehicle[]; explicit?: boolean }> = ({ vehicles = [], explicit = false }) => {
    const first = get(vehicles, [0]);
    const total = vehicles.length || '-';
    const isSingle = vehicles.length === 1;

    if (explicit) {
        return vehicles.length ? (
            <ul style={{ listStyle: 'none' }} className="padding-0">
                {vehicles.map(vehicle => (
                    <li key={vehicle.vin || vehicle.vehicleId} className="margin-0 ellipsis-1">
                        <SingleVehicleLabel vehicle={vehicle} />
                    </li>
                ))}
            </ul>
        ) : (
            <span>-</span>
        );
    }

    return isSingle ? <SingleVehicleLabel vehicle={first} /> : <>{total}</>;
};

export default React.memo(VehicleLabel);
