import _ from 'lodash';
import { useContext, useState } from 'react';

import { WITH_DATA_COLOR, WITHOUT_DATA_COLOR } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { DataEntry, hasValue } from './utils';

//TODO : Rename this Component since it renders more than just days.
export default function Day({
    onHover,
    onClick,
    activeDay,
}: {
    onClick: (start: Date) => void;
    activeDay?: Date;
    onHover: (hover: { x: number; y: number; data: DataEntry } | null) => void;
}) {
    const {
        xScale: x,
        yScale: y,
        data,
        dimensions: { height, margin },
    } = useContext(GraphContext);
    const [hovered, setHovered] = useState<Date | undefined>();
    const calculateWidth = (index: number) => {
        let dayWidth = margin * 2;

        if (data.length > 1) {
            dayWidth = x(data[1].x) - x(data[0].x);

            switch (index) {
                case 0:
                case data.length - 1:
                    return dayWidth / 2 + margin;
            }
        }
        return dayWidth;
    };

    const transform = (d: DataEntry, index: number) => {
        let dayWidth = margin * 2;
        if (data.length > 1) {
            dayWidth = x(data[1].x) - x(data[0].x);
            if (index === 0) {
                return 'translate(0 0)';
            }
        }

        return `translate(${x(d.x) - dayWidth / 2} 0)`;
    };

    return (
        <g>
            {data.map((d, idx) => {
                const isHovered = hovered && hovered.getTime() === d.x.getTime();
                const isActive = activeDay && activeDay.getTime() === d.x.getTime();

                return (
                    <g key={idx} className="day cursor-pointer">
                        <rect
                            height={height}
                            stroke={hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR}
                            fill={hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR}
                            fillOpacity={0.1}
                            strokeWidth={1}
                            opacity={isActive || isHovered ? 1 : 0}
                            width={calculateWidth(idx)}
                            transform={transform(d, idx)}
                            onMouseOut={() => {
                                setHovered(undefined);
                                onHover(null);
                            }}
                            onMouseOver={() => {
                                setHovered(d.x);
                                onHover({
                                    x: x(d.x),
                                    y: !_.isUndefined(d.y) ? y(d.y) : 0,
                                    data: d,
                                });
                            }}
                            onClick={() => onClick(d.x)}
                        />
                    </g>
                );
            })}
        </g>
    );
}
