import { ReactNode } from 'react';

import { SignalValue, StatisticsReport } from '../../types';

type Values = { date: Date; value: SignalValue }[];
export type Data = StatisticsReport & { values: Values };

export type Section = {
    key: string;
    unit: string | undefined;
    title: React.ReactNode;
    stats: { average?: number; values?: any[] };
    tabValue: React.ReactNode;
    formatter: (value: any, data?: any) => ReactNode;
    values: Values;
};

export enum SummaryStatus {
    LOADING,
    LOADED,
    ERROR,
}

export type Loading = { type: SummaryStatus.LOADING };
export type Loaded = { type: SummaryStatus.LOADED; data: Data[] };
export type Errored = { type: SummaryStatus.ERROR; data: unknown };

export type Status = Loading | Loaded | Errored;
