import { ButtonDropdown } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';

import Label from '../../components/Label';
import DeleteConfiguration from './DeleteConfiguration';
import EditConfiguration from './EditConfiguration';
import { ReportRow } from './ReportTable';
import { ReportConfiguration, ReportStatus } from './types';

const configureConfigurationToRow = (activeReportId?: string) => (report: ReportConfiguration): ReportRow => ({
    id: report.id,
    active: report.id === activeReportId,
    name: <span>{report.name}</span>,
    reportType: <FormattedMessage id={`reports.reportTypes.${report.reportType}`} />,
    selection: ((report: ReportConfiguration) => {
        switch (report.type) {
            case 'assets':
                return (
                    <Label id="assets" icon="rioglyph-truck">
                        {report.assets.length}
                    </Label>
                );
            case 'groups':
                return (
                    <Label id="group" icon="rioglyph-group">
                        {report.groups.length}
                    </Label>
                );
            case 'drivers':
                return (
                    <Label id="drivers" icon="rioglyph-driver">
                        {report.drivers.length}
                    </Label>
                );
            case 'fleet':
                return <FormattedMessage id="reports.entireFleet" />;
        }
        return <></>;
    })(report),
    interval: (
        <span>
            {report.status === ReportStatus.disabled ? (
                <span className="label label-default label-condensed margin-right-5">
                    <FormattedMessage id="reports.inactive" />
                </span>
            ) : null}
            <FormattedMessage id={`reports.intervalTypes.${report.timeframe}`} />
        </span>
    ),
    actions: (
        <span onClick={event => event.stopPropagation()}>
            <ButtonDropdown
                title={<span className="rioglyph rioglyph-option-vertical" />}
                bsStyle={'link'}
                iconOnly
                items={[
                    {
                        value: (
                            <div>
                                <DeleteConfiguration reportId={report.id}>
                                    <div role="button" data-test="delete">
                                        <span className="rioglyph rioglyph-trash margin-right-10" />
                                        <FormattedMessage id="delete" />
                                    </div>
                                </DeleteConfiguration>
                            </div>
                        ),
                    },
                    {
                        value: <EditConfiguration reportId={report.id} />,
                    },
                ]}
            />
        </span>
    ),
});

export default configureConfigurationToRow;
