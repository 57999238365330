import { createSlice } from '@reduxjs/toolkit';

export const LOADING = 'loading';
export const LOADED = 'loaded';
export const FAILED = 'failed';

export const createId = (vehicleId: string[] = [], driverId: string[] = [], from = new Date(), to = new Date()) =>
    `${vehicleId.join(',')}-${driverId.join(',')}-${from.valueOf()}-${to.valueOf()}`;

const defaultState = {
    pendingDownloads: {},
};

const coDriverSlice = createSlice({
    name: 'coDriver',
    initialState: defaultState,
    reducers: {
        setDownloadStatus(state, { payload }) {
            return {
                ...state,
                pendingDownloads: {
                    ...state.pendingDownloads,
                    [payload.id]: payload.status,
                },
            };
        },
        setSelection(state, { payload }) {
            return {
                ...state,
                selection: {
                    id: payload?.id,
                    type: payload?.type,
                    entity: payload?.entity,
                },
            };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const { setDownloadStatus, setSelection } = coDriverSlice.actions;

export default coDriverSlice.reducer;
