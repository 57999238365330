import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import { fetchDrivers } from '../../../api';
import { backendConfig } from '../../../data/selectors';
import { ThenArg } from '../../../types';
import { getAccessToken } from '../../tokenHandling/selectors';
import { TREE_SETTINGS } from '../../tree/saga';
import { Settings } from '../reducer';

function* migrateSelectedDrivers(settings: Settings, oldSettingsVersion: number) {
    const selectedDrivers = settings[TREE_SETTINGS.SELECTED_DRIVERS];
    if (oldSettingsVersion < 2 && _.isArray(selectedDrivers) && !_.isEmpty(selectedDrivers)) {
        const authToken: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
        const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'DRIVERS_SERVICE');
        const drivers: ThenArg<ReturnType<typeof fetchDrivers>> = yield call(fetchDrivers, url, authToken);
        settings[TREE_SETTINGS.SELECTED_DRIVERS] = selectedDrivers
            .map(driverId => {
                const driver = _.find(drivers, ['legacyRioId', driverId]) || _.find(drivers, ['id', driverId]);
                return driver ? driver.id : null;
            })
            .filter(driverId => Boolean(driverId));
    }
    return settings;
}

export default migrateSelectedDrivers;
