import { Dispatch } from '@reduxjs/toolkit';
import { EmptyState } from '@rio-cloud/rio-uikit';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getDateRange } from '../../features/ui/selectors';
import Toolbar from '../../features/ui/Toolbar';
import { State } from '../../setup/types';
import { DateRange } from '../../types';
import { setSelection, startDownload } from './actions';
import DataLoader from './DataLoader';
import { DRIVER, getSelection, getStatus, getSuggestions, VEHICLE } from './selectors';
import Suggest from './Suggest';
import TableElement from './TableElement';
import { CoDriverDownloadPayload, Status } from './types';

interface AutoSuggestSuggestion {
    [name: string]: any;
}

export const CoDriver: React.FC<{
    intl: IntlFormatters;
    suggestions: AutoSuggestSuggestion[];
    setSelection: (suggestions?: AutoSuggestSuggestion) => void;
    selection: AutoSuggestSuggestion;
    startDownload: (payload: CoDriverDownloadPayload) => void;
    status: Record<string, Status>;
    dateRange: DateRange;
}> = ({ intl, suggestions, setSelection, selection, startDownload, status, dateRange }) => {
    const placeholderMessage = intl.formatMessage({ id: 'selectVehicleOrDriver' });

    const vehicles = selection?.type === VEHICLE ? [selection.id] : [];
    const drivers = selection?.type === DRIVER ? [selection.id] : [];
    return (
        <div data-test="CoDriver">
            <Toolbar showLabel={true}>
                <div>
                    <label className="table-toolbar-label">
                        <FormattedMessage id="vehicleOrDriver" />
                    </label>
                    <Suggest
                        suggestions={suggestions}
                        placeholderMessage={placeholderMessage}
                        setSelection={setSelection}
                    />
                </div>
            </Toolbar>
            {selection?.type ? (
                <DataLoader
                    vehicles={vehicles}
                    drivers={drivers}
                    dateRange={dateRange}
                    selection={selection}
                    startDownload={startDownload}
                    status={status}
                >
                    <TableElement
                        startDownload={startDownload}
                        selection={selection}
                        dateRange={dateRange}
                        status={status}
                    />
                </DataLoader>
            ) : (
                <div>
                    <EmptyState headline={<FormattedMessage id="subModuleName.coDriver" />} />
                </div>
            )}
        </div>
    );
};

export const mapStateToProps = (state: State) => {
    return {
        suggestions: getSuggestions(state),
        selection: getSelection(state),
        status: getStatus(state),
        dateRange: getDateRange(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setSelection: (selection: AutoSuggestSuggestion) => dispatch(setSelection(selection)),
        startDownload: (params: CoDriverDownloadPayload) => dispatch(startDownload(params)),
    };
};

export default compose<React.FC>(injectIntl, connect(mapStateToProps, mapDispatchToProps))(CoDriver);
