import _ from 'lodash';
import moment from 'moment';

const getMinDateRangeValue = tenDaysLimit => {
    return tenDaysLimit
        ? moment()
              .subtract(10, 'days')
              .startOf('day')
              .toDate()
        : null;
};

const getMaxDateRangeValue = isTodayAllowed => {
    if (isTodayAllowed) {
        return moment()
            .endOf('day')
            .toDate();
    }
    return moment()
        .subtract(1, 'days')
        .endOf('day')
        .toDate();
};

const getMaxOf = (tenDaysLimit, min, max) => {
    if (!min) {
        return max;
    }
    return tenDaysLimit && moment(min).isAfter(max) ? min : max;
};

export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const CURRENT_WEEK = 'currentWeek';
export const LAST_10_DAYS = 'last10Days';
export const LAST_14_DAYS = 'last14Days';
export const CURRENT_MONTH = 'currentMonth';
export const LAST_WEEK = 'lastWeek';
export const LAST_MONTH = 'lastMonth';
export const LAST_YEAR = 'lastYear';
const knownPresets = [
    TODAY,
    YESTERDAY,
    CURRENT_WEEK,
    LAST_10_DAYS,
    LAST_14_DAYS,
    CURRENT_MONTH,
    LAST_WEEK,
    LAST_MONTH,
    LAST_YEAR,
];
const allRangePresets = _.reduce(
    knownPresets,
    (result, preset) => {
        result[preset] = true;
        return result;
    },
    {}
);

export const presets = {
    [TODAY]: {
        startValue: () =>
            moment()
                .startOf('day')
                .toDate(),
        endValue: isTodayAllowed => getMaxDateRangeValue(isTodayAllowed),
    },
    [YESTERDAY]: {
        startValue: () =>
            moment()
                .subtract(1, 'days')
                .startOf('day')
                .toDate(),
        endValue: () =>
            moment()
                .subtract(1, 'days')
                .endOf('day')
                .toDate(),
    },
    [CURRENT_WEEK]: {
        startValue: () =>
            moment()
                .startOf('isoweek')
                .toDate(),
        endValue: isTodayAllowed => getMaxDateRangeValue(isTodayAllowed),
    },
    [LAST_10_DAYS]: {
        startValue: () =>
            moment()
                .subtract(10, 'days')
                .startOf('day')
                .toDate(),
        endValue: () =>
            moment()
                .subtract(1, 'days')
                .endOf('day')
                .toDate(),
    },
    [LAST_14_DAYS]: {
        startValue: tenDaysLimit =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(14, 'days')
                    .startOf('day')
                    .toDate()
            ),

        endValue: () =>
            moment()
                .subtract(1, 'days')
                .endOf('day')
                .toDate(),
    },
    [CURRENT_MONTH]: {
        startValue: tenDaysLimit =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .startOf('month')
                    .toDate()
            ),
        endValue: isTodayAllowed => getMaxDateRangeValue(isTodayAllowed),
    },
    [LAST_WEEK]: {
        startValue: tenDaysLimit =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(1, 'weeks')
                    .startOf('isoweek')
                    .toDate()
            ),
        endValue: () =>
            moment()
                .subtract(1, 'weeks')
                .endOf('isoweek')
                .toDate(),
    },
    [LAST_MONTH]: {
        startValue: tenDaysLimit =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(1, 'months')
                    .startOf('month')
                    .toDate()
            ),
        endValue: () =>
            moment()
                .subtract(1, 'months')
                .endOf('month')
                .toDate(),
    },
    [LAST_YEAR]: {
        startValue: tenDaysLimit =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(1, 'years')
                    .startOf('year')
                    .toDate()
            ),
        endValue: () =>
            moment()
                .subtract(1, 'years')
                .endOf('year')
                .toDate(),
    },
};

export const generateDateRangeConfig = ({
    featureFlags = allRangePresets,
    tenDaysLimit = false,
    isTodayAllowed = true,
    hasTimePicker = true,
} = {}) =>
    Object.freeze({
        min: getMinDateRangeValue(tenDaysLimit),
        max: getMaxDateRangeValue(isTodayAllowed),
        hasTimePicker,
        presets: knownPresets
            .filter(key => _.has(featureFlags, key))
            .map(key => {
                const preset = presets[key];
                const startValue = preset.startValue(tenDaysLimit);
                const endValue = preset.endValue(isTodayAllowed);
                return {
                    key,
                    translationKey: `history.dateRange.${key}`,
                    startValue,
                    endValue,
                    disabled: !featureFlags[key] || !moment(startValue).isBefore(endValue),
                };
            }),
    });

export const dayChangeDetection = () => moment().day();

export const getDefaultDateRangeConfig = _.memoize(() => generateDateRangeConfig(), dayChangeDetection);
