import { Dialog, ListMenu, SplitDialog } from '@rio-cloud/rio-uikit';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import Faq from './Faq';
import GlossaryItem from './Glossary';
import HelpLink from './HelpLink';
import LanguageData from './LanguageData';
import Welcome from './Welcome';

const header = <FormattedMessage id="help.header" defaultMessage="Help" />;

const createHeaderLink = ({ id, title }: { id: string; title: string }) => ({
    groupNavItem: (
        <HelpLink id={id}>
            <FormattedMessage id={title} />
        </HelpLink>
    ),
    navItems: [{ key: id, item: null }],
});

const createMenuLinks = (languageData: { items: { title: string; id: string }[] }) => {
    return languageData.items.map(({ title, id }) => ({
        key: id,
        item: (
            <HelpLink id={id}>
                <FormattedMessage id={title} />
            </HelpLink>
        ),
    }));
};

function GlossaryRoutes({
    languageData,
}: {
    languageData: { items: { id: string; description: string; title: string }[] };
}) {
    const items = languageData.items;
    return (
        <Switch>
            {items.map(({ id, description, title }) => (
                <Route
                    exact
                    path={`/${id}`}
                    key={id}
                    render={() => <GlossaryItem title={title} description={description} />}
                />
            ))}
        </Switch>
    );
}

export const HelpDialog = ({
    show,
    intl: { messages },
    toggleHelpDialog,
}: {
    show?: boolean;
    intl: any;
    toggleHelpDialog: () => void;
}) => {
    if (!show) {
        return null;
    }

    const formattedFaqLanguageData = LanguageData(messages, 'help.faq');
    const formattedGlossaryLanguageData = LanguageData(messages, 'help.glossar');

    const welcomeItems = createHeaderLink({ id: '', title: 'help.title.welcome' });
    const faqItems = createHeaderLink({ id: 'help/faq', title: 'help.title.faq' });
    const glossaryItems = {
        group: <FormattedMessage id="help.title.glossary" defaultMessage="Glossary" />,
        navItems: createMenuLinks(formattedGlossaryLanguageData),
    };

    const leftContent = (
        <div data-test="HelpNavigation">
            <ListMenu menuItems={[welcomeItems, faqItems, glossaryItems]} />
        </div>
    );

    const rightContent = (
        <div data-test="HelpContent" className="position-sticky top-0">
            <Route exact path="/" render={() => <Welcome />} />
            <Route exact path="/help/faq" render={() => <Faq languageData={formattedFaqLanguageData} />} />
            <GlossaryRoutes languageData={formattedGlossaryLanguageData} />
        </div>
    );

    return (
        <MemoryRouter>
            <SplitDialog
                show={true}
                bsSize={Dialog.SIZE_LG}
                title={header}
                leftContent={leftContent}
                rightContent={rightContent}
                onClose={toggleHelpDialog}
            />
        </MemoryRouter>
    );
};

export default injectIntl(HelpDialog);
