import React from 'react';
import ContentLoader from 'react-content-loader';
const SkeletonLoader: React.FC<{ height?: string; yOffset?: number }> = ({
    children,
    height = '100%',
    yOffset = 0,
}) => {
    return (
        <div className="position-relative" data-test="skeleton-loader">
            <span style={{ userSelect: 'none', color: 'transparent' }}>
                <span style={{ visibility: 'hidden' }}>{children}</span>
                <ContentLoader className="position-absolute" style={{ height: '100%', width: '100%', top: 0, left: 0 }}>
                    <rect rx="3" ry="3" y={yOffset} width="80%" height={height} />
                </ContentLoader>
            </span>
        </div>
    );
};

export default SkeletonLoader;
