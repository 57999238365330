import _ from 'lodash';
import moment from 'moment';

export type DataEntry = { x: Date; y: any | undefined; interpolated?: boolean };

export const hasValue = (d: DataEntry) => _.isNumber(d.y);

export type TimeSegmentUnit = 'hours' | 'day' | 'week' | 'month' | 'year';

export const calculateDateRange = (unit: TimeSegmentUnit) => (start: Date, end: Date) => {
    const step = Math.max(Math.floor(Math.log(Math.abs(moment(start).diff(end, unit)))), 1);
    return _.range(0, moment(end).diff(start, unit) + 1, step).map(e =>
        moment(start)
            .add(e, unit)
            .toDate()
    );
};
