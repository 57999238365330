import { StatusBar } from '@rio-cloud/rio-uikit';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';

import { Entity } from '../../api/models/types';
import { Column as ColumnType } from '../../columns/createColumn';
import Column from '../../components/Column';
import { getRatingCSSColor } from '../../utils/rating';

const DisciplineTitle = ({ title, entity }: { title: ColumnType; entity: Entity }) => {
    const value = title.valueExtractor(entity);
    const { color: textColor, background } = getRatingCSSColor(value);
    const intl = useIntl();
    return (
        <div className="display-flex align-items-center">
            <div className={`${title.icon} rioglyph text-size-18`} />
            <div className="width-200 ellipsis-1 margin-left-10 margin-right-10 text-color-dark">
                <FormattedMessage id={title.labelId} />
            </div>
            <StatusBar
                label={{
                    className: 'text-right width-45 margin-left-10',
                    value: title.formatter(value),
                    alignment: 'right',
                    color: textColor,
                }}
                progress={[
                    {
                        percentage: value,
                        color: background,
                        tooltip: (
                            <IntlProvider locale={intl.locale} messages={intl.messages}>
                                <Column column={title} entity={entity} />
                            </IntlProvider>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default DisciplineTitle;
