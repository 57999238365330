import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { VEHICLE_ROUTE } from '../../constants/routes';
import { getSelectedVehicleIds } from '../../data/selectors';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { hideSidebar, showSidebar } from '../../features/ui/reducer';
import { getActiveDateRange, getDateRange } from '../../features/ui/selectors';
import Content from './Content';
import { getSelectedUseCase, getUseCaseSetting } from './selectors';
import VehicleContentLoader from './VehicleContentLoader';

export const Entry = ({
    onShowDetails,
    onHideDetails,
    vehicles = [],
    dateRange = {},
    performVehicles = [],
    activeDateRange,
}) => {
    const [previousVehicleIds, setPreviousVehicleIds] = useState(vehicles);

    const constraints = JSON.stringify([vehicles, previousVehicleIds]);

    useEffect(() => {
        if (!_.isEqual(previousVehicleIds, vehicles)) {
            onHideDetails();
        }
        setPreviousVehicleIds(vehicles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constraints]);

    return (
        <div>
            <VehicleContentLoader vehicles={vehicles} dateRange={activeDateRange} performVehicles={performVehicles}>
                <Content dateRange={dateRange} vehicles={vehicles} onItemClick={onShowDetails} />
            </VehicleContentLoader>
        </div>
    );
};

export const mapDispatchToProps = dispatch => ({
    onHideDetails: () => dispatch(hideSidebar()),
    onShowDetails: data => dispatch(showSidebar({ data, type: VEHICLE_ROUTE })),
});

export default connect(
    state => ({
        vehicles: getSelectedVehicleIds(state),
        dateRange: getDateRange(state),
        activeDateRange: getActiveDateRange(state),
        performVehicles: getPerformVehicles(state),
    }),
    mapDispatchToProps
)(Entry);
