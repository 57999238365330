import { Id, ReportConfiguration } from '../../api';

// eslint-disable-next-line import/no-unused-modules,prettier/prettier
export type { ReportConfiguration, Id } from '../../api';
/* eslint-disable-next-line import/no-unused-modules */
export { ReportStatus, ReportType, ReportTimeframe } from '../../api';

export enum Status {
  Ready,
  Loading,
  Failed,
  Loaded,
}

export type ReportForm = Omit<ReportConfiguration, 'status' | 'groups' | 'drivers' | 'assets'> & {
  entities: { items: Id[]; groups: Id[] };
  status?: boolean;
};