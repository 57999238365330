import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadSettingsSuccess as settingsLoaded } from '../../features/settings/reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from '../../features/settings/saga';
import { configureReporting } from '../../setup/errorReporting';
import { setColumnOrderForUseCase, setFilteredColumnNamesForUseCase, setSortedBy, setUseCase } from './actions';
import { getUseCaseConfig } from './selectors';
import { getUseCaseConfigForKey } from './useCaseConfig';

const { captureException } = configureReporting(window, process.env);

export const VEHICLE_ANALYSIS_STORAGE_KEYS = {
    FILTERED_COLUMNS: 'vehicleAnalysisFilteredColumnNames',
    COLUMN_ORDER: 'vehicleAnalysisColumnOrder',
    USE_CASE: 'vehicleAnalysisUseCase',
    SORT_BY: 'vehicleAnalysisSortBy',
};

const mergeSettings = (oldValue, newValue) => ({ ...oldValue, ...newValue });

export function* clearAllSettings() {
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS, {});
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER, {});
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.USE_CASE, '');
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.SORT_BY, '');
}

export function* saveUseCase({ payload }) {
    try {
        yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.USE_CASE, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSortedBy({ payload }) {
    try {
        yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.SORT_BY, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveFilteredColumnNames({ payload }) {
    try {
        const { useCaseKey, filteredColumnNames } = payload;
        const key = VEHICLE_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS;
        const savedData = yield loadSettingsFromStorage(key);
        yield saveSettingsToStorage(key, mergeSettings(savedData, { [useCaseKey]: filteredColumnNames }));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveColumnOrder({ payload }) {
    try {
        const { useCaseKey, columnOrder } = payload;
        const key = VEHICLE_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER;
        const savedData = yield loadSettingsFromStorage(key);
        yield saveSettingsToStorage(key, mergeSettings(savedData, { [useCaseKey]: columnOrder }));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const useCase = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.USE_CASE, null);
        const sortBy = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.SORT_BY, null);
        const columnOrders = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER, []);
        const filteredNames = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS, []);

        const useCaseConfig = yield select(getUseCaseConfig);
        if (getUseCaseConfigForKey(useCase, useCaseConfig)) {
            yield put(setUseCase(useCase));
        }

        if (sortBy) {
            yield put(setSortedBy(sortBy));
        }

        yield all(
            Object.entries(columnOrders)
                .filter(([, order]) => _.isArray(order))
                .map(([useCaseKey, columnOrder]) => put(setColumnOrderForUseCase({ useCaseKey, columnOrder })))
        );

        yield all(
            Object.entries(filteredNames)
                .filter(([, order]) => _.isArray(order))
                .map(([useCaseKey, columnOrder]) =>
                    put(setFilteredColumnNamesForUseCase({ useCaseKey, filteredColumnNames: columnOrder }))
                )
        );
    } catch (e) {
        yield clearAllSettings();
        yield call(captureException, e);
    }
}

export default function* root() {
    yield takeLatest(setUseCase, saveUseCase);
    yield takeLatest(setSortedBy, saveSortedBy);
    yield takeLatest(setColumnOrderForUseCase, saveColumnOrder);
    yield takeLatest(setFilteredColumnNamesForUseCase, saveFilteredColumnNames);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
