import VehicleLabel from '../../components/VehicleLabel';
import { Vehicle } from '../../types';

interface VehicleRow {
    id: string;
    vehicles: Vehicle[];
}

const ClickableVehicleList = ({
    onClick,
    rows,
}: {
    onClick: (vehicle: VehicleRow) => void;
    rows: VehicleRow[];
}): JSX.Element => {
    return (
        <div className="text-size-18 ellipsis-1">
            <ul className="padding-0" style={{ listStyle: 'none' }} data-test="clickable-vehicles">
                {rows.map(vehicle => (
                    <li
                        className="hover-text-color-highlight cursor-pointer"
                        data-test="vehicle"
                        key={vehicle.id}
                        onClick={() => onClick(vehicle)}
                    >
                        <VehicleLabel vehicles={vehicle.vehicles} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClickableVehicleList;
