import { get } from 'lodash';

import { State } from '../../setup/types';
import { DEFAULT_LOCALE } from './lang';

export const getLocale = (state: State) => get(state, 'lang.displayLocale', DEFAULT_LOCALE);

export const getLangLocale = (state: State) => get(state, 'lang.displayLocale', DEFAULT_LOCALE);

export const getLanguageData = (state: State) => get(state, 'lang.displayMessages');

export const getSupportedLocale = (state: State) => get(state, 'lang.supportedLocale');

export const getAvailableLocales = (state: State) => get(state, 'lang.availableLocales');
