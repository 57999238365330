import { AssetTree, Tree as RioTree, TreeCategory, TreeOption } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { unassignedGroup } from '../../api';
import { DRIVER_ROUTE, VEHICLE_ROUTE } from '../../constants/routes';
import { TREE_TABS } from '../../constants/treeTabs';
import { getFeatureToggles } from '../permissions/selectors';
import { isMobile } from '../ui/reducer';
import {
    selectDriverTab,
    selectVehicleTab,
    setSelectedDriverGroups,
    setSelectedDrivers,
    setSelectedVehicleGroups,
    setSelectedVehicles,
    setShowEmptyGroups,
} from './actions';
import {
    getActiveTab,
    getSelectedDriverGroupIds,
    getSelectedDriverIds,
    getSelectedVehicleGroupIds,
    getSelectedVehicleIds,
    showEmptyGroups,
} from './selectors';
import { getActiveDriverList, getActiveVehicleList, getGroupList } from './uiSelectors';

export const Tree = ({
    history,
    location,
    intl,
    groups = [],
    vehicles = [],
    drivers = [],
    selectedDriverIds,
    selectedVehicleIds,
    selectedVehicleGroupIds,
    selectVehiclesAndGroups,
    selectDriversAndGroups,
    selectTreeVehicleTab,
    selectTreeDriverTab,
    selectedDriverGroupIds,
    categories = [],
    currentCategory,
    setShowEmptyGroups,
    showEmptyGroups,
    isMobile,
}) => {
    const pathname = location.pathname;
    const [isTreeOpen, handleToggleTree] = useState(!isMobile); /*If we're on mobile we hide the tree*/
    const [expandedDriverGroups, onExpandedDriverGroups] = useState([]);
    const [expandedVehicleGroups, onExpandedVehicleGroups] = useState([]);
    const handleToggleEmptyGroups = useCallback(() => setShowEmptyGroups(!showEmptyGroups), [showEmptyGroups]);

    useEffect(() => {
        if (pathname.includes(DRIVER_ROUTE)) {
            selectTreeDriverTab();
        }
        if (pathname.includes(VEHICLE_ROUTE)) {
            selectTreeVehicleTab();
        }
    }, [pathname, selectTreeDriverTab, selectTreeVehicleTab]);

    const changeCategory = category => {
        switch (category) {
            case TREE_TABS.VEHICLES:
                selectTreeVehicleTab(TREE_TABS.VEHICLES);
                history.push(VEHICLE_ROUTE);
                break;
            case TREE_TABS.DRIVER:
                selectTreeDriverTab(TREE_TABS.DRIVER);
                history.push(DRIVER_ROUTE);
                break;
        }
    };

    const mappedGroups = groups.map(group =>
        group.id === unassignedGroup.id
            ? {
                  ...group,
                  name: <FormattedMessage id="intl-msg:asset-tree.groups.ungrouped" />,
              }
            : group
    );

    const options = [
        <TreeOption
            key="intl-msg:asset-tree.option.showEmptyGroups"
            label={<FormattedMessage id="intl-msg:asset-tree.option.showEmptyGroups" />}
            isChecked={showEmptyGroups}
            onChange={handleToggleEmptyGroups}
        />,
    ];

    const treeCategories = _.intersection(categories, Object.values(TREE_TABS)).map(category => {
        switch (category) {
            case TREE_TABS.VEHICLES:
                return (
                    <TreeCategory
                        key={TREE_TABS.VEHICLES}
                        id={TREE_TABS.VEHICLES}
                        icon="rioglyph-truck"
                        hasSelection={!_.isEmpty(selectedVehicleIds) || !_.isEmpty(selectedVehicleGroupIds)}
                    >
                        <RioTree
                            groups={mappedGroups}
                            expandedGroups={expandedVehicleGroups}
                            items={vehicles}
                            onExpandGroupsChange={onExpandedVehicleGroups}
                            onSelectionChange={selectVehiclesAndGroups}
                            searchPlaceholder={intl.formatMessage({ id: 'find.vehicle' })}
                            selectedGroups={selectedVehicleGroupIds}
                            selectedItems={selectedVehicleIds}
                            showEmptyGroups={showEmptyGroups}
                            treeOptions={options}
                        />
                    </TreeCategory>
                );
            case TREE_TABS.DRIVER:
                return (
                    <TreeCategory
                        key={TREE_TABS.DRIVER}
                        id={TREE_TABS.DRIVER}
                        icon="rioglyph-driver"
                        hasSelection={!_.isEmpty(selectedDriverIds) || !_.isEmpty(selectedDriverGroupIds)}
                    >
                        <RioTree
                            groups={mappedGroups}
                            expandedGroups={expandedDriverGroups}
                            items={drivers}
                            onExpandGroupsChange={onExpandedDriverGroups}
                            onSelectionChange={selectDriversAndGroups}
                            searchPlaceholder={intl.formatMessage({ id: 'find.driver' })}
                            selectedGroups={selectedDriverGroupIds}
                            selectedItems={selectedDriverIds}
                            showEmptyGroups={showEmptyGroups}
                            treeOptions={options}
                        />
                    </TreeCategory>
                );
        }
    });

    return (
        <AssetTree
            fly={isMobile && isTreeOpen}
            minWidth={300}
            maxWidth={450}
            currentCategoryId={currentCategory}
            onCategoryChange={changeCategory}
            isOpen={isTreeOpen}
            onToggleTree={handleToggleTree}
        >
            {treeCategories}
        </AssetTree>
    );
};

export const mapDispatchToProps = dispatch => ({
    selectVehiclesAndGroups: ({ items, groups }) => {
        dispatch(setSelectedVehicles(items));
        dispatch(setSelectedVehicleGroups(groups));
    },
    selectDriversAndGroups: ({ items, groups }) => {
        dispatch(setSelectedDrivers(items));
        dispatch(setSelectedDriverGroups(groups));
    },
    selectTreeDriverTab: () => dispatch(selectDriverTab()),
    selectTreeVehicleTab: () => dispatch(selectVehicleTab()),
    setShowEmptyGroups: isShown => dispatch(setShowEmptyGroups(isShown)),
});

const mapStateToProps = state => ({
    vehicles: getActiveVehicleList(state),
    groups: getGroupList(state),
    drivers: getActiveDriverList(state),
    selectedVehicleGroupIds: getSelectedVehicleGroupIds(state),
    selectedDriverGroupIds: getSelectedDriverGroupIds(state),
    selectedVehicleIds: getSelectedVehicleIds(state),
    selectedDriverIds: getSelectedDriverIds(state),
    showEmptyGroups: showEmptyGroups(state),
    currentCategory: getActiveTab(state),
    isMobile: isMobile(state),
});

export default compose(injectIntl, withRouter, connect(mapStateToProps, mapDispatchToProps))(Tree);
