import {
    ReportConfiguration,
    ReportConfigurationResponse,
    ReportConfigurationResponseType,
    ReportConfigurationSingleResponse,
    ReportConfigurationSingleResponseType,
    TargetType,
} from '../models/reportConfiguration';
import { decodeJson } from './decoder';

export function decodeSingleReportConfiguration(raw: ReportConfigurationSingleResponseType): ReportConfiguration {
    const decoded = decodeJson(ReportConfigurationSingleResponse, raw);
    return transformReportConfiguration(decoded);
}

export function decodeReportConfigurations(raw: ReportConfigurationResponseType): ReportConfiguration[] {
    const decoded: ReportConfigurationResponseType = decodeJson(ReportConfigurationResponse, raw);
    return decoded.items.map(transformReportConfiguration);
}

function transformReportConfiguration(decoded: ReportConfigurationSingleResponseType): ReportConfiguration {
    const defaults = { ...decoded };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete defaults.target;

    if ('drivers' in decoded.target) {
        return {
            ...defaults,
            type: TargetType.drivers,
            drivers: decoded.target.drivers,
        };
    }
    if ('groups' in decoded.target) {
        return {
            ...defaults,
            type: TargetType.groups,
            groups: decoded.target.groups,
        };
    }
    if ('assets' in decoded.target) {
        return {
            ...defaults,
            type: TargetType.assets,
            assets: decoded.target.assets,
        };
    }
    return {
        ...defaults,
        type: TargetType.fleet,
    };
}
