import release4Image from '../static/release-4.0.0.png';

const releaseNotes = {
    '4.0.0': {
        date: '2021-05-05T00:00:00',
        content: [
            <div key="releaseNotes.4.0.0">
                <p>
                    In der Sidebar finden Sie jetzt für MAN Fahrzeuge mit aktiver Perform-Buchung Details zum Fahrzeug
                    im gleichnamigen Register:
                </p>
                <ul>
                    <li>
                        u.a. finden Sie Informationen zur Motorleistung, Getriebetyp, Hinterachsübersetzung,
                        Schadstoffklasse, Kraftstofftank
                    </li>
                    <li>die Anzeige kann nur für ein ausgewähltes Fahrzeug erfolgen</li>
                    <li>die Information ist sowohl in der Fahrzeug- als auch Fahreransicht verfügbar</li>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <li>bitte beachten Sie, dass einzelne Informationen ggf. nicht verfügbar sind (Anzeige "-") </li>
                </ul>
                <p></p>
                <img
                    src={release4Image}
                    width={640}
                    className="border-style-solid border-width-1 border-color-lighter display-inline-block"
                />
            </div>,
        ],
    },
};

export default releaseNotes;
