import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export default function Welcome() {
    return (
        <Fragment>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.welcome.title" />
            </h3>
            <div className="position-sticky top-0" style={{ whiteSpace: 'pre-line' }}>
                <FormattedMessage id="help.welcome.description" />
            </div>
        </Fragment>
    );
}
