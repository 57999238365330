import createColumn, { Column } from '../../columns/createColumn';
import { Driver } from '../../types';
import { columns, getColumn } from '../../utils/columns';
import ClickableDriverList from './ClickableDriverList';
import DriverLabel from './DriverLabel';

export const driverNames = (drivers: Driver[], data: any) => <DriverLabel drivers={drivers} data={data} />;

const replacements: { [key: string]: Record<string, unknown> } = {
    drivers: { formatter: driverNames },
};

export const tableColumns = columns.map(column => {
    const replacementColumnProps = replacements[column.key];
    return replacementColumnProps ? createColumn({ ...column, ...replacementColumnProps }) : column;
});

// eslint-disable-next-line react/display-name
export const driverNamesList = (onDriverNameClick: (driver: unknown) => void) => (
    drivers: Driver[],
    data: { children?: { id: string }[] }
) => <ClickableDriverList onClick={onDriverNameClick} drivers={data.children ? data.children : []} />;

export const getTopColumns = (
    isDriverNameClickable: boolean,
    onDriverNameClick: (driver: unknown) => void,
    hasPerformVehicle: boolean
) => {
    const defaultDriversColumn = (hasPerformVehicle ? getColumn('explicitDrivers') : getColumn('drivers')) as Column;
    const driverColumn = isDriverNameClickable
        ? createColumn({
              ...defaultDriversColumn,
              formatter: driverNamesList(onDriverNameClick),
          })
        : defaultDriversColumn;
    return [getColumn('vehicle'), driverColumn];
};
