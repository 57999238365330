import { createSlice } from '@reduxjs/toolkit';

const LAST_SEEN_VERSION = 'last_seen_version';

const shouldShowChangeDialog = () => {
    try {
        return !(localStorage.getItem(LAST_SEEN_VERSION) === process.env.SERVICE_VERSION);
    } catch (e) {
        return false;
    }
};

const initialState = {
    showHelpDialog: false,
    showChangeDialog: shouldShowChangeDialog(),
};

const serviceInfoSlice = createSlice({
    name: 'serviceInfo',
    initialState,
    reducers: {
        toggleHelpDialog(state) {
            return {
                ...state,
                showHelpDialog: !state.showHelpDialog,
            };
        },
        toggleChangeDialog(state) {
            // Side effect placed here. We normally shouldn't do this here.
            localStorage.setItem(LAST_SEEN_VERSION, process.env.SERVICE_VERSION as string);
            return {
                ...state,
                showChangeDialog: !state.showChangeDialog,
            };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const { toggleHelpDialog, toggleChangeDialog } = serviceInfoSlice.actions;

export default serviceInfoSlice.reducer;
