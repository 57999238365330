import { fetchGetWithJSONResponse } from './api';
import { AssetApiResponseType, AssociationApiResponseType, decodeVehicle } from './decoder/vehicleDecoder';

export async function fetchVehicles(baseUrlAssets: string, authToken: string) {
    const assetRequest = fetchGetWithJSONResponse(baseUrlAssets, `assets?embed=(tags)`, authToken);
    const associationsRequest = fetchGetWithJSONResponse(baseUrlAssets, `associations?embed=(device)`, authToken);

    const [rawAssets, rawAssociations]: [AssetApiResponseType, AssociationApiResponseType] = await Promise.all([
        assetRequest,
        associationsRequest,
    ]);

    return decodeVehicle(rawAssets, rawAssociations);
}
