import _ from 'lodash';

import { getSignal, Id } from '../../api';
import ErrorBoundary from '../../components/ErrorBoundry';
import { DateRange } from '../../types';
import AccessControl from '../permissions/AccessControl';
import { SEE_MAP } from '../permissions/hasPermissions';
import DrivenElevation from './DrivenElevation';
import ElevationGraph from './ElevationGraph';
import EmptyOpConState from './EmptyOpConState';
import GetBasicOpConData from './GetBasicOpConData';
import isBelowMileageThreshold from './isBelowMileageThreshold';
import { OpCon } from './types';

interface Props {
    opCon: OpCon;
    mileageFromPerformance: number;
    dateRange: DateRange;
    vehicleIds: Id[];
    driverIds: Id[];
}

export const Elevation = ({ opCon, mileageFromPerformance, dateRange, vehicleIds, driverIds }: Props) => {
    const operationRange = getSignal(opCon, 'range', {});
    const topology = getSignal(opCon, 'topology', {});

    if (
        isBelowMileageThreshold(_.get(topology, 'evaluatedMileage', 0), mileageFromPerformance) ||
        isBelowMileageThreshold(_.get(operationRange, 'evaluatedMileage', 0), mileageFromPerformance)
    ) {
        return <EmptyOpConState />;
    }

    return (
        <div data-test="elevation">
            <ErrorBoundary>
                <AccessControl requiredPermissions={[SEE_MAP]}>
                    <ElevationGraph dateRange={dateRange} vehicleIds={vehicleIds} driverIds={driverIds} />
                </AccessControl>
                <DrivenElevation opCon={opCon} />
            </ErrorBoundary>
        </div>
    );
};

const SelfLoadingElevation = ({
    dateRange,
    driverIds,
    vehicleIds,
    ...props
}: {
    dateRange: DateRange;
    driverIds: Id[];
    vehicleIds: Id[];
} & any) => (
    <GetBasicOpConData start={dateRange.start} end={dateRange.end} driverIds={driverIds} vehicleIds={vehicleIds}>
        <Elevation {...props} dateRange={dateRange} vehicleIds={vehicleIds} driverIds={driverIds} />
    </GetBasicOpConData>
);

export default SelfLoadingElevation;
