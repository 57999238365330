import { CustomState, TableToolbar, TableViewToggles } from '@rio-cloud/rio-uikit';
import { TableViewTogglesValues } from '@rio-cloud/rio-uikit/lib/types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { matchPath, Route, useHistory, useLocation } from 'react-router-dom';

import SimpleErrorState from '../../components/SimpleErrorState';
import SkeletonLoader from '../../components/SkeletonLoader';
import { CREATE_REPORT_ROUTE, EDIT_REPORT_ROUTE, REPORT_ROUTE } from '../../constants/routes';
import { useAppDispatch } from '../../setup/store';
import configureConfigurationToRow from './configureConfigurationToRow';
import CreateReportDialog from './CreateReportDialog';
import { getReports, getStatus, loadReportConfigurations } from './reducer';
import ReportTable, { ReportRow } from './ReportTable';
import { ReportConfiguration, Status } from './types';

const createLoadingColumn = () => {
    const skeleton = <SkeletonLoader>loading ..</SkeletonLoader>;
    return {
        id: Math.random().toString(),
        name: skeleton,
        reportType: skeleton,
        selection: skeleton,
        interval: skeleton,
        actions: skeleton,
        active: false,
    };
};

const loading = [createLoadingColumn(), createLoadingColumn(), createLoadingColumn()];

const Centered: React.FC = ({ children, ...rest }) => (
    <div {...rest} className="display-flex justify-content-center max-width-100pct margin-top-15pct">
        {children}
    </div>
);

function useActiveReportIdFromLocation() {
    const location = useLocation();
    return (matchPath(location.pathname, { path: `${EDIT_REPORT_ROUTE}/:id` })?.params as {
        id?: string;
    })?.id;
}

// is lazy loaded
// eslint-disable-next-line import/no-unused-modules
export default function Entry() {
    // filter for hidden columns
    const [viewType, setViewType] = useState<TableViewTogglesValues>(TableViewToggles.VIEW_TYPE_TABLE);
    const history = useHistory();
    const dispatcher = useAppDispatch();
    const openCreateForm = () => history.replace(CREATE_REPORT_ROUTE);
    const closeForm = () => history.replace(REPORT_ROUTE);
    const activeReportId = useActiveReportIdFromLocation();
    const openEditForm = (row: ReportRow) => {
        const newPath = `${EDIT_REPORT_ROUTE}/${row.id}`;
        if (row.id === activeReportId) {
            history.replace(REPORT_ROUTE);
        } else {
            history.replace(newPath);
        }
    };

    useEffect(() => {
        dispatcher(loadReportConfigurations());
    }, []);

    const configurationToRow = configureConfigurationToRow(activeReportId);
    const reports: ReportConfiguration[] = useSelector(getReports);
    const status = useSelector(getStatus);

    const isEmpty = status === Status.Loaded && !reports.length;
    const hasErrored = status === Status.Failed;

    if (hasErrored) {
        return (
            <Centered data-test="error-state">
                <SimpleErrorState fullWidth headlineId={'error.default'} messageId={'error.server'} />
            </Centered>
        );
    }

    const EmptyView = () => (
        <Centered data-test="empty-state">
            <CustomState
                headline={<FormattedMessage id="reports.noReportsAvailable.title" />}
                message={<FormattedMessage id="reports.noReportsAvailable.message" />}
                icons={[
                    {
                        name: 'rioglyph rioglyph-pdf-file ',
                        className: 'text-size-300pct',
                    },
                ]}
                buttons={[
                    {
                        text: <FormattedMessage id="reports.createNew" />,
                        onClick: openCreateForm,
                    },
                ]}
            />
        </Centered>
    );

    const TableView = () => (
        <>
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">
                            <div className="btn-toolbar table-btn-toolbar">
                                <button data-test="create-new" className="btn btn-primary" onClick={openCreateForm}>
                                    <FormattedMessage id="reports.createNew" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-toolbar-group-right">
                        <div className="table-toolbar-column">
                            <TableViewToggles onViewTypeChange={setViewType} />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            <ReportTable
                onRowClick={openEditForm}
                rows={status === Status.Loading ? loading : reports.map(configurationToRow)}
                viewType={viewType}
            />
        </>
    );

    return (
        <>
            {isEmpty ? <EmptyView /> : <TableView />}
            <Route path="/reports/new" render={() => <CreateReportDialog onClose={closeForm} />} />
        </>
    );
}
