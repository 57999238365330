import React from 'react';

import { Status } from './types';

export const DownloadButton = ({ onClick, status }: { onClick: () => void; status?: Status }) => {
    return (
        <React.Fragment>
            {status === Status.FAILED ? (
                <button className="btn btn-default btn-icon-only pull-right" onClick={onClick}>
                    <span className="rioglyph rioglyph-error-sign" />
                </button>
            ) : status === Status.LOADING ? (
                <button className="btn btn-primary btn-loading btn-icon-only pull-right" />
            ) : status === Status.LOADED ? (
                <button className="btn btn-default btn-icon-only pull-right" onClick={onClick}>
                    <span className="rioglyph rioglyph-ok" />
                </button>
            ) : (
                <button className="btn btn-default btn-icon-only pull-right" onClick={onClick}>
                    <span className="rioglyph rioglyph-cloud-download" />
                </button>
            )}
        </React.Fragment>
    );
};
