import { Tooltip } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';

import OverlayTriggerWithInjectedIntl from '../../components/OverlayTriggerWithInjectedIntl';
import WithUnit from '../../components/WithUnit';
import { ValidUnit } from '../../constants/units';
import { getMileageSplitIntoUrbanNonUrban } from '../../utils/getMileageSplitIntoUrbanNonUrban';
import toPercent from '../../utils/toPercent';

interface Props {
    name: string;
    totalMileage: number;
    roads: {
        urban: boolean;
        mileage: number;
    }[];
}

const RoadTypeRow = ({ roads = [], name, totalMileage = 0 }: Props) => {
    const { urban, nonUrban } = getMileageSplitIntoUrbanNonUrban(roads);
    const urbanPercent = toPercent(urban, totalMileage, 0);
    const nonUrbanPercent = toPercent(nonUrban, totalMileage, 0);
    const displayName = name || <FormattedMessage id={'sidepanel.circulationType.unknownRoadType'} />;

    const urbanTooltip = (
        <Tooltip id="tooltip" width="auto">
            <FormattedMessage
                id={'sidepanel.distribution.urban-part'}
                values={{ percentage: urbanPercent ? urbanPercent : '<1' }}
            />
        </Tooltip>
    );
    const nonUrbanTooltip = (
        <Tooltip id="tooltip" width="auto">
            <FormattedMessage
                id={'sidepanel.distribution.non-urban-part'}
                values={{ percentage: nonUrbanPercent ? nonUrbanPercent : '<1' }}
            />
        </Tooltip>
    );

    const combinedPercent = nonUrbanPercent + urbanPercent;

    return (
        <tr>
            <td className="border-top-0 padding-top-10 padding-bottom-10 max-width-250">
                <div className="display-flex align-items-center">
                    <div className="rioglyph-road rioglyph text-size-18 text-color-highlight" />
                    <div className="margin-left-10 margin-right-10 text-color-dark ellipsis-1">{displayName}</div>
                </div>
            </td>
            <td className="border-top-0 padding-top-10 padding-bottom-10">
                <div className="width-100pct display-flex align-items-center">
                    <div className="StatusBar width-100pct display-flex align-items-center">
                        <div className="display-flex flex-1-0 align-items-center">
                            <div className="flex-order-2 width-45 margin-left-10">
                                <span className="display-flex flex-1-0 justify-content-end text-color-highlight text-size-16 text-bold">
                                    <WithUnit unit={ValidUnit.PERCENTAGE}>
                                        {combinedPercent ? combinedPercent : '<1'}
                                    </WithUnit>
                                </span>
                            </div>
                            <div className="display-flex flex-1-0 flex-order-1">
                                <div className="progress margin-0 flex-1-0 height-5">
                                    <OverlayTriggerWithInjectedIntl placement="top" overlay={nonUrbanTooltip}>
                                        <div
                                            className="progress-bar progress-bar-highlight progress-divider"
                                            style={{ width: nonUrbanPercent + '%' }}
                                        />
                                    </OverlayTriggerWithInjectedIntl>
                                    <OverlayTriggerWithInjectedIntl placement="top" overlay={urbanTooltip}>
                                        <div
                                            className="progress-bar progress-bar-highlight-light progress-divider"
                                            style={{ width: urbanPercent + '%' }}
                                        />
                                    </OverlayTriggerWithInjectedIntl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default RoadTypeRow;
