import _ from 'lodash';

import { DriverAnalysisStatePart } from '../../setup/types';
import { Id } from '../../types';
import { DEFAULT_USE_CASE_CONFIG, Sorting, UseCaseKey, UseCaseSettingsType } from './driverAnalysisReducer';

export const getUseCase = (state: DriverAnalysisStatePart): UseCaseKey => _.get(state, 'driverAnalysis.useCase');
export const getUseCaseSettings = (state: DriverAnalysisStatePart): UseCaseSettingsType =>
    _.get(state, ['driverAnalysis', 'useCaseSettings', getUseCase(state)], DEFAULT_USE_CASE_CONFIG);

export const getSortingBy = (state: DriverAnalysisStatePart): Sorting => _.get(state, 'driverAnalysis.sorting', {});

export const getOpenRows = (state: DriverAnalysisStatePart): Id[] => _.get(state, 'driverAnalysis.openRows');

export const areSettingsVisible = (state: DriverAnalysisStatePart): boolean =>
    _.get(state, 'driverAnalysis.areSettingsVisible');

export const getActiveSummaryTab = (state: DriverAnalysisStatePart) => {
    return state.driverAnalysis.activeSummaryTab;
};
