import cn from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';

export enum Alignments {
    RIGHT,
    LEFT,
}

export const Overlay: React.FC<{
    y: number;
    x: number;
    offset?: number;
    alignment?: Alignments;
    hasData?: boolean;
    withOutDataClasses?: string;
    withDataClasses?: string;
}> = ({
    children,
    y,
    x,
    offset = 0,
    alignment = Alignments.LEFT,
    hasData = true,
    withOutDataClasses = 'border-color-light text-color-light bg-white',
    withDataClasses = 'border-color-highlight text-color-highlight bg-white',
}) => {
    const [width, setWidth] = useState(0);
    const ref = useRef<HTMLDivElement | null>(null);
    useLayoutEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth);
        }
    }, [children]);

    const adjustedXOffset = Alignments.RIGHT === alignment ? x - width - offset : x + offset;

    return (
        <div
            ref={ref}
            className={cn(
                hasData ? withDataClasses : withOutDataClasses,
                'border-style-solid border-width-1 padding-5 text-bold'
            )}
            data-test="graph-overlay"
            style={{
                position: 'absolute',
                zIndex: 1,
                transition: 'transform 100ms ease',
                transform: `translate(${adjustedXOffset}px, ${y}px)`,
                pointerEvents: 'none',
            }}
        >
            {children}
        </div>
    );
};
