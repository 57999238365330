import { DetailPDFProps } from './Pdf';

export default {
    run: function runOnWorker(props: DetailPDFProps) {
        const worker = new Worker('./worker.ts', { type: 'module' });
        return new Promise<Blob>((resolve, reject) => {
            worker.postMessage(props);
            worker.onmessage = event => {
                event.data ? resolve(event.data) : reject(new Error('Error generating PDF on Worker'));
            };
        }).finally(() => worker.terminate());
    },
};
