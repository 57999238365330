/* eslint no-console: 0 */

import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { loadSettingsSuccess as settingsLoaded } from '../../features/settings/reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from '../../features/settings/saga';
import { configureReporting } from '../../setup/errorReporting';
import { setColumnOrder, setFilteredColumnNames, setSortKeyAndDirection, setUseCase } from './driverAnalysisActions';
import { Sorting, UseCaseKey } from './driverAnalysisReducer';
import { getUseCaseConfigForKey } from './useCaseConfig';

const { captureException } = configureReporting(window, process.env);

export const DRIVER_ANALYSIS_STORAGE_KEYS = {
    FILTERED_COLUMNS: 'driverAnalysisFilteredColumnNames',
    COLUMN_ORDER: 'driverAnalysisColumnOrder',
    SORT_BY: 'driverAnalysisSortBy',
    USE_CASE: 'driverAnalysisUserCase',
};

const mergeSettings = (oldValue: any, newValue: { [x: string]: string[] }) => ({ ...oldValue, ...newValue });

export function* clearAllSettings() {
    yield saveSettingsToStorage(DRIVER_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS, {});
    yield saveSettingsToStorage(DRIVER_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER, {});
    yield saveSettingsToStorage(DRIVER_ANALYSIS_STORAGE_KEYS.USE_CASE, '');
    yield saveSettingsToStorage(DRIVER_ANALYSIS_STORAGE_KEYS.SORT_BY, '');
}

export function* saveUseCase({ payload }: { payload: UseCaseKey }) {
    try {
        yield saveSettingsToStorage(DRIVER_ANALYSIS_STORAGE_KEYS.USE_CASE, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSortedBy({ payload }: { payload: Sorting }) {
    try {
        yield saveSettingsToStorage(DRIVER_ANALYSIS_STORAGE_KEYS.SORT_BY, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveFilteredColumnNames({
    payload,
}: {
    payload: { useCaseKey: UseCaseKey; filteredColumnNames: string[] };
}) {
    try {
        const { useCaseKey, filteredColumnNames } = payload;
        const key = DRIVER_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS;
        const savedData: string = yield loadSettingsFromStorage(key, null);
        yield saveSettingsToStorage(key, mergeSettings(savedData, { [useCaseKey]: filteredColumnNames }));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveColumnOrder({ payload }: { payload: { useCaseKey: UseCaseKey; columnOrder: string[] } }) {
    try {
        const { useCaseKey, columnOrder } = payload;
        const key = DRIVER_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER;
        const savedData: string = yield loadSettingsFromStorage(key, null);
        yield saveSettingsToStorage(key, mergeSettings(savedData, { [useCaseKey]: columnOrder }));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const useCase: UseCaseKey = yield loadSettingsFromStorage(DRIVER_ANALYSIS_STORAGE_KEYS.USE_CASE, null);
        const sortBy: Sorting = yield loadSettingsFromStorage(DRIVER_ANALYSIS_STORAGE_KEYS.SORT_BY, null);
        const columnOrders: string = yield loadSettingsFromStorage(DRIVER_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER, []);
        const filteredNames: string = yield loadSettingsFromStorage(DRIVER_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS, []);

        if (getUseCaseConfigForKey(useCase)) {
            yield put(setUseCase(useCase));
        }

        if (sortBy) {
            yield put(setSortKeyAndDirection({ key: sortBy.key, order: sortBy.order }));
        }

        yield all(
            Object.entries(columnOrders)
                .filter(([, order]) => _.isArray(order))
                .map(([useCaseKey, columnOrder]: any) => put(setColumnOrder({ useCaseKey, columnOrder })))
        );

        yield all(
            Object.entries(filteredNames)
                .filter(([, order]) => _.isArray(order))
                .map(([useCaseKey, columnOrder]: any) =>
                    put(setFilteredColumnNames({ useCaseKey, filteredColumnNames: columnOrder }))
                )
        );
    } catch (e) {
        yield clearAllSettings();
        yield call(captureException, e);
    }
}

export default function* root() {
    yield takeLatest(setUseCase, saveUseCase);
    yield takeLatest(setSortKeyAndDirection, saveSortedBy);
    yield takeLatest(setColumnOrder, saveColumnOrder);
    yield takeLatest(setFilteredColumnNames, saveFilteredColumnNames);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
