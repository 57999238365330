import { mean } from 'd3-array';
import _ from 'lodash';
import { Fragment } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';

const MILEAGE = 'mileage';

export const plainAverage = values => mean(values);
export const formatIntegerNumber = value =>
    !_.isUndefined(value) && value !== '-' ? <FormattedNumber value={value} maximumFractionDigits={0} /> : '-';
export const formatOneDigitNumber = value =>
    !_.isUndefined(value) && value !== '-' ? <FormattedNumber value={value} maximumFractionDigits={1} /> : '-';
export const weightAverageByMileage = (values, intervals) => getWeightedAverageBy(values, intervals, MILEAGE);

export const getWeightedAverageBy = values => {
    const weightingValues = [];
    const weightedValues = _.sum(values.map((v, i) => v * weightingValues[i]));
    const weightedSum = _.sum(weightingValues);
    return weightedSum ? weightedValues / weightedSum : 0;
};

export const withUnit = unit => value => {
    if (value !== '-') {
        return (
            <Fragment>
                {value} <FormattedMessage id={`unit.${unit}`} />
            </Fragment>
        );
    }
    return value;
};

export const formatDateTime = ({ value }) => {
    if (value) {
        return (
            <Fragment>
                <FormattedDate value={value} /> <FormattedTime value={value} />
            </Fragment>
        );
    }
};
