import _ from 'lodash';
import always from 'lodash/fp/always';
import { call, put, retry, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    createId,
    fetchDrivers,
    fetchGroups,
    fetchOpcon,
    fetchPerform,
    fetchRoute,
    fetchVehicles,
    fetchVehicleSpec,
    VehspecEntity,
} from '../api';
import { getAccount } from '../features/login/selectors';
import { getAccessToken } from '../features/tokenHandling/selectors';
import { errorNotification } from '../features/ui/notificationSaga';
import { configureReporting } from '../setup/errorReporting';
import {
    OpconQueryPayload,
    PerformQueryPayload,
    PositionQueryPayload,
    RawDriver,
    ThenArg,
    VehicleGroup,
    VehspecPayload,
} from '../types';
import { generatePushToDataLayer } from '../utils/googleTagManager';
import {
    requestDrivers,
    requestDriversFailed,
    requestDriversSuccess,
    requestEntitiesReceived,
    requestEntitiesReceivedFailed,
    requestEntitiesReceivedSuccess,
    requestEntitiesStatisticsReceivedSuccess,
    requestGroups,
    requestGroupsFailed,
    requestOpConData,
    requestPerformanceData,
    requestPositions,
    requestVehicles,
    requestVehiclesFailed,
    requestVehicleSpecification,
    requestVehicleSpecificationFailed,
    requestVehicleSpecificationReceived,
    requestVehicleSpecificationSuccess,
    requestVehiclesSuccess,
} from './actions';
import { Loadable, LoadableType } from './loadable';
import { requestGroupsSuccess } from './reducer';
import { backendConfig, getRequest, vehSpecForRequest } from './selectors';

const { captureException } = configureReporting(window, process.env);

const isLoading = <T>(loadable: LoadableType<T>) =>
    Loadable.cata(loadable, always(true), always(false), always(true), always(false));

export function* loadDrivers() {
    try {
        const authToken: string = yield select(getAccessToken);
        const url: string = yield select(backendConfig, 'DRIVERS_SERVICE');
        const drivers: RawDriver[] = yield call(fetchDrivers, url, authToken);
        yield put(requestDriversSuccess({ drivers: _.keyBy(drivers, 'driverId') }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestDriversFailed());
        captureException(e);
    }
}

export function* loadGroups() {
    try {
        const authToken: string = yield select(getAccessToken);
        const tagsUrl: string = yield select(backendConfig, 'TAGS_SERVICE');
        const groups: VehicleGroup[] = yield call(fetchGroups, tagsUrl, authToken);
        yield put(requestGroupsSuccess({ groups: _.keyBy(groups, 'id') }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestGroupsFailed());
        captureException(e);
    }
}

export function* loadVehicles() {
    const authToken: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const assetsUrl: ReturnType<typeof backendConfig> = yield select(backendConfig, 'ASSETS_SERVICE');
    try {
        const vehicles: ThenArg<ReturnType<typeof fetchVehicles>> = yield call(fetchVehicles, assetsUrl, authToken);
        yield put(requestVehiclesSuccess({ vehicles: _.keyBy(vehicles, 'id') }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestVehiclesFailed());
        captureException(e);
    }
}

export function* loadVehicleSpecifications({ payload: request }: { payload: VehspecPayload }) {
    const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'VEHICLE_SPEC');
    const loadableRequest = (yield select(vehSpecForRequest, createId(request))) as ReturnType<
        typeof vehSpecForRequest
    >;
    try {
        if (isLoading(loadableRequest)) {
            return;
        }

        if (request.assetIds.length === 0) {
            return;
        }
        yield put(requestVehicleSpecificationReceived({ request }));
        const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
        const specs: VehspecEntity[] = yield call(fetchVehicleSpec, url, request, token);
        yield put(requestVehicleSpecificationSuccess({ request, specs }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestVehicleSpecificationFailed({ request }));
        captureException(e);
    }
}

export function* loadPositions({ payload: request }: { payload: PositionQueryPayload }) {
    const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'ROUTE_HISTORY');
    const loadableRequest: ReturnType<typeof getRequest> = yield select(getRequest, createId(request));
    try {
        if (isLoading(loadableRequest)) {
            return;
        }

        if (request.assetIds.length === 0) {
            return;
        }

        yield put(requestEntitiesReceived({ request }));
        const positions: ThenArg<ReturnType<typeof fetchRoute>> = yield retry(3, 1000, function* load() {
            const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
            return (yield call(fetchRoute, url, request, token)) as ThenArg<ReturnType<typeof fetchRoute>>;
        });
        yield put(requestEntitiesReceivedSuccess({ request, response: { entities: [positions] } }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestEntitiesReceivedFailed({ request }));
        captureException(e);
    }
}

export function* loadOpCon({ payload: request }: { payload: OpconQueryPayload }) {
    const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'OPCON');
    const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const loadableRequest: ReturnType<typeof getRequest> = yield select(getRequest, createId(request));
    try {
        if (isLoading(loadableRequest)) {
            return;
        }

        if (!('asset_ids' in request) && !('driver_ids' in request)) {
            return;
        }

        yield put(requestEntitiesReceived({ request }));
        const requestWithFeatureToggle = { ...request, driver_identification: 'id' };
        const { entities } = yield call(fetchOpcon, url, requestWithFeatureToggle, token);

        yield put(requestEntitiesReceivedSuccess({ request, response: { entities } }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestEntitiesReceivedFailed({ request }));
        captureException(e);
    }
}

export function* loadPerformance({ payload: request }: { payload: PerformQueryPayload }) {
    const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'PERFORMANCE_MERGER');
    const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const loadableRequest: ReturnType<typeof getRequest> = yield select(getRequest, createId(request));
    try {
        if (isLoading(loadableRequest)) {
            return;
        }

        const startTime = Date.now();

        // TODO: [ TBMTWOPA-2695 ] clean this up
        // We should clean up our abstractions, right now we send two events
        // to the reducer for success, but handle the error case in Failed, for both statistics and data

        yield put(requestEntitiesReceived({ request }));

        if (!('driver_ids' in request) && !('vehicle_ids' in request)) {
            // todo: why was this needed.
        } else {
            // We are not passing permitted drivers for now as of MTBPERF-578
            const modifiedRequest = { ...request, use_driver_admin_driver_ids: true };
            const { entities, statistics } = yield call(fetchPerform, url, modifiedRequest, token);
            const response = { entities };
            const endTime = Date.now();
            const accountId: ReturnType<typeof getAccount> = yield select(getAccount);
            const pushToDataLayer = generatePushToDataLayer(accountId);
            pushToDataLayer({
                eventCategory: 'performance',
                eventAction: 'PERFORMANCE_LOAD_DATA',
                eventLabel: 'Performance Load time in seconds',
                eventValue: Math.round((endTime - startTime) / 1000),
            });

            yield put(requestEntitiesReceivedSuccess({ request, response }));
            yield put(
                requestEntitiesStatisticsReceivedSuccess({
                    request,
                    response: {
                        statistics,
                    },
                })
            );
        }
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestEntitiesReceivedFailed({ request }));
        captureException(e);
    }
}

export default function* root() {
    yield takeLatest(requestDrivers, loadDrivers);
    yield takeLatest(requestPositions, loadPositions);
    yield takeLatest(requestVehicleSpecification, loadVehicleSpecifications);
    yield takeLatest(requestVehicles, loadVehicles);
    yield takeLatest(requestGroups, loadGroups);
    yield takeEvery(requestPerformanceData, loadPerformance);
    yield takeEvery(requestOpConData, loadOpCon);
}
