import moment from 'moment';

import { VEHICLE_REQUEST_BY_TIME_ATTRIBUTES } from '../../pages/vehicleAnalysis/queries';
import {
    QueryTemplateExcel,
    QueryTemplateExcelResponse,
    QueryTemplateOpcon,
    QueryTemplateOpConResponse,
    QueryTemplateVeh,
    QueryTemplateVehResponse,
} from './types';

export const TEMPLATE_EXCEL_DOWNLOAD = ({
    start,
    end,
    vehicleIds,
    segmentBy = 'vehicle',
    driverIds,
}: QueryTemplateExcel): QueryTemplateExcelResponse => ({
    from: moment(start).toISOString(true),
    to: moment(end).toISOString(true),
    attributes: [
        'acceleration_pedal_count_with_cc',
        'acceleration_pedal_distance_with_cc',
        'acceleration_pedal_rating',
        'acceleration_pedal_time_with_cc',
        'active_coasting_count',
        'active_coasting_distance',
        'active_coasting_time',
        'average_speed',
        'average_weight',
        'braking_rating',
        'co_2_emission',
        'coasting_cc_count',
        'coasting_cc_distance',
        'coasting_cc_time',
        'coasting_rating',
        'cruise_control_consumption_in_potential',
        'cruise_control_distance_in_potential',
        'cruise_control_potential_distance',
        'cruise_control_rating',
        'cruise_control_time_in_potential',
        'distance_fuel_consumption',
        'driver_ids',
        'driving_consumption',
        'driving_time',
        'emergency_brake_count',
        'foresighted_driving_percentage',
        'foresighted_driving_percentage_without_cc',
        'fuel_consumption',
        'fuel_efficiency',
        'idling_consumption',
        'idling_time',
        'kickdown_count',
        'kickdown_distance',
        'kickdown_time',
        'mileage',
        'motor_brake_count',
        'motor_brake_distance',
        'motor_brake_time',
        'no_cruise_control_consumption_in_potential',
        'no_cruise_control_distance_in_potential',
        'no_cruise_control_time_in_potential',
        'operating_fuel_consumption',
        'operating_time',
        'overspeed_distance',
        'overspeed_rating',
        'result_from',
        'result_to',
        'retarder_count',
        'retarder_distance',
        'retarder_time',
        'service_brake_count',
        'service_brake_distance',
        'service_brake_time',
        'vehicle_ids',
    ],
    driver_ids: driverIds,
    vehicle_ids: vehicleIds,
    segment_by: segmentBy,
    includes: [],
});

export const TEMPLATE_OPCON_REQUEST = ({
    start,
    end,
    vehicleIds = [],
    driverIds = [],
    groupBy = ['asset-id', 'driver-id'],
}: QueryTemplateOpcon): QueryTemplateOpConResponse => ({
    from: moment(start).toISOString(true),
    to: moment(end).toISOString(true),
    ...(vehicleIds.length ? { asset_ids: vehicleIds } : {}),
    ...(driverIds.length ? { driver_ids: driverIds } : {}),
    facets: ['weight', 'topology', 'range', 'traffic', 'route-type'],
    group_by: groupBy,
});

export const TEMPLATE_VEHICLE_REQUEST_BY_TIME = ({
    start,
    end,
    vehicleIds,
    intervalType = 'day',
}: QueryTemplateVeh): QueryTemplateVehResponse => ({
    from: moment(start).toISOString(true),
    to: moment(end).toISOString(true),
    attributes: VEHICLE_REQUEST_BY_TIME_ATTRIBUTES,
    interval_type: intervalType,
    vehicle_ids: vehicleIds,
    segment_by: 'interval',
    includes: ['total_segment'],
});
