// Import application style
import './app/styles.less';

import IframeResizer from '@rio-cloud/iframe-resizer';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import {
    ApplicationHeader,
    ApplicationLayout,
    NotificationsContainer,
    OverlayTrigger,
    Spinner,
    Tooltip,
} from '@rio-cloud/rio-uikit';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useEffect } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { matchPath, NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as routes from '../constants/routes';
import { subModules } from '../constants/subModules';
import { requestDrivers, requestGroups, requestVehicles } from '../data/actions';
import { isInitialized as isMFHInitialized } from '../data/selectors';
import { env } from '../env';
import Administration from '../pages/administration/AdministrationEntry';
import CoDriver from '../pages/coDriver/CoDriver';
import DriverSidebar from '../pages/driverAnalysis/DriverSidebar';
import DriverAnalysis from '../pages/driverAnalysis/Entry';
import VehicleEntry from '../pages/vehicleAnalysis/Entry';
import VehicleSidepanel from '../pages/vehicleAnalysis/Sidepanel';
import { State } from '../setup/types';
import { hideSessionExpiredDialog } from './app/actions';
import { getSessionExpiredAcknowledged } from './app/selectors';
// Import language configuration
import { DEFAULT_LOCALE } from './lang/lang';
import { getLangLocale, getLanguageData } from './lang/selectors';
import { isUserSessionExpired } from './login/selectors';
import ElevationGraph from './opcon/ElevationGraph';
import { PermissionGate } from './permissions/AccessControl';
import { LegacyPermissionsType } from './permissions/reducer';
import { getLegacyPermissions } from './permissions/selectors';
import EditReportSidebar from './reports/EditReportSidebar';
import ReportEntry from './reports/Entry';
import ServiceInfoContainer from './serviceInfo/ServiceInfoContainer';
import WelcomePage, { shouldShowWelcomePage } from './serviceInfo/WelcomePage';
import { getIdToken } from './tokenHandling/selectors';
import Tree from './tree/Tree';
import Feedback from './ui/Feedback';
import { isSidebarVisible } from './ui/reducer';

// We do not want to load the baggage of the Pdf stuff if not needed.
const PdfEntry = React.lazy(() => import('./pdf/Entry'));

const { MENU_URL } = _.get(env.runtimeConfig, 'backend');
const {
    DRIVER_ROUTE,
    VEHICLE_ROUTE,
    ADMINISTRATION_ROUTE,
    WELCOME_ROUTE,
    CODRIVER_ROUTE,
    REPORT_ROUTE,
    EDIT_REPORT_ROUTE,
} = routes;

// Define the navigation items of the current application
const title = (
    <div>
        <span>
            <FormattedMessage id="moduleName" />
        </span>
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="tooltip" className="top" width="auto">
                    {process.env.COMMIT}
                </Tooltip>
            }
        >
            <span className="text-color-gray margin-left-10">v</span>
        </OverlayTrigger>
        <span className="text-color-gray">{process.env.SERVICE_VERSION}</span>
    </div>
);

export const DriverVehicleAndGroupLoader = (props: any) => {
    useEffect(() => {
        props.loadDrivers();
        props.loadVehicles();
        props.loadGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

const ConnectedDriverVehicleAndGroupLoader = connect(undefined, dispatch => ({
    loadDrivers: () => dispatch(requestDrivers()),
    loadVehicles: () => dispatch(requestVehicles()),
    loadGroups: () => dispatch(requestGroups()),
}))(DriverVehicleAndGroupLoader);

const getLastRoute = () => {
    try {
        if (shouldShowWelcomePage()) {
            return WELCOME_ROUTE;
        }
        const lastRoute = localStorage.getItem('lastRoute');
        return lastRoute && Object.values(routes).some(route => matchPath(lastRoute, route))
            ? lastRoute
            : WELCOME_ROUTE;
    } catch (e) {
        return WELCOME_ROUTE;
    }
};

const route2Component: Record<string, React.FC> = {
    [VEHICLE_ROUTE]: VehicleEntry,
    [ADMINISTRATION_ROUTE]: Administration,
    [DRIVER_ROUTE]: DriverAnalysis,
    [CODRIVER_ROUTE]: CoDriver,
};

const withPermission = ({
    childProps,
    route,
}: {
    childProps: RouteComponentProps<{
        [x: string]: string | undefined;
    }>;
    route: string;
}) => React.createElement(route2Component[route], childProps as React.Attributes);

const noPermission = () => <Redirect to={WELCOME_ROUTE} />;
const noPermissionPending = () => <Spinner />;

const NULL_LOCATION_OBJ = { pathname: '' };

export const AppContainer = ({
    idToken,
    hideSessionDialog,
    homeRoute,
    languageData,
    showSessionExpired,
    location,
    userLocale: locale,
    legacyPermissions,
    isDriverVehicleSidePanelVisible,
    isInitialized,
}: {
    idToken: string;
    hideSessionDialog: () => void;
    languageData: Record<string, string>;
    homeRoute: string;
    showSessionExpired: boolean;
    location: Location;
    userLocale: string;
    legacyPermissions: LegacyPermissionsType;
    isDriverVehicleSidePanelVisible: boolean;
    isInitialized: boolean;
}) => {
    const lastRoute = getLastRoute();
    const pathname = (location || NULL_LOCATION_OBJ).pathname;
    const appTitle = <FormattedMessage id={'moduleName'} />;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userMenu = <DefaultUserMenu idToken={idToken} environment={process.env.NODE_ENV!} />;
    //TODO: Remove me after 2020/06/01 tracked under: [TBMTWOPA-2870]
    const serviceInfoContainer = <ServiceInfoContainer title={title} />;
    const menu = <IframeResizer url={MENU_URL} />;

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    const homeLink = <a href={homeRoute} />;

    const filteredSubModules = subModules.filter(subModule =>
        _.get(legacyPermissions, ['submodules', subModule.legacyPermission])
    );

    const navItems = filteredSubModules.map(subModule => ({
        key: subModule.id,
        route: (
            <NavLink to={subModule.route}>
                <FormattedMessage id={subModule.translationKey} />
            </NavLink>
        ),
    }));

    if (env.featureToggles.developer) {
        navItems.splice(2, 0, {
            key: 'reports',
            route: (
                <NavLink to={REPORT_ROUTE}>
                    <FormattedMessage id="subModuleName.reports" />
                </NavLink>
            ),
        });
    }

    const subModuleRoutes = subModules.map(subModule => (
        <Route
            key={subModule.id}
            path={subModule.route}
            render={props => (
                <PermissionGate
                    hasPermission={_.includes(filteredSubModules, subModule)}
                    isPermissionLoaded={legacyPermissions.isLoaded!}
                    components={{
                        withPermission: () => withPermission({ childProps: props, route: subModule.route }),
                        noPermission,
                        noPermissionPending,
                    }}
                />
            )}
        />
    ));

    // When loading the root path / we still don't know if the tree should be shown. So do not show w
    // it we redirect
    const isAnalyzeRoute = pathname !== '/' && (pathname?.includes(DRIVER_ROUTE) || pathname?.includes(VEHICLE_ROUTE));

    useEffect(() => {
        if (pathname !== '/' && pathname !== lastRoute && isAnalyzeRoute) {
            localStorage.setItem('lastRoute', pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <IntlProvider locale={locale} key={locale} messages={languageData} defaultLocale={DEFAULT_LOCALE}>
            <ApplicationLayout className={'PerformanceAnalysis'}>
                <ConnectedDriverVehicleAndGroupLoader />
                <ApplicationLayout.Header>
                    <ApplicationHeader
                        label={appTitle}
                        appNavigator={menu}
                        homeRoute={homeLink}
                        navItems={navItems}
                        actionBarItems={[serviceInfoContainer, userMenu]}
                    />
                </ApplicationLayout.Header>
                <ApplicationLayout.Sidebar>
                    {isAnalyzeRoute && <Tree categories={_.map(filteredSubModules, 'treeTab')} />}
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className="right">
                    <Switch>
                        <Route
                            path={VEHICLE_ROUTE}
                            component={() => {
                                return isDriverVehicleSidePanelVisible ? <VehicleSidepanel /> : null;
                            }}
                        />
                        <Route path={DRIVER_ROUTE} component={DriverSidebar} />
                        {env.featureToggles.developer ? (
                            <Route path={`${EDIT_REPORT_ROUTE}/:id`} render={() => <EditReportSidebar />} />
                        ) : null}
                    </Switch>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body className="responsive">
                    {isAnalyzeRoute && <Feedback />}
                    <NotificationsContainer />
                    <SessionExpiredDialog locale={locale} onClose={hideSessionDialog} show={showSessionExpired} />
                    {isInitialized ? (
                        <Switch>
                            {subModuleRoutes}
                            <Route path={WELCOME_ROUTE} component={WelcomePage} />
                            {env.featureToggles.developer ? (
                                <Route
                                    path="/elevation"
                                    component={() => (
                                        <Suspense fallback={<Spinner />}>
                                            <ElevationGraph
                                                dateRange={{
                                                    start: moment(new Date())
                                                        .subtract(10, 'days')
                                                        .toDate(),
                                                    end: new Date(),
                                                }}
                                                vehicleIds={['1']}
                                                driverIds={[]}
                                            />
                                        </Suspense>
                                    )}
                                />
                            ) : null}
                            {env.featureToggles.developer ? (
                                <Route path={REPORT_ROUTE} component={ReportEntry} />
                            ) : null}
                            {env.featureToggles.developer ? (
                                <Route
                                    path="/dev-pdf"
                                    component={() => (
                                        <Suspense fallback={<Spinner />}>
                                            <PdfEntry />
                                        </Suspense>
                                    )}
                                />
                            ) : null}
                            <Route render={() => <Redirect to={lastRoute} />} />
                        </Switch>
                    ) : (
                        <div className="display-flex justify-content-center position-relative height-100pct">
                            <span
                                style={{
                                    left: '50%',
                                    top: '50%',
                                    position: 'absolute',
                                    translate: 'transform(-50%, -50%}',
                                }}
                            >
                                <Spinner />
                            </span>
                        </div>
                    )}
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

const mapStateToProps = (state: State) => ({
    idToken: getIdToken(state),
    homeRoute: _.get(env.runtimeConfig, 'homeRoute'),
    languageData: getLanguageData(state),
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLangLocale(state),
    legacyPermissions: getLegacyPermissions(state),
    isDriverVehicleSidePanelVisible: isSidebarVisible(state, VEHICLE_ROUTE),
    isInitialized: isMFHInitialized(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
