import _ from 'lodash';
import moment from 'moment';

export const formatNoUnit = value => (_.isNil(value) ? value : `(${value})`);

export const formattedNumber = (value, digits, locale) => {
    if (_.isNil(value) || !_.isNumber(value)) {
        return '-';
    }
    return value.toLocaleString(locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

export const factoredNumber = (value, factor, digits, locale) => {
    if (_.isNil(value) || !_.isNumber(value)) {
        return '-';
    }
    const num = value * factor;
    return num.toLocaleString(locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

export const formatSecondsToTime = seconds => {
    if (_.isNil(seconds) || !_.isNumber(seconds)) {
        return '-';
    }
    const minutesTotal = Math.floor(seconds / 60);
    const hours = Math.floor(minutesTotal / 60);
    const minutes = minutesTotal % 60;
    return [`${hours}`.padStart(2, '0'), `${minutes}`.padStart(2, '0')].join(':');
};

export const percentNumber = (value, locale) => factoredNumber(value, 100, 1, locale);

/**
 * Convert a given timestamp to the format the backend requires.
 * see http://momentjs.com/docs/#/displaying/format/
 *
 * @param {Object} timestamp - The frontend timestamp.
 * @returns {String} - The convert timestamp.
 */
export const convertTimestampToServerFormat = timestamp => {
    return moment.isMoment(timestamp) ? timestamp.toISOString(true) : timestamp;
};

/**
 * Resolve visible name for a vehicle. In order, if possible: "Name", "Customer vehicle name", "VIN", "-"
 * @param {Object} vehicle - Vehicle data object.
 * @returns {string} Name / label for a vehicle.
 */
export const extractVehicleName = vehicle => {
    if (vehicle) {
        if (vehicle.customerVehicleName) {
            return vehicle.customerVehicleName;
        }
        if (vehicle.name) {
            return vehicle.name;
        }
        if (vehicle.vin) {
            return vehicle.vin;
        }
    }
    return '-';
};

export const extractDriverName = (driver, { lastNameFirst = false } = {}) => {
    if (driver) {
        if (driver.lastName && driver.firstName) {
            if (lastNameFirst) {
                return `${driver.lastName}, ${driver.firstName}`;
            } else {
                return `${driver.firstName} ${driver.lastName}`;
            }
        }
        if (driver.lastName) {
            return driver.lastName;
        }
        if (driver.firstName) {
            return driver.firstName;
        }
        if (driver.displayName) {
            return driver.displayName;
        }
    }
    return '-';
};
