export const configureStorage = () => {
    let accessToken: string | null = null;
    return {
        discardAccessToken: () => {
            accessToken = null;
        },
        getAccessToken: () => accessToken,
        hasAccessToken: () => Boolean(accessToken),
        saveAccessToken: (token: string) => {
            accessToken = token;
        },
    };
};
