export const VEHICLE_REQUEST_BY_TIME_ATTRIBUTES = [
    'average_speed',
    'average_weight',
    'co_2_emission',
    'distance_fuel_consumption',
    'driver_ids',
    'driving_consumption',
    'driving_time',
    'fuel_consumption',
    'fuel_efficiency',
    'idling_consumption',
    'idling_time',
    'interval_desc', // TODO remove this when cache service is no longer used
    'mileage',
    'operating_fuel_consumption',
    'operating_time',
    'result_from',
    'result_to',
    'vehicle_ids',
];

export const VEHICLE_REQUEST_ATTRIBUTES = [
    'average_speed',
    'average_weight',
    'co_2_emission',
    'distance_fuel_consumption',
    'driver_ids',
    'driving_consumption',
    'driving_time',
    'fuel_consumption',
    'fuel_efficiency',
    'idling_consumption',
    'idling_time',
    'inaccurate_vehicle_ids',
    'mileage',
    'operating_fuel_consumption',
    'operating_time',
    'result_from',
    'result_to',
    'vehicle_ids',
];
