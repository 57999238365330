import colors from '@rio-cloud/rio-uikit/lib/es/Colors';
import { useContext } from 'react';

import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './utils';

const COLOR = colors['color-highlight-dark'];

export default function SingleDataPoint({ selectedElement }: { selectedElement: DataEntry | undefined }) {
    const { xScale: x, yScale: y } = useContext(GraphContext);

    if (selectedElement) {
        return (
            <circle stroke={COLOR} r={4} cy={y(selectedElement.y)} cx={x(selectedElement.x)} className="dataPoint" />
        );
    }
    return null;
}
