import './styles.less';

import _ from 'lodash';
import always from 'lodash/fp/always';
import identity from 'lodash/fp/identity';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { Column } from '../../columns/createColumn';
import { loadingFormatter } from '../../columns/DOM';
import SimpleErrorState from '../../components/SimpleErrorState';
import SettingsDialog from '../../components/table/SettingsDialog';
import SettingsToolbar from '../../components/table/SettingsToolbar';
import Table, { Row } from '../../components/table/Table';
import UseCases from '../../components/UseCases';
import { DRIVER_ROUTE } from '../../constants/routes';
import { Loadable, LoadableType } from '../../data/loadable';
import { getDrivers, getSelectedDriverIds, getVehicles } from '../../data/selectors';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { includeCruiseControlInRating as includeCruiseControlInRatingSelector } from '../../features/settings/reducer';
import { getAccessToken } from '../../features/tokenHandling/selectors';
import { getTableViewType, hideSidebar, isMobile, showSidebar, sidebarData } from '../../features/ui/reducer';
import { getDateRange, isFilteringOnSegment } from '../../features/ui/selectors';
import Toolbar from '../../features/ui/Toolbar';
import { State } from '../../setup/types';
import { DateRange, Map, RawDriver, Vehicle } from '../../types';
import { calculateColumnOrder } from '../../utils/sorting';
import { findValueExtractor, sortByProperty } from '../../utils/sortyByProperty';
import {
    closeRow,
    openRow,
    setColumnOrder,
    setFilteredColumnNames,
    setSortKeyAndDirection as setSortKeyAndDirectionAction,
    setUseCase,
    toggleSettings,
} from './driverAnalysisActions';
import { Sorting, UseCaseKey } from './driverAnalysisReducer';
import {
    areSettingsVisible,
    getOpenRows,
    getSortingBy,
    getUseCase,
    getUseCaseSettings,
} from './driverAnalysisSelectors';
import DriverDownloadMenu from './DriverDownloadMenu';
import DriverState from './DriverState';
import DriverSummary from './DriverSummary';
import { Data, DataType } from './types';
import { getUseCaseConfigForKey, UseCaseConfig, useCaseConfig } from './useCaseConfig';

// There is a lot of duplicate code also in vehicleAnalysis, maybe we can extract it?
const sortChildrenBy = (sortFn: { (children: DataType): Data[] }) => (data: Data[]) =>
    data.map(d => ({
        ...d,
        children: sortFn(d.children),
    }));

const hasLoadableChildren = (loadable: LoadableType<Data[]>) => Loadable.map(loadable, data => Boolean(data.length));

const getShouldShowGraph = compose(
    loadable => Loadable.cata(loadable, identity, always(true), always(true), always(true)),
    hasLoadableChildren
);

const getAreSettingsEnabled = compose(
    loadable => Loadable.cata(loadable, identity, always(false), always(false), always(false)),
    hasLoadableChildren
);

const createSortAndFlattenFunction = (openRows: string[], sortColumn: Column | undefined, sortOrder: string) => {
    const { key, dataField } = sortColumn || {};

    const dataSort: (arg0: Data[]) => Data[] = compose(
        sortChildrenBy((children: DataType) => sortByProperty(children, dataField, sortOrder, findValueExtractor(key))),
        sortChildrenBy((children: DataType) =>
            sortByProperty(children, 'vehicles', 'asc', findValueExtractor('vehicles'))
        ),
        (data: Data[]) => sortByProperty(data, dataField, sortOrder, findValueExtractor(key))
    );

    const flattenChildren = (data: Data[]) =>
        data.reduce((result: Data[], row: Data) => {
            const isRowOpen = openRows.includes(row.id);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return [...result, row, ...((isRowOpen && row.children) || [])];
        }, []);

    return compose(flattenChildren, dataSort);
};

const findColumnByKey = (columns: Column[], key: string): Column | undefined =>
    columns.find(column => column.key === key);
const transformColumnStringsToObjects = (columnOrder: string[], filteredColumnNames: string[], columns: Column[]) => {
    const columnKeys = _.map(columns, 'key');
    const columnKeysInRightOrder = calculateColumnOrder(columnKeys, columnOrder);
    return {
        columnKeysInRightOrder,
        filteredColumns: columnKeysInRightOrder
            .filter(key => !filteredColumnNames.includes(key))
            .map(key => findColumnByKey(columns, key))
            .filter(Boolean) as Column[],
    };
};

const openExpanderRow = ({
    openRows,
    openRow,
    closeRow,
}: {
    openRows: string[];
    openRow: (id: string) => void;
    closeRow: (id: string) => void;
}) => (row: Row) => {
    const id = row.id;
    if (openRows.includes(id)) {
        closeRow(id);
    } else {
        openRow(id);
    }
};

export const DriverAnalysis: React.FunctionComponent<{
    driverIds: string[];
    openSidebar: (sideBarData: Row) => void;
    sortBy: Sorting;
    setSortKeyAndDirection: (sortBy: Sorting) => void;
    setUseCase: (useCase: UseCaseKey) => void;
    requestConfiguration: {
        accessToken: string;
        useCase: UseCaseKey;
        dateRange: DateRange;
        drivers: Map<RawDriver>;
        vehicles: Map<Vehicle>;
    };
    toggleSettings: (visible: boolean) => void;
    areSettingsVisible: boolean;
    setFilteredColumnNames: (useCaseKey: UseCaseKey, filteredColumns: string[]) => void;
    setColumnOrder: (useCaseKey: UseCaseKey, columnOrder: string[]) => void;
    filteredColumnNames: string[];
    columnOrder: string[];
    closeSidebar: () => void;
    useCase: UseCaseKey;
    useCaseConfig: UseCaseConfig[];
    selectedUseCase?: UseCaseConfig;
    allowedVehicles: string[];
    isFilteringOnSegment: boolean;
    parentsWithChildren?: LoadableType<Data[]>;
    selectedDriver: string;
    openRow: (id: string) => void;
    closeRow: (id: string) => void;
    openRows: string[];
    viewType?: string;
    isMobile?: boolean;
}> = ({
    driverIds,
    openSidebar,
    sortBy,
    setSortKeyAndDirection,
    setUseCase,
    requestConfiguration,
    toggleSettings,
    areSettingsVisible,
    setFilteredColumnNames,
    setColumnOrder,
    filteredColumnNames = [],
    columnOrder = [],
    closeSidebar,
    useCase,
    useCaseConfig,
    selectedUseCase,
    allowedVehicles,
    isFilteringOnSegment,
    parentsWithChildren,
    selectedDriver,
    openRow,
    closeRow,
    openRows = [],
    viewType,
    isMobile = false,
}) => {
    const dateRange = {
        start: _.get(requestConfiguration, 'dateRange.start'),
        end: _.get(requestConfiguration, 'dateRange.end'),
    };
    const { columns = [] } = selectedUseCase || {};

    const { columnKeysInRightOrder, filteredColumns } = useMemo(
        () => transformColumnStringsToObjects(columnOrder, filteredColumnNames, columns),
        [columnOrder, columns, filteredColumnNames]
    );

    const expandedChildren = useMemo(() => {
        const sortAndFlatten = createSortAndFlattenFunction(
            openRows,
            findColumnByKey(columns, sortBy.key),
            sortBy.order
        );
        return Loadable.map(parentsWithChildren!, sortAndFlatten);
    }, [parentsWithChildren, sortBy.key, sortBy.order, openRows, columns]);

    const shouldShowGraph = isFilteringOnSegment || (getShouldShowGraph(expandedChildren) && Boolean(driverIds.length));
    const areSettingsEnabled = getAreSettingsEnabled(expandedChildren);

    const mandatoryInput = dateRange.start && dateRange.end && driverIds.length > 0;

    useEffect(closeSidebar, [closeSidebar]);

    const resultIsEmpty = Loadable.cata(
        parentsWithChildren!,
        data => _.isEmpty(data),
        always(true),
        always(true),
        always(true)
    );
    const showTable = mandatoryInput && !resultIsEmpty;

    const tableSettings = <SettingsToolbar disabled={!showTable} toggleSettings={toggleSettings} />;

    const table = Loadable.cata(
        expandedChildren,
        data =>
            !data.length ? (
                <div className={!isFilteringOnSegment ? 'margin-top-20pct' : ''}>
                    <DriverState
                        headlineId="driverAnalysisNoDriversReturned"
                        messageId="driverAnalysisNoDriversReturnedExplanation"
                    />
                </div>
            ) : (
                <Table
                    key={useCase}
                    filteredColumns={filteredColumns}
                    onOpenChildren={openExpanderRow({ openRows, openRow, closeRow })}
                    sortBy={sortBy}
                    onSort={setSortKeyAndDirection}
                    selectedElements={[selectedDriver]}
                    openRows={openRows}
                    data={data}
                    onRowClicked={(row, active) => (active || !row ? closeSidebar() : openSidebar(row))}
                    viewType={viewType}
                />
            ),
        () => (
            <div className="margin-top-20">
                <SimpleErrorState headlineId="error.default" messageId="error.server" />
            </div>
        ),
        () => (
            <Table
                key={'loading'}
                filteredColumns={filteredColumns.map(c => ({ ...c, formatter: loadingFormatter }))}
                onOpenChildren={() => {}}
                sortBy={sortBy}
                onSort={setSortKeyAndDirection}
                selectedElements={[]}
                openRows={[]}
                data={_.fill(Array(3), { level: 1, id: '' })}
                onRowClicked={() => {}}
                viewType={viewType}
            />
        ),
        () => (
            <div className="margin-top-20pct">
                <DriverState
                    headlineId="driverAnalysisNoDriversSelected"
                    messageId="driverAnalysisNoDriversSelectedExplanation"
                />
            </div>
        )
    );

    return (
        <div className="driver-section" data-test="DriverAnalysis">
            <Toolbar>
                <div className="table-toolbar-column table-toolbar-column-spacer">
                    <UseCases
                        onSelect={setUseCase}
                        active={requestConfiguration.useCase}
                        useCaseConfig={useCaseConfig}
                    />
                </div>
                <div className="table-toolbar-column">
                    <DriverDownloadMenu
                        drivers={driverIds}
                        disabled={!areSettingsEnabled}
                        dateRange={dateRange}
                        useCaseKey={useCase}
                    />
                </div>
            </Toolbar>
            <SettingsDialog
                areSettingsVisible={areSettingsVisible}
                hiddenColumns={filteredColumnNames}
                toggleSettings={toggleSettings}
                columnOrder={columnKeysInRightOrder}
                setColumnOrder={(order: string[]) => setColumnOrder(useCase, order)}
                columnLabels={columns.reduce(
                    (acc, column) => ({ ...acc, [column.key]: <FormattedMessage id={column.labelId} /> }),
                    {}
                )}
                defaultColumnOrder={columns.map(column => column.key)}
                setColumns={filteredColumnNames => setFilteredColumnNames(useCase, filteredColumnNames)}
            />
            {!isMobile && shouldShowGraph && (
                <DriverSummary
                    {...{
                        dateRange,
                        driverIds,
                        allowedVehicles,
                        parentsWithChildren,
                    }}
                />
            )}
            {mandatoryInput ? (
                <React.Fragment>
                    {tableSettings}
                    {table}
                </React.Fragment>
            ) : (
                <div className="margin-top-20pct">
                    <DriverState
                        headlineId="driverAnalysisNoDriversSelected"
                        messageId="driverAnalysisNoDriversSelectedExplanation"
                    />
                </div>
            )}
        </div>
    );
};

const useCaseConfigForKey = createSelector(
    [getUseCase, includeCruiseControlInRatingSelector],
    (useCase, includeCruiseControlInRating) => {
        return getUseCaseConfigForKey(useCase, {
            includeCruiseControlInRating,
        });
    }
);

export const mapStateToProps = (state: State) => {
    const useCase = getUseCase(state);
    const useCaseSettings = getUseCaseSettings(state);

    return {
        driverIds: getSelectedDriverIds(state),
        useCase,
        useCaseConfig,
        // eslint-disable-next-line react-hooks/rules-of-hooks
        selectedUseCase: useCaseConfigForKey(state),
        openRows: getOpenRows(state),
        sortBy: getSortingBy(state),
        selectedDriver: _.get(sidebarData(state, DRIVER_ROUTE), 'id'),
        areSettingsVisible: areSettingsVisible(state),
        columnOrder: useCaseSettings.columnOrder,
        filteredColumnNames: useCaseSettings.filteredColumnNames,
        allowedVehicles: getPerformVehicles(state),
        requestConfiguration: {
            accessToken: getAccessToken(state),
            useCase,
            dateRange: getDateRange(state),
            drivers: getDrivers(state),
            vehicles: getVehicles(state),
        },
        isFilteringOnSegment: isFilteringOnSegment(state),
        viewType: getTableViewType(state),
        isMobile: isMobile(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        openSidebar: function(sideBarData: Row) {
            dispatch(showSidebar({ data: sideBarData, type: DRIVER_ROUTE }));
        },
        closeSidebar: function() {
            dispatch(hideSidebar());
        },
        setSortKeyAndDirection: function(sortBy: Sorting) {
            dispatch(setSortKeyAndDirectionAction(sortBy));
        },
        setUseCase: function(useCase: UseCaseKey) {
            dispatch(setUseCase(useCase));
        },
        toggleSettings: function(visible: boolean) {
            dispatch(toggleSettings(visible));
        },
        setFilteredColumnNames: function(useCaseKey: UseCaseKey, filteredColumns: string[]) {
            dispatch(setFilteredColumnNames({ useCaseKey, filteredColumnNames: filteredColumns }));
        },
        setColumnOrder: function(useCaseKey: UseCaseKey, columnOrder: string[]) {
            dispatch(setColumnOrder({ useCaseKey, columnOrder }));
        },
        openRow: function(id: string) {
            dispatch(openRow({ id }));
        },
        closeRow: function(id: string) {
            dispatch(closeRow({ id }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverAnalysis);
