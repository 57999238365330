import _ from 'lodash';
const RATING_INACTIVE = { color: 'text-color-dark', background: 'bg-dark', hexColor: '#697A8B' };
const RATING_POOR = { color: 'text-color-rating-1', background: 'bg-rating-1', hexColor: '#d90000' };
const RATING_FAIR = { color: 'text-color-rating-2', background: 'bg-rating-2', hexColor: '#ff8e3c' };
const RATING_GOOD = { color: 'text-color-rating-3', background: 'bg-rating-3', hexColor: '#f8c575' };
const RATING_VERYGOOD = { color: 'text-color-rating-4', background: 'bg-rating-4', hexColor: '#5cb85c' };
const RATING_EXCELLENT = { color: 'text-color-rating-5', background: 'bg-rating-5', hexColor: '#4b924c' };

const isPoorRating = rating => rating >= 0 && rating < 20.0;
const isFairRating = rating => rating >= 20 && rating < 40.0;
const isGoodRating = rating => rating >= 40 && rating < 60.0;
const isVeryGoodRating = rating => rating >= 60 && rating < 80.0;
const isExcellentRating = rating => rating >= 80 && rating <= 100.0;

export const getRatingCSSColor = rating => {
    if (!_.isNumber(rating)) {
        return RATING_INACTIVE;
    }
    if (isPoorRating(rating)) {
        return RATING_POOR;
    }
    if (isFairRating(rating)) {
        return RATING_FAIR;
    }
    if (isGoodRating(rating)) {
        return RATING_GOOD;
    }
    if (isVeryGoodRating(rating)) {
        return RATING_VERYGOOD;
    }
    if (isExcellentRating(rating)) {
        return RATING_EXCELLENT;
    }
    return RATING_INACTIVE;
};
