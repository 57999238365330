import _ from 'lodash';

import SimpleBarChart from '../../../components/SimpleBarChart';
import { OpconSignals } from '../../../types';
import IndexToWording from '../IndexToWording';
import { isValidScore } from '../isValidScore';
import ratingToIndex from '../ratingToIndex';

const Rating = ({ opCon, totalMileage }: { opCon: OpconSignals; totalMileage: number }) => {
    const score = isValidScore(opCon, totalMileage) ? _.get(opCon, 'score.value') : -1;
    const index = ratingToIndex(score);

    return <SimpleBarChart activeIndex={index} tooltip={<IndexToWording index={index} />} />;
};

export default Rating;
