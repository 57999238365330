import { createSlice } from '@reduxjs/toolkit';

import { TREE_TABS } from '../../constants/treeTabs';
export type TreeState = {
    selectedVehicles: string[];
    selectedDrivers: string[];
    selectedVehicleGroups: string[];
    selectedDriverGroups: [];
    showEmptyGroups: boolean;
};

const defaultState: TreeState = {
    showEmptyGroups: false,
    selectedVehicles: [],
    selectedDrivers: [],
    selectedVehicleGroups: [],
    selectedDriverGroups: [],
};

const treeSlice = createSlice({
    name: 'tree',
    initialState: defaultState,
    reducers: {
        setShowEmptyGroups(state, { payload }) {
            return { ...state, showEmptyGroups: payload };
        },
        selectVehicleTab(state) {
            return { ...state, tab: TREE_TABS.VEHICLES };
        },
        selectDriverTab(state) {
            return { ...state, tab: TREE_TABS.DRIVER };
        },
        setSelectedDrivers(state, { payload }) {
            return { ...state, selectedDrivers: payload };
        },
        setSelectedVehicles(state, { payload }) {
            return { ...state, selectedVehicles: payload };
        },
        setSelectedVehicleGroups(state, { payload }) {
            return { ...state, selectedVehicleGroups: payload };
        },
        setSelectedDriverGroups(state, { payload }) {
            return { ...state, selectedDriverGroups: payload };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const {
    selectVehicleTab,
    selectDriverTab,
    setSelectedDrivers,
    setSelectedVehicles,
    setSelectedVehicleGroups,
    setSelectedDriverGroups,
    setShowEmptyGroups,
} = treeSlice.actions;

export default treeSlice.reducer;
