import * as _ from 'lodash';

import { Id, Signal, Signals } from '../models/types';
const sha1 = require('js-sha1');

export const createId = (a: unknown): Id => sha1(JSON.stringify(a));

//TODO: investigate the right type here.
export const createSignal = <T>(value: T): Signal<T> => ({
    value,
    unit: 'noUnit',
    accuracy: 'high',
});

export const extractValueFromSignal = <T>(signal: Signal<T>) => signal.value;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSignal = <T>(entity: any, key: string, defaultValue: T | '-') => {
    if (key === 'drivers') {
        return entity.drivers || [];
    }

    if (key === 'vehicles') {
        return entity.vehicles || [];
    }

    if (entity.signals) {
        return _.get(entity, `signals.${key}.value`, defaultValue);
    }

    return _.get(entity, key, defaultValue);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const mapSignals = rawInput => _.mapValues(rawInput, createSignal);

export const TBM3ReportEntityFactory = <T>(
    transformed: T,
    rawInput: T,
    reportId: string
): { id: string; signals: { [key: string]: Signals } } => ({
    id: createId({ ...rawInput, reportId }),
    signals: mapSignals(transformed),
});
