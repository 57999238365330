import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import hasPermissions from './hasPermissions';

export function PermissionGate({
    hasPermission,
    isPermissionLoaded,
    components = {},
}: {
    hasPermission: boolean;
    isPermissionLoaded: boolean;
    components: Record<string, () => React.ReactElement>;
}) {
    let childFunc;
    if (hasPermission) {
        childFunc = components.withPermission;
    } else {
        if (isPermissionLoaded) {
            childFunc = components.noPermission;
        } else {
            childFunc = components.noPermissionPending;
        }
    }
    return (_.isFunction(childFunc) && childFunc()) || null;
}

export function AccessControl({
    account,
    children,
    requiredPermissions = [],
}: {
    account: string;
    children: React.ReactElement;
    requiredPermissions: string[];
}) {
    const hasAllRequiredPermissions = hasPermissions(requiredPermissions, account);
    if (hasAllRequiredPermissions) {
        return children;
    }
    return null;
}

export default connect(state => {
    return {
        account: _.get(state, 'login.userProfile.account'),
    };
})(AccessControl);
