import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { URL_MARKETPLACE_PERFORM, URL_MARKETPLACE_PERFORM_KOREA } from '../../constants/URLs';
import { getSelectedVehicleIds, getVehicles } from '../../data/selectors';
import { isKoreanTenant } from '../../features/login/selectors';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { isMobile } from '../../features/ui/reducer';
import { VehicleType } from '../../types';
import { getPluralization } from '../../utils/getPluralization';

function MixedFleetAlert({ vehicles, selectedVehicleIds, performVehicleIds, isMobile }) {
    if (_.isEmpty(performVehicleIds)) {
        return null;
    }
    const nonPerformVehicleIds = _.difference(selectedVehicleIds, performVehicleIds);
    if (_.isEmpty(nonPerformVehicleIds)) {
        return null;
    }

    const nonPerformVehicles = nonPerformVehicleIds.map(vehicleId => _.find(vehicles, { vehicleId: vehicleId }));
    const vehicleTypes = _.uniq(_.map(nonPerformVehicles, 'type'));
    const nonPerformVehiclesCount = nonPerformVehicleIds.length;

    const vehicleIcons = (
        <span className="padding-left-4 padding-right-4 icon-container">
            {vehicleTypes.includes(VehicleType.TRUCK) && <span className="rioglyph rioglyph-truck-baseline" />}
            {vehicleTypes.includes(VehicleType.BUS) && <span className="rioglyph rioglyph-bus-baseline" />}
            {vehicleTypes.includes(VehicleType.VAN) && <span className="rioglyph rioglyph-van-baseline" />}
        </span>
    );

    const offset = isMobile ? -15 : -30;

    return (
        <div
            className="bg-highlight text-color-white padding-right-15 padding-left-15"
            style={{ marginLeft: offset, marginRight: offset, marginTop: offset, marginBottom: 10 }}
            data-test="MixedFleetAlert"
        >
            <div className="padding-5 padding-right-15 padding-left-15">
                <b>
                    <FormattedMessage id="mixedFleetRequestWarning" />:{' '}
                </b>
                <FormattedMessage
                    id="mixedFleetRequestWarningDescription"
                    values={{
                        vehicleCount: nonPerformVehiclesCount,
                        vehicleIcons: vehicleIcons,
                        pluralizedVehicle: getPluralization('vehicle', 'vehicles', nonPerformVehiclesCount),
                        pluralizedVehicleWithPronoun: getPluralization(
                            'vehicleWithPronoun',
                            'vehiclesWithPronoun',
                            nonPerformVehiclesCount
                        ),
                    }}
                />{' '}
                <a
                    href={isKoreanTenant ? URL_MARKETPLACE_PERFORM_KOREA : URL_MARKETPLACE_PERFORM}
                    className="text-color-white"
                >
                    <span className="rioglyph rioglyph-shopping-cart" /> <FormattedMessage id="bookNow" />
                </a>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        vehicles: getVehicles(state),
        selectedVehicleIds: getSelectedVehicleIds(state),
        performVehicleIds: getPerformVehicles(state),
        isKoreanTenant: isKoreanTenant(state),
        isMobile: isMobile(state),
    };
}

export default connect(mapStateToProps)(MixedFleetAlert);
