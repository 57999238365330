import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal, Vehicle } from '../../api';
import { VehicleType } from '../../types';

export function getBusOrVanWarning(row: unknown): JSX.Element | null {
    const vehicles: Vehicle[] = getSignal(row, 'vehicles', []);
    const busesOrVans = vehicles
        .filter(vehicle => [VehicleType.BUS, VehicleType.VAN].includes(vehicle.type))
        .map(vehicle => vehicle.name);

    return !_.isEmpty(busesOrVans) ? (
        <BusOrVanWarning key="busOrVanInformation" busOrVanVehicleNames={busesOrVans} />
    ) : null;
}

function BusOrVanWarning({ busOrVanVehicleNames }: { busOrVanVehicleNames: string[] }): JSX.Element {
    return (
        <React.Fragment>
            <dt>
                <FormattedMessage id="busOrVanInformation" />
            </dt>
            <dd>
                <FormattedMessage
                    id="busOrVanInformationDetail"
                    values={{ vehicles: busOrVanVehicleNames.join(', ') }}
                />
            </dd>
        </React.Fragment>
    );
}

export default BusOrVanWarning;
