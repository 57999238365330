import { SortArrows, Tooltip } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../columns/createColumn';
import { trackingAttributes } from '../../utils/googleTagManager';
import OverlayTriggerWithInjectedIntl from '../OverlayTriggerWithInjectedIntl';

type SortDirectionType = 'asc' | 'desc';

const TableHead: React.FunctionComponent<{
    column: Column;
    hasSortActive: boolean;
    sortDirection?: SortDirectionType;
    handleSortChange: (event: React.MouseEvent<HTMLTableHeaderCellElement>) => void;
    sortDisabled: boolean;
}> = ({ column, hasSortActive, sortDirection, handleSortChange, sortDisabled }) => {
    const tableHeadClassNames = ['user-select-none', 'sort-column'].join(' ');

    const formattedHeaderLabel = <FormattedMessage id={column.labelId} />;

    const tooltip = (
        <Tooltip id={'tooltip'} width="auto">
            {formattedHeaderLabel}
        </Tooltip>
    );

    const iconComponent = column.icon ? <span className={`${column.icon} text-size-20 margin-right-5`} /> : null;

    return (
        <OverlayTriggerWithInjectedIntl placement={'top'} overlay={tooltip}>
            <th
                key={column.key}
                className={tableHeadClassNames}
                onClick={!sortDisabled ? handleSortChange : _.noop}
                data-test={column.dataField}
                data-sortby={column.key}
                {...trackingAttributes('click', 'performance, table', 'sort:Table', column.dataField)}
            >
                <span>
                    {hasSortActive ? <SortArrows direction={sortDirection} /> : !sortDisabled && <SortArrows />}
                    {iconComponent}
                    {formattedHeaderLabel}
                </span>
            </th>
        </OverlayTriggerWithInjectedIntl>
    );
};

export default TableHead;
