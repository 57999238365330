import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Column as ColumnType } from '../columns/createColumn';
import { Entity } from '../types';
import SimpleColumn from './SimpleColumn';

const Column = ({ column, entity }: { column: ColumnType; entity: Entity }) => (
    <SimpleColumn
        title={<FormattedMessage id={column.labelId} />}
        content={column.formatter(column.valueExtractor(entity), entity)}
        testAttribute={`column-${column.labelId}`}
    />
);

export default Column;

export const ColumnPropType = PropTypes.shape({
    key: PropTypes.string.isRequired,
    dataField: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
    valueExtractor: PropTypes.func.isRequired,
    formatter: PropTypes.func.isRequired,
    unit: PropTypes.string,
    dataSort: PropTypes.bool,
});

const EntityType = PropTypes.shape({
    id: PropTypes.any,
    signals: PropTypes.object.isRequired,
    drivers: PropTypes.arrayOf(PropTypes.object),
    vehicles: PropTypes.arrayOf(PropTypes.object),
});

Column.propTypes = {
    column: ColumnPropType.isRequired,
    entity: EntityType,
};
