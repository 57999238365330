import { Sidebar } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getSignal } from '../../api';
import { Column as ColumnType } from '../../columns/createColumn';
import Box from '../../components/Box';
import OperatingPeriod from '../../components/OperatingPeriod';
import { DRIVER_ROUTE } from '../../constants/routes';
import OperationDays from '../../features/operationalDays/OperationalDays';
import PDFDownloader from '../../features/pdf/detail/DriverPDFDownloader';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { hideSidebar, showSidebar, sidebarData } from '../../features/ui/reducer';
import { getActiveDateRange } from '../../features/ui/selectors';
import { State } from '../../setup/types';
import { DateRange } from '../../types';
import { getColumns } from '../../utils/columns';
import { convertToRows } from '../../utils/convertToRows';
import { trackingAttributes } from '../../utils/googleTagManager';
import { extractDriverName } from '../../utils/stringFormatters';
import { getTopColumns } from './columns';
import { openRow } from './driverAnalysisReducer';
import Column from './DriverColumn';
import Tabs from './DriverSidebarTabs';
import { Data } from './types';

const convertToRow = convertToRows(3);

export const FormattedOperationDays = ({ entity, dateRange }: { entity: any; dateRange: DateRange }) => (
    <div className=" margin-bottom-5">
        <label className="margin-bottom-0 text-size-12">
            <FormattedMessage id="operationDays" />
        </label>
        <div className="text-size-18 ellipsis-1">
            <OperationDays
                // On the first level we also need to include the no driver card for operationDays
                // This feature is unique to the vehicle. On the driver side we don't have to worry about it,
                drivers={getSignal(entity, 'driverIds', [])}
                vehicles={getSignal(entity, 'vehicleIds', [])}
                start={dateRange.start}
                end={dateRange.end}
            />
        </div>
    </div>
);

export const DriverSidebar: React.FC<{
    data: any;
    onClose: () => void;
    vehicleColumns: ColumnType[];
    dateRange: DateRange;
    onVehicleClick: (vehicle: Data) => void;
    openParent: (id: string) => void;
    allowedVehicles: string[];
}> = ({ data, onClose, vehicleColumns, dateRange, onVehicleClick, openParent, allowedVehicles }) => {
    const onVehiclesNameClick = useCallback(
        vehicle => {
            onVehicleClick(vehicle);
            openParent(data.id);
        },
        [onVehicleClick, openParent, data]
    );

    if (!data) {
        return null;
    }

    const isVehiclesNameClickable = data.children && data.children.length > 1;
    const topColumns = getTopColumns(isVehiclesNameClickable, onVehiclesNameClick);

    const topRows = convertToRow(topColumns.map(column => <Column key={column?.key} column={column!} entity={data} />));

    let displayedVehicleColumns = vehicleColumns
        .map(column => <Column key={column.key} column={column} entity={data} />)
        .concat([<FormattedOperationDays key="operationDays" entity={data} dateRange={dateRange} />]);

    const driver = extractDriverName(_.get(data, 'drivers[0]', {}));
    const vehicleRows = convertToRow(displayedVehicleColumns);

    return (
        <Sidebar
            fly
            onClose={onClose}
            position={'right'}
            width={640}
            title={
                <div
                    className="padding-left-10 justify-content-between flex align-items-center"
                    data-test="DriverSidebar"
                    {...trackingAttributes('visibility', 'performance, sidebar', 'open:driverSidebar')}
                >
                    <FormattedMessage
                        id="driverAnalysis.sidebar.title.oneDriver"
                        values={{
                            driver,
                            start: <FormattedDate value={dateRange.start} />,
                            end: <FormattedDate value={dateRange.end} />,
                        }}
                    />
                </div>
            }
            headerButtons={<PDFDownloader entity={data} dateRange={dateRange} />}
            bodyClassName={'overflow-y-scroll'}
        >
            <OperatingPeriod start={getSignal(data, 'start', undefined)} end={getSignal(data, 'end', undefined)} />
            <div className="padding-left-20 padding-right-20 padding-top-15">
                <Box rows={topRows} />
                <Box rows={vehicleRows} hasBorder={false} />
                <Tabs dateRange={dateRange} entity={data} allowedVehicles={allowedVehicles} />
            </div>
        </Sidebar>
    );
};

export const mapStateToProps = (state: State) => {
    return {
        vehicleColumns: getColumns([
            'operatingFuelConsumption',
            'fuelConsumption',
            'distanceFuelConsumption',
            'drivingConsumption',
            'idlingConsumption',
            'fuelEfficiency',
            'co2Emission',
            'operatingTime',
            'drivingTime',
            'idlingTime',
            'mileage',
            'averageWeight',
            'averageSpeed',
        ]),
        data: sidebarData(state, DRIVER_ROUTE),
        dateRange: getActiveDateRange(state),
        allowedVehicles: getPerformVehicles(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onVehicleClick: (data: Data) => dispatch(showSidebar({ data, type: DRIVER_ROUTE })),
        openParent: (id: string) => dispatch(openRow({ id })),
        onClose: () => dispatch(hideSidebar()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DriverSidebar);
