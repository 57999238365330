import { isEmpty, isUndefined } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';
import { Vehicle } from '../../api/models/types';

export function getAccuracyWarning(row: unknown): JSX.Element | null {
    const vehicles: Vehicle[] = getSignal(row, 'vehicles', []);
    const inaccurateVehiclesIds = getSignal(row, 'inaccurateVehicleIds', []);

    const inaccurateVehicleNames = inaccurateVehiclesIds
        .map((id: string) => vehicles.find(vehicle => vehicle.vehicleId === id))
        .filter((vehicle: Vehicle) => !isUndefined(vehicle))
        .map((vehicle: Vehicle) => vehicle.name);

    return !isEmpty(inaccurateVehicleNames) ? (
        <AccuracyWarning key="inaccuracyWarnings" inaccurateVehicleNames={inaccurateVehicleNames} />
    ) : null;
}

function AccuracyWarning({ inaccurateVehicleNames }: { inaccurateVehicleNames: string[] }): JSX.Element {
    return (
        <React.Fragment>
            <dt data-test="inaccuracy-warning">
                <FormattedMessage id="inaccuracyWarnings" />
            </dt>
            <dd>
                <FormattedMessage id="inaccuracyWarning" values={{ vehicles: inaccurateVehicleNames.join(', ') }} />
            </dd>
        </React.Fragment>
    );
}

export default AccuracyWarning;
