import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import { PADDED_DATE_FORMAT } from '../utils/paddedDateFormat';

interface FormattedDateTimeI {
    value: string | number | Date | undefined;
}

const FormattedDateTime: React.FC<FormattedDateTimeI> = ({ value }) => (
    <>
        <FormattedDate value={value} {...(PADDED_DATE_FORMAT as { format?: string })} /> <FormattedTime value={value} />
    </>
);

export default FormattedDateTime;
