import { Vehicle } from '../../api/models/types';
import { Driver, OpconSignals } from '../../types';

export interface Slope {
    low: number;
    medium: number;
    steep: number;
}

export interface OpCon {
    drivers: Driver[];
    vehicles: Vehicle[];
    id: string;
    signals: OpconSignals & { vehicleIds: string; driverIds: string };
}

export enum Sign {
    PLUS = '+',
    MINUS = '-',
}
