import { AuthToken, VehspecPayload } from '../types';
import { fetchPostWithJSONResponse } from './api';
import { decodeVehspecResponse, VehspecResponseApiType } from './decoder/vehspecDecoder';

export async function fetchVehicleSpec(baseUrl: string, payload: VehspecPayload, authToken: AuthToken) {
    const rawResponse = ((await fetchPostWithJSONResponse(
        baseUrl,
        'report',
        {
            asset_ids: payload.assetIds,
            from: payload.dateRange.start.toISOString(),
            to: payload.dateRange.end.toISOString(),
        },
        authToken
    )) as unknown) as VehspecResponseApiType;
    return decodeVehspecResponse(rawResponse);
}
