import moment from 'moment';
import { IntlFormatters } from 'react-intl';

import configureGetCreateTimeInterval from '../../utils/timeInterval';

const timeFormatBasedOnDateRange = (
    formatDate: IntlFormatters['formatDate'],
    formatMessage: IntlFormatters['formatMessage']
) =>
    configureGetCreateTimeInterval<(d: Date) => string>({
        days: (d: Date) =>
            formatDate(d, {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
            }),
        weeks: (d: Date) => `${moment(d).week()} ${formatMessage({ id: 'time_interval_calendar_week_short' })}`,
        months: (d: Date) =>
            formatDate(d, {
                year: '2-digit',
                month: 'long',
            }),
        quarters: (d: Date) =>
            `${moment(d).quarter()} ${formatMessage({ id: 'time_interval_quarter_short' })} ${formatDate(d, {
                year: 'numeric',
            })}`,
        years: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
            }),
    });

export default timeFormatBasedOnDateRange;
