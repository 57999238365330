import { Dialog } from '@rio-cloud/rio-uikit';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import { Driver, ReportTimeframe, ReportType, TargetType, Vehicle, VehicleGroup } from '../../api';
import { DataState } from '../../data/reducer';
import { getLocale } from '../lang/selectors';
import { getActiveDriverList, getActiveVehicleList, getGroupList } from '../tree/uiSelectors';
import CreateReportForm from './forms/CreateReportForm';
import { ReportForm } from './types';

export const REPORT_FORM = 'reportForm';

function CreateReportDialog({
    onClose,
    vehicles,
    groups,
    drivers,
}: {
    onClose: () => void;
    vehicles: Vehicle[];
    groups: VehicleGroup[];
    drivers: Driver[];
}) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const defaultLocale: string = useSelector(getLocale);

    const defaultConfig: Omit<ReportForm, 'id'> = {
        reportType: ReportType.asset_details,
        informViaEmail: true,
        languageCode: defaultLocale,
        timezone: 'Europe/Berlin',
        name: '',
        timeframe: ReportTimeframe.monthly,
        type: TargetType.assets,
        evaluationStart: new Date(),
        entities: { items: [], groups: [] },
    };

    return (
        <Dialog
            show={true}
            title={<FormattedMessage id="reports.creationTitle" />}
            footer={
                <label
                    htmlFor={REPORT_FORM}
                    role="button"
                    tabIndex={0}
                    className={`btn btn-primary  ${isSubmitting ? 'btn-loading-overlay' : ''}`}
                    data-test="submit-create-dialog"
                >
                    <FormattedMessage id="reports.createNew" />
                </label>
            }
            body={
                <CreateReportForm
                    report={defaultConfig}
                    vehicles={vehicles}
                    groups={groups}
                    drivers={drivers}
                    onCloseDialog={onClose}
                    setIsSubmitting={setIsSubmitting}
                />
            }
            bsSize={Dialog.SIZE_SM}
            onHide={onClose}
        />
    );
}

const mapStateToProps = (state: { data: DataState }) => ({
    vehicles: getActiveVehicleList(state) as Vehicle[],
    groups: getGroupList(state),
    drivers: getActiveDriverList(state),
});

export default connect(mapStateToProps)(CreateReportDialog);
