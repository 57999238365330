import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';

import { Alignments, Overlay } from '../../../components/graphComponents/Overlay';
import { ElevationData } from '../../../data/hooks/useElevationData';

const HOVER_OFFSET = 70;
export default function GraphOverlay({
    x,
    y,
    data,
    parentDimensions: { width },
}: {
    parentDimensions: { width: number; height: number };
    x: number;
    y: number;
    data: ElevationData;
}) {
    const isOverHalf = x > width / 2;
    return (
        <Overlay
            hasData={!data.interpolated}
            alignment={isOverHalf ? Alignments.RIGHT : Alignments.LEFT}
            x={x}
            offset={HOVER_OFFSET}
            y={y - HOVER_OFFSET}
        >
            {!data.interpolated ? (
                <div className="text-color-dark text-normal">
                    <dl className="padding-0 margin-0">
                        <dt className="text-color-dark text-normal">
                            <FormattedMessage id="minAltitude" />
                        </dt>
                        <dd>
                            <div className="text-color-highlight text-size-18">
                                <span className="text-bold">
                                    <FormattedNumber value={data.min} maximumFractionDigits={1} />
                                    <FormattedMessage id="unit.m" />
                                </span>
                            </div>
                        </dd>
                    </dl>
                    <dl className="padding-0 margin-0">
                        <dt className="text-color-dark text-normal">
                            <FormattedMessage id="maxAltitude" />
                        </dt>
                        <dd>
                            <div className="text-color-highlight text-size-18">
                                <span className="text-bold">
                                    <FormattedNumber value={data.max} maximumFractionDigits={1} />
                                    <FormattedMessage id="unit.m" />
                                </span>
                            </div>
                        </dd>
                    </dl>
                    <span>
                        <FormattedDate
                            value={data.date}
                            hour="numeric"
                            minute="numeric"
                            year="numeric"
                            month="short"
                            day="2-digit"
                        />
                    </span>
                </div>
            ) : (
                <FormattedMessage id="noData" />
            )}
        </Overlay>
    );
}
