import { EmptyState, Spinner } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import createColumn from '../../columns/createColumn';
import SimpleErrorState from '../../components/SimpleErrorState';
import Table from '../../components/table/Table';
import { Loadable, LoadableType } from '../../data/loadable';
import { DateRange } from '../../types';
import { getColumns } from '../../utils/columns';
import { DownloadButton } from './DownloadButton';
import { LOADING } from './reducer';
import { VEHICLE } from './selectors';
import { CoDriverDownloadPayload, Status } from './types';

interface AutoSuggestSuggestion {
    [name: string]: any;
}

const downloadColumn = createColumn({
    key: 'coDriverDownload',
    dataField: 'download',
    // Workaround for empty table head
    labelId: ' ',
    valueExtractor: entity => _.get(entity, 'download', {}),
    // eslint-disable-next-line react/display-name
    formatter: ({ onClick, status }) => {
        return <DownloadButton onClick={onClick} status={status} />;
    },
    dataSort: false,
});

const TableElement: React.FC<{
    mappedEntities?: LoadableType<any>;
    startDownload: (payload: CoDriverDownloadPayload) => void;
    selection: AutoSuggestSuggestion;
    dateRange: DateRange;
    status?: Record<string, Status>;
    shouldShowAllDriversButton?: boolean;
}> = ({
    mappedEntities = { data: [] },
    startDownload = _.noop,
    selection = {},
    dateRange = {},
    status = {},
    shouldShowAllDriversButton,
}) => {
    const columns = getColumns(['drivers', 'vehicle', 'tripStartEnd']).concat([downloadColumn]);

    const allButtonState = status?.[`${selection?.id}-all`] === LOADING ? 'btn-loading-overlay' : '';
    return (
        <div className="margin-top-20">
            {Loadable.cata(
                mappedEntities as LoadableType<any>,
                data => (
                    <div>
                        <Table
                            key={'coDriver'}
                            filteredColumns={columns}
                            data={data}
                            onSort={_.noop}
                            sortDisabled={true}
                        />
                        {shouldShowAllDriversButton && (
                            <button
                                className={`btn btn-primary ${allButtonState}`}
                                onClick={() =>
                                    startDownload({
                                        type: VEHICLE,
                                        vehicle: selection.entity,
                                        start: dateRange.start,
                                        end: dateRange.end,
                                        isTotalSegment: true,
                                    })
                                }
                            >
                                <FormattedMessage id="coDriver.downloadAll" />
                            </button>
                        )}
                    </div>
                ),
                () => (
                    <div className="margin-top-20">
                        <SimpleErrorState headlineId={'error.default'} messageId={'error.server'} />
                    </div>
                ),
                () => (
                    <Spinner />
                ),
                () => (
                    <EmptyState message={<FormattedMessage id="coDriver.nothingFound" />} />
                )
            )}
        </div>
    );
};

export default TableElement;
