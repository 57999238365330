import _ from 'lodash';

export default function LanguageData(messages: Record<string, any> = {}, category = '') {
    return {
        items: Object.keys(messages)
            .filter(key => key.startsWith(category) && key.endsWith('Title'))
            .sort((a, b) => _.get(messages, a, '').localeCompare(_.get(messages, b, '')))
            .map(titleKey => {
                const baseKey = titleKey.replace(/Title$/, '');
                return {
                    id: baseKey.replace(/\./g, '/'),
                    title: titleKey,
                    description: `${baseKey}Description`,
                };
            }),
    };
}
