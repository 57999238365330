import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';
import aggregateSlopeInBuckets from './aggregateSlopeInBuckets';
import SlopeRow from './SlopeRow';
import { OpCon, Sign, Slope } from './types';

const SlopeRowsForBucket = ({
    bucket: { low, medium, steep },
    direction,
    totalMileage,
}: {
    bucket: Slope;
    direction: Sign;
    totalMileage: number;
}) => {
    const translationPrefix = direction === '-' ? 'slopeDown' : 'slopeUp';
    return (
        <tbody className="border-width-1">
            <SlopeRow
                name={<FormattedMessage id={`${translationPrefix}.low`} />}
                sign={direction}
                mileage={low}
                totalMileage={totalMileage}
            />
            <SlopeRow
                name={<FormattedMessage id={`${translationPrefix}.medium`} />}
                sign={direction}
                mileage={medium}
                totalMileage={totalMileage}
            />
            <SlopeRow
                name={<FormattedMessage id={`${translationPrefix}.steep`} />}
                sign={direction}
                mileage={steep}
                totalMileage={totalMileage}
            />
        </tbody>
    );
};

const sumAllValues = (obj: Slope) => _.sum(Object.values(obj));
const getFlatMileage = (totalMileage: number, slopeBuckets: { up: Slope; down: Slope }) => {
    const upMileage = sumAllValues(slopeBuckets.up);
    const downMileage = sumAllValues(slopeBuckets.down);

    return totalMileage - upMileage - downMileage;
};

const DrivenElevation = ({ opCon }: { opCon: OpCon }) => {
    const topologySignal = getSignal(opCon, 'topology', {});

    const slopeBuckets = aggregateSlopeInBuckets(_.get(topologySignal, 'slopes', []));
    const totalMileage = _.sumBy(topologySignal.slopes, 'mileage');
    const flatMileage = getFlatMileage(totalMileage, slopeBuckets);

    return (
        <React.Fragment>
            <table className="margin-top-15 table">
                <colgroup>
                    <col className="width-40pct width-30pct-lg" />
                    <col />
                </colgroup>
                <thead>
                    <tr className="text-color-dark text-left">
                        <th className="max-width-250 border-width-1">
                            <FormattedMessage id={'sidepanel.header.typeOfElevation'} />
                        </th>
                        <th>
                            <FormattedMessage id={'sidepanel.header.partOfDrivingDistance'} />
                        </th>
                    </tr>
                </thead>
                <tbody className="border-width-1">
                    <SlopeRow
                        name={<FormattedMessage id="slope.flat" />}
                        mileage={flatMileage}
                        totalMileage={totalMileage}
                    />
                </tbody>
                <SlopeRowsForBucket bucket={slopeBuckets.up} direction={Sign.PLUS} totalMileage={totalMileage} />
                <SlopeRowsForBucket bucket={slopeBuckets.down} direction={Sign.MINUS} totalMileage={totalMileage} />
            </table>
        </React.Fragment>
    );
};

export default DrivenElevation;
