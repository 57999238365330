import React, { ReactNode } from 'react';

const FormGroup: React.FC<{ label: ReactNode; htmlFor: string }> = ({ label, htmlFor, children }) => {
    return (
        <div className="form-group">
            <label htmlFor={htmlFor} className="control-label">
                {label}
            </label>
            <div>{children}</div>
        </div>
    );
};

export default FormGroup;
