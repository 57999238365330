import { NO_UNIT } from '../constants/units';
import { formatOneDigitNumber, plainAverage } from '../utils/formatters';
import createColumn from './createColumn';

export const scoreSignal = {
    unit: NO_UNIT,
    displayedUnit: NO_UNIT,
    format: formatOneDigitNumber,
    average: plainAverage,
};

export const operationCondition = createColumn({
    key: 'operationCondition',
    dataField: 'operationCondition',
    labelId: 'operationCondition',
    formatter: scoreSignal.format,
    unit: scoreSignal.unit,
    dataSort: true,
});
