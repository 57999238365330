import _ from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { GraphContext } from '../../components/graphComponents/GraphDataProvider';
import { Overlay } from '../../components/graphComponents/Overlay';
import { State } from '../../setup/types';
import { getActiveSegmentIdentifier } from './selectors';

export function SegmentableDayOverlay({
    activeSegmentIdentifier,
    formatter,
}: {
    formatter: (key: any) => React.ReactNode;
    activeSegmentIdentifier?: Date;
}) {
    const {
        xScale: x,
        yScale: y,
        data,
        dimensions: { height, margin },
    } = useContext(GraphContext);

    if (activeSegmentIdentifier) {
        const value = data.find(d => d.x && d.x.getTime() === activeSegmentIdentifier.getTime())?.y;
        const yPosition = y(value) || 0;
        return (
            <Overlay
                withDataClasses="border-color-highlight text-color-white bg-highlight"
                hasData={!!value}
                x={x(activeSegmentIdentifier)}
                y={yPosition + (yPosition >= height - margin * 4 ? -40 : 40)}
            >
                {!_.isUndefined(value) ? formatter(value) : <FormattedMessage id="noData" />}
            </Overlay>
        );
    }
    return null;
}

const mapStateToProps = (state: State) => {
    return { activeSegmentIdentifier: getActiveSegmentIdentifier(state) };
};

export default connect(mapStateToProps)(SegmentableDayOverlay);
