import FileSaver from 'file-saver';
import { camelCase, isArray, isPlainObject } from 'lodash';

import { CoDriverRequestDownloadPayload } from './types';

export const renameObjectKeys = (target: any): any => {
    if (isArray(target)) {
        const newArray = [];
        for (let i = 0; i < target.length; i++) {
            newArray.push(renameObjectKeys(target[i]));
        }
        return newArray;
    } else if (isPlainObject(target)) {
        const initial: Record<string, string> = {};
        return Object.entries(target).reduce((res, [key, value]) => {
            res[camelCase(key)] = renameObjectKeys(value);
            return res;
        }, initial);
    }
    return target;
};

export const getCoDriverCSV = async (
    serviceBaseUrl: string,
    payload: CoDriverRequestDownloadPayload,
    authToken: string,
    timeout = 90000,
    interval = 500
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    const requestObject = {
        method: 'POST',
        headers: headers,
    };

    return fetch(
        `${serviceBaseUrl}/codriver_export?from=${payload.start}&to=${payload.end}&equipmentId=${payload.equipmentId}&driverId=${payload.driverId}&vin=${payload.vin}&locale=${payload.locale}`,
        requestObject
    ).then(postResponse => {
        const poll = (timeout: number, interval: number) => {
            const endTime = Number(new Date()) + timeout;
            const checkCondition = async (resolve: () => void, reject: (reason?: Error) => void) => {
                try {
                    const response = await fetch(postResponse.headers.get('Location') as string, {
                        method: 'GET',
                        headers,
                    });
                    switch (response.status) {
                        case 200:
                            FileSaver.saveAs(await response.blob(), 'utilization_data.csv');
                            resolve();
                            break;
                        case 204:
                            if (Number(new Date()) < endTime) {
                                setTimeout(checkCondition, interval, resolve, reject);
                            } else {
                                throw new Error('Response takes too long');
                            }
                            break;
                        default:
                            reject();
                    }
                } catch (e) {
                    reject(e);
                }
            };
            return new Promise<void>(checkCondition);
        };
        return poll(timeout, interval).then(getResponse => renameObjectKeys(getResponse));
    });
};
