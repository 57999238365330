import _ from 'lodash';
import moment from 'moment';
import { cloneElement, useMemo } from 'react';

import { getSignal } from '../../api';
import usePerformData from '../../data/hooks/usePerformData';
import { Loadable as LoadableDataStructure } from '../../data/loadable';
import { ISODateToMomentDate } from '../../utils/ISODateToMomentDateObject';
import { VEHICLE_REQUEST_ATTRIBUTES } from './queries';

const removeEmptyEntity = entity => {
    const start = getSignal(entity, 'start', null);
    return ISODateToMomentDate(start).isValid();
};

const mergeParentsWithChildren = (parents, children) => {
    const childrenAsMap = _.groupBy(children, child => getSignal(child, 'vehicleIds', []));
    return parents.filter(removeEmptyEntity).map(parent => {
        const vehicleIdsForParent = getSignal(parent, 'vehicleIds', []);
        const childrenForParent = childrenAsMap[vehicleIdsForParent] || [];
        return {
            ...parent,
            level: 1,
            childrenCount: childrenForParent.length,
            children: childrenForParent.filter(removeEmptyEntity).map(child => ({
                ...child,
                level: 2,
            })),
        };
    });
};

export default function VehicleContentLoader({
    dateRange = { from: moment().subtract(1, 'week'), to: moment() },
    vehicles,
    children,
    performVehicles,
}) {
    const loadableEntities = usePerformData(VEHICLE_REQUEST_ATTRIBUTES, {
        variables: { ...dateRange, vehicleIds: vehicles, segmentBy: 'vehicle' },
    });

    const requestedPerformVehicles = _.intersection(vehicles, performVehicles);
    const loadableChildren = usePerformData(VEHICLE_REQUEST_ATTRIBUTES, {
        variables: { ...dateRange, vehicleIds: requestedPerformVehicles, segmentBy: 'driver_and_vehicle' },
    });

    const parentsWithChildren = useMemo(
        () => LoadableDataStructure.combine(mergeParentsWithChildren, loadableEntities, loadableChildren),
        [loadableChildren, loadableEntities]
    );

    return cloneElement(children, { parentsWithChildren });
}
