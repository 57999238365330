import { keyBy, omit, pick } from 'lodash';

import {
    AssetsReportType,
    Driver,
    DriverReportType,
    FleetReportType,
    GroupReportType,
    ReportConfiguration,
    ReportStatus,
    ReportType,
    TargetType,
    Vehicle,
    VehicleGroup,
} from '../../../api';
import { ReportForm } from '../types';

const isAssetReportType = (reportType: ReportType) => {
    return reportType === ReportType.asset_details;
};

export const handleFormData = (
    report: ReportForm,
    groups: VehicleGroup[],
    drivers: Driver[],
    vehicles: Vehicle[]
): ReportConfiguration => {
    const { entities, ...requestReport } = { ...report, status: ReportStatus.active };

    if (!isAssetReportType(report.reportType)) {
        if ((report.entities.groups.length && report.entities.items.length) || report.entities.items.length) {
            return {
                ...requestReport,
                type: TargetType.drivers,
                drivers: [
                    ...entities.groups.flatMap(group =>
                        drivers?.filter(d => d.groupIds.includes(group)).map(d => d.driverId)
                    ),
                    ...entities.items,
                ],
            } as DriverReportType;
        }
    }

    if (isAssetReportType(report.reportType)) {
        if ((report.entities.groups.length && report.entities.items.length) || report.entities.items.length) {
            return {
                ...requestReport,
                type: TargetType.assets,
                assets: [
                    ...entities.groups.flatMap(group =>
                        vehicles?.filter(v => v.groupIds.includes(group)).map(v => v.vehicleId)
                    ),
                    ...entities.items,
                ],
            } as AssetsReportType;
        }
    }

    if (report.entities.groups.length !== groups.length) {
        return {
            ...requestReport,
            type: TargetType.groups,
            groups: [...entities.groups, ...entities.items],
        } as GroupReportType;
    }

    return {
        ...requestReport,
        type: TargetType.fleet,
    } as FleetReportType;
};

export const handleResponseApi = (report: ReportConfiguration): ReportForm => {
    const responseReport = omit(report, 'drivers', 'assets', 'groups');
    return {
        ...responseReport,
        status: responseReport.status === ReportStatus.active,
        entities: {
            items: [
                ...Object.values((report as DriverReportType)?.drivers || []),
                ...Object.values((report as AssetsReportType)?.assets || []),
            ],
            groups: [...Object.values((report as GroupReportType)?.groups || [])],
        },
    };
};

const convertToListGroupedByGroupId = <A extends { groupIds: string[] }[]>(items: A) => {
    return Object.values(items).reduce((acc, item) => {
        item.groupIds.forEach(group => {
            if (!acc.has(group)) {
                acc.set(group, []);
            }
            acc.set(group, acc.get(group)!.concat([item]));
        });
        return acc;
    }, new Map());
};

export const selectTree = (
    report: ReportConfiguration,
    vehicles: Vehicle[],
    groups: VehicleGroup[],
    drivers: Driver[]
): { drivers: Driver[]; groups: VehicleGroup[]; vehicles: Vehicle[] } => {
    const defaults = { vehicles: [], drivers: [], groups: [] };
    switch (report.type) {
        case TargetType.assets: {
            const vehiclesAsMap = keyBy(vehicles, 'vehicleId');
            return {
                ...defaults,
                vehicles: report.assets.map(asset => vehiclesAsMap[asset]).filter(Boolean),
            };
        }

        case TargetType.drivers: {
            const driversAsMap = keyBy(drivers, 'driverId');
            return {
                ...defaults,
                drivers: report.drivers.map(driver => driversAsMap[driver]).filter(Boolean),
            };
        }

        case TargetType.groups: {
            const groupAsMap = keyBy(groups, 'id');
            const vehiclesGroupedByGroup = convertToListGroupedByGroupId(vehicles);
            const driverGroupedByGroup = convertToListGroupedByGroupId(drivers);
            return {
                groups: report.groups.map(group => groupAsMap[group]).filter(Boolean),
                vehicles: report.groups.flatMap(group => vehiclesGroupedByGroup.get(group)).filter(Boolean),
                drivers: report.groups.flatMap(group => driverGroupedByGroup.get(group)).filter(Boolean),
            };
        }

        case TargetType.fleet: {
            return {
                vehicles: Object.values(vehicles),
                drivers: Object.values(drivers),
                groups: Object.values(groups),
            };
        }
    }
};

export const handlePatch = (report: ReportForm) => {
    const patchedReport = pick(report, 'status', 'name', 'informViaEmail', 'id');
    return { ...patchedReport, status: report.status ? ReportStatus.active : ReportStatus.disabled };
};
