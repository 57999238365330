import dataReducer from '../data/reducer';
import appReducer from '../features/app/reducer';
import downloadReducer from '../features/download/reducer';
import langReducer from '../features/lang/reducer';
import loginReducer from '../features/login/reducer';
import permissionsReducer from '../features/permissions/reducer';
import reportReducer from '../features/reports/reducer';
import serviceInfoReducer from '../features/serviceInfo/reducer';
import settingsReducer from '../features/settings/reducer';
import tokenHandlingReducer from '../features/tokenHandling/reducer';
import treeReducer from '../features/tree/reducer';
import uiReducer from '../features/ui/reducer';
import coDriverReducer from '../pages/coDriver/reducer';
import driverAnalysisReducer from '../pages/driverAnalysis/driverAnalysisReducer';
import vehicleAnalysisReducer from '../pages/vehicleAnalysis/reducer';
import configReducer from './configReducer';

export default {
    app: appReducer,
    config: configReducer,
    lang: langReducer,
    login: loginReducer,
    ui: uiReducer,
    data: dataReducer,
    tree: treeReducer,
    reports: reportReducer,
    tokenHandling: tokenHandlingReducer,
    driverAnalysis: driverAnalysisReducer,
    vehicleAnalysis: vehicleAnalysisReducer,
    download: downloadReducer,
    serviceInfo: serviceInfoReducer,
    permissions: permissionsReducer,
    coDriver: coDriverReducer,
    settings: settingsReducer,
};
