import cn from 'classnames';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import LabelContent from '../../components/DriverLabel';
import { Driver } from '../../types';
import { closeRow, openRow } from './actions';
import { getOpenRows } from './selectors';

export const DriverLabel = ({
    drivers = [],
    openRow,
    closeRow,
    data = { id: '' },
    openRows = [],
}: {
    drivers?: Driver[];
    data?: { id: string; childrenCount?: number };
    openRow: (arg0: string) => void;
    closeRow: (arg0: string) => void;
    openRows?: string[];
}): JSX.Element => {
    const isExpandable = _.get(data, 'drivers', []).length >= 1 && _.get(data, 'childrenCount', 0) >= 2;
    const onClick = (event: React.MouseEvent): void => {
        const rowId = data.id;
        if (isExpandable) {
            event.preventDefault();
            event.stopPropagation();
            openRows.includes(rowId) ? closeRow(rowId) : openRow(rowId);
        }
    };

    return (
        <span className={cn('text-color-dark', isExpandable ? 'hover-text-color-highlight' : '')} onClick={onClick}>
            <LabelContent drivers={drivers} />
        </span>
    );
};

const mapStateToProps = (state: any) => {
    return {
        openRows: getOpenRows(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openRow: function(id: string) {
        dispatch(openRow({ id }));
    },
    closeRow: function(id: string) {
        dispatch(closeRow({ id }));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DriverLabel));
