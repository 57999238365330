import _ from 'lodash';
import always from 'lodash/fp/always';
import identity from 'lodash/fp/identity';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { loadingFormatter } from '../../columns/DOM';
import { ColumnPropType } from '../../components/Column';
import SimpleErrorState from '../../components/SimpleErrorState';
import SettingsDialog from '../../components/table/SettingsDialog';
import SettingsToolbar from '../../components/table/SettingsToolbar';
import Table from '../../components/table/Table';
import UseCases from '../../components/UseCases';
import { VEHICLE_ROUTE } from '../../constants/routes';
import { Loadable, LoadableEntityPropType } from '../../data/loadable';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { getTableViewType, hideSidebar, isMobile, sidebarData } from '../../features/ui/reducer';
import { isFilteringOnSegment } from '../../features/ui/selectors';
import Toolbar from '../../features/ui/Toolbar';
import { calculateColumnOrder } from '../../utils/sorting';
import { findValueExtractor, sortByProperty } from '../../utils/sortyByProperty';
import {
    closeRow,
    openRow,
    setColumnOrderForUseCase,
    setFilteredColumnNamesForUseCase,
    setSortedBy,
    setUseCase,
} from './actions';
import MixedFleetAlert from './MixedFleetAlert';
import { DEFAULT_USE_CASE_CONFIG } from './reducer';
import {
    getOpenRows,
    getSelectedUseCase,
    getSelectedUseCaseConfig,
    getSortedBy,
    getUseCaseConfig,
    getUseCaseSetting,
} from './selectors';
import { defaultUseCaseConfig } from './useCaseConfig';
import { decorateWithWarnings } from './utils';
import DownloadMenu from './VehicleDownloadMenu';
import VehicleFilterSummary from './VehicleFilterSummary';
import VehicleState from './VehicleState';
import VehicleSummary from './VehicleSummary';

const sortChildrenBy = sortFn => data =>
    data.map(d => ({
        ...d,
        children: sortFn(d.children),
    }));

const hasLoadableChildren = loadable => Loadable.map(loadable, data => Boolean(data.length));

const alwaysTrue = always(true);
const alwaysFalse = always(false);

const getShouldShowGraph = compose(
    loadable => Loadable.cata(loadable, identity, alwaysTrue, alwaysTrue, alwaysTrue),
    hasLoadableChildren
);

const getAreSettingsEnabled = compose(
    loadable => Loadable.cata(loadable, identity, alwaysFalse, alwaysFalse, alwaysFalse),
    hasLoadableChildren
);

const createSortAndFlattenFunction = (openRows, sortColumn, sortOrder) => {
    const { key, dataField } = sortColumn || {};

    const dataSort = compose(
        sortChildrenBy(children => sortByProperty(children, dataField, sortOrder, findValueExtractor(key))),
        sortChildrenBy(children => sortByProperty(children, 'drivers', 'asc', findValueExtractor('drivers'))),
        data => sortByProperty(data, dataField, sortOrder, findValueExtractor(key))
    );

    const flattenChildren = data =>
        data.reduce((result, row) => {
            const isRowOpen = openRows.includes(row.id);
            return [...result, row, ...((isRowOpen && row.children) || [])];
        }, []);

    return compose(flattenChildren, dataSort);
};

const createTransformFunction = performVehicleIds => entities =>
    entities.map(entity => decorateWithWarnings(entity, performVehicleIds));

const openExpanderRow = ({ openRows, openRow, closeRow }) => row => {
    const id = row.id;
    if (openRows.includes(id)) {
        closeRow(id);
    } else {
        openRow(id);
    }
};

const findColumnByKey = (columns, key) => columns.find(column => column.key === key);

const transformColumnStringsToObjects = (columnOrder, filteredColumnNames, summaryColumns, columns) => {
    const columnKeys = _.map(columns, 'key');
    const columnKeysInRightOrder = calculateColumnOrder(columnKeys, columnOrder);
    const columnsInRightOrder = columnKeysInRightOrder.map(key => findColumnByKey(columns, key));
    return {
        columnKeysInRightOrder,
        filteredColumns: columnsInRightOrder
            .filter(Boolean)
            .filter(column => !filteredColumnNames.includes(column.key)),
        filteredSummaryColumns: summaryColumns.filter(column => !filteredColumnNames.includes(column.key)),
    };
};

export const Content = ({
    dateRange = {
        start: moment()
            .subtract(1, 'week')
            .toDate(),
        end: moment().toDate(),
    },
    onItemClick,
    setUseCase,
    useCase = { columns: [], summaryColumns: [] },
    selectedVehicle,
    vehicles = [],
    parentsWithChildren,
    useCaseConfig = defaultUseCaseConfig,
    setFilteredColumnNamesForUseCase,
    setColumnOrderForUseCase,
    tableType,
    hideSidebar,
    useCaseSettings = DEFAULT_USE_CASE_CONFIG,
    sortBy = { key: 'vehicles', order: 'asc' },
    setSortBy,
    performVehicles,
    openRow,
    closeRow,
    isFilteringOnSegment,
    openRows = [],
    viewType,
    isMobile = false,
}) => {
    const { columns, summaryColumns, key: selectedUseCaseKey, defaultHiddenColumns } = useCase;
    const [areSettingsVisible, toggleSettings] = useState(false);
    const { columnOrder, filteredColumnNames } = useCaseSettings;
    const { columnKeysInRightOrder, filteredColumns, filteredSummaryColumns } = useMemo(
        () => transformColumnStringsToObjects(columnOrder, filteredColumnNames, summaryColumns, columns),
        [columnOrder, columns, filteredColumnNames, summaryColumns]
    );

    const setColumnOrder = order => setColumnOrderForUseCase({ useCaseKey: selectedUseCaseKey, columnOrder: order });
    const setColumns = columnNames =>
        setFilteredColumnNamesForUseCase({ useCaseKey: selectedUseCaseKey, filteredColumnNames: columnNames });

    const expandedChildren = useMemo(() => {
        const sortAndFlatten = createSortAndFlattenFunction(
            openRows,
            findColumnByKey(columns, sortBy.key),
            sortBy.order
        );
        const transform = createTransformFunction(performVehicles);
        return Loadable.map(parentsWithChildren, compose(sortAndFlatten, transform));
    }, [parentsWithChildren, performVehicles, sortBy.key, sortBy.order, openRows, columns]);

    const shouldShowGraph = isFilteringOnSegment || (getShouldShowGraph(expandedChildren) && Boolean(vehicles.length));
    const areSettingsEnabled = getAreSettingsEnabled(expandedChildren);

    const onOpenChildren = useCallback(openExpanderRow({ openRows, openRow, closeRow }), [openRows, openRow, closeRow]);

    const noVehiclesSelected = (
        <div className="margin-top-20pct">
            <VehicleState
                headlineId="vehicleAnalysis.noVehiclesSelected"
                messageId="vehicleAnalysis.noVehiclesSelected.explanation"
            />
        </div>
    );

    const handleRowToggle = useCallback(
        ({ id, level, children } = {}, active) => {
            if (!active && id) {
                onItemClick({ id, level, childrenIds: (children || []).map(child => child.id) });
            } else {
                // hideSidebar when there is no id returned, so we must be in an
                // unknown disallowed state.
                hideSidebar();
            }
        },
        [hideSidebar, onItemClick]
    );

    const table = Loadable.cata(
        expandedChildren,
        data => {
            if (!data.length) {
                return (
                    <div className={!isFilteringOnSegment ? 'margin-top-20pct' : ''}>
                        <VehicleState
                            headlineId="vehicleAnalysisNoVehiclesReturned"
                            messageId="vehicleAnalysisNoVehiclesReturnedExplanation"
                        />
                    </div>
                );
            }
            return (
                <Table
                    key={_.get(useCase, 'key')}
                    filteredColumns={filteredColumns}
                    onOpenChildren={onOpenChildren}
                    tableType={tableType}
                    sortBy={sortBy}
                    onSort={setSortBy}
                    selectedElements={[selectedVehicle]}
                    openRows={openRows}
                    data={data}
                    onRowClicked={handleRowToggle}
                    viewType={viewType}
                />
            );
        },
        () => (
            <div className="margin-top-20">
                <SimpleErrorState headlineId="error.default" messageId="error.server" />
            </div>
        ),
        () => (
            <Table
                key={'loading'}
                filteredColumns={filteredColumns.map(c => ({ ...c, formatter: loadingFormatter }))}
                onOpenChildren={() => {}}
                tableType={tableType}
                sortBy={sortBy}
                onSort={setSortBy}
                selectedElements={[]}
                openRows={[]}
                data={_.fill(Array(3), { level: 1, id: '' })}
                onRowClicked={() => {}}
                viewType={viewType}
            />
        ),
        () => noVehiclesSelected
    );

    const filterSummary = useMemo(
        () => <VehicleFilterSummary loadableEntities={parentsWithChildren} dateRange={dateRange} />,
        [dateRange, parentsWithChildren]
    );
    const selectedPerformVehicles = performVehicles ? _.intersection(vehicles, performVehicles) : vehicles;

    return (
        <div data-test="VehicleContent">
            <MixedFleetAlert />
            <Toolbar onDateRangeChange={hideSidebar}>
                <div className="table-toolbar-column table-toolbar-column-spacer">
                    <UseCases onSelect={setUseCase} active={selectedUseCaseKey} useCaseConfig={useCaseConfig} />
                </div>
                <div className="table-toolbar-column">
                    <DownloadMenu
                        dateRange={dateRange}
                        vehicles={selectedPerformVehicles}
                        disabled={!areSettingsEnabled}
                        useCaseKey={useCase.key}
                    />
                </div>
            </Toolbar>
            <SettingsDialog
                areSettingsVisible={areSettingsVisible}
                hiddenColumns={filteredColumnNames}
                toggleSettings={toggleSettings}
                defaultHiddenColumns={defaultHiddenColumns}
                columnOrder={columnKeysInRightOrder}
                setColumnOrder={setColumnOrder}
                columnLabels={columns.reduce(
                    (acc, column) => ({ ...acc, [column.key]: <FormattedMessage id={column.labelId} /> }),
                    {}
                )}
                defaultColumnOrder={columns.map(column => column.key)}
                setColumns={setColumns}
            />
            {!isMobile && shouldShowGraph && (
                <VehicleSummary dateRange={dateRange} columns={filteredSummaryColumns} filterSummary={filterSummary} />
            )}
            {vehicles.length ? (
                <Fragment>
                    <SettingsToolbar disabled={!areSettingsEnabled} toggleSettings={toggleSettings} />
                    {table}
                </Fragment>
            ) : (
                noVehiclesSelected
            )}
        </div>
    );
};

Content.propTypes = {
    dateRange: PropTypes.shape({ start: PropTypes.instanceOf(Date), end: PropTypes.instanceOf(Date) }),
    onItemClick: PropTypes.func,
    setUseCase: PropTypes.func,
    selectedVehicle: PropTypes.string,
    sortBy: PropTypes.shape({
        key: PropTypes.string.isRequired,
        order: PropTypes.string.isRequired,
    }),
    setFilteredColumnNamesForUseCase: PropTypes.func,
    setColumnOrderForUseCase: PropTypes.func,
    setSortBy: PropTypes.func,
    hideSidebar: PropTypes.func,
    tableType: PropTypes.string,
    useCaseSettings: PropTypes.shape({
        columnOrder: PropTypes.array,
        filteredColumnNames: PropTypes.array,
    }),
    useCaseConfig: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            columns: PropTypes.arrayOf(ColumnPropType).isRequired,
            summaryColumns: PropTypes.arrayOf(ColumnPropType).isRequired,
        })
    ),
    isFilteringOnSegment: PropTypes.bool,
    parentsWithChildren: LoadableEntityPropType,
    vehicles: PropTypes.arrayOf(PropTypes.string).isRequired,
    performVehicles: PropTypes.arrayOf(PropTypes.string),
    openRows: PropTypes.array,
    openRow: PropTypes.func,
    closeRow: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    setFilteredColumnNamesForUseCase: ({ useCaseKey, filteredColumnNames }) =>
        dispatch(setFilteredColumnNamesForUseCase({ useCaseKey, filteredColumnNames })),
    setColumnOrderForUseCase: ({ useCaseKey, columnOrder }) =>
        dispatch(setColumnOrderForUseCase({ useCaseKey, columnOrder })),
    setUseCase: useCaseKey => dispatch(setUseCase(useCaseKey)),
    hideSidebar: () => dispatch(hideSidebar()),
    setSortBy: sortBy => dispatch(setSortedBy(sortBy)),
    openRow: id => dispatch(openRow({ id })),
    closeRow: id => dispatch(closeRow({ id })),
});

export default connect(
    state => ({
        useCaseSettings: getUseCaseSetting(state, getSelectedUseCase(state)),
        useCaseConfig: getUseCaseConfig(),
        useCase: getSelectedUseCaseConfig(state),
        sortBy: getSortedBy(state),
        selectedVehicle: _.get(sidebarData(state, VEHICLE_ROUTE), 'id'),
        performVehicles: getPerformVehicles(state),
        isFilteringOnSegment: isFilteringOnSegment(state),
        openRows: getOpenRows(state),
        viewType: getTableViewType(state),
        isMobile: isMobile(state),
    }),
    mapDispatchToProps
)(Content);
