import { createAsyncThunk, createSlice, Draft } from '@reduxjs/toolkit';

import {
    deleteConfiguration,
    EditReportConfigurationRequest,
    fetchReportConfigurations,
    patchReportConfiguration,
    postReportConfiguration,
} from '../../api';
import { backendConfig } from '../../data/selectors';
import { errorNotification, successNotification } from '../../middlewares/notificationMiddleware';
import { RootState } from '../../setup/store';
import { ReportStatePart } from '../../setup/types';
import { Id } from '../../types';
import { getAccessToken } from '../tokenHandling/selectors';
import { ReportConfiguration, Status } from './types';

const getUrlAndToken = (state: RootState) => {
    const accessToken = getAccessToken(state);
    const url = backendConfig(state, 'REPORTS_SERVICE');
    return { accessToken, url };
};

// Thunk Actions
export const loadReportConfigurations = createAsyncThunk<
    { reports: ReportConfiguration[] },
    void,
    { state: RootState }
>('reports/fetchReports', async (__, { getState, dispatch }) => {
    const { accessToken, url } = getUrlAndToken(getState());
    try {
        const reports = await fetchReportConfigurations(url, accessToken);
        return { reports };
    } catch (e) {
        dispatch(errorNotification('error.default'));
        throw e;
    }
});

export const createReportConfiguration = createAsyncThunk<
    { report: ReportConfiguration },
    Omit<ReportConfiguration, 'id'>,
    { state: RootState }
>('reports/createReportConfiguration', async (configPayload, { getState, dispatch }) => {
    const { accessToken, url } = getUrlAndToken(getState());

    try {
        const report = await postReportConfiguration(url, accessToken, configPayload);
        dispatch(successNotification('reports.create.success'));
        return { report };
    } catch (e) {
        dispatch(errorNotification('reports.create.error'));
        throw e;
    }
});

export const deleteReportConfiguration = createAsyncThunk<string, string, { state: RootState }>(
    'reports/deleteReportConfiguration',
    async (reportId, { getState, dispatch }) => {
        const { accessToken, url } = getUrlAndToken(getState());
        try {
            await deleteConfiguration(url, accessToken, reportId);
            dispatch(successNotification('reports.delete.success'));
            return reportId;
        } catch (e) {
            dispatch(errorNotification('reports.delete.error'));
            throw e;
        }
    }
);

export const editReportConfiguration = createAsyncThunk<
    { report: ReportConfiguration },
    EditReportConfigurationRequest,
    { state: RootState }
>('reports/editReportConfiguration', async (changes, { getState, dispatch }) => {
    const { accessToken, url } = getUrlAndToken(getState());
    try {
        const configuration = getReportById(getState(), changes.id);
        await patchReportConfiguration(url, accessToken, changes);
        dispatch(successNotification('reports.edit.success'));
        return { report: { ...configuration, ...changes } };
    } catch (e) {
        dispatch(errorNotification('reports.edit.error'));
        throw e;
    }
});

// Reducers
const loadAllReportsFailed = (state: Draft<ReportsState>) => {
    state.status = Status.Failed;
};

const loadAllReportsPending = (state: Draft<ReportsState>) => {
    state.status = Status.Loading;
};

const loadReportConfiguration = (
    state: Draft<ReportsState>,
    { payload: { report } }: { payload: { report: ReportConfiguration } }
) => {
    state.reports[report.id] = report;
};

const loadAllReportsConfiguration = (
    state: Draft<ReportsState>,
    { payload: { reports } }: { payload: { reports: ReportConfiguration[] } }
) => {
    reports.forEach(report => {
        state.reports[report.id] = report;
    });
    state.status = Status.Loaded;
};

const deleteReportConfigurationFulfilled = (state: Draft<ReportsState>, { payload: reportId }: { payload: string }) => {
    delete state.reports[reportId];
};

// Slice
export type ReportsState = { reports: Record<Id, ReportConfiguration>; status: Status };

const defaultState: ReportsState = {
    reports: {},
    status: Status.Ready,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState: defaultState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadReportConfigurations.fulfilled, loadAllReportsConfiguration);
        builder.addCase(createReportConfiguration.fulfilled, loadReportConfiguration);
        builder.addCase(deleteReportConfiguration.fulfilled, deleteReportConfigurationFulfilled);
        builder.addCase(editReportConfiguration.fulfilled, loadReportConfiguration);
        builder.addCase(loadReportConfigurations.rejected, loadAllReportsFailed);
        builder.addCase(loadReportConfigurations.pending, loadAllReportsPending);
    },
});

export default reportsSlice.reducer;

export const getReportById = (state: ReportStatePart, id: string) => state.reports.reports[id];
export const getReports = (state: ReportStatePart) => Object.values(state.reports.reports);
export const getStatus = (state: ReportStatePart) => state.reports.status;
