import _ from 'lodash';
import moment from 'moment';

import { TimeSegmentUnit } from '../../components/graphComponents/utils';
import { DateRange } from '../../types';

const difference = (dateRange: DateRange, type: TimeSegmentUnit) =>
    Math.abs(moment(dateRange.start).diff(dateRange.end, type, true));

const configureGetCreateTimeInterval = <T>(config: {
    hours: T;
    days: T;
    weeks: T;
    months: T;
    quarters: T;
    years: T;
}) => (dateRange: DateRange): T => {
    if (difference(dateRange, 'day') <= 3) {
        return config.hours;
    }

    if (difference(dateRange, 'month') <= 1) {
        return config.days;
    }

    if (difference(dateRange, 'month') <= 6) {
        return config.weeks;
    }

    if (difference(dateRange, 'year') <= 2) {
        return config.months;
    }

    if (difference(dateRange, 'year') <= 4) {
        return config.quarters;
    }

    return config.years;
};

const calculateDateRange = (unit: TimeSegmentUnit) => (start: Date, end: Date) => {
    const step = Math.max(Math.floor(Math.log(Math.abs(moment(start).diff(end, unit)) * 5)), 1);
    return _.range(0, moment(end).diff(start, unit) + 1, step).map(e =>
        moment(start)
            .add(e, unit)
            .toDate()
    );
};

export const createTickFormatter = (
    formatDate: (d: Date, options: any) => string,
    formatMessage: ({ id }: { id: string }) => string
) =>
    configureGetCreateTimeInterval({
        hours: (d: Date) =>
            formatDate(d, {
                hour: 'numeric',
                minute: 'numeric',
            }),
        days: (d: Date) =>
            formatDate(d, {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
            }),
        weeks: (d: Date) => `${moment(d).week()} ${formatMessage({ id: 'time_interval_calendar_week_short' })}`,
        months: (d: Date) =>
            formatDate(d, {
                year: '2-digit',
                month: 'long',
            }),
        quarters: (d: Date) =>
            `${moment(d).quarter()} ${formatMessage({ id: 'time_interval_quarter_short' })} ${formatDate(d, {
                year: 'numeric',
            })}`,
        years: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
            }),
    });

export const timeDiff = configureGetCreateTimeInterval<moment.unitOfTime.Base>({
    hours: 'hours',
    days: 'hours',
    weeks: 'day',
    months: 'day',
    quarters: 'months',
    years: 'year',
});

export const axisTimeDiff = configureGetCreateTimeInterval<moment.unitOfTime.Base>({
    hours: 'hours',
    days: 'hours',
    weeks: 'day',
    months: 'day',
    quarters: 'months',
    years: 'year',
});

export const interval = configureGetCreateTimeInterval({
    hours: calculateDateRange('hours'),
    days: calculateDateRange('day'),
    weeks: calculateDateRange('week'),
    months: calculateDateRange('month'),
    quarters: calculateDateRange('month'),
    years: calculateDateRange('year'),
});
