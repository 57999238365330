import { Slope } from './types';

const isLow = (slope: number) => slope >= 1 && slope < 2;
const isMedium = (slope: number) => slope >= 2 && slope < 5;
const isSteep = (slope: number) => slope >= 5;
const isPositiveSlope = (slope: number) => slope >= 0;

const sortIntoBucket = (buckets: Slope, { slope, mileage }: { slope: number; mileage: number }) => {
    const value = Math.abs(slope);
    if (isLow(value)) {
        return { ...buckets, low: buckets.low + mileage };
    }
    if (isMedium(value)) {
        return { ...buckets, medium: buckets.medium + mileage };
    }
    if (isSteep(value)) {
        return { ...buckets, steep: buckets.steep + mileage };
    }
    return buckets;
};

const createBucket = () => ({
    low: 0,
    medium: 0,
    steep: 0,
});

const aggregateSlopeInBuckets = (slopes: { slope: number; mileage: number }[]) =>
    slopes.reduce(
        (result, { slope, mileage }) => {
            return isPositiveSlope(slope)
                ? { ...result, up: sortIntoBucket(result.up, { slope, mileage }) }
                : { ...result, down: sortIntoBucket(result.down, { slope: (slope + 1) * -1, mileage }) };
        },
        { up: createBucket(), down: createBucket() }
    );

export default aggregateSlopeInBuckets;
