// This is the runtime configuration being used for tests

export const testConfig = {
    backend: {
        PERFORMANCE_HISTORY_SERVICE: `${window.location.origin}/api/performancehistory/v2`,
        USERADMIN_SERVICE: `${window.location.origin}/user-management`,
        PERFORMANCE_MERGER: `${window.location.origin}/api/performancemerger`,
        CONNECTED_CODRIVER_SERVICE: `${window.location.origin}`,
        HERE_TOKEN_HANDLING: `${window.location.origin}/map/configurations`,
        EXCEL_EXPORT_SERVICE: window.location.origin,
        OPCON: `${window.location.origin}`,
        MENU_URL: `${window.location.origin}/menu/rio`,
        TAGS_SERVICE: `${window.location.origin}/tags`,
        ASSETS_SERVICE: `${window.location.origin}/assets`,
        DRIVERS_SERVICE: `${window.location.origin}/drivers`,
        SETTINGS_SERVICE: `${window.location.origin}/settings`,
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.test',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: false,
        mockLocale: 'de-DE',
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
};
