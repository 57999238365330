import { connect } from 'react-redux';

import { Loadable } from '../../data/loadable';
import { getSelectedDriverIds } from '../../data/selectors';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { getActiveDateRange } from '../../features/ui/selectors';
import { State } from '../../setup/types';
import DriverAnalysis from './DriverAnalysis';
import DriverContentLoader from './DriverContentLoader';

export const Entry: React.FC<{
    selectedDriverIds: string[];
    vehicles: string[];
    activeDateRange: any;
}> = ({ selectedDriverIds, vehicles, activeDateRange }) => {
    // TODO: remove hotfix that prevents calling backend with empty driver list
    return selectedDriverIds.length ? (
        <DriverContentLoader selectedDriverIds={selectedDriverIds} vehicles={vehicles} dateRange={activeDateRange}>
            <DriverAnalysis />
        </DriverContentLoader>
    ) : (
        <DriverAnalysis parentsWithChildren={Loadable.createNotRequested()} />
    );
};

export const mapStateToProps = (state: State) => ({
    selectedDriverIds: getSelectedDriverIds(state),
    vehicles: getPerformVehicles(state),
    activeDateRange: getActiveDateRange(state),
});

export default connect(mapStateToProps)(Entry);
