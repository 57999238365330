import _ from 'lodash';
import moment from 'moment';
import { memo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { DataPoint, Guidelines, ValueText } from '../../components/graphComponents/Graph';
import GraphDataProvider from '../../components/graphComponents/GraphDataProvider';
import Line from '../../components/graphComponents/Line';
import { Overlay } from '../../components/graphComponents/Overlay';
import timeFormatBasedOnDateRange from '../../components/graphComponents/timeFormatBasedOnDateRange';
import XAxis from '../../components/graphComponents/XAxis';
import {
    generateTicks,
    generateTicksFromSeconds,
    YAxisLines,
    YAxisTicks,
} from '../../components/graphComponents/YAxis';
import { Section } from '../../components/summary/types';
import { SECONDS } from '../../constants/units';
import SegmentableDay from '../../features/ui/SegmentableDay';
import SegmentableDayOverlay from '../../features/ui/SegmentableDayOverlay';
import { steps } from '../../features/ui/utils';
import { DateRange } from '../../types';
import { dateRangeSegmentedByStep } from '../../utils/dateRangeSegmentedByStep';
import { formatSecondsToTime } from '../../utils/stringFormatters';
import { HoverInfo } from '../driverAnalysis/types';

const findMatchingY = (key: Date, data: any, step: moment.unitOfTime.Diff) => {
    const possibleMatch = data.find(
        (d: any) =>
            moment(d.x)
                .startOf(step)
                .diff(moment(key).startOf(step), 'days') === 0
    );
    return _.get(possibleMatch, 'y', undefined);
};

const convertDataToXYValue = ({ data }: any) =>
    data.map((e: any) => ({
        x: moment(e.date).toDate(),
        y: _.get(e, 'value.value'),
    }));

const mergeExistingDataWithPlaceholders = ({
    dateRange,
    existingData,
}: {
    dateRange: DateRange;
    existingData: any;
}) => {
    const step = steps(dateRange);
    const xAxisValues = dateRangeSegmentedByStep({ dateRange, step });

    if (!xAxisValues.length) {
        return existingData;
    }

    return xAxisValues.map(date => {
        return {
            x: date,
            y: findMatchingY(date, existingData, step),
        };
    });
};

export const Graph: React.FC<{
    data: any;
    dateRange: DateRange;
    intl: any;
    column: Section;
    width?: number;
}> = ({
    data = [],
    dateRange = { start: new Date(), end: new Date() },
    intl: { formatNumber = (value: number) => value, formatDate, formatMessage },
    column,
    width = 1251,
}) => {
    const dimensions = { height: 400, width, margin: 40 };
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);
    const xYList = convertDataToXYValue({ data });
    const mappedData = mergeExistingDataWithPlaceholders({ dateRange, existingData: xYList });
    const hoverOffset = 40;

    const tickFormatter = timeFormatBasedOnDateRange(formatDate, formatMessage);
    const { average } = column.stats;
    return (
        <div
            className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
            style={{ height: dimensions.height }}
        >
            {hoverInfo && (
                <Overlay
                    hasData={!!hoverInfo.data}
                    x={hoverInfo.x}
                    y={
                        hoverInfo.y +
                        (hoverInfo.y >= dimensions.height - dimensions.margin * 4 ? -hoverOffset : hoverOffset)
                    }
                >
                    {!_.isUndefined(_.get(hoverInfo, 'data.y')) ? (
                        <>{column.formatter(hoverInfo.data.y)}</>
                    ) : (
                        <FormattedMessage id="noData" />
                    )}
                </Overlay>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                dimensions={dimensions}
                data={mappedData}
            >
                {!_.isUndefined(average) && (
                    <ValueText
                        show={Boolean(hoverInfo)}
                        value={average}
                        formatter={column.formatter}
                        className="text-bold"
                        label={<FormattedMessage id="average" />}
                    />
                )}
                <SegmentableDayOverlay formatter={column.formatter} />
                <svg
                    width={'100%'}
                    height={'100%'}
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <Guidelines average={average!} show={Boolean(hoverInfo)} />
                    <YAxisLines tickGenerator={column.unit === SECONDS ? generateTicksFromSeconds : generateTicks} />
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                    />
                    <Line />
                    <DataPoint />
                    <YAxisTicks
                        formatter={column.unit === SECONDS ? formatSecondsToTime : formatNumber}
                        tickGenerator={column.unit === SECONDS ? generateTicksFromSeconds : generateTicks}
                    />
                    <SegmentableDay onHover={setHover} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default memo(
    withResizeDetector(injectIntl(Graph), {
        handleWidth: true,
        handleHeight: false,
        refreshMode: 'throttle',
        refreshRate: 100,
    })
);
