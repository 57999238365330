import { ToggleButton } from '@rio-cloud/rio-uikit';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UseCaseKey } from '../pages/driverAnalysis/driverAnalysisReducer';
import { UseCaseConfig } from '../pages/driverAnalysis/useCaseConfig';
import { trackingAttributes } from '../utils/googleTagManager';

interface UseCasesI {
    onSelect: (key: UseCaseKey) => void;
    active: string;
    useCaseConfig: UseCaseConfig[];
}

const UseCases: React.FC<UseCasesI> = ({ onSelect, active, useCaseConfig = [] }) => (
    <div className="btn-toolbar">
        <div className="btn-group display-flex flex-row">
            {useCaseConfig.map(({ key }) => (
                <ToggleButton key={key} active={active === key} onClick={(): void => onSelect(key as UseCaseKey)}>
                    <div {...trackingAttributes('click', 'performance, toggleButton', 'click:toggleButton', key)}>
                        <FormattedMessage id={`view-data.${key}`} />
                    </div>
                </ToggleButton>
            ))}
        </div>
    </div>
);

export default UseCases;
