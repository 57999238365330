import { compose } from 'redux';

import { SECONDS, TIME } from '../constants/units';
import { plainAverage, withUnit } from '../utils/formatters';
import { formatSecondsToTime } from '../utils/stringFormatters';
import createColumn from './createColumn';

export const timeSignal = {
    unit: SECONDS,
    displayedUnit: TIME,
    format: compose(withUnit(TIME), formatSecondsToTime),
    average: plainAverage,
};

export const drivingTime = createColumn({
    key: 'drivingTime',
    dataField: 'drivingTime',
    labelId: 'drivingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
    dataSort: true,
});

export const idlingTime = createColumn({
    key: 'idlingTime',
    dataField: 'idlingTime',
    labelId: 'idlingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const operatingTime = createColumn({
    key: 'operatingTime',
    dataField: 'operatingTime',
    labelId: 'operatingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const activeCoastingTime = createColumn({
    key: 'activeCoastingTime',
    dataField: 'activeCoastingTime',
    labelId: 'activeCoastingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const coastingCCTime = createColumn({
    key: 'coastingCcTime',
    dataField: 'coastingCcTime',
    labelId: 'coastingCcTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const kickdownTime = createColumn({
    key: 'kickdownTime',
    dataField: 'kickdownTime',
    labelId: 'kickdownTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const accelerationPedalTimeWithCc = createColumn({
    key: 'accelerationPedalTimeWithCc',
    dataField: 'accelerationPedalTimeWithCc',
    labelId: 'accelerationPedalTimeWithCc',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const serviceBrakeTime = createColumn({
    key: 'serviceBrakeTime',
    dataField: 'serviceBrakeTime',
    labelId: 'serviceBrakeTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const motorBrakeTime = createColumn({
    key: 'motorBrakeTime',
    dataField: 'motorBrakeTime',
    labelId: 'motorBrakeTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const retarderTime = createColumn({
    key: 'retarderTime',
    dataField: 'retarderTime',
    labelId: 'retarderTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const cruiseControlTimeInPotential = createColumn({
    key: 'cruiseControlTimeInPotential',
    dataField: 'cruiseControlTimeInPotential',
    labelId: 'cruiseControlTimeInPotential',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const noCruiseControlTimeInPotential = createColumn({
    key: 'noCruiseControlTimeInPotential',
    dataField: 'noCruiseControlTimeInPotential',
    labelId: 'noCruiseControlTimeInPotential',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});
