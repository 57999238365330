import {
    AssetsReportType,
    DriverReportType,
    FleetReportType,
    GroupReportType,
    ReportConfiguration,
    ReportConfigurationRequest,
    ReportConfigurationRequestType,
} from '../models/reportConfiguration';
import { encodeJson } from './encoder';

export function encodeReportConfiguration(raw: Omit<ReportConfiguration, 'id'>): ReportConfigurationRequestType {
    const transformConfiguration = transformReportConfiguration(raw);
    return encodeJson(ReportConfigurationRequest, transformConfiguration);
}

function transformReportConfiguration(decoded: Omit<ReportConfiguration, 'id'>): ReportConfigurationRequestType {
    if ('drivers' in decoded) {
        const { drivers, type, ...defaults } = decoded as DriverReportType;
        return {
            ...defaults,
            target: { drivers, type },
        };
    }
    if ('groups' in decoded) {
        const { groups, type, ...defaults } = decoded as GroupReportType;
        return {
            ...defaults,
            target: { groups, type },
        };
    }
    if ('assets' in decoded) {
        const { assets, type, ...defaults } = decoded as AssetsReportType;
        return {
            ...defaults,
            target: { assets, type },
        };
    }
    const { type, ...defaults } = decoded as FleetReportType;
    return {
        ...defaults,
        target: { type },
    };
}
