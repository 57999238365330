import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';

export function getBelowMileageWarning(row: unknown): JSX.Element | null {
    const drivenMileage = getSignal(row, 'mileage', null);
    return _.isNumber(drivenMileage) && drivenMileage < 5 ? (
        <BelowMileageWarning key="minMileageThresholdWarning" />
    ) : null;
}

function BelowMileageWarning(): JSX.Element {
    return (
        <React.Fragment>
            <dt>
                <FormattedMessage id="minMileageThresholdWarning" />
            </dt>
            <dd>
                <FormattedMessage id="minMileageThresholdWarningDetail" />
            </dd>
        </React.Fragment>
    );
}

export default BelowMileageWarning;
