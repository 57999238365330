import _ from 'lodash';
import { createSelector } from 'reselect';

import { PermissionsStatePart } from '../../setup/types';
import { dayChangeDetection, generateDateRangeConfig } from '../../utils/dateRangePresets';
import { LegacyPermissionsType } from './reducer';

export const getPermissions = (state: PermissionsStatePart): { legacy: LegacyPermissionsType } =>
    _.get(state, 'permissions');

export const getLegacyPermissions = (state: PermissionsStatePart) => _.get(getPermissions(state), 'legacy');

export const getPerformVehicles = (state: PermissionsStatePart): string[] =>
    _.get(getLegacyPermissions(state), 'perform', []);
export const hasPerformVehicles = (state: PermissionsStatePart): boolean => getPerformVehicles(state).length > 0;

export const getPermissionBasedDateRangeConfig = createSelector(
    [getLegacyPermissions, dayChangeDetection],
    legacyPermissions =>
        generateDateRangeConfig({
            featureFlags: legacyPermissions.dateRangePresets,
            tenDaysLimit: legacyPermissions.tenDaysLimit,
            isTodayAllowed: legacyPermissions.isTodayAllowed,
            hasTimePicker: legacyPermissions.isTimeSelectionAllowed,
        })
);

export const getFeatureToggles = (state: PermissionsStatePart) => state.permissions.featureToggles;
