import { TableToolbar } from '@rio-cloud/rio-uikit';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { useSelector } from 'react-redux';

import { getTableViewType, setTableViewType } from '../../features/ui/reducer';
import SegmentFilter from '../../features/ui/SegmentFilter';
import { useAppDispatch } from '../../setup/store';

export const SettingsToolbar: React.FunctionComponent<{
    disabled?: boolean;
    toggleSettings: (visible: boolean) => void;
}> = ({ disabled, toggleSettings }) => {
    const dispatch = useAppDispatch();
    const viewType = useSelector(getTableViewType);
    const onViewTypeChange = (viewType: string) => dispatch(setTableViewType(viewType));

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column min-width-200">
                        <SegmentFilter />
                    </div>
                </div>

                <div className="table-toolbar-group-right display-none-xs display-flex-sm">
                    <div className="table-toolbar-column table-toolbar-column-spacer">
                        <TableViewToggles
                            initialViewType={viewType}
                            viewType={viewType}
                            onViewTypeChange={onViewTypeChange}
                        />
                    </div>
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar">
                            <button
                                disabled={disabled}
                                className="btn btn-default btn-icon-only"
                                data-test="table-settings-toggler"
                                onClick={() => toggleSettings(true)}
                            >
                                <span className="rioglyph rioglyph-cog" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};

export default SettingsToolbar;
