import _ from 'lodash';

import { consumptionSignal } from '../columns/consumption';
import { distanceSignal } from '../columns/distance';
import { timeSignal } from '../columns/time';
import { weightSignalInKg } from '../columns/weight';

const summedUnits = [consumptionSignal.unit, distanceSignal.unit, timeSignal.unit, weightSignalInKg.unit];
export const getSummaryValue = (section, stats) =>
    section.formatter(_.includes(summedUnits, section.unit) ? _.sumBy(stats.values, 'value.value') : stats.average);
