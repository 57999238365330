import { Spinner } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import always from 'lodash/fp/always';
import { cloneElement } from 'react';

import useOpconData from '../../data/hooks/useOpconData';
import { Loadable } from '../../data/loadable';

export default function GetBasicOpConData({
    start,
    end,
    driverIds,
    vehicleIds,
    children,
}: {
    start: Date;
    end: Date;
    driverIds: string[];
    vehicleIds: string[];
    children?: any;
}) {
    const groupBy = [
        driverIds.filter(Boolean).length && 'driver-id',
        vehicleIds.filter(Boolean).length && 'asset-id',
    ].filter<string>(Boolean as any);

    const opConDataRequestForSelectedVehicle = useOpconData({
        dateRange: { start, end },
        vehicleIds,
        driverIds,
        groupBy,
        debounced: false,
    });

    return Loadable.cata(
        opConDataRequestForSelectedVehicle,
        data => {
            const props = { opCon: _.get(data, [0], {}) };
            return cloneElement(children, props);
        },
        always(null),
        always(<Spinner />),
        always(<Spinner />)
    );
}
