import { array, intersection, literal, nullType, partial, string, type, TypeOf, union } from 'io-ts';

import { Vehicle, VehicleStatus, VehicleType } from '../models/types';
import { decodeJson } from './decoder';
import { getVehicles } from './mfhConverter';

const Asset = type({
    account_id: string,
    brand: union([string, nullType]),
    id: string,
    identification: union([string, nullType]),
    identification_type: union([literal('vin'), nullType]),
    name: string,
    status: union([literal(VehicleStatus.ACTIVE), literal(VehicleStatus.ARCHIVED)]),
    type: union([
        literal(VehicleType.TRAILER.toLowerCase()),
        literal(VehicleType.BUS.toLowerCase()),
        literal(VehicleType.TRUCK.toLowerCase()),
        literal(VehicleType.VAN.toLowerCase()),
    ]),
    _embedded: type({
        tags: type({
            items: array(
                type({
                    id: string,
                })
            ),
        }),
    }),
});

const AssetApiResponse = type({
    items: array(Asset),
});

const AssociationApiResponse = type({
    items: array(
        intersection([
            partial({
                asset_id: string,
            }),
            type({
                asset_id: string,
                _embedded: type({
                    device: type({
                        type: string, // we soften this here, in order to not explode when new types get added
                    }),
                }),
            }),
        ])
    ),
});

export type AssociationApiResponseType = TypeOf<typeof AssociationApiResponse>;
export type AssetApiResponseType = TypeOf<typeof AssetApiResponse>;
export type AssetType = TypeOf<typeof Asset>;

export function decodeVehicle(rawAssets: AssetApiResponseType, rawAssociations: AssociationApiResponseType): Vehicle[] {
    const assets = decodeJson(AssetApiResponse, rawAssets);
    const associations = decodeJson(AssociationApiResponse, rawAssociations);
    return getVehicles(assets, associations);
}
