import { createSlice } from '@reduxjs/toolkit';
import TableViewTogglesValues from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import _ from 'lodash';
import moment from 'moment';

import { UiStatePart } from '../../setup/types';
import { DateRange } from '../../types';

export type UiState = {
    dateRange: DateRange;
    sidebar?: { type: string; data: { id: string; level: number; childrenIds: string[] } };
    filteredSegment?: DateRange;
    columnToggleDialogVisible: boolean;
    isGraphCollapsed: boolean;
    tableViewType: string;
    isMobile: boolean;
};

export const defaultState: UiState = {
    isGraphCollapsed: false,
    dateRange: {
        start: moment()
            .startOf('day')
            .subtract(10, 'days')
            .toDate(),
        end: moment()
            .endOf('day')
            .toDate(),
    },
    sidebar: undefined,
    columnToggleDialogVisible: false,
    filteredSegment: undefined,
    tableViewType: TableViewTogglesValues.VIEW_TYPE_TABLE,
    isMobile: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState: defaultState,
    reducers: {
        setFilteredSegment(state, { payload }) {
            state.filteredSegment = payload;
            state.sidebar = undefined;
        },
        showSidebar(state, { payload: { data, type } }) {
            if (data) {
                state.sidebar = { data, type };
            }
        },
        hideSidebar(state) {
            return { ...state, sidebar: undefined };
        },
        setIsGraphCollapsed(state, { payload: isCollapsed }) {
            return { ...state, isGraphCollapsed: isCollapsed };
        },
        setDateRange(state, { payload }: { payload: DateRange }) {
            state.dateRange = payload;
            state.filteredSegment = undefined;
            state.sidebar = undefined;
        },
        setTableViewType(state, { payload: viewType }) {
            return { ...state, tableViewType: viewType };
        },

        setIsMobile(state) {
            return { ...state, tableViewType: TableViewTogglesValues.VIEW_TYPE_MULTI_CARDS, isMobile: true };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const {
    setFilteredSegment,
    showSidebar,
    hideSidebar,
    setDateRange,
    setIsGraphCollapsed,
    setTableViewType,
    setIsMobile,
} = uiSlice.actions;

export default uiSlice.reducer;

export const isSidebarVisible = (state: UiStatePart, type: string) =>
    !!state.ui.sidebar && state.ui.sidebar.type === type;
export const sidebarData = (state: UiStatePart, key: string) => {
    if (state.ui.sidebar?.type === key) {
        return _.get(state, 'ui.sidebar.data');
    }
};
export const isGraphCollapsed = (state: UiStatePart) => !!_.get(state, 'ui.isGraphCollapsed');
export const getTableViewType = (state: UiStatePart) => state.ui.tableViewType;
export const isMobile = (state: UiStatePart) => state.ui.isMobile;
