import { configureStorage } from './storage';

export const extractAccessTokenFromWindowLocation = (window?: Window) => {
    if (!window || !window.location || !window.location.href) {
        return undefined;
    }

    if (typeof window.location.href !== 'string') {
        return undefined;
    }

    let token;
    window.location.href.replace(/access_token=([^&]+)/u, (_: unknown, it: string) => {
        return (token = it);
    });

    return token;
};

export const configureAccessToken = (storage: Storage, window?: Window) => {
    const urlToken = extractAccessTokenFromWindowLocation(window);

    if (urlToken) {
        storage.saveAccessToken(urlToken);
    }

    return storage;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const accessToken = configureAccessToken(configureStorage(), typeof window === 'undefined' ? null : window);
