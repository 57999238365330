import moment from 'moment';

import { DateRange } from '../../types';
import configureGetCreateTimeInterval from '../../utils/timeInterval';

export const steps = configureGetCreateTimeInterval<moment.unitOfTime.Diff>({
    days: 'day',
    weeks: 'weeks',
    months: 'months',
    quarters: 'quarters',
    years: 'year',
});

export const formatSegmentDescriptionLong = (
    formatDate: (d: Date, options: any) => string,
    formatMessage: ({ id }: { id: string }) => string
) =>
    configureGetCreateTimeInterval({
        days: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
            }),
        weeks: (d: Date) =>
            `${moment(d).week()} ${formatMessage({ id: 'time_interval_calendar_week_short' })} ${formatDate(d, {
                year: 'numeric',
            })}`,
        months: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
                month: 'long',
            }),
        quarters: (d: Date) =>
            `${moment(d).quarter()} ${formatMessage({ id: 'time_interval_quarter_short' })} ${formatDate(d, {
                year: 'numeric',
            })}`,
        years: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
            }),
    });

export const segmentInterval = (dateRange: DateRange) => {
    const step = steps(dateRange);
    const momentRange = { start: moment(dateRange.start), end: moment(dateRange.end) };

    return (date: Date): DateRange => {
        const momentStart = moment(date);
        const momentEnd = momentStart
            .clone()
            .add(1, step)
            .subtract(1, 'second');

        return {
            start: moment.max(momentStart, momentRange.start).toDate(),
            end: moment.min(momentEnd, momentRange.end).toDate(),
        };
    };
};
