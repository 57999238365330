import _ from 'lodash';

const isUnknownRoadType = type => type.endsWith('-0');

export const compareCountryRoads = (first, second) => {
    const firstType = _.get(first, 'type');
    const secondType = _.get(second, 'type');
    if (!firstType && !secondType) {
        return 0;
    }
    if (!firstType) {
        return 1;
    }
    if (!secondType) {
        return -1;
    }
    if (isUnknownRoadType(firstType) && isUnknownRoadType(secondType)) {
        return 0;
    }
    if (isUnknownRoadType(firstType)) {
        return 1;
    }
    if (isUnknownRoadType(secondType)) {
        return -1;
    }
    return firstType.localeCompare(secondType);
};

export const groupRoadsByCountry = roads => {
    const roadsByCountry = _.sortBy(
        _.map(_.groupBy(roads, 'country'), (roads, country) => ({ country, roads })),
        'country'
    );

    return _.reverse(
        _.sortBy(
            _.map(roadsByCountry, countryRoads => {
                return {
                    country: countryRoads.country,
                    mileage: _.sumBy(countryRoads.roads, 'mileage'),
                    roads: _.map(_.groupBy(countryRoads.roads, 'highestRoadType'), (roads, type) => ({
                        type,
                        name: _.get(_.first(roads), 'name'),
                        roads,
                    })).sort(compareCountryRoads),
                };
            }),
            ['mileage']
        )
    );
};
