import { fetchGetWithJSONResponseCyclicWithPagination } from './api';
import { decodeDrivers } from './decoder/driverDecoder';

const mergeResponses = (responses: any[]) => ({
    items: responses.reduce((acc: any[], response: any) => acc.concat(...response.items), []),
});

export async function fetchDrivers(baseUrl: string, authToken: string) {
    const responses = await fetchGetWithJSONResponseCyclicWithPagination(
        baseUrl,
        `drivers?embed=(identifications,tags)`,
        authToken
    );

    return decodeDrivers(mergeResponses(responses));
}
