import { createSlice } from '@reduxjs/toolkit';
import { TableViewToggles } from '@rio-cloud/rio-uikit';

export const defaultState = {
    useCaseSettings: {
        overview: {
            columnOrder: [],
            filteredColumnNames: ['system'],
        },
    },
    activeSummaryTab: null,
    tableType: TableViewToggles.VIEW_TYPE_TABLE,
    selectedUseCase: 'overview',
    openRows: [],
};

export const DEFAULT_USE_CASE_CONFIG = {
    columnOrder: [],
    filteredColumnNames: [],
};

function getUseCaseFromState(vehicleAnalysisStateSlice, key) {
    return vehicleAnalysisStateSlice.useCaseSettings[key] || DEFAULT_USE_CASE_CONFIG;
}

function updateUseCaseSettings(state, key, useCaseSettings) {
    return { ...state, useCaseSettings: { ...state.useCaseSettings, [key]: useCaseSettings } };
}

const vehicleAnalysisSlice = createSlice({
    name: 'vehicleAnalysis',
    initialState: defaultState,
    reducers: {
        setTableType(state, { payload }) {
            return { ...state, tableType: payload };
        },
        setColumnOrderForUseCase(state, { payload: { useCaseKey, columnOrder } }) {
            const oldUseCaseSettings = getUseCaseFromState(state, useCaseKey);
            const newUseCaseSettings = { ...oldUseCaseSettings, columnOrder };
            return updateUseCaseSettings(state, useCaseKey, newUseCaseSettings);
        },
        setFilteredColumnNamesForUseCase(state, { payload: { useCaseKey, filteredColumnNames } }) {
            const oldUseCaseSettings = getUseCaseFromState(state, useCaseKey);
            const newUseCaseSettings = { ...oldUseCaseSettings, filteredColumnNames };
            return updateUseCaseSettings(state, useCaseKey, newUseCaseSettings);
        },
        setUseCase(state, { payload }) {
            return { ...state, selectedUseCase: payload };
        },
        setActiveSummaryTab(state, { payload }) {
            return { ...state, activeSummaryTab: payload };
        },
        setSortedBy(state, { payload }) {
            return { ...state, sortedBy: payload };
        },
        openRow(state, { payload: { id } }) {
            return {
                ...state,
                openRows: [...state.openRows, id],
            };
        },
        closeRow(state, { payload: { id } }) {
            return {
                ...state,
                openRows: state.openRows.filter(rowId => rowId !== id),
            };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const {
    setTableType,
    setColumnOrderForUseCase,
    setFilteredColumnNamesForUseCase,
    setUseCase,
    setActiveSummaryTab,
    setSortedBy,
    openRow,
    closeRow,
} = vehicleAnalysisSlice.actions;

export default vehicleAnalysisSlice.reducer;
