import _ from 'lodash';
import { useContext } from 'react';

import AnimatedRect from './AnimatedRect';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './utils';

const BAR_MAX_WIDTH = 40;

export function Bar({
    colorSchema,
    minHeight = 0,
}: {
    colorSchema: (d: DataEntry) => string;
    minHeight?: number;
}): JSX.Element {
    const {
        xScale: x,
        yScale: y,
        data,
        dimensions: { height },
    } = useContext(GraphContext);

    let barWidth = BAR_MAX_WIDTH;
    if (data.length > 1) {
        barWidth = Math.min((x(data[1].x) - x(data[0].x)) * 0.7, BAR_MAX_WIDTH);
    }

    return (
        <g>
            {data.map(d => {
                if (isNaN(d.y)) {
                    return;
                }
                const hasValue = d.y > 0;
                const barHeight = hasValue ? height - y(d.y) : minHeight;
                const fill = colorSchema(d);
                const yOffset = hasValue ? y(d.y) : height - minHeight;
                return (
                    <AnimatedRect
                        key={d.x.toString()}
                        fill={fill as string}
                        width={barWidth}
                        x={x(d.x) - barWidth / 2}
                        y={yOffset}
                        height={barHeight}
                    />
                );
            })}
        </g>
    );
}
