import _ from 'lodash';
import moment from 'moment';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';
import SkeletonLoader from '../../components/SkeletonLoader';
import { Loadable, LoadableType } from '../../data/loadable';
import { DateRange, VehicleType } from '../../types';
import { getPluralization } from '../../utils/getPluralization';
import ISODateToMomentDate from '../../utils/ISODateToMomentDateObject';
import { PADDED_DATE_FORMAT } from '../../utils/paddedDateFormat';
import { Data } from './types';

const get = (field: string) => (entity: Data) => getSignal(entity, field, null);
const sortAscending = (a: moment.Moment, b: moment.Moment) => a.diff(b);

const failure = () => <div style={{ height: '20px' }} />;
const loading = () => (
    <SkeletonLoader>
        From trip start 28/08/2020 to trip end 07/09/2020, with a team of12 drivers and a fleet of5 vehicles
    </SkeletonLoader>
);
const unknown = () => null;

export default function DriverFilterSummary({
    loadableEntities,
    dateRange,
}: {
    loadableEntities: LoadableType<Data[]>;
    dateRange: DateRange;
}) {
    return Loadable.cata(
        loadableEntities,
        entities => {
            const start =
                entities
                    .map(get('start'))
                    .map(ISODateToMomentDate)
                    .filter(date => moment(date).isValid())
                    .sort(sortAscending)
                    .shift()
                    ?.toDate() || dateRange.start;

            const end =
                entities
                    .map(get('end'))
                    .map(ISODateToMomentDate)
                    .filter(date => moment(date).isValid())
                    .sort(sortAscending)
                    .pop()
                    ?.toDate() || dateRange.end;

            const filteredEntities = entities.filter(get('start'));
            const vehicles = _.uniqBy(_.flatten(filteredEntities.map(get('vehicles'))), 'vehicleId');
            const vehicleTypes = _.uniq(_.map(vehicles, 'type'));
            const driversIds = _.uniq(_.flatten(filteredEntities.map(get('driverIds'))));

            const vehicleIcons = (
                <span className="padding-left-4 padding-right-4 icon-container">
                    {vehicleTypes.includes(VehicleType.TRUCK) && <span className="rioglyph rioglyph-truck" />}
                    {vehicleTypes.includes(VehicleType.BUS) && <span className="rioglyph rioglyph-bus" />}
                    {vehicleTypes.includes(VehicleType.VAN) && <span className="rioglyph rioglyph-van" />}
                </span>
            );

            const driverIcon = <span className="rioglyph rioglyph-driver padding-left-4 padding-right-4" />;
            const pluralizedVehicleString = (
                <FormattedMessage
                    id="vehicleCount"
                    values={{
                        pluralizedVehicle: getPluralization('vehicle', 'vehicles', vehicles.length),
                        vehicleCount: vehicles.length,
                    }}
                />
            );

            const pluralizedDriverString = (
                <FormattedMessage
                    id="driverCount"
                    values={{
                        pluralizedDriver: getPluralization('driver', 'akkDrivers', driversIds.length),
                        driverCount: driversIds.length,
                    }}
                />
            );

            return (
                <div className="text-color-darker">
                    <FormattedMessage
                        id="summaryFilterDriver"
                        values={{
                            start: (
                                <b>
                                    <FormattedDate value={start} {...(PADDED_DATE_FORMAT as { format?: string })} />
                                </b>
                            ),
                            end: (
                                <b>
                                    <FormattedDate value={end} {...(PADDED_DATE_FORMAT as { format?: string })} />
                                </b>
                            ),
                            vehicles: (
                                <b>
                                    {vehicles.length === 1 ? null : <FormattedMessage id="fleetOf" />}
                                    {vehicleIcons}
                                    {pluralizedVehicleString}
                                </b>
                            ),
                            drivers: (
                                <b>
                                    {driversIds.length === 1 ? null : <FormattedMessage id="teamOf" />}
                                    {driverIcon}
                                    {pluralizedDriverString}
                                </b>
                            ),
                        }}
                    />
                </div>
            );
        },
        failure,
        loading,
        unknown
    );
}
