import React, { ReactElement } from 'react';

const Card: React.FC<{
    score: ReactElement;
    title: ReactElement;
}> = ({ score, title }) => {
    return (
        <div
            data-test="Card"
            className="
            height-100pct
            text-center
                justify-content-between
    display-flex
    flex-column
            text-color-dark text-size-12 padding-bottom-10 padding-10 margin-bottom-10 border-style-solid border-width-1 border-color-light"
        >
            <div className="title text-size-16">{title}</div>
            <div className="score">{score}</div>
        </div>
    );
};

export default Card;
