import _ from 'lodash';
import moment from 'moment';

import { PositionQueryPayload } from '../types';
import { fetchGetWithJSONResponse } from './api';
import { routeDecoder } from './decoder/routeDecoder';

export async function fetchRoute(baseUrl: string, { dateRange, assetIds }: PositionQueryPayload, authToken: string) {
    // The api expects the max date to never be larger than the current time
    const maxEndTime = moment.min(moment(Date.now()), moment(dateRange.end));
    const response = await fetchGetWithJSONResponse(
        baseUrl,
        `${_.head(assetIds)}?from=${moment(dateRange.start).toISOString()}&to=${maxEndTime.toISOString()}`,
        authToken
    );

    return routeDecoder(response, dateRange);
}
