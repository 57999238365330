import React from 'react';
export default function Panel({ children }: { children: React.ReactNode }): JSX.Element {
    return <div className="bg-white border-style-solid border-width-1 border-color-light panel">{children}</div>;
}

export function PanelHeader({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <div className="panel-heading border-style-solid border-bottom-width-1 border-color-light border-top-0 border-left-0 border-right-0">
            {children}
        </div>
    );
}

export function PanelContent({ children }: { children: React.ReactNode }): JSX.Element {
    return <div className="panel-content padding-0">{children}</div>;
}
