import { EmptyState, ExpanderList, Spinner } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../columns/createColumn';
import SimpleErrorState from '../../components/SimpleErrorState';
import usePerformData from '../../data/hooks/usePerformData';
import { Loadable, LoadableType } from '../../data/loadable';
import { RawDriver } from '../../types';
import { acceleration, braking, coasting, cruise, overspeed } from './configuration';
import Discipline from './Discipline';
import DisciplineTitle from './DisciplineTitle';
import { FORESIGHTED_REQUEST_ATTRIBUTES } from './queries';
import { EntityType } from './types';

interface DisciplineI {
    id: string;
    title?: Column;
    entries: { title: React.ReactNode; values: Column[] }[];
}

export const createDisciplineListItem = ({ discipline, entity }: { discipline: DisciplineI; entity: EntityType }) => ({
    id: discipline.id,
    header: discipline.title && <DisciplineTitle title={discipline.title} entity={entity} />,
    body: (
        <div className="text-size-12 text-color-dark">
            {discipline.entries.map(({ title, values }, index) => (
                <Discipline columns={values} title={title} entity={entity} key={index} />
            ))}
        </div>
    ),
});

const success = (d: LoadableType<EntityType>) => <>{d}</>;
const failure = () => <SimpleErrorState />;
const loading = () => <Spinner />;
const unknown = () => null;

export const ForesightedDriving: React.FC<{
    data: LoadableType<EntityType>;
    children?: ({ entity }: { entity: EntityType }) => void;
    disciplines?: DisciplineI[];
}> = ({
    data = Loadable.createNotRequested(),
    children,
    disciplines = [coasting, acceleration, braking, cruise, overspeed],
}) => {
    const mappedData = Loadable.map(data, entity => {
        if (entity) {
            return (
                <React.Fragment>
                    <div>
                        {children?.({ entity })}
                        <div className="padding-bottom-10 padding-top-20 text-size-14">
                            <FormattedMessage id="foresightedDriving.signalsTitle" />
                        </div>
                    </div>
                    <ExpanderList
                        items={disciplines.map((discipline: DisciplineI) =>
                            createDisciplineListItem({ discipline, entity })
                        )}
                    />
                </React.Fragment>
            );
        }
        return <EmptyState headline={<FormattedMessage id="noData" />} />;
    });

    return Loadable.cata(mappedData, success, failure, loading, unknown);
};

const isMatchingEntityForDriver = (driverId: string) => (entity: EntityType) =>
    (entity?.drivers?.length === 0 && driverId === null) ||
    (entity?.drivers?.length === 1 && driverId === (entity.drivers as RawDriver[])?.[0]?.driverId);

export function ForesightedDrivingByVehicle({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    children,
}: {
    vehicleIds?: string[];
    driverIds?: string[];
    start: Date;
    end: Date;
    children?: ({ entity }: { entity: EntityType }) => JSX.Element;
}): JSX.Element {
    const requestDataForOneDriver = driverIds.length === 1;
    const segmentBy = requestDataForOneDriver ? 'driver_and_vehicle' : 'vehicle';

    // request data for all drivers and select the wanted entry afterwards
    const data = usePerformData(FORESIGHTED_REQUEST_ATTRIBUTES, {
        variables: { start, end, vehicleIds, segmentBy },
        debounced: false,
    });

    const desiredData = Loadable.map(data, entities =>
        requestDataForOneDriver ? entities.find(isMatchingEntityForDriver(driverIds[0])) : _.head(entities)
    );
    return <ForesightedDriving data={desiredData}>{children}</ForesightedDriving>;
}

const isMatchingEntityForVehicle = (vehicleId: string) => (entity: EntityType) =>
    entity?.vehicles?.length === 1 && vehicleId === entity.vehicles[0].vehicleId;

export function ForesightedDrivingByDriver({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    allowedVehicles,
    children,
}: {
    vehicleIds?: string[];
    driverIds: string[];
    allowedVehicles: string[];
    start: Date;
    end: Date;
    children?: ({ entity }: { entity: EntityType }) => JSX.Element;
}): JSX.Element {
    const requestDataForOneVehicle = vehicleIds.length === 1;
    const segmentBy = requestDataForOneVehicle ? 'driver_and_vehicle' : 'driver';

    // request data for all perform vehicles and select the wanted entry afterwards
    const data = usePerformData(FORESIGHTED_REQUEST_ATTRIBUTES, {
        variables: { start, end, driverIds, vehicleIds: allowedVehicles, segmentBy },
        debounced: false,
    });

    const desiredData = Loadable.map(data, entities =>
        requestDataForOneVehicle ? entities.find(isMatchingEntityForVehicle(vehicleIds[0])) : _.head(entities)
    );
    return <ForesightedDriving data={desiredData}>{children}</ForesightedDriving>;
}
