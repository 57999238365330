import { ConfirmationDialog, Dialog } from '@rio-cloud/rio-uikit';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import React, { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '../../setup/store';
import { deleteReportConfiguration } from './reducer';

const DeleteConfiguration = ({
    reportId,
    children,
    onDelete = () => {},
}: {
    reportId: string;
    children: ReactElement;
    onDelete?: () => void;
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const onClickDelete = () => {
        onDelete();
        setIsLoading(true);
        dispatch(deleteReportConfiguration(reportId)).then(() => {
            setShowDialog(false);
            setIsLoading(false);
        });
    };

    return (
        <>
            {showDialog ? (
                <ConfirmationDialog
                    show={showDialog}
                    title={intl.formatMessage({ id: 'reports.delete.title' })}
                    content={intl.formatMessage({ id: 'reports.confirm.delete' })}
                    onClickConfirm={onClickDelete}
                    onClickCancel={() => setShowDialog(false)}
                    cancelButtonText={intl.formatMessage({ id: 'cancel' })}
                    confirmButtonText={
                        isLoading ? (
                            <Spinner text={intl.formatMessage({ id: 'loading' })} isInverse />
                        ) : (
                            intl.formatMessage({ id: 'confirm' })
                        )
                    }
                    useOverflow
                    bsSize={Dialog.SIZE_SM}
                />
            ) : null}

            {React.cloneElement(children, {
                onClick: (e: MouseEvent) => {
                    e.preventDefault();
                    setShowDialog(true);
                },
            })}
        </>
    );
};

export default DeleteConfiguration;
