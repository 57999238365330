import { FormattedMessage } from 'react-intl';

import { getColumn, getColumns } from '../../utils/columns';

export const coasting = {
    id: 'coasting',
    title: getColumn('coastingRating'),
    entries: [
        {
            title: <FormattedMessage id="activeCoasting" />,
            values: getColumns(['activeCoastingDistance', 'activeCoastingTime']),
        },
        {
            title: <FormattedMessage id="passiveCoasting" />,
            values: getColumns(['coastingCcDistance', 'coastingCcTime']),
        },
    ],
};

export const acceleration = {
    id: 'acceleration',
    title: getColumn('accelerationPedalRating'),
    entries: [
        {
            title: <FormattedMessage id="kickdown" />,
            values: getColumns(['kickdownDistance', 'kickdownTime']),
        },
        {
            title: <FormattedMessage id="cruiseControl" />,
            values: getColumns(['accelerationPedalDistanceWithCc', 'accelerationPedalTimeWithCc']),
        },
    ],
};

export const braking = {
    id: 'braking',
    title: getColumn('brakingRating'),
    entries: [
        {
            title: <FormattedMessage id="serviceBrake" />,
            values: getColumns(['serviceBrakeDistance', 'serviceBrakeTime']),
        },
        {
            title: <FormattedMessage id="motorBrake" />,
            values: getColumns(['motorBrakeDistance', 'motorBrakeTime']),
        },
        {
            title: <FormattedMessage id="retarder" />,
            values: getColumns(['retarderDistance', 'retarderTime']),
        },
    ],
};

export const cruise = {
    id: 'cruise',
    title: getColumn('cruiseControlRating'),
    entries: [
        {
            title: <FormattedMessage id="withCruiseControl" />,
            values: getColumns(['cruiseControlDistanceInPotential', 'cruiseControlTimeInPotential']),
        },
        {
            title: <FormattedMessage id="withoutCruiseControl" />,
            values: getColumns(['noCruiseControlDistanceInPotential', 'noCruiseControlTimeInPotential']),
        },
        {
            title: <FormattedMessage id="cruiseControlPotentialDistance" />,
            values: getColumns(['cruiseControlPotentialDistance']),
        },
        {
            title: <FormattedMessage id="cruiseControlConsumption" />,
            values: getColumns(['cruiseControlConsumptionInPotential']),
        },
        {
            title: <FormattedMessage id="noCruiseControlConsumption" />,
            values: getColumns(['noCruiseControlConsumptionInPotential']),
        },
    ],
};

export const overspeed = {
    id: 'overspeed',
    title: getColumn('overspeedRating'),
    entries: [
        {
            title: <FormattedMessage id="overspeed" />,
            values: getColumns(['overspeedDistance']),
        },
    ],
};
