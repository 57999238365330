import { TableToolbar } from '@rio-cloud/rio-uikit';
import { ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { State } from '../../setup/types';
import { DateRange } from '../../types';
import { getPermissionBasedDateRangeConfig } from '../permissions/selectors';
import DateRangePicker from './DateRangePicker';
import { setDateRange as setDateRangeAction } from './reducer';
import { getDateRange } from './selectors';

export type DateRangeConfig = {
    min: Date | null;
    max: Date;
    hasTimePicker: boolean;
    presets: {
        key: string;
        translationKey: string;
        startValue: Date;
        endValue: Date;
        disabled: boolean;
    }[];
};
export const Toolbar = ({
    dateRange,
    dateRangeConfig,
    onSetDateRange,
    onDateRangeChange,
    showLabel,
    children,
}: {
    dateRange: DateRange;
    onSetDateRange: (start: Date, end: Date) => void;
    dateRangeConfig: DateRangeConfig;
    onDateRangeChange?: () => void;
    showLabel?: boolean;
    children?: ReactNode;
}) => {
    const convertedDateRange = useMemo(() => ({ from: dateRange.start, to: dateRange.end }), [
        dateRange.end,
        dateRange.start,
    ]);

    const onChange = useCallback(
        (start, end) => {
            onSetDateRange(start, end);
            onDateRangeChange && onDateRangeChange();
        },
        [onDateRangeChange, onSetDateRange]
    );

    const labelDateRange = showLabel ? (
        <label className="table-toolbar-label">
            <FormattedMessage id="dateRange" />
        </label>
    ) : null;

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar">
                            <div data-test="toolbar-dateRangePicker">
                                {labelDateRange}
                                <DateRangePicker
                                    dateRange={convertedDateRange}
                                    onSetDateRange={onChange}
                                    dateRangeConfig={dateRangeConfig}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-toolbar-group-right">{children}</div>
            </div>
        </TableToolbar>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSetDateRange: function(start: Date, end: Date) {
        dispatch(setDateRangeAction({ start: start, end: end }));
    },
});

const mapStateToProps = (state: State) => {
    return {
        dateRange: getDateRange(state),
        dateRangeConfig: getPermissionBasedDateRangeConfig(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
