import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Map } from '../../api/models/types';
import { backendConfig, getVehicles } from '../../data/selectors';
import { getLocale } from '../../features/lang/selectors';
import { getAccessToken } from '../../features/tokenHandling/selectors';
import { errorNotification } from '../../features/ui/notificationSaga';
import { configureReporting } from '../../setup/errorReporting';
import { Vehicle } from '../../types';
import { setDownloadStatus, startDownload } from './actions';
import { getCoDriverCSV } from './api';
import { createId, FAILED, LOADED, LOADING } from './reducer';
import { CoDriverDownloadPayload } from './types';

const { captureException } = configureReporting(window, process.env);

function getDriverParam(params: CoDriverDownloadPayload) {
    if (params.isTotalSegment) {
        return 'all';
    }
    if (!params.driver) {
        return 'unknown';
    }
    return params.driver?.driverId;
}

export function* requestDownload({ payload: { params } }: { payload: { params: CoDriverDownloadPayload } }) {
    const vehicleId = params?.vehicle?.vehicleId ?? null;
    const driverId = getDriverParam(params);
    const downloadId = createId([vehicleId], [driverId], params.start, params.end);
    const token: string = yield select(getAccessToken);
    const locale: string = yield select(getLocale);

    try {
        const serviceBaseUrl: string = yield select(backendConfig, 'CONNECTED_CODRIVER_SERVICE');
        yield put(setDownloadStatus({ status: LOADING, id: downloadId }));

        const vehicles: Map<Vehicle> = yield select(getVehicles);
        const entity = vehicles[vehicleId];

        yield call(
            getCoDriverCSV as (...args: any[]) => Promise<void>,
            serviceBaseUrl,
            {
                start: params.start.valueOf(),
                end: params.end.valueOf(),
                equipmentId: vehicleId,
                driverId,
                vin: entity.vin,
                locale,
            },
            token
        );

        yield put(setDownloadStatus({ status: LOADED, id: downloadId }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(setDownloadStatus({ status: FAILED, id: downloadId }));
        yield call(captureException, e);
    }
}

export default function* root() {
    yield takeEvery(startDownload, requestDownload);
}
