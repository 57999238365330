import { EmptyState } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';

export default function EmptyOpConState() {
    return (
        <div data-test="emptyOpconState">
            <EmptyState
                headline={<FormattedMessage id="inaccuracyOperationConditionWarning" />}
                message={<FormattedMessage id="inaccuracyOperationConditionWarningDetail" />}
            />
        </div>
    );
}
