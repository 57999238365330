import moment from 'moment';

import { Id } from '../../api/models/types';
import { IntervalTypes, PerformSegmentBy } from '../../types';
import useQuery from '../../utils/useQuery';
import { requestPerformanceData } from '../actions';

type QueryProps = {
    start: string | Date;
    end: string | Date;
    vehicleIds?: Id[];
    driverIds?: Id[];
    segmentBy: PerformSegmentBy;
};
type IntervalQueryProps = Omit<QueryProps, 'segmentBy'> & {
    segmentBy: 'interval';
    intervalType: IntervalTypes;
};

export const makeQuery = (attributes: string[], includes: string[] = []) => (
    queryProps: QueryProps | IntervalQueryProps
) => {
    const { start, end, vehicleIds = [], driverIds = [], segmentBy } = queryProps;
    return {
        from: moment(start).toDate(),
        to: moment(end).toDate(),
        driver_ids: driverIds,
        vehicle_ids: vehicleIds,
        segment_by: segmentBy,
        ...(queryProps.segmentBy === 'interval' ? { interval_type: queryProps.intervalType } : {}),
        attributes,
        includes,
    };
};

const usePerformData = (
    attributes: string[],
    {
        variables,
        selector,
        debounced,
    }: {
        variables: { [key: string]: string | string[] | Date };
        selector?: any;
        debounced?: boolean;
    },
    includes: string[] | never[] = []
) =>
    useQuery(makeQuery(attributes, includes), {
        endPoint: requestPerformanceData,
        variables,
        selector,
        debounced,
    });

export default usePerformData;
