import { FormattedMessage } from 'react-intl';

import Card from '../../components/Card';
import ErrorBoundary from '../../components/ErrorBoundry';
import { DateRange } from '../../types';
import Rating from './components/Rating';
import GetBasicOpConData from './GetBasicOpConData';
import { OpCon } from './types';

export const OperationConditionRating = ({ opCon, totalMileage }: { opCon?: OpCon; totalMileage: number }) => {
    return (
        <ErrorBoundary>
            <Card
                score={
                    <div style={{ margin: '0 auto' }} className="height-25 width-100">
                        <Rating opCon={opCon!.signals} totalMileage={totalMileage} />
                    </div>
                }
                title={<FormattedMessage id="operationCondition" />}
            />
        </ErrorBoundary>
    );
};

const SelfLoadingOperationConditionRating = ({
    dateRange,
    driverIds,
    vehicleIds,
    mileageFromPerformance,
}: {
    dateRange: DateRange;
    driverIds: string[];
    vehicleIds: string[];
    mileageFromPerformance: number;
}) => (
    <GetBasicOpConData start={dateRange.start} end={dateRange.end} driverIds={driverIds} vehicleIds={vehicleIds}>
        <OperationConditionRating totalMileage={mileageFromPerformance} />
    </GetBasicOpConData>
);

export default SelfLoadingOperationConditionRating;
