// https://collaboration.msi.audi.com/confluence/pages/viewpage.action?pageId=263107122
const accountWhitelist = [
    '4c8e1f07-8510-4bbf-79ca-9d706675b9da',
    '8ef04c71-8105-4bfb-a97c-a70db9d66975',
    '237c58e3-60b5-4442-a9ec-8641ea39325b',
    '1fdb99c9-854d-48f1-894a-d4fab2f30607',
    '1c314ed3-883b-4d57-bf62-6ca85f86ff3b',
    '4ca69133-1ce0-4568-8322-cda42831dbac',
    '6ce5c381-524c-44de-9c05-a73390b1f999',
    '3ff8cda3-6b22-4de5-8595-aac6974e7ff4',
    '2a4b5e75-b60b-4412-95a2-e4c6f73e17fe',
    '7283d2d7-88f3-46ee-828e-01ee81d7f2b7',
    '7904fa06-9cbc-49d1-81d3-ad21b8bddd6d',
    '23639feb-ca5e-4b9a-9e16-5e9cf92f07e2',
    'df61c0a7-fe5e-4f8a-bc39-47a7374def84',
    '05b49e87-83b9-417c-b090-a6f69d277b50',
    '1530d0a5-db5e-49a2-ab9d-8249e9aa640c',
    '46cb6538-668f-4a39-aeda-2dea1c7c8fba',
    'af6bc25d-70af-4134-9195-e9b2bb5baa37',
];

export default function hasPermissionsToApp(account: string) {
    return accountWhitelist.includes(account);
}
