import {
    cruiseControlConsumptionInPotential,
    distanceFuelConsumption,
    drivingConsumption,
    fuelConsumption,
    fuelEfficiency,
    idlingConsumption,
    noCruiseControlConsumptionInPotential,
    operatingFuelConsumption,
} from '../columns/consumption';
import {
    accelerationPedalDistanceWithCc,
    activeCoastingDistance,
    coastingCcDistance,
    cruiseControlDistanceInPotential,
    cruiseControlPotentialDistance,
    kickdownDistance,
    mileage,
    motorBrakeDistance,
    noCruiseControlDistanceInPotential,
    overspeedDistance,
    retarderDistance,
    serviceBrakeDistance,
} from '../columns/distance';
import {
    drivers,
    end,
    explicitDrivers,
    start,
    system,
    tripStartEnd,
    vehicle,
    vehicleNames,
    vehicles,
} from '../columns/DOM';
import { drivenElevationDown, drivenElevationUp } from '../columns/height';
import {
    accelerationPedalRating,
    averageSlopeDown,
    averageSlopeUp,
    brakingRating,
    coastingRating,
    cruiseControlRating,
    foresightedDrivingPercentage,
    overspeedRating,
    totalRating,
    totalRatingWithoutCC,
} from '../columns/percentage';
import { operationCondition } from '../columns/score';
import { averageSpeed } from '../columns/speed';
import {
    accelerationPedalTimeWithCc,
    activeCoastingTime,
    coastingCCTime,
    cruiseControlTimeInPotential,
    drivingTime,
    idlingTime,
    kickdownTime,
    motorBrakeTime,
    noCruiseControlTimeInPotential,
    operatingTime,
    retarderTime,
    serviceBrakeTime,
} from '../columns/time';
import { averageWeight, co2Emission } from '../columns/weight';

export const columns = [
    explicitDrivers,
    drivers,
    vehicle,
    vehicles,
    vehicleNames,
    start,
    end,
    system,
    tripStartEnd,

    distanceFuelConsumption,
    co2Emission,
    idlingConsumption,
    fuelConsumption,
    averageWeight,
    averageSpeed,
    drivenElevationUp,
    drivenElevationDown,

    drivingTime,
    idlingTime,
    operatingTime,
    activeCoastingTime,
    coastingCCTime,
    kickdownTime,
    accelerationPedalTimeWithCc,
    serviceBrakeTime,
    motorBrakeTime,
    retarderTime,
    cruiseControlTimeInPotential,
    noCruiseControlTimeInPotential,

    averageSlopeDown,
    averageSlopeUp,
    foresightedDrivingPercentage,
    coastingRating,
    accelerationPedalRating,
    brakingRating,
    cruiseControlRating,
    overspeedRating,
    totalRating,
    totalRatingWithoutCC,

    mileage,
    coastingCcDistance,
    kickdownDistance,
    accelerationPedalDistanceWithCc,
    serviceBrakeDistance,
    motorBrakeDistance,
    cruiseControlDistanceInPotential,
    noCruiseControlDistanceInPotential,
    cruiseControlPotentialDistance,
    overspeedDistance,
    activeCoastingDistance,
    retarderDistance,

    noCruiseControlConsumptionInPotential,
    cruiseControlConsumptionInPotential,
    operatingFuelConsumption,
    drivingConsumption,
    operationCondition,
    fuelEfficiency,
];

export const getColumn = (requestedColumn, existingColumns = columns) =>
    existingColumns.find(e => e.key === requestedColumn);
export const getColumns = (requestedColumns, existingColumns = columns) =>
    requestedColumns.map(r => existingColumns.find(e => e.key === r)).filter(c => c);
