import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import { OverlayTriggerProps } from '@rio-cloud/rio-uikit/lib/types';
import React from 'react';

const OverlayTriggerWithInjectedIntl: React.FC<OverlayTriggerProps & { className?: string }> = ({
    children,
    ...props
}) => {
    const { overlay, ...rest } = props;

    return (
        <OverlayTrigger {...rest} overlay={overlay}>
            {children}
        </OverlayTrigger>
    );
};

export default OverlayTriggerWithInjectedIntl;
