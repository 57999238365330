import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import InfoOverlay from '../../components/InfoOverlay';
import { SingleVehicleLabel } from '../../components/VehicleLabel';
import { getAccuracyWarning } from '../../components/warnings/AccuracyWarning';
import { getBelowMileageWarning } from '../../components/warnings/BelowMileageWarning';
import { getBusOrVanWarning } from '../../components/warnings/BusOrVanWarning';
import { getTbm2AndTbm3Warning } from '../../components/warnings/Tbm2AndTbm3Warning';
import { getOpconAccuracyWarning } from '../../features/opcon/OpconAccuracyWarning';
import { DriverAnalysisStatePart } from '../../setup/types';
import { Vehicle } from '../../types';
import { closeRow, openRow } from './driverAnalysisReducer';
import { getOpenRows } from './driverAnalysisSelectors';

const buildWarnings = (data: unknown): JSX.Element[] | [] =>
    [
        getOpconAccuracyWarning(data),
        getAccuracyWarning(data),
        getTbm2AndTbm3Warning(data),
        getBelowMileageWarning(data),
        getBusOrVanWarning(data),
    ].filter(Boolean) as JSX.Element[] | [];

export function VehicleLabel({
    vehicles = [],
    data = { id: '' },
    openRow,
    closeRow,
    openRows = [],
}: {
    vehicles?: Vehicle[];
    data?: { id: string };
    openRow: (arg0: string) => void;
    closeRow: (arg0: string) => void;
    openRows?: string[];
}): React.ReactElement {
    const isFirstVehicleEmpty = !vehicles[0];

    if (vehicles.length === 0 || (vehicles.length === 1 && isFirstVehicleEmpty)) {
        return (
            <div className="max-width-200 overflow-hidden">
                <span className="rioglyph text-size-20 margin-right-5 rioglyph-truck text-color-gray" />-
            </div>
        );
    } else if (vehicles.length === 1) {
        return (
            <div className="display-flex">
                <div className="max-width-200 overflow-hidden align-items-center display-flex flex-1-1 text-color-dark">
                    <SingleVehicleLabel vehicle={vehicles[0]} />
                </div>
                <InfoOverlay warnings={buildWarnings(data)} />
            </div>
        );
    }

    const toggleChildren = () => {
        const rowId = data.id;
        openRows.includes(rowId) ? closeRow(rowId) : openRow(rowId);
    };

    return (
        <div className="display-flex">
            <div
                className="max-width-200 overflow-hidden align-items-center display-flex flex-1-1 hover-text-color-highlight text-color-dark"
                /*role="button"
                tabIndex={0}*/
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    toggleChildren();
                }}
            >
                <span className="rioglyph text-size-20 margin-right-5 rioglyph-truck" />
                {vehicles.length}
            </div>
            <InfoOverlay warnings={buildWarnings(data)} />
        </div>
    );
}

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    openRow: function(id: string) {
        dispatch(openRow({ id }));
    },
    closeRow: function(id: string) {
        dispatch(closeRow({ id }));
    },
});

export const mapStateToProps = (state: DriverAnalysisStatePart) => {
    return { openRows: getOpenRows(state) };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(VehicleLabel));
