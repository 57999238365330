import { scaleLinear } from 'd3-scale';

import Area from '../../components/graphComponents/Area';
import GraphDataProvider from '../../components/graphComponents/GraphDataProvider';
import XAxis from '../../components/graphComponents/XAxis';
import { YAxisLines, YAxisTicks } from '../../components/graphComponents/YAxis';

const DUMMY_X = Array(5)
    .fill(1)
    .map((_, idx) => idx);
const DUMMY_Y = Array(4)
    .fill(1)
    .map((_, idx) => Math.random() * 10);

function LoadingGraph({ dimensions }: { dimensions: { width: number; height: number; margin: number } }) {
    const xScale = scaleLinear<number>()
        .domain([0, DUMMY_X.length])
        .range([dimensions.margin, dimensions.width]);

    const yScale = scaleLinear<number>()
        .domain([DUMMY_Y.length, 0])
        .range([dimensions.margin, dimensions.height - dimensions.margin]);

    const height = dimensions.height - dimensions.margin;
    const fixedId = 1;
    const idClip = `${fixedId}-diff`;
    const idGradient = `${fixedId}-animated-diff`;
    const gradientRatio = 0.2;

    const backgroundOpacity = 1;
    const foregroundOpacity = 0.06;
    const backgroundColor = '#f3f3f3';
    const foregroundColor = '#ecebeb';
    const keyTimes = `0; 0.25; 1`;
    const dur = `2s`;

    const gradient = (
        <linearGradient id={idGradient}>
            <stop offset="0%" stopColor={backgroundColor} stopOpacity={backgroundOpacity}>
                <animate
                    attributeName="offset"
                    values={`${-gradientRatio}; ${-gradientRatio}; 1`}
                    keyTimes={keyTimes}
                    dur={dur}
                    repeatCount="indefinite"
                />
            </stop>

            <stop offset="50%" stopColor={foregroundColor} stopOpacity={foregroundOpacity}>
                <animate
                    attributeName="offset"
                    values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 + gradientRatio / 2}`}
                    keyTimes={keyTimes}
                    dur={dur}
                    repeatCount="indefinite"
                />
            </stop>

            <stop offset="100%" stopColor={backgroundColor} stopOpacity={backgroundOpacity}>
                <animate
                    attributeName="offset"
                    values={`0; 0; ${1 + gradientRatio}`}
                    keyTimes={keyTimes}
                    dur={dur}
                    repeatCount="indefinite"
                />
            </stop>
        </linearGradient>
    );

    return (
        <div
            className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
            style={{ height: dimensions.height }}
        >
            <GraphDataProvider
                yScale={yScale}
                formatDate={() => ''}
                formatMessage={() => ''}
                dimensions={dimensions}
                data={[]}
            >
                <svg
                    width={'100%'}
                    height={'100%'}
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <YAxisTicks formatter={() => ''} />
                    <XAxis dateRange={{ start: new Date(), end: new Date() }} tickFormatter={() => () => ''} />
                    <YAxisLines />
                    <rect
                        role="presentation"
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        clipPath={`url(#${idClip})`}
                        style={{ fill: `url(#${idGradient})` }}
                    />

                    <defs role="presentation">
                        <clipPath id={idClip}>
                            <rect x={xScale(0)} y={yScale(0)} width={dimensions.width} height={2} />
                            {DUMMY_Y.map((i, idx) => (
                                <rect key={i} x={0} y={yScale(idx + 1)! + 4} rx="3" ry="3" width="50" height="10" />
                            ))}
                            <Area />

                            {DUMMY_X.map(i => (
                                <rect key={i} x={xScale(i)} y={height + 13} rx="3" ry="3" width="50" height="10" />
                            ))}
                        </clipPath>

                        {gradient}
                    </defs>
                </svg>
            </GraphDataProvider>
        </div>
    );
}

export default LoadingGraph;
