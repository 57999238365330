import { useEffect } from 'react';

const NotFoundState = ({ onClose }: { onClose: () => void }) => {
    useEffect(() => {
        onClose();
    }, []);
    return null;
};

export default NotFoundState;
