import hasPermissionsToApp from './hasPermissionsToApp';
import hasPermissionsToSeeMap from './hasPermissionsToSeeMap';

export const SEE_MAP = 'SEE_MAP';
const USE_APP = 'USE_APP';

function hasPermission(permission: string, account: string) {
    switch (permission) {
        case SEE_MAP:
            return hasPermissionsToSeeMap(account);
        case USE_APP:
            return hasPermissionsToApp(account);
        default:
            return true;
    }
}

export default function hasPermissions(requiredPermissions: string[], account: string) {
    return requiredPermissions.reduce((result, requiredPermission) => {
        return result && hasPermission(requiredPermission, account);
    }, true);
}
