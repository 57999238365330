import { CustomState, EmptyState, ErrorState, Spinner } from '@rio-cloud/rio-uikit';
import { memo, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { Id, VehspecEntity } from '../../api';
import Box from '../../components/Box';
import SimpleColumn from '../../components/SimpleColumn';
import WithUnit from '../../components/WithUnit';
import { ValidUnit } from '../../constants/units';
import useVehspecData from '../../data/hooks/useVehspecData';
import { Loadable } from '../../data/loadable';
import { getVehicles } from '../../data/selectors';
import { DataStatePart, PermissionsStatePart } from '../../setup/types';
import { DateRange, Vehicle } from '../../types';
import convertToRows from '../../utils/convertToRows';
import { formatOneDigitNumber } from '../../utils/formatters';
import { getPerformVehicles } from '../permissions/selectors';
const convertToRow = convertToRows(3);

const Title = ({ icon, translationId }: { translationId: string; icon: string }) => (
    <span className="text-size-18 ellipsis-1 padding-left-0 display-flex align-items-center">
        <span className={`rioglyph ${icon} text-color-highlight padding-right-10`} style={{ fontSize: 24 }} />
        <FormattedMessage id={translationId} />
    </span>
);

const Format = ({ value, unit }: { value: unknown; unit: ValidUnit }) => {
    if (value) {
        return <WithUnit unit={unit}>{formatOneDigitNumber(value)}</WithUnit>;
    }
    return ('-' as unknown) as ReactElement;
};

export function VehicleSpecification({ dateRange, vehicles }: { vehicles: Vehicle[]; dateRange: DateRange }) {
    const vehicle = vehicles[0] || {};
    const data = useVehspecData(
        dateRange,
        vehicles.map(v => v.id)
    );

    const vehicleData = Loadable.map(data, (data: VehspecEntity[]) => data.find(d => d.vehicleId === vehicle.id));

    if (vehicles.length > 1) {
        return (
            <CustomState
                headline={<FormattedMessage id="vehspec.multipleVehiclesSelected" />}
                message={<FormattedMessage id="vehspec.multipleVehiclesSelectedMessage" />}
                data-test="vehspec-multiple-vehicles"
                icons={[
                    {
                        name: 'rioglyph-van',
                        className: 'text-size-200pct',
                    },
                    {
                        name: 'rioglyph-truck',
                        className: 'text-size-300pct',
                    },
                    {
                        name: 'rioglyph-bus',
                        className: 'text-size-200pct',
                    },
                ]}
            />
        );
    }

    return (
        <div>
            {Loadable.cata(
                vehicleData,
                data => {
                    if (data == undefined) {
                        return <EmptyState headline={<FormattedMessage id="noData" />} />;
                    }
                    const fuelTanks = data.fuelTanks || [];
                    const vehicleTypeRows = convertToRow([
                        <SimpleColumn
                            key="vehspec.vin"
                            testAttribute="vehspec.vin"
                            title={<FormattedMessage id={'vehspec.vin'} />}
                            content={vehicle.vin || '-'}
                        />,
                        <SimpleColumn
                            key="vehspec.manufacturer"
                            testAttribute="vehspec.manufacturer"
                            title={<FormattedMessage id={'vehspec.manufacturer'} />}
                            content={data.manufacturer || '-'}
                        />,
                        <SimpleColumn
                            key="vehspec.vehicleType"
                            testAttribute="vehspec.vehicleType"
                            title={<FormattedMessage id={'vehspec.vehicleType'} />}
                            content={data.vehicleType || '-'}
                        />,
                    ]);

                    const vehicleDataRows = convertToRow([
                        <SimpleColumn
                            key="vehspec.output"
                            testAttribute="vehspec.output"
                            title={<FormattedMessage id={'vehspec.output'} />}
                            content={<Format value={data.output} unit={ValidUnit.PS} />}
                        />,
                        <SimpleColumn
                            key="vehspec.emission"
                            testAttribute="vehspec.emission"
                            title={<FormattedMessage id={'vehspec.emission'} />}
                            content={data.emission || '-'}
                        />,
                        <SimpleColumn
                            key="vehspec.motor"
                            testAttribute="vehspec.motor"
                            title={<FormattedMessage id={'vehspec.motor'} />}
                            content={data.motor || '-'}
                        />,
                        <SimpleColumn
                            key="vehspec.gearing"
                            testAttribute="vehspec.gearing"
                            title={<FormattedMessage id={'vehspec.gearing'} />}
                            content={data.gearing || '-'}
                        />,
                        <SimpleColumn
                            key="vehspec.axle"
                            testAttribute="vehspec.axle"
                            title={<FormattedMessage id={'vehspec.axle'} />}
                            content={data.axisRatio || '-'}
                        />,
                        <SimpleColumn
                            key="vehspec.wheelBase"
                            testAttribute="vehspec.wheelbase"
                            title={<FormattedMessage id={'vehspec.wheelBase'} />}
                            content={<Format value={data.wheelBase} unit={ValidUnit.MILLI_METERS} />}
                        />,
                        ...fuelTanks.map((tank, idx) => (
                            <SimpleColumn
                                testAttribute="vehspec.fueltank"
                                key={idx}
                                title={
                                    <>
                                        <FormattedMessage id={'vehspec.fuelTank'} /> {idx + 1}
                                    </>
                                }
                                content={<Format value={tank} unit={ValidUnit.LITERS} />}
                            />
                        )),
                        <SimpleColumn
                            key="vehspec.ureatank"
                            testAttribute="vehspec.ureatank"
                            title={<FormattedMessage id={'vehspec.ureaTank'} />}
                            content={<Format value={data.ureaTank} unit={ValidUnit.LITERS} />}
                        />,
                    ]);
                    return (
                        <div className="padding-top-10">
                            <div className="padding-top-10 padding-bottom-10">
                                {/* We're moving the content to the left in order to have a nicer alignment. The trucks' mirrors mess up
                                the overall alignment.
                                 */}
                                <div style={{ marginLeft: -4 }}>
                                    <Title translationId="vehspec.vehicleTypeTitle" icon="rioglyph-truck" />
                                </div>
                                <Box rows={vehicleTypeRows} />
                            </div>
                            <div className="padding-top-10 padding-bottom-10">
                                <Title translationId="vehspec.vehicleDataTitle" icon="rioglyph-axle" />
                                <Box rows={vehicleDataRows} />
                            </div>
                        </div>
                    );
                },

                () => (
                    <ErrorState headline={<FormattedMessage id="error.default" />} />
                ),
                () => (
                    <Spinner />
                ),
                () => null
            )}
        </div>
    );
}

// In order to optimize our re-rendering we memoize here.
// see caveats here: https://github.com/reduxjs/reselect/blob/master/README.md#accessing-react-props-in-selectors
const getVehicleIds = (_: unknown, vehicleIds: Id[]) => vehicleIds;
const hydrateVehicles = createSelector(
    [getPerformVehicles, getVehicles, getVehicleIds],
    (performVehicles, vehicles, vehicleIds) => {
        return vehicleIds
            .filter(id => performVehicles.includes(id))
            .map(id => vehicles[id])
            .filter(Boolean);
    }
);

export const mapStateToProps = (state: PermissionsStatePart & DataStatePart, props: { vehicleIds: Id[] }) => {
    return { vehicles: hydrateVehicles(state, props.vehicleIds) };
};

export default connect(mapStateToProps)(memo(VehicleSpecification));
