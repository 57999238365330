import { TableSettingsDialog } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';

interface SettingsDialogI {
    columnLabels: Record<string, React.ReactNode>;
    defaultColumnOrder: string[];
    data?: Record<string, string>[];
    setColumns: (filtered: any) => void;
    areSettingsVisible: boolean;
    toggleSettings: (bool: boolean) => void;
    setColumnOrder: (order: any) => void;
    columnOrder: string[];
    defaultHiddenColumns?: string[];
    hiddenColumns: string[];
}

export default function SettingsDialog({
    columnOrder = [],
    areSettingsVisible,
    setColumns,
    toggleSettings,
    setColumnOrder,
    columnLabels = {},
    defaultColumnOrder = [],
    defaultHiddenColumns = [],
    hiddenColumns,
}: SettingsDialogI): JSX.Element {
    return (
        <FormattedMessage id={'searchPlaceholder'}>
            {(placeholder: string) => (
                <TableSettingsDialog
                    show={areSettingsVisible}
                    title={<FormattedMessage id="tableSettings" />}
                    onApply={(order: any, filtered: any) => {
                        setColumns(filtered);
                        setColumnOrder(order);
                    }}
                    onColumnChange={() => {}} //mandatory on rio-uikit library
                    onHide={() => toggleSettings(false)}
                    defaultColumnOrder={defaultColumnOrder}
                    defaultHiddenColumns={defaultHiddenColumns}
                    columnOrder={columnOrder.length ? columnOrder : defaultColumnOrder}
                    hiddenColumns={hiddenColumns}
                    columnSearchValue={''}
                    columnLabels={columnLabels}
                    disabledColumns={['type', 'from', 'to', 'vehicle', 'drivers', 'vehicles']}
                    applyButtonText={<FormattedMessage id="apply" />}
                    cancelButtonText={<FormattedMessage id="cancel" />}
                    resetButtonText={<FormattedMessage id="resetChanges" />}
                    searchPlaceholder={placeholder}
                    notFoundMessage={<FormattedMessage id="noColumnsFoundForSearch" />}
                />
            )}
        </FormattedMessage>
    );
}
