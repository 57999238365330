import { NotFoundState, Spinner } from '@rio-cloud/rio-uikit';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import cn from 'classnames';
import _ from 'lodash';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Column } from '../../columns/createColumn';
import { Driver, Id, Signal, Signals, Vehicle } from '../../types';
import { SortDirection } from '../../utils/sortyByProperty';
import TableHead from './TableHead';

export const createLoadingData = () => ({ isLoading: true });

export interface Row {
    isLoading?: boolean;
    level: number;
    childrenCount?: number;
    id: string;
    start?: Signal<Date>;
    vehicleIds?: Signal<Id[]>;
    driverIds?: Signal<Id[]>;
    [key: string]:
        | Signals
        | number
        | string
        | string[]
        | Driver[]
        | Vehicle[]
        | undefined
        | boolean
        | Record<string, unknown>;
}

type SortDirectionType = 'asc' | 'desc';

const defaultTableClassNames = [
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled',
    'table-hover',
].join(' ');

const TableCaptions: React.FunctionComponent<{ column: Column }> = ({ column }) => {
    const className = column.width ? column.width : '';
    return <col className={className} />;
};

const ExpandableTr: React.FunctionComponent<{
    onClick: (row: Row, selected: boolean) => void;
    row: Row;
    active: boolean;
}> = ({ children, onClick, row, active }) => {
    const className = cn(row.level === 1 ? 'compactRow' : 'extendedRow', { active, 'cursor-pointer': onClick });
    return (
        <tr onClick={() => onClick(row, active)} className={className}>
            {children}
        </tr>
    );
};

const Td = memo(function Td({ column, row }: { column: Column; row: Row }) {
    const intl = useIntl();
    return (
        <td key={column.key} data-test={column.dataField} data-field={intl.formatMessage({ id: column.labelId })}>
            <span className="text-color-darkest">{column.formatter(column.valueExtractor(row), row)}</span>
        </td>
    );
});

const Table: React.FunctionComponent<{
    data: Row[];
    onRowClicked?: (row: Row | undefined, active: boolean) => void;
    filteredColumns?: Column[];
    onOpenChildren?: (row: Row) => void;
    openRows?: string[];
    onSort?: ({ key, order }: { key: string; order: SortDirectionType }) => void;
    sortBy?: { key?: string; order?: SortDirectionType };
    selectedElements?: string[];
    sortDisabled?: boolean;
    viewType?: string;
}> = ({
    data = [],
    onRowClicked = _.noop,
    filteredColumns = [],
    onOpenChildren = () => {},
    openRows = [],
    onSort = _.noop,
    sortBy = {},
    selectedElements = [],
    sortDisabled = false,
    viewType = TableViewToggles.VIEW_TYPE_TABLE,
}) => {
    const formatExpander = useCallback(
        (row: Row) => {
            if (row.isLoading) {
                return (
                    <div className="`btn btn-muted btn-icon-only display-inline-block">
                        <Spinner />
                    </div>
                );
            }
            if (_.get(row, 'level', 1) >= 2) {
                return null;
            } else if (_.get(row, 'childrenCount', 0) < 2) {
                return <div className={'cursor-default btn btn-muted btn-icon-only'} />;
            }

            const entityReference = row.id;
            const isOpen = openRows.includes(entityReference);
            const classNames = `formatExpander btn btn-muted btn-icon-only animate ${isOpen ? 'open' : ''}`;
            return (
                <div
                    className={classNames}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onOpenChildren(row);
                    }}
                >
                    <span className="rioglyph rioglyph-chevron-down" />
                </div>
            );
        },
        [onOpenChildren, openRows]
    );

    const selectedItemAsMap = _.keyBy(selectedElements);

    function onSelectionChange({ id }: { id: string }, active: boolean) {
        const row = _.find(data, { id });
        onRowClicked(row, active);
    }

    function handleSortChange(event: React.MouseEvent<HTMLTableHeaderCellElement>) {
        if (event.currentTarget instanceof Element) {
            const clickedKey = event.currentTarget.getAttribute('data-sortby');
            const { key, order } = sortBy;
            if (!clickedKey) {
                return;
            }
            // the default case when we sort by something new
            if (key !== clickedKey) {
                onSort({ key: clickedKey, order: SortDirection.ASCENDING as SortDirectionType });
                return;
            }
            // we're already sorting so invert
            if (order === SortDirection.DESCENDING) {
                onSort({ key: clickedKey, order: SortDirection.ASCENDING as SortDirectionType });
            } else {
                onSort({ key: clickedKey, order: SortDirection.DESCENDING as SortDirectionType });
            }
        }
    }

    const tableClassNames =
        defaultTableClassNames +
        (viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD ? ' table-cards table-single-card' : '') +
        (viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS ? ' table-cards table-multi-cards' : '');

    return (
        <div>
            {data.length ? (
                <table className={tableClassNames}>
                    <colgroup>
                        {filteredColumns.map(column => (
                            <TableCaptions key={column.key} column={column} />
                        ))}
                        <col className="table-action" />
                    </colgroup>
                    <thead>
                        <tr>
                            {filteredColumns.map(column => (
                                <TableHead
                                    key={column.key}
                                    column={column}
                                    hasSortActive={sortBy.key === column.key}
                                    sortDirection={sortBy.order}
                                    handleSortChange={handleSortChange}
                                    sortDisabled={sortDisabled}
                                />
                            ))}
                            <th className="table-action" />
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            const id = row.id;
                            return (
                                <ExpandableTr
                                    key={id || index}
                                    onClick={onSelectionChange}
                                    row={row}
                                    active={!!selectedItemAsMap[id]}
                                >
                                    {filteredColumns.map(column => (
                                        <Td key={column.key} column={column} row={row} />
                                    ))}
                                    <td key="expander">{formatExpander(row)}</td>
                                </ExpandableTr>
                            );
                        })}
                        {viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && <EmptyPlaceholders />}
                    </tbody>
                </table>
            ) : (
                <NotFoundState headline="Nothing found" message="Please refine your search" />
            )}
        </div>
    );
};

function EmptyPlaceholders() {
    // We use this in order to fix a flex issue with the different table displays
    return (
        <>
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />

            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
        </>
    );
}

export default Table;
