import { Tooltip } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getSignal } from '../../api';
import OverlayTriggerWithInjectedIntl from '../../components/OverlayTriggerWithInjectedIntl';
import WithUnit from '../../components/WithUnit';
import { ValidUnit } from '../../constants/units';
import { DateRange } from '../../types';
import { formatSecondsToTime } from '../../utils/stringFormatters.js';
import toPercent from '../../utils/toPercent';
import EmptyOpConState from './EmptyOpConState';
import GetBasicOpConData from './GetBasicOpConData';
import isBelowMileageThreshold from './isBelowMileageThreshold';
import TrafficBox from './TrafficBox';
import { NormalIllustration, SlowMovingIllustration, TrafficJamIllustration } from './TrafficIllustrations';
import { OpCon } from './types';

export const TrafficJams = ({ opCon, mileageFromPerformance }: { opCon?: OpCon; mileageFromPerformance?: number }) => {
    const traffic = getSignal(opCon, 'traffic', {});

    const freeFlow = traffic.free_flow || 0;
    const slowMoving = traffic.slow_moving || 0;
    const trafficJam = traffic.traffic_jam || 0;
    const totalMileage = freeFlow + slowMoving + trafficJam;

    const jamPercent = toPercent(trafficJam, totalMileage);
    const slowPercent = toPercent(slowMoving, totalMileage);
    const trafficPercent = 100 - slowPercent - jamPercent;

    const roundedTotalMileage = (
        <FormattedNumber value={trafficJam + slowMoving + freeFlow} maximumFractionDigits={1} />
    );

    let jamTime = 0;
    if (traffic.traffic_jam_details !== undefined) {
        jamTime = traffic.traffic_jam_details.lost_time;
    }
    let slowTime = 0;
    if (traffic.slow_moving_details !== undefined) {
        slowTime = traffic.slow_moving_details.lost_time;
    }
    const totalTimeLost = formatSecondsToTime(jamTime + slowTime);

    if (isBelowMileageThreshold(_.get(traffic, 'evaluatedMileage', 0), mileageFromPerformance!)) {
        return <EmptyOpConState />;
    }

    return (
        <React.Fragment>
            <TrafficDataField
                trafficJam={trafficJam}
                slowMoving={slowMoving}
                freeFlow={freeFlow}
                jamPercent={jamPercent}
                slowPercent={slowPercent}
                trafficPercent={trafficPercent}
            />
            <GeneralDataField totalTimeLost={totalTimeLost} roundedTotalMileage={roundedTotalMileage!} />
        </React.Fragment>
    );
};

interface TrafficDataFieldI {
    trafficJam: number;
    slowMoving: number;
    freeFlow: number;
    jamPercent: number;
    slowPercent: number;
    trafficPercent: number;
}

const TrafficDataField = ({
    trafficJam,
    slowMoving,
    freeFlow,
    jamPercent,
    slowPercent,
    trafficPercent,
}: TrafficDataFieldI) => (
    <div className="display-flex border-style-solid border-width-1 border-color-light border-top-0 border-left-0 border-right-0 margin-bottom-15 text-center">
        <TrafficBox
            title={'traffic_jam'}
            percentage={jamPercent}
            illustration={<TrafficJamIllustration />}
            mileage={trafficJam}
            classes={
                'border-style-solid border-width-1 border-color-light border-top-0 border-left-0 border-bottom-0 width-33pct padding-bottom-15'
            }
        />
        <TrafficBox
            title={'slow_moving_traffic'}
            percentage={slowPercent}
            illustration={<SlowMovingIllustration />}
            mileage={slowMoving}
            classes={
                'border-style-solid border-width-1 border-color-light border-top-0 border-left-0 border-bottom-0 width-33pct padding-bottom-15'
            }
        />
        <TrafficBox
            title={'normal_traffic'}
            percentage={trafficPercent}
            illustration={<NormalIllustration />}
            mileage={freeFlow}
            classes={'width-33pct padding-bottom-15'}
        />
    </div>
);

const GeneralDataField = ({
    totalTimeLost,
    roundedTotalMileage,
}: {
    totalTimeLost: string;
    roundedTotalMileage: React.ReactElement;
}) => (
    <div className="display-flex text-center">
        <div className="width-50pct">
            <label className="text-size-14">
                <FormattedMessage id={'timeLostInTraffic'} />
            </label>
            <OverlayTriggerWithInjectedIntl
                placement="top"
                overlay={
                    <Tooltip className="top-right">
                        <FormattedMessage id={'timeLostInTrafficExplanation'} />
                    </Tooltip>
                }
            >
                <span className="rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-2" />
            </OverlayTriggerWithInjectedIntl>
            <div className="text-size-18">
                <WithUnit unit={ValidUnit.TIME}>{totalTimeLost}</WithUnit>
            </div>
        </div>
        <div className="width-50pct">
            <label className="text-size-14">
                <FormattedMessage id={'evaluatedDistance'} />
            </label>
            <div className="text-size-18">
                <WithUnit unit={ValidUnit.KILOMETERS}>{roundedTotalMileage}</WithUnit>
            </div>
        </div>
    </div>
);

const SelfLoadingTrafficJams = ({
    dateRange,
    driverIds,
    vehicleIds,
    ...props
}: {
    dateRange: DateRange;
    driverIds: string[];
    vehicleIds: string[];
}) => (
    <GetBasicOpConData start={dateRange.start} end={dateRange.end} driverIds={driverIds} vehicleIds={vehicleIds}>
        <TrafficJams {...props} />
    </GetBasicOpConData>
);

export default SelfLoadingTrafficJams;
