import _ from 'lodash';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../columns/createColumn';
import { getSummaryValue } from '../../utils/getSummaryValue';
import Chevron from '../Chevron';
import Panel, { PanelContent, PanelHeader } from '../Panel';
import SkeletonLoader from '../SkeletonLoader';
import Content from './Content';
import { Data, Errored, Loaded, Loading, Section, Status, SummaryStatus } from './types';

const createDefaultSection = (column: Column): Section => ({
    key: column.dataField,
    unit: column.unit,
    title: (
        <React.Fragment>
            {column.icon ? <span className={`${column.icon} text-size-20 margin-right-5`} /> : null}
            <FormattedMessage id={column.labelId} />
        </React.Fragment>
    ),

    stats: {},
    tabValue: (
        <SkeletonLoader height={'50%'} yOffset={5}>
            <span>100 kmh</span>
        </SkeletonLoader>
    ),
    formatter: column.formatter,
    values: [],
});

const decorateWithValues = (data: Data[]) => (section: Section): Section => {
    const stats = data.find(d => d.attribute === section.key) || { values: [] };
    return {
        ...section,
        stats,
        tabValue: getSummaryValue(section, stats),
        values: stats.values,
    };
};

export const createLoading = (): Loading => ({ type: SummaryStatus.LOADING });
export const createLoaded = (data: Data[]): Loaded => ({ type: SummaryStatus.LOADED, data });
export const createError = (data?: React.ReactNode): Errored => ({ type: SummaryStatus.ERROR, data });

type Props = {
    header?: React.ReactNode;
    columns: Column[];
    entities: unknown[];
    children?: ({ activeSection }: { activeSection: Section }) => React.ReactNode;
    status: Status;
    isExpanded?: boolean;
    onHeaderClick?: () => void;
    activeTab: string | null;
    onTabChange: (s: string | null) => void;
};

// Exposed to simplify testing.
export const LoadedContent: React.FC<{
    entities: unknown[];
    children: ({ activeSection }: { activeSection: Section }) => React.ReactNode;
    isExpanded: boolean;
    onHeaderClick?: () => void;
    data: Data[];
    activeTab: string | null;
    onTabChange: (s: string | null) => void;
    rawSections: Section[];
}> = ({ entities, children, rawSections, isExpanded, data, onTabChange, activeTab }) => {
    const sections = useMemo(() => rawSections.map(decorateWithValues(data)), [rawSections, data]);
    const activeSection = sections.find(s => s.key === activeTab)!;
    return (
        <Content
            activeTab={activeTab}
            entities={entities}
            sections={sections}
            isExpanded={isExpanded}
            setActiveTab={onTabChange}
        >
            {activeSection ? children({ activeSection }) : null}
        </Content>
    );
};
const Summary = ({
    header = null,
    columns = [],
    entities = [],
    children = () => null,
    status = createLoading(),
    isExpanded = true,
    onHeaderClick,
    activeTab,
    onTabChange,
}: Props) => {
    const defaultSections = useMemo(() => columns.map(createDefaultSection), [columns]);
    if (!activeTab || (defaultSections.length && !_.find(defaultSections, ['key', activeTab]))) {
        activeTab = _.get(defaultSections, '[0].key', null);
    }

    let content = null;
    switch (status.type) {
        case SummaryStatus.LOADED: {
            content = (
                <LoadedContent
                    entities={entities}
                    onTabChange={onTabChange}
                    data={status.data}
                    rawSections={defaultSections}
                    activeTab={activeTab}
                    isExpanded={isExpanded}
                >
                    {children}
                </LoadedContent>
            );
            break;
        }
        case SummaryStatus.LOADING: {
            const activeSection = defaultSections.find(s => s.key === activeTab);
            content = (
                <Content
                    isLoading={true}
                    activeTab={activeTab}
                    entities={entities}
                    sections={defaultSections}
                    isExpanded={isExpanded}
                    setActiveTab={onTabChange}
                >
                    {activeSection ? children({ activeSection }) : null}
                </Content>
            );
            break;
        }
        case SummaryStatus.ERROR:
            content = status.data as Error;
            break;
    }

    return (
        <Panel>
            <PanelHeader>
                <div
                    onClick={onHeaderClick}
                    className="display-flex justify-content-center align-items-center cursor-pointer"
                    data-test="summary-header"
                >
                    <div className="flex-1-1">{header}</div>
                    <Chevron isOpen={isExpanded} />
                </div>
            </PanelHeader>
            <PanelContent>{content}</PanelContent>
        </Panel>
    );
};

export default Summary;
