import { ReactNode } from 'react';

const SimpleColumn = ({
    title,
    testAttribute,
    content,
}: {
    content: ReactNode;
    title: ReactNode;
    testAttribute?: string;
}): JSX.Element => (
    <div className="margin-bottom-5" data-test={testAttribute}>
        <label className="margin-bottom-0 text-size-12">{title}</label>
        <div className="text-size-18 ellipsis-1">{content}</div>
    </div>
);

export default SimpleColumn;
