import colors from '@rio-cloud/rio-uikit/lib/es/Colors';
import { axisRight } from 'd3-axis';
import { ScaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import _ from 'lodash';
import { useContext, useEffect, useRef } from 'react';

import { GraphContext } from './GraphDataProvider';

const cleanUpTicks = (height: number, y: ScaleLinear<number, unknown>, ticks: number[]) => {
    const [maxValue] = y.domain();
    const largestTick = _.last(ticks);
    const smallestTick = _.first(ticks);
    const step = ticks[1] - ticks[0];
    const newMaxStep = (largestTick as number) + step;
    const spaceToLowerBound = height - (y(smallestTick!) as number);

    // if there is less than 20 pixel of space to lower bound we remove the tick
    if (spaceToLowerBound < 20) {
        ticks.shift();
    }

    // if have more than 4 pixel space add a new step
    if (largestTick! < maxValue && (y(newMaxStep) as number) > 4) {
        ticks.push(newMaxStep);
    }

    return ticks;
};

const milliSecondsToSeconds = (milliSeconds: Date | number) => (milliSeconds as number) / 1000;
const secondsToMilliSeconds = (seconds: Date | number) => (seconds as number) * 1000;

export const generateTicksFromSeconds = (y: ScaleLinear<number, unknown>, height: number) => {
    const [maxValue, minValue] = y.domain();
    const time = scaleTime().domain([secondsToMilliSeconds(minValue), secondsToMilliSeconds(maxValue)]);

    const yTicks = time.ticks(5).map(milliSecondsToSeconds);

    return cleanUpTicks(height, y, yTicks);
};

export const generateTicks = (y: ScaleLinear<number, unknown>, height: number) => {
    const yTicks = y.ticks(5).reverse();
    return cleanUpTicks(height, y, yTicks);
};

export function YAxisTicks({
    formatter,
    tickGenerator = generateTicks,
}: {
    formatter: (value: number) => string;
    tickGenerator?: (y: ScaleLinear<number, number>, height: number) => number[];
}): JSX.Element {
    const {
        yScale: y,
        dimensions: { margin, height },
    } = useContext(GraphContext);
    const axisRef = useRef(null);

    useEffect(() => {
        if (axisRef && axisRef.current) {
            const el = select(axisRef.current);
            const yAxis = axisRight(y as ScaleLinear<number, number>)
                .tickValues(tickGenerator(y, height) as any)
                .tickFormat(value => formatter(value as number));

            el.call(yAxis as any);
            el.select('.domain').remove();
            el.selectAll('.tick line').remove();
            el.selectAll('.tick text')
                .attr('x', margin / 2)
                .attr('dy', 12);
        }
    }, [axisRef, formatter, height, margin, tickGenerator, y]);

    return (
        <g transform={`translate(-10 0)`}>
            <g ref={axisRef} className="axis yAxisTicks" textAnchor="start" />
        </g>
    );
}

export function YAxisLines({ tickGenerator = generateTicks }): JSX.Element {
    const {
        yScale: y,
        dimensions: { widthWithoutMargin, height },
    } = useContext(GraphContext);
    const axisRef = useRef(null);

    useEffect(() => {
        if (axisRef && axisRef.current) {
            const el = select(axisRef.current);
            const yAxis = axisRight(y).tickValues(tickGenerator(y, height));

            el.call(yAxis as any);
            el.select('.domain').remove();
            el.selectAll('.tick text').remove();
            el.selectAll('.tick line')
                .attr('x0', 0)
                .attr('stroke', colors['gray-lighter'])
                .attr('x1', widthWithoutMargin + 10);
        }
    }, [axisRef, height, tickGenerator, widthWithoutMargin, y]);

    return (
        <g transform={`translate(-10 0)`}>
            <g ref={axisRef} className="axis yAxisLines" textAnchor="start" />
        </g>
    );
}
