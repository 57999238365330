import { Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import WithUnit from '../../components/WithUnit';
import { ValidUnit } from '../../constants/units';

const isInSmallestBucket = (value: number) => value < 1 && value > 0;

interface Props {
    classes: string;
    mileage: number;
    title: string;
    percentage: number;
    illustration: React.ReactElement;
}

export default function TrafficBox({ classes, mileage, title, percentage, illustration }: Props) {
    const formattedMileage = isInSmallestBucket(mileage) ? (
        <Fragment>{'<'} 1</Fragment>
    ) : (
        <Fragment>
            = <FormattedNumber value={mileage} maximumFractionDigits={1} />
        </Fragment>
    );

    const formattedPercentage = isInSmallestBucket(percentage) ? (
        <Fragment>{'<'} 1</Fragment>
    ) : (
        <FormattedNumber value={percentage} maximumFractionDigits={0} />
    );

    return (
        <div className={classes}>
            <label className="text-size-14">
                <FormattedMessage id={title} />
            </label>
            <div className="text-size-18">
                <WithUnit unit={ValidUnit.PERCENTAGE}>
                    <span data-test="trafficValue">{formattedPercentage}</span>
                </WithUnit>
            </div>
            <div className="display-flex justify-content-center">{illustration}</div>
            <div>
                <label className="text-size-14">
                    <WithUnit unit={ValidUnit.KILOMETERS}>{formattedMileage}</WithUnit>
                </label>
            </div>
        </div>
    );
}
