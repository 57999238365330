import * as RioUserMenuComponent from '@rio-cloud/rio-user-menu-component';
import _ from 'lodash';
import moment from 'moment';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import { env } from './env';
import { NoMatch } from './features/app/NoMatch';
import AppContainer from './features/AppContainer';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './features/login/actions';
import { storeLegacyPermissions } from './features/permissions/actions';
import RootErrorBoundary from './features/RootErrorBoundary';
import { accessTokenStored, hereTokenStored, idTokenStored } from './features/tokenHandling/actions';
import { setIsMobile } from './features/ui/reducer';
import { configureReporting, configureReportingGlobal } from './setup/errorReporting';
import { history } from './setup/store';
import { generatePushToDataLayer } from './utils/googleTagManager';

const { captureException } = configureReporting(window, process.env);

export const trace = env.featureToggles.tracing
    ? // eslint-disable-next-line no-console
      (...args) => console.log(`[src/index]`, ...args)
    : () => {};

function main({
    accessTokenStorage,
    configureFetchHereSettings,
    configureFetchLanguageData,
    configureFetchLegacyPermissions,
    document,
    oauthBehavior,
    render,
    store,
}) {
    const root = document.querySelector('#root');
    const fetchLanguageData = configureFetchLanguageData(store);

    document.addEventListener(RioUserMenuComponent.EVENT_USER_LANGUAGE_CHANGED, ({ detail = {} }) => {
        moment.locale(detail.language);
        return fetchLanguageData(detail.language);
    });

    // If we render on mobile we set emit isMobile
    if (document && document.documentElement && !document.documentElement.classList.contains('ua-desktop')) {
        store.dispatch(setIsMobile());
    }

    let renderApp = () => {
        renderApp = () => {};

        // Note that we need to use the base "Router" with a "hash" history
        // because the "HashRouter" doesn't allow handing in a history
        // from the outside. So this is effectively a "HashRouter" despite
        // that not being obvious here
        render(
            <RootErrorBoundary>
                <Provider store={store}>
                    <Router history={history}>
                        <Switch>
                            <Route path="/error" component={NoMatch} />
                            <Route path="/" component={AppContainer} />
                            <Route component={NoMatch} />
                        </Switch>
                    </Router>
                </Provider>
            </RootErrorBoundary>,
            root
        );
    };

    const oauthConfig = {
        onSessionError: error => {
            trace('index.onSessionError', error);
            captureException(error);
        },
        onTokenExpired: () => {
            trace('index.onTokenExpired');

            accessTokenStorage.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onTokenRenewed: result => {
            trace('index.onTokenRenewed', result);
            const { accessToken, profile } = result;
            const fetchHereSettings = configureFetchHereSettings(accessToken);
            const fetchLegacyPermissions = configureFetchLegacyPermissions(accessToken);

            const account = _.get(result, 'profile.account');

            if (env.shouldSendMetrics) {
                configureReportingGlobal({ account });
            }

            accessTokenStorage.saveAccessToken(accessToken);
            store.dispatch(accessTokenStored(accessToken));
            store.dispatch(idTokenStored(profile));
            store.dispatch(userProfileObtained(profile));

            if (result.profile && result.profile.account) {
                const pushToDataLayer = generatePushToDataLayer(account);
                pushToDataLayer({
                    eventCategory: 'performance',
                    eventAction: 'NEW_PERFORMANCE_USED',
                    eventLabel: 'Customer uses new performance frontend',
                });
            }

            store.dispatch(userSessionRenewed());

            fetchHereSettings()
                .then(hereSettings => store.dispatch(hereTokenStored(hereSettings || {})))
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    captureException(error);
                });

            fetchLegacyPermissions()
                .then(legacyPermissions => store.dispatch(storeLegacyPermissions(legacyPermissions)))
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    captureException(error);
                });

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            const { enforcedLocale } = env.featureToggles;

            if (enforcedLocale) {
                moment.locale(enforcedLocale);
                trace(`Enforcing locale "${enforcedLocale}" due to feature toggle`);

                fetchLanguageData(enforcedLocale)
                    .then(() => {
                        trace(`Language data fetched for enforced locale "${result.locale}"`);
                        renderApp();
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console, max-len
                        console.error(
                            `Language data for enforced locale "${enforcedLocale}" could not be fetched.`,
                            error
                        );
                        captureException(error);
                    });
            } else {
                fetchLanguageData(result.locale)
                    .then(() => {
                        moment.locale(result.locale);
                        trace(`Language data fetched for "${result.locale}"`);
                        renderApp();
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console, max-len
                        console.error(`Language data for "${result.locale}" could not be fetched.`, error);
                        captureException(error);
                    });
            }
        },
    };

    oauthBehavior(oauthConfig).catch(error => {
        trace('auth problem?', error);
    });
}

export default main;
