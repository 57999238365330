import { Dispatch } from '@reduxjs/toolkit';
import { Switch } from '@rio-cloud/rio-uikit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { includeCruiseControlInRating, setIncludeCruiseControlInRating } from '../../features/settings/reducer';
import { SettingStatePart } from '../../setup/types';

export const CruiseControlSettings: React.FC<{
    setIncludeCruiseControlInRating: (setting: boolean) => void;
    includeCruiseControlInRating: boolean;
}> = ({ setIncludeCruiseControlInRating, includeCruiseControlInRating }) => {
    const cruiseControlKey = 'includeCruiseControlInRating';
    return (
        <div className="panel panel-default">
            <div className="panel-body">
                <div className="row align-items-center display-block-xs display-flex-md">
                    <div className="col-md-3 text-size-16 col-xs-12">
                        <FormattedMessage id="administration.rating.cruiseControl" />
                    </div>
                    <div className="col-md-6 col-xs-12 text-color-gray">
                        <FormattedMessage id="administration.rating.cruiseControl.description" />
                    </div>
                    <div className="col-md-3 text-right col-xs-12 display-flex align-items-center justify-content-end">
                        <span
                            className="margin-right-10 text-bold"
                            style={{ userSelect: 'none' } as React.CSSProperties}
                            data-test="includeCruiseControlInRating"
                        >
                            {includeCruiseControlInRating ? (
                                <FormattedMessage id="setting.activated" />
                            ) : (
                                <FormattedMessage id="setting.deactivated" />
                            )}
                        </span>
                        <Switch
                            keyName={cruiseControlKey}
                            onChange={setIncludeCruiseControlInRating}
                            checked={includeCruiseControlInRating}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const mapStateToProps = (state: SettingStatePart) => ({
    includeCruiseControlInRating: includeCruiseControlInRating(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setIncludeCruiseControlInRating: (setting: boolean) => dispatch(setIncludeCruiseControlInRating(setting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CruiseControlSettings);
