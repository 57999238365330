import { compose } from 'redux';

import { FUEL_EFFICIENCY, KILOMETERS_PER_LITERS, LITERS, LITERS_PER_100KM, LITERS_PER_HOUR } from '../constants/units';
import {
    formatOneDigitNumber,
    getWeightedAverageBy,
    plainAverage,
    weightAverageByMileage,
    withUnit,
} from '../utils/formatters';
import createColumn from './createColumn';

const OPERATING_TIME = 'operatingTime';

export const consumptionPerTimeSignal = {
    unit: LITERS_PER_HOUR,
    displayedUnit: LITERS_PER_HOUR,
    format: compose(withUnit(LITERS_PER_HOUR), formatOneDigitNumber),
    average: (values, intervals) => getWeightedAverageBy(values, intervals, OPERATING_TIME),
};

export const distancePerConsumptionSignal = {
    unit: KILOMETERS_PER_LITERS,
    displayedUnit: KILOMETERS_PER_LITERS,
    format: compose(withUnit(KILOMETERS_PER_LITERS), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const fuelEfficiencySignal = {
    unit: FUEL_EFFICIENCY,
    displayedUnit: FUEL_EFFICIENCY,
    format: compose(withUnit(FUEL_EFFICIENCY), formatOneDigitNumber),
    average: plainAverage,
};

export const consumptionSignal = {
    unit: LITERS,
    displayedUnit: LITERS,
    rawFormat: formatOneDigitNumber,
    format: compose(withUnit(LITERS), formatOneDigitNumber),
    average: plainAverage,
};

export const consumptionPerDistanceSignal = {
    unit: LITERS_PER_100KM,
    displayedUnit: LITERS_PER_100KM,
    format: compose(withUnit(LITERS_PER_100KM), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const fuelConsumption = createColumn({
    key: 'fuelConsumption',
    dataField: 'fuelConsumption',
    labelId: 'fuelConsumption',
    formatter: consumptionSignal.format,
    unit: consumptionSignal.unit,
});

export const cruiseControlConsumptionInPotential = createColumn({
    key: 'cruiseControlConsumptionInPotential',
    dataField: 'cruiseControlConsumptionInPotential',
    labelId: 'cruiseControlConsumptionInPotential',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});
export const noCruiseControlConsumptionInPotential = createColumn({
    key: 'noCruiseControlConsumptionInPotential',
    dataField: 'noCruiseControlConsumptionInPotential',
    labelId: 'noCruiseControlConsumptionInPotential',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});

export const operatingFuelConsumption = createColumn({
    key: 'operatingFuelConsumption',
    dataField: 'operatingFuelConsumption',
    labelId: 'operatingFuelConsumption',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});

export const drivingConsumption = createColumn({
    key: 'drivingConsumption',
    dataField: 'drivingConsumption',
    labelId: 'drivingConsumption',
    formatter: consumptionPerDistanceSignal.format,
    rawFormatter: consumptionPerDistanceSignal.rawFormat,
    unit: consumptionPerDistanceSignal.unit,
});

export const distanceFuelConsumption = createColumn({
    key: 'distanceFuelConsumption',
    dataField: 'distanceFuelConsumption',
    labelId: 'distanceFuelConsumption',
    formatter: distancePerConsumptionSignal.format,
    unit: distancePerConsumptionSignal.unit,
});

export const idlingConsumption = createColumn({
    key: 'idlingConsumption',
    dataField: 'idlingConsumption',
    labelId: 'idlingConsumption',
    formatter: consumptionPerTimeSignal.format,
    unit: consumptionPerTimeSignal.unit,
});

export const fuelEfficiency = createColumn({
    key: 'fuelEfficiency',
    dataField: 'fuelEfficiency',
    labelId: 'fuelEfficiency',
    formatter: fuelEfficiencySignal.format,
    unit: fuelEfficiencySignal.unit,
});
