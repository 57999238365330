import cn from 'classnames';

export default function Chevron({ isOpen }: { isOpen: boolean }) {
    return (
        <div className="btn btn-muted btn-icon-only cursor-pointer">
            <span
                style={{
                    transition: 'transform 100ms ease-in-out',
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                className={cn('expander-icon rioglyph rioglyph-chevron-down')}
            />
        </div>
    );
}
