import { call, select, takeLatest } from 'redux-saga/effects';

import { changeLocale, languageDataFetched } from './actions';
import { extractLanguage } from './lang';
import { getLanguageData } from './selectors';

let previousLanguage = '';

function setTitle(title: string) {
    document.title = title;
}

function setHtmlAttribute(attribute: string) {
    const html = document.querySelector('html') as HTMLElement;
    html.setAttribute(attribute, 'lang');
}

function* changeTitle() {
    const languageData: Record<string, string> = yield select(getLanguageData);
    const title = 'RIO - ' + languageData.moduleName;
    yield call(setTitle, title || 'RIO - Performance Analysis');
}

function* synchHtmlTag({ payload }: { payload: string }) {
    const lang = extractLanguage(payload) as string;

    if (previousLanguage !== lang) {
        yield call(setHtmlAttribute, lang || 'en');
        previousLanguage = lang;
    }
}

export default function* root() {
    yield takeLatest(changeLocale, synchHtmlTag);
    // Listen to FETCHED event instead of CHANGE event to make sure that the language file is already loaded
    yield takeLatest(languageDataFetched, changeTitle);
}
