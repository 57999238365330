import { ExpanderPanel } from '@rio-cloud/rio-uikit';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export default function Faq({
    languageData,
}: {
    languageData?: { items: { id: string; description: string; title: string }[] };
}) {
    return (
        <Fragment>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.title.faq" defaultMessage="Frequently asked questions" />
            </h3>
            {languageData &&
                languageData.items &&
                languageData.items.map(({ id, title, description }) => (
                    <ExpanderPanel key={id} title={<FormattedMessage id={title} />} bsStyle="default">
                        <div className="position-sticky top-0" style={{ whiteSpace: 'pre-line' }}>
                            <FormattedMessage id={description} defaultMessage="" />
                        </div>
                    </ExpanderPanel>
                ))}
        </Fragment>
    );
}
