/* eslint-disable import/no-unused-modules */
import { array, boolean, intersection, literal, string, type, TypeOf, union } from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

import { Id } from './types';

export enum ReportStatus {
    'active' = 'ACTIVE',
    'disabled' = 'DISABLED',
}

export enum ReportTimeframe {
    'weekly' = 'WEEKLY',
    'monthly' = 'MONTHLY',
}

export enum ReportType {
    // 'fleet-analysis-overview' = 'fleet-analysis-overview',
    // 'fleet-analysis-consumption' = 'fleet-analysis-consumption',
    // 'fleet-analysis-operating-status' = 'fleet-analysis-operating-status',
    // 'driving-analysis-overview' = 'driving-analysis-overview',
    // 'driving-analysis-ratings' = 'driving-analysis-ratings',
    // 'driver-details-comparison' = 'driver-details-comparison',
    // 'drivers-tops-flops' = 'drivers-tops-flops',
    'driver_details' = 'DRIVER_DETAILS',
    'asset_details' = 'ASSET_DETAILS',
}

export enum TargetType {
    'assets' = 'assets',
    'groups' = 'groups',
    'drivers' = 'drivers',
    'fleet' = 'fleet',
}

type ReportName = string;

type _ReportConfiguration = {
    id: Id;
    name: ReportName;
    timeframe: ReportTimeframe;
    informViaEmail: boolean;
    status: ReportStatus;
    reportType: ReportType;
    evaluationStart: Date;
    languageCode: string;
    timezone: string;
};

export type AssetsReportType = {
    type: TargetType.assets;
    assets: Id[];
} & _ReportConfiguration;

export type GroupReportType = {
    type: TargetType.groups;
    groups: Id[];
} & _ReportConfiguration;

export type DriverReportType = {
    type: TargetType.drivers;
    drivers: Id[];
} & _ReportConfiguration;

// eslint-disable-next-line import/no-unused-modules
export type FleetReportType = {
    type: TargetType.fleet;
} & _ReportConfiguration;

export type ReportConfiguration = AssetsReportType | GroupReportType | DriverReportType | FleetReportType;

// io-ts
export const ReportConfigurationEditableFields = type({
    name: string,
    informViaEmail: boolean,
    status: union([literal(ReportStatus.active), literal(ReportStatus.disabled)]),
});

const ReportConfigurationCommon = intersection([
    ReportConfigurationEditableFields,
    type({
        reportType: union([literal(ReportType.driver_details), literal(ReportType.asset_details)]),
        timeframe: union([literal(ReportTimeframe.monthly), literal(ReportTimeframe.weekly)]),
        languageCode: string,
        timezone: string,
    }),
]);

export const ReportConfigurationRequest = intersection([
    ReportConfigurationCommon,
    type({ evaluationStart: date }),
    type({
        target: union([
            type({
                type: literal(TargetType.groups),
                groups: array(string),
            }),
            type({
                type: literal(TargetType.drivers),
                drivers: array(string),
            }),
            type({
                type: literal(TargetType.assets),
                assets: array(string),
            }),
            type({ type: literal(TargetType.fleet) }),
        ]),
    }),
]);

export const ReportConfigurationSingleResponse = intersection([
    ReportConfigurationCommon,
    type({ id: string }),
    type({ evaluationStart: DateFromISOString }),
    type({
        target: union([
            type({
                groups: array(string),
            }),
            type({
                drivers: array(string),
            }),
            type({
                assets: array(string),
            }),
            type({}),
        ]),
    }),
]);

export const ReportConfigurationResponse = type({
    items: array(ReportConfigurationSingleResponse),
});

export type ReportConfigurationResponseType = TypeOf<typeof ReportConfigurationResponse>;
export type ReportConfigurationSingleResponseType = TypeOf<typeof ReportConfigurationSingleResponse>;
export type ReportConfigurationRequestType = TypeOf<typeof ReportConfigurationRequest>;
export type EditReportConfigurationRequest = TypeOf<typeof ReportConfigurationEditableFields> & { id: string };
