import colors from '@rio-cloud/rio-uikit/lib/es/Colors';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { Bar } from '../../components/graphComponents/Bar';
import { Guidelines, ValueText } from '../../components/graphComponents/Graph';
import GraphDataProvider from '../../components/graphComponents/GraphDataProvider';
import { Overlay } from '../../components/graphComponents/Overlay';
import timeFormatBasedOnDateRange from '../../components/graphComponents/timeFormatBasedOnDateRange';
import { DataEntry } from '../../components/graphComponents/utils';
import XAxis from '../../components/graphComponents/XAxis';
import { YAxisLines, YAxisTicks } from '../../components/graphComponents/YAxis';
import { Section } from '../../components/summary/types';
import { ValidUnit } from '../../constants/units';
import SegmentableDay from '../../features/ui/SegmentableDay';
import SegmentableDayOverlay from '../../features/ui/SegmentableDayOverlay';
import { steps } from '../../features/ui/utils';
import { DateRange } from '../../types';
import { dateRangeSegmentedByStep } from '../../utils/dateRangeSegmentedByStep';
import { getRatingCSSColor } from '../../utils/rating';
import { formattedNumber } from '../../utils/stringFormatters';
import { HoverInfo } from './types';

const findMatchingY = (key: Date, data: any, step: moment.unitOfTime.Diff) => {
    const possibleMatch = data.find(
        (d: any) =>
            moment(d.x)
                .startOf(step)
                .diff(moment(key).startOf(step), 'days') === 0
    );
    return _.get(possibleMatch, 'y');
};

export const convertDataToXYValue = ({ data }: any) =>
    data.map((e: any) => ({
        x: moment(e.date).toDate(),
        y: _.get(e, 'value.value'),
    }));

export const mergeExistingDataWithPlaceholders = ({
    dateRange,
    existingData,
}: {
    dateRange: DateRange;
    existingData: any;
}) => {
    const step = steps(dateRange);
    const xAxisValues = dateRangeSegmentedByStep({ dateRange, step });

    if (!xAxisValues.length) {
        return existingData;
    }

    return xAxisValues.map(date => {
        return {
            x: date,
            y: findMatchingY(date, existingData, step),
        };
    });
};

export const Graph: React.FC<{
    data: any;
    dateRange: DateRange;
    intl: any;
    column: Section;
    width?: number;
}> = ({
    data = [],
    dateRange = { start: new Date(), end: new Date() },
    intl: { formatDate, formatMessage },
    column,
    width = 1251,
}) => {
    const dimensions = { height: 400, width, margin: 40 };
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);
    const xYList = convertDataToXYValue({ data });
    const mappedData = mergeExistingDataWithPlaceholders({ dateRange, existingData: xYList });
    const hoverOffset = 40;

    const average = column?.stats?.average;

    const unit = column.unit;
    const colorSchema = useMemo(() => {
        if (unit === ValidUnit.PERCENTAGE) {
            return (d: DataEntry) => getRatingCSSColor(d.y).hexColor;
        } else {
            return () => colors['color-highlight-dark'];
        }
    }, [unit]);

    const yScale = useMemo(() => {
        if (unit === ValidUnit.PERCENTAGE) {
            return scaleLinear()
                .domain([100, 0])
                .range([dimensions.margin, dimensions.height - dimensions.margin]);
        }
        return undefined;
    }, [dimensions.height, dimensions.margin, unit]);

    const tickFormatter = timeFormatBasedOnDateRange(formatDate, formatMessage);
    return (
        <div className="graph bg-white position-relative overflow-hidden" style={{ height: dimensions.height }}>
            {hoverInfo && (
                <Overlay
                    hasData={!_.isUndefined(hoverInfo?.data)}
                    x={hoverInfo?.x}
                    y={
                        hoverInfo.y +
                        (hoverInfo.y >= dimensions.height - dimensions.margin * 4 ? -hoverOffset : hoverOffset)
                    }
                >
                    {!_.isUndefined(_.get(hoverInfo, 'data.y')) ? (
                        column.formatter(hoverInfo.data.y)
                    ) : (
                        <FormattedMessage id="noData" />
                    )}
                </Overlay>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                dimensions={dimensions}
                yScale={yScale}
                data={mappedData}
            >
                <SegmentableDayOverlay formatter={column.formatter} />
                {!_.isUndefined(average) && (
                    <ValueText
                        show={Boolean(hoverInfo)}
                        value={average}
                        formatter={column.formatter}
                        className="text-bold"
                        label={<FormattedMessage id="average" />}
                    />
                )}
                <svg
                    width={'100%'}
                    height={'100%'}
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <Guidelines average={average!} show={Boolean(hoverInfo)} />
                    <YAxisLines />
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                    />
                    <Bar colorSchema={colorSchema} minHeight={1} />
                    <YAxisTicks formatter={formattedNumber} />
                    <SegmentableDay onHover={setHover} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default withResizeDetector(injectIntl(Graph), {
    handleWidth: true,
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 100,
});
