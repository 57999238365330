import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userProfileObtained(state, { payload }) {
            return {
                ...state,
                userProfile: payload,
            };
        },
        userSessionExpired(state) {
            return {
                ...state,
                hasUserSessionEverExpired: true,
                userSessionExpired: true,
            };
        },
        userSessionRenewed(state) {
            return {
                ...state,
                userSessionExpired: false,
            };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const { userProfileObtained, userSessionExpired, userSessionRenewed } = loginSlice.actions;

export default loginSlice.reducer;
