import React from 'react';

import { Column } from '../../columns/createColumn';
import { EntityType } from './types';
const Discipline = ({
    title,
    entity,
    columns,
}: {
    title: React.ReactNode;
    entity: EntityType;
    columns: Column[];
}): JSX.Element => {
    const pattern = [4, 3, 1];
    return (
        <div className="row">
            <div className="col-md-4">{title}</div>
            {columns.map((column, index) => (
                <div key={column.key} className={`col-md-${pattern[index % pattern.length]}`}>
                    {column.formatter(column.valueExtractor(entity), entity)}
                </div>
            ))}
        </div>
    );
};

export default Discipline;
