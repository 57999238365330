import { ErrorState } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';

const SimpleErrorState: React.FC<{
    headlineId?: string;
    messageId?: string;
    fullWidth?: boolean;
}> = ({ headlineId = 'error.default', messageId, fullWidth }) => {
    return (
        <ErrorState
            fullWidth={fullWidth}
            headline={<FormattedMessage id={headlineId} />}
            message={messageId && <FormattedMessage id={messageId} />}
        />
    );
};

export default SimpleErrorState;
