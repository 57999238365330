import moment from 'moment';
import React from 'react';

import FormattedDateTime from './FormattedDateTime';

const isValidDate = (date: Date | string): boolean => !!date && moment(date).isValid();

interface FromToRangeI {
    start?: Date | string;
    end?: Date | string;
}

const FromToRange: React.FC<FromToRangeI> = ({ start, end }) => {
    return start && end && isValidDate(start) && isValidDate(end) ? (
        <div>
            <div>
                <FormattedDateTime value={start} />
            </div>
            <div>
                <FormattedDateTime value={end} />
            </div>
        </div>
    ) : (
        <span>-</span>
    );
};

export default React.memo(FromToRange);
