import { createSlice } from '@reduxjs/toolkit';

import { accessToken } from './accessToken';

const initialState = {
    accessToken: accessToken.getAccessToken(),
    idToken: undefined,
    hereToken: undefined,
};

const tokenHandlingReducer = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored(state, { payload }) {
            return {
                ...state,
                accessToken: payload,
            };
        },
        idTokenStored(state, { payload }) {
            return {
                ...state,
                idToken: payload,
            };
        },
        hereTokenStored(state, { payload }) {
            return {
                ...state,
                hereToken: payload,
            };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const { accessTokenStored, idTokenStored, hereTokenStored } = tokenHandlingReducer.actions;

export default tokenHandlingReducer.reducer;
