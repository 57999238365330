import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export type Settings = { [key: string]: any };
export type SettingState = {
    settings: Settings;
} & { includeCruiseControlInRating: boolean };

export const defaultState: SettingState = {
    settings: {},
    includeCruiseControlInRating: true,
};

const settingSlice = createSlice({
    name: 'settings',
    initialState: defaultState,
    reducers: {
        setIncludeCruiseControlInRating(state, { payload }) {
            return {
                ...state,

                includeCruiseControlInRating: payload,
            };
        },
        updateSavingFailed() {},
        loadSettings() {},
        loadSettingsFailed() {},
        loadSettingsSuccess(state, { payload: { settings } }: { payload: { settings: Settings } }) {
            state.settings = settings;
        },
        updateSetting(state, { payload: { key, value } }: { payload: { key: string; value: any } }) {
            state.settings[key] = value;
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const {
    updateSetting,
    loadSettings,
    loadSettingsSuccess,
    loadSettingsFailed,
    updateSavingFailed,
    setIncludeCruiseControlInRating,
} = settingSlice.actions;
export default settingSlice.reducer;

export const includeCruiseControlInRating = (state: { settings: SettingState }) =>
    _.get(state, 'settings.includeCruiseControlInRating', true);
