export const convertToRows = columnsPerRow => data =>
    data.reduce((acc, item, idx) => {
        if (idx % columnsPerRow === 0) {
            acc.push([]);
        }
        acc[acc.length - 1].push(item);
        return acc;
    }, []);

export default convertToRows;
