import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

import { getSignal } from '../../api';
import { DateRange, Id, OpconSignals } from '../../types';
import configureGetCreateTimeInterval from '../../utils/timeInterval';
import useQuery from '../../utils/useQuery';
import { requestOpConData } from '../actions';
import { Loadable, LoadableType } from '../loadable';

export type ElevationData = {
    date: Date;
    min: number;
    max: number;
    interpolated: boolean;
};

export const floorDate = (date: Date, unit: moment.unitOfTime.Base) =>
    moment(date)
        .startOf(unit)
        .toDate();
export const ceilDate = (date: Date, unit: moment.unitOfTime.Base) =>
    moment(date)
        .endOf(unit)
        .toDate();

const resolution = configureGetCreateTimeInterval<number>({
    days: 60,
    weeks: 60 * 24,
    months: 60 * 24,
    quarters: 60 * 24 * 30,
    years: 365 * 60,
});

export const QUERY = ({
    dateRange,
    vehicleIds,
    driverIds,
}: {
    dateRange: DateRange;
    vehicleIds: Id[];
    driverIds: Id[];
}) => ({
    from: moment(dateRange.start).toISOString(true),
    to: moment(dateRange.end).toISOString(true),
    ...(vehicleIds.length ? { asset_ids: vehicleIds } : {}),
    ...(driverIds.length ? { driver_ids: driverIds } : {}),
    from_offset: 0,
    from_interval: 1000 * 60 * resolution(dateRange),
    facets: ['topology'],
    group_by: ['from'],
});

// We're trying to be smart here and fix empty spots with prev. data
// but not smart enough to look behind
const findDataSetByDate = (
    dateRange: DateRange,
    diff: moment.unitOfTime.Diff,
    map: Record<number, { min: number; max: number }>,
    date: Date
): { min: number; max: number } => {
    if (date < dateRange.start) {
        return { min: 0, max: 0 };
    }

    return (
        map[date.getTime()] ||
        findDataSetByDate(
            dateRange,
            diff,
            map,
            moment(date)
                .subtract(1, diff)
                .toDate()
        ) || { min: 0, max: 0 }
    );
};

const useElevationData = ({
    dateRange,
    driverIds,
    vehicleIds,
    unit,
}: {
    dateRange: DateRange;
    driverIds: Id[];
    vehicleIds: Id[];
    unit: moment.unitOfTime.Base;
}): LoadableType<ElevationData[]> => {
    const data = useQuery(QUERY, {
        endPoint: requestOpConData as any,
        variables: { dateRange, driverIds, vehicleIds },
    }) as LoadableType<OpconSignals[]>;

    return useMemo(() => {
        const emptyDataSet = Loadable.map(data, data => {
            const sortedData = [...data].sort(
                (a, b) => getSignal(b, 'start', dateRange.start) - getSignal(a, 'start', dateRange.start)
            );
            const start = getSignal(_.last(sortedData) || {}, 'start', dateRange.start);
            const end = moment(getSignal(_.first(sortedData) || {}, 'start', start))
                .add(1, unit)
                .toDate();

            const days = Math.abs(moment(end).diff(start, unit));
            return Array(days)
                .fill(start)
                .map((day, idx) =>
                    moment(day)
                        .add(idx, unit)
                        .toDate()
                );
        });

        const transformDataToMap = Loadable.map(data, data =>
            data.reduce((acc: Record<number, { min: number; max: number }>, segment) => {
                const date = getSignal(segment, 'start', {});
                const min = getSignal(segment, 'topology', {}).min_altitude;
                const max = getSignal(segment, 'topology', {}).max_altitude;
                return { ...acc, [date.getTime()]: { min, max } };
            }, {})
        );

        return Loadable.combine(
            (map, emptyDataSet) =>
                emptyDataSet.map(date => {
                    //change to date and elevation
                    const { min, max } = findDataSetByDate(dateRange, unit, map, date);
                    return {
                        date,
                        min,
                        max,
                        interpolated: !map[date.getTime()],
                    };
                }),
            transformDataToMap,
            emptyDataSet
        );
    }, [data]);
};

export default useElevationData;
