import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sessionExpiredAcknowledged: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog(state) {
            return { ...state, sessionExpiredAcknowledged: true };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const { hideSessionExpiredDialog } = appSlice.actions;

export default appSlice.reducer;
