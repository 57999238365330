import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ConditionalTooltip from '../../components/ConditionalTooltip';
import { AVAILABLE_CONFIGURATIONS } from '../../constants/pdf';
import { hasSomePerformVehicleSelected as getHasSomePerformVehicleSelected } from '../../data/selectors';
import { requestExcelDownload } from '../../features/download/actions';
import DownloadMenu from '../../features/download/DownloadMenu';
import fileName from '../../features/download/fileName';
import {
    TEMPLATE_EXCEL_DOWNLOAD,
    TEMPLATE_OPCON_REQUEST,
    TEMPLATE_VEHICLE_REQUEST_BY_TIME,
} from '../../features/download/queries';
import { requestPrint } from '../../features/pdf/actions';
import { TEMPLATE_VEHICLE_REQUEST } from '../../features/pdf/queries';
import { trackingAttributes } from '../../utils/googleTagManager';
import configureGetCreateTimeInterval from '../../utils/timeInterval';
import { getSelectedUseCase, getSortedBy, getUseCaseSetting } from './selectors';

const timeIntervalType = configureGetCreateTimeInterval({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'quarter',
    years: 'year',
});

export function VehicleDownloadMenu({
    dateRange,
    useCaseKey,
    vehicles,
    onDownloadRequested,
    onPDFRequested,
    disabled,
    hasSomePerformVehicleSelected,
    columnSettings,
    sortBy,
}) {
    const downloadOptions = [
        { header: true, value: <FormattedMessage id="download.excelReport" /> },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'performance, downloadButton',
                        'download:vehicleDownloadButton',
                        'byVehicle'
                    )}
                >
                    <FormattedMessage id="download.excelByVehicle" />
                </div>
            ),
            onSelect: () => {
                onDownloadRequested({
                    aggregationQuery: TEMPLATE_EXCEL_DOWNLOAD({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                    }),
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        groupBy: ['asset-id'],
                    }),
                    fileName: fileName({ dateRange, value: 'vehicle' }),
                });
            },
        },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'performance, downloadButton',
                        'download:vehicleDownloadButton',
                        'byDriverAndVehicle'
                    )}
                >
                    <FormattedMessage id="download.excelByDriverAndVehicle" />
                </div>
            ),
            onSelect: () => {
                onDownloadRequested({
                    aggregationQuery: TEMPLATE_EXCEL_DOWNLOAD({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        segmentBy: 'driver_and_vehicle',
                    }),
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        groupBy: ['asset-id', 'driver-id'],
                    }),
                    fileName: fileName({ dateRange, value: 'driver-and-vehicle' }),
                });
            },
        },
    ];

    const pdfExportOptions = [
        { header: true, value: <FormattedMessage id="download.pdfReport" /> },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'performance, printButton',
                        'download:vehiclePrintButton',
                        'byVehicle'
                    )}
                >
                    <FormattedMessage id="download.pdfReportByVehicle" />
                </div>
            ),
            onSelect: () =>
                onPDFRequested({
                    id: AVAILABLE_CONFIGURATIONS.VEHICLE,
                    useCase: useCaseKey,
                    columnSettings,
                    dateRange: { start: moment(dateRange.start).toDate(), end: moment(dateRange.end).toDate() },
                    sortBy,
                    aggregationQuery: TEMPLATE_VEHICLE_REQUEST({
                        ...dateRange,
                        vehicleIds: vehicles,
                    }),
                    summaryQuery: TEMPLATE_VEHICLE_REQUEST_BY_TIME({
                        ...dateRange,
                        intervalType: timeIntervalType(dateRange),
                        vehicleIds: vehicles,
                    }),
                }),
        },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'performance, printButton',
                        'download:vehiclePrintButton',
                        'byVehicleAndDrivers'
                    )}
                >
                    <FormattedMessage id="download.pdfReportByVehicleAndDrivers" />
                </div>
            ),
            onSelect: () =>
                onPDFRequested({
                    id: AVAILABLE_CONFIGURATIONS.VEHICLE_AND_DRIVER,
                    useCase: useCaseKey,
                    columnSettings,
                    dateRange: { start: moment(dateRange.start).toDate(), end: moment(dateRange.end).toDate() },
                    sortBy,
                    aggregationQuery: TEMPLATE_VEHICLE_REQUEST({
                        ...dateRange,
                        vehicleIds: vehicles,
                        segmentBy: 'driver_and_vehicle',
                    }),
                    summaryQuery: TEMPLATE_VEHICLE_REQUEST_BY_TIME({
                        ...dateRange,
                        intervalType: timeIntervalType(dateRange),
                        vehicleIds: vehicles,
                    }),
                }),
        },
    ];

    return (
        <ConditionalTooltip
            enabled={!disabled && !hasSomePerformVehicleSelected}
            getTooltipChildren={() => <FormattedMessage id={'featureNotIncludedInEssentials'} />}
        >
            <span className="btn-group">
                <DownloadMenu
                    options={downloadOptions.concat(pdfExportOptions)}
                    owner="vehicle"
                    disabled={disabled || !hasSomePerformVehicleSelected}
                />
            </span>
        </ConditionalTooltip>
    );
}

const mapDispatchToProps = dispatch => ({
    onPDFRequested: args => dispatch(requestPrint(args)),
    onDownloadRequested: ({ aggregationQuery, opConQuery, fileName }) =>
        dispatch(requestExcelDownload({ owner: 'vehicle', opConQuery, aggregationQuery, fileName })),
});

export default connect(
    state => ({
        columnSettings: getUseCaseSetting(state, getSelectedUseCase(state)),
        sortBy: getSortedBy(state),
        hasSomePerformVehicleSelected: getHasSomePerformVehicleSelected(state),
    }),
    mapDispatchToProps
)(VehicleDownloadMenu);
