/*global window */
import { configureStore } from '@reduxjs/toolkit';
import { Notification } from '@rio-cloud/rio-uikit';
import { createHashHistory } from 'history';
import { useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import dataSaga from '../data/saga';
import downloadSaga from '../features/download/saga';
import langSaga from '../features/lang/langSaga';
import printSaga from '../features/pdf/saga';
import cruiseControlSettingsSaga from '../features/settings/cruiseControlSettingsSaga';
import settingsSaga from '../features/settings/saga';
import treeSagas from '../features/tree/saga';
import uiSaga from '../features/ui/settingsSaga';
import { createNotificationMiddleware } from '../middlewares/notificationMiddleware';
import coDriverSaga from '../pages/coDriver/saga';
import driverSettingsSaga from '../pages/driverAnalysis/settingsSaga';
import vehicleSettingsSaga from '../pages/vehicleAnalysis/settingsSaga';
import { storesInitialized } from './actions';
import reducers from './reducers';

const getUserConfirmation = (message: string, callback: (answer: boolean) => void) => callback(window.confirm(message)); // eslint-disable-line no-alert

const history = createHashHistory({
    getUserConfirmation,
    hashType: 'noslash',
});

const sagaMiddleware = createSagaMiddleware();
const notificationMiddleware = createNotificationMiddleware({
    onError: Notification.error.bind(Notification),
    onSuccess: Notification.success.bind(Notification),
});

const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat([sagaMiddleware, notificationMiddleware]),
    devTools: true,
});
[
    ...treeSagas,
    settingsSaga,
    dataSaga,
    vehicleSettingsSaga,
    downloadSaga,
    driverSettingsSaga,
    cruiseControlSettingsSaga,
    langSaga,
    printSaga,
    coDriverSaga,
    uiSaga,
].forEach(saga => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sagaMiddleware.run(saga);
});

// needs to run after boostrapping saga and redux
store.dispatch(storesInitialized());

// eslint-disable-next-line import/no-unused-modules
export { history, store };

// eslint-disable-next-line import/no-unused-modules
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line import/no-unused-modules
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
