import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { configureReporting } from '../../setup/errorReporting';
import { infoNotification } from '../ui/notificationSaga';
import { loadSettingsSuccess as settingsLoaded, setIncludeCruiseControlInRating } from './reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from './saga';

const { captureException } = configureReporting(window, process.env);

export const SETTINGS = {
    CC: 'includeCC',
};

export function* clearAllSettings() {
    yield saveSettingsToStorage(SETTINGS.CC, true);
}

export function* saveCCSetting({ payload }: { payload: boolean }) {
    try {
        yield saveSettingsToStorage(SETTINGS.CC, Boolean(payload));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const includeCC: boolean = yield loadSettingsFromStorage(SETTINGS.CC, true);

        yield put(setIncludeCruiseControlInRating(includeCC));
    } catch (e) {
        yield call(captureException, e);
        yield clearAllSettings();
        yield delay(1000);
        yield call(infoNotification, 'info.settingsCouldNotBeRestored');
    }
}

export default function* root() {
    yield takeLatest(setIncludeCruiseControlInRating, saveCCSetting);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
