import _ from 'lodash';
import { createSelector } from 'reselect';

import { getDrivers, getGroups, getVehicles } from '../../data/selectors';
import { Map, VehicleStatus } from '../../types';

const toList = <T>(list: Map<T>) => Object.values(list);

export const getDriverList = createSelector(getDrivers, drivers =>
    _.sortBy(toList(drivers), ['lastName', 'firstName', 'displayName']).map(driver => ({
        ...driver,
        type: 'driver',
        name: driver.lastName
            ? {
                  firstName: driver.firstName,
                  lastName: driver.lastName,
              }
            : driver.firstName || driver.displayName,
    }))
);

export const getVehicleList = createSelector(getVehicles, vehicles =>
    toList(vehicles).map(vehicle => ({
        ...vehicle,
        type: vehicle.type!.toLowerCase(),
    }))
);

export const getGroupList = createSelector(getGroups, toList);

export const getActiveDriverList = createSelector(getDriverList, drivers =>
    drivers.filter(driver => driver.status === 'ACTIVE')
);

export const getActiveVehicleList = createSelector(getVehicleList, vehicles =>
    vehicles.filter(vehicle => vehicle.status === VehicleStatus.ACTIVE)
);
