import { Action, createAction, Middleware } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Dispatch } from 'redux';

import { getLanguageData } from '../features/lang/selectors';

export const errorNotification = createAction('notification/error', (translationId: string) => ({
    payload: translationId,
}));

export const successNotification = createAction('notification/success', (translationId: string) => ({
    payload: translationId,
}));

const isNotificationActionError = (action: Action<unknown>): action is ReturnType<typeof errorNotification> =>
    action.type === errorNotification.type;

const isNotificationActionSuccess = (action: Action<unknown>): action is ReturnType<typeof successNotification> =>
    action.type === successNotification.type;

export const createNotificationMiddleware = ({
    onError,
    onSuccess,
}: {
    onError: (message: string) => void;
    onSuccess: (message: string) => void;
}): Middleware => store => (next: Dispatch) => (action: Action<string | typeof errorNotification>) => {
    const languageData = getLanguageData(store.getState());
    if (isNotificationActionError(action)) {
        const errorMessage = _.get(languageData, action.payload, 'Unknown Error');
        onError(errorMessage);
    }
    if (isNotificationActionSuccess(action)) {
        const successMessage = _.get(languageData, action.payload, 'Success');
        onSuccess(successMessage);
    }

    next(action);
};
