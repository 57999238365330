import _ from 'lodash';

import { TREE_TABS } from '../../constants/treeTabs';
import { TreeStatePart } from '../../setup/types';

export const getSelectedVehicleIds = (state: TreeStatePart) => _.get(state, 'tree.selectedVehicles', []);
export const getSelectedDriverIds = (state: TreeStatePart) => _.get(state, 'tree.selectedDrivers', []);
export const getSelectedVehicleGroupIds = (state: TreeStatePart) => _.get(state, 'tree.selectedVehicleGroups', []);
export const getSelectedDriverGroupIds = (state: TreeStatePart) => _.get(state, 'tree.selectedDriverGroups', []);
export const getActiveTab = (state: TreeStatePart) => _.get(state, 'tree.tab', TREE_TABS.VEHICLES);
export const showEmptyGroups = (state: TreeStatePart) => state.tree.showEmptyGroups;
