import { ForbiddenState, NotBookedState } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { URL_MARKETPLACE_PERFORM, URL_MARKETPLACE_PERFORM_KOREA } from '../constants/URLs';
import { isKoreanTenant } from '../features/login/selectors';
import { State } from '../setup/types';
import { System, Vehicle } from '../types';

export function PerformUpSell({ vehicles, isKoreanTenant = false }: { vehicles: Vehicle[]; isKoreanTenant?: boolean }) {
    const hasOneTbm3Vehicle = vehicles.some(vehicle => vehicle.system === System.TBM3);

    if (hasOneTbm3Vehicle) {
        return (
            <div data-test="perform-up-sell-tmb3">
                <NotBookedState
                    message={null}
                    headline={<FormattedMessage id="upSell.performTitle" />}
                    buttons={[
                        {
                            text: (
                                <span>
                                    <FormattedMessage id="upSell.callToAction" />
                                </span>
                            ),
                            href: isKoreanTenant ? URL_MARKETPLACE_PERFORM_KOREA : URL_MARKETPLACE_PERFORM,
                        },
                    ]}
                />
            </div>
        );
    }

    const hasOneTbm2Vehicle = vehicles.some(vehicle => vehicle.system === System.TBM2);

    if (hasOneTbm2Vehicle) {
        return (
            <div data-test="perform-up-sell-tmb2">
                <ForbiddenState
                    headline={<FormattedMessage id={'upSell.performNotAvailableForTBM2'} />}
                    message={<span />}
                    buttons={[]}
                />
            </div>
        );
    }

    return (
        <div data-test="perform-up-sell-other">
            <ForbiddenState
                headline={<FormattedMessage id={'upSell.performNotAvailable'} />}
                message={<span />}
                buttons={[]}
            />
        </div>
    );
}

function mapStateToProps(state: State) {
    return { isKoreanTenant: isKoreanTenant(state) };
}

export default connect(mapStateToProps)(PerformUpSell);
