import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal, Vehicle } from '../../api';
import { System } from '../../types';

export function getTbm2AndTbm3Warning(row: unknown): JSX.Element | null {
    const vehicles: Vehicle[] = getSignal(row, 'vehicles', []);
    const systemsGroupedByType = _.groupBy(vehicles.map(vehicle => _.get(vehicle, 'system')).filter(Boolean));
    const tbm2Vehicles = vehicles
        .filter(vehicle => _.get(vehicle, 'system') === System.TBM2)
        .map(vehicle => vehicle.name);
    const containsTmb2AndTmb3 = systemsGroupedByType[System.TBM2] && systemsGroupedByType[System.TBM3];

    return containsTmb2AndTmb3 ? (
        <Tbm2AndTbm3Warning key="containsTbm2AndTbm3Warnings" tbm2VehicleNames={tbm2Vehicles} />
    ) : null;
}

function Tbm2AndTbm3Warning({ tbm2VehicleNames }: { tbm2VehicleNames: string[] }): JSX.Element {
    return (
        <React.Fragment>
            <dt>
                <FormattedMessage id="containsTbm2AndTbm3Warnings" />
            </dt>
            <dd>
                <FormattedMessage id="containsTbm2AndTbm3Warning" values={{ vehicles: tbm2VehicleNames.join(', ') }} />
            </dd>
        </React.Fragment>
    );
}

export default Tbm2AndTbm3Warning;
