export const getMileageSplitIntoUrbanNonUrban = roads => {
    const urban = roads
        .filter(r => {
            return r.urban;
        })
        .reduce((mileage, road) => {
            return mileage + road.mileage;
        }, 0);

    const nonUrban = roads
        .filter(r => {
            return !r.urban;
        })
        .reduce((mileage, road) => {
            return mileage + road.mileage;
        }, 0);

    return { urban, nonUrban };
};
