import { Fragment } from 'react';

export function TrafficJamIllustration() {
    return (
        <Fragment>
            <div className="display-flex justify-content-center">
                <div className="display-flex flex-column height-100 border-style-dashed border-width-1 border-color-white border-bottom-0 border-top-0 border-left-0 padding-top-10 padding-left-5 padding-right-5 margin-bottom-10 margin-top-5 bg-highlight-darker">
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-2" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-2" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-2" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                </div>
                <div className="display-flex flex-column padding-top-10 padding-right-5 padding-left-5 margin-bottom-10 margin-top-5 bg-highlight-darker">
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-2" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-2" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-2" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                </div>
            </div>
        </Fragment>
    );
}

export function SlowMovingIllustration() {
    return (
        <Fragment>
            <div className="display-flex justify-content-center">
                <div className="display-flex flex-column height-100 border-style-dashed border-width-1 border-color-white border-bottom-0 border-top-0 border-left-0 padding-top-15 padding-left-5 padding-right-5 margin-bottom-10 margin-top-5 bg-highlight-dark">
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-15" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                </div>
                <div className="display-flex flex-column padding-top-10 padding-right-5 padding-left-5 margin-bottom-10 margin-top-5 bg-highlight-dark">
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-15" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                </div>
            </div>
        </Fragment>
    );
}

export function NormalIllustration() {
    return (
        <Fragment>
            <div className="display-flex justify-content-center">
                <div className="display-flex flex-column height-100 border-style-dashed border-width-1 border-color-white border-bottom-0 border-top-0 border-left-0 padding-top-25 padding-left-5 padding-right-5 margin-bottom-10 margin-top-5 bg-highlight">
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-25" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                </div>
                <div className="display-flex flex-column padding-top-10 padding-right-5 padding-left-5 margin-bottom-10 margin-top-5 bg-highlight">
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-25" />
                    <span className="rioglyph rioglyph-truck text-color-white text-size-18 margin-bottom-5" />
                </div>
            </div>
        </Fragment>
    );
}
