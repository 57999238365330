import always from 'lodash/fp/always';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Column } from '../../columns/createColumn';
import SimpleErrorState from '../../components/SimpleErrorState';
import Summary, { createError, createLoaded, createLoading } from '../../components/summary/Summary';
import usePerformData from '../../data/hooks/usePerformData';
import { Loadable } from '../../data/loadable';
import { entitiesForGraphSelection, getSelectedVehicleIds } from '../../data/selectors';
import { isGraphCollapsed, setIsGraphCollapsed } from '../../features/ui/reducer';
import { State } from '../../setup/types';
import { DateRange, Id } from '../../types';
import configureGetCreateTimeInterval from '../../utils/timeInterval';
import { VEHICLE_REQUEST_BY_TIME_ATTRIBUTES } from './queries';
import { setActiveSummaryTab } from './reducer';
import { getActiveSummaryTab } from './selectors';
import Graph from './VehicleDataGraph';

const timeIntervalType = configureGetCreateTimeInterval({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'quarter',
    years: 'year',
});

type Props = {
    columns?: Column[];
    dateRange: DateRange;
    vehicles: Id[];
    filterSummary: React.ReactNode;
    isGraphCollapsed?: boolean;
    setIsGraphCollapsed?: (b: boolean) => void;
    activeTab: string | null;
    setActiveTab: (tab: string | null) => void;
};

export const VehicleSummary = ({
    columns = [],
    dateRange,
    vehicles = [],
    filterSummary,
    isGraphCollapsed,
    setIsGraphCollapsed,
    setActiveTab,
    activeTab,
}: Props) => {
    const loadableEntities = usePerformData(
        VEHICLE_REQUEST_BY_TIME_ATTRIBUTES,
        {
            variables: {
                ...dateRange,
                vehicleIds: vehicles,
                segmentBy: 'interval',
                intervalType: timeIntervalType(dateRange),
            },
            selector: entitiesForGraphSelection,
        },
        ['total_segment']
    );

    const hasErrored = Loadable.cata(loadableEntities, always(false), always(true), always(false), always(false));
    const status = Loadable.cata(loadableEntities, createLoaded, createError, createLoading, createLoading);

    return hasErrored ? (
        <SimpleErrorState fullWidth headlineId={'error.default'} messageId={'error.server'} />
    ) : (
        <Summary
            activeTab={activeTab}
            columns={columns}
            entities={vehicles}
            header={filterSummary}
            isExpanded={!isGraphCollapsed}
            onHeaderClick={setIsGraphCollapsed ? () => setIsGraphCollapsed(!isGraphCollapsed) : undefined}
            onTabChange={setActiveTab}
            status={status}
        >
            {({ activeSection }) => <Graph dateRange={dateRange} column={activeSection} data={activeSection.values} />}
        </Summary>
    );
};

export default compose(
    connect(
        (state: State) => ({
            isGraphCollapsed: isGraphCollapsed(state),
            vehicles: getSelectedVehicleIds(state),
            activeTab: getActiveSummaryTab(state),
        }),
        dispatch => ({
            setIsGraphCollapsed: (isCollapsed: boolean) => dispatch(setIsGraphCollapsed(isCollapsed)),
            setActiveTab: (tab: string | null) => dispatch(setActiveSummaryTab(tab)),
        })
    )
)(VehicleSummary);
