import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export type Sorting = { key: string; order: 'asc' | 'desc' };

export type UseCaseKey = 'overview' | 'ratings';

export type UseCaseSettingsType = { columnOrder: string[]; filteredColumnNames: string[] };

export type DriverAnalysisState = {
    sorting: Sorting | unknown;
    useCase: UseCaseKey;
    areSettingsVisible: boolean;
    activeSummaryTab: string | null;
    useCaseSettings: { [key: string]: UseCaseSettingsType };
    openRows: string[];
};

export const DEFAULT_USE_CASE_CONFIG = {
    columnOrder: [],
    filteredColumnNames: [],
};

function getUseCaseFromState(driverAnalysisStateSlice: DriverAnalysisState, key: UseCaseKey) {
    return _.get(driverAnalysisStateSlice.useCaseSettings, key, DEFAULT_USE_CASE_CONFIG);
}

function updateUseCaseSettings(
    state: DriverAnalysisState,
    key: UseCaseKey,
    useCaseSettings: UseCaseSettingsType
): DriverAnalysisState {
    return { ...state, useCaseSettings: { ...state.useCaseSettings, [key]: useCaseSettings } };
}

export const defaultState: DriverAnalysisState = {
    sorting: {},
    useCase: 'ratings',
    activeSummaryTab: null,
    areSettingsVisible: false,
    useCaseSettings: {},
    openRows: [],
};

const driverAnalysisSlice = createSlice({
    name: 'driverAnalysis',
    initialState: defaultState,
    reducers: {
        setColumnOrder(
            state,
            { payload: { useCaseKey, columnOrder } }: PayloadAction<{ useCaseKey: UseCaseKey; columnOrder: string[] }>
        ) {
            const oldUseCaseSettings = getUseCaseFromState(state, useCaseKey);
            const newUseCaseSettings = { ...oldUseCaseSettings, columnOrder };
            return updateUseCaseSettings(state, useCaseKey, newUseCaseSettings);
        },
        setFilteredColumnNames(
            state,
            {
                payload: { useCaseKey, filteredColumnNames },
            }: PayloadAction<{ useCaseKey: UseCaseKey; filteredColumnNames: string[] }>
        ) {
            const oldUseCaseSettings = getUseCaseFromState(state, useCaseKey);
            const newUseCaseSettings = { ...oldUseCaseSettings, filteredColumnNames };
            return updateUseCaseSettings(state, useCaseKey, newUseCaseSettings);
        },
        toggleSettings(state, { payload }: PayloadAction<boolean>) {
            return {
                ...state,
                areSettingsVisible: payload,
            };
        },
        setSortKeyAndDirection(state, { payload }: PayloadAction<Sorting>) {
            return {
                ...state,
                sorting: payload,
            };
        },
        setUseCase(state, { payload }: PayloadAction<UseCaseKey>) {
            return {
                ...state,
                useCase: payload,
            };
        },
        setActiveSummaryTab(state, { payload }) {
            return { ...state, activeSummaryTab: payload };
        },
        openRow(state, { payload: { id } }: PayloadAction<{ id: string }>) {
            return {
                ...state,
                openRows: [...state.openRows, id],
            };
        },
        closeRow(state, { payload: { id } }: PayloadAction<{ id: string }>) {
            return {
                ...state,
                openRows: state.openRows.filter(rowId => rowId !== id),
            };
        },
    },
});

// eslint-disable-next-line import/no-unused-modules
export const {
    setColumnOrder,
    setFilteredColumnNames,
    toggleSettings,
    setSortKeyAndDirection,
    setUseCase,
    openRow,
    closeRow,
    setActiveSummaryTab,
} = driverAnalysisSlice.actions;

export default driverAnalysisSlice.reducer;
