import { FormattedMessage } from 'react-intl';

export default function GlossaryItem({ title, description }: { title: string; description: string }) {
    return (
        <div className="position-sticky top-0" style={{ whiteSpace: 'pre-line' }}>
            <h3 className="margin-top-0">
                <FormattedMessage id={title} />
            </h3>
            <FormattedMessage id={description} defaultMessage="" />
        </div>
    );
}
