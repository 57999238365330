import { ScaleLinear, scaleLinear, ScaleTime, scaleTime } from 'd3-scale';
import _, { get, head, sortBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import { DataEntry } from './utils';

export type Data = DataEntry[];
type formatMessage = ({ id }: { id: string }) => string;
type formatDate = (date: Date) => string;
type Dimensions = {
    height: number;
    width: number;
    margin: number;
    widthWithoutMargin: number;
    heightWithoutMargin: number;
};

export const GraphContext = React.createContext<{
    data: Data;
    xScale: ScaleTime<number, any>;
    yScale: ScaleLinear<any, any>;
    formatDate: formatDate;
    formatMessage: formatMessage;
    dimensions: Dimensions;
}>({
    data: [],
    xScale: scaleTime<number, number>(),
    yScale: scaleLinear(),
    formatDate: (_: Date) => '',
    formatMessage: () => '',
    dimensions: { height: 0, width: 0, margin: 0, widthWithoutMargin: 0, heightWithoutMargin: 0 },
});

type props = {
    data: Data;
    dimensions: { height: number; width: number; margin: number };
    formatMessage: ({ id }: { id: string }) => string;
    formatDate: (date: Date) => string;
    xScale?: ScaleTime<number, any>;
    yScale?: ScaleLinear<any, any>;
};

const GraphDataProvider: React.FC<props> = ({
    data,
    dimensions: { height, width, margin },
    formatMessage = () => '',
    formatDate = _ => '',
    children,
    xScale,
    yScale,
}) => {
    const sortedByY = sortBy(data, 'y')
        .filter(data => _.isNumber(data.y))
        .reverse();
    const largestXData = get(head(sortBy(data, 'x').reverse()), 'x', new Date());
    const smallestXData = get(head(sortBy(data, 'x')), 'x', new Date());
    const largestDataPoint = get(head(sortedByY), 'y', 0);
    const smallestDataPoint = get(head(sortedByY.reverse()), 'y', 0);
    const smallestDataPointMinus10Percent = Math.max(
        smallestDataPoint - (largestDataPoint - smallestDataPoint) * 0.1,
        0
    );
    const yDomain = useMemo(() => [largestDataPoint, smallestDataPointMinus10Percent], [
        largestDataPoint,
        smallestDataPointMinus10Percent,
    ]);
    const xDomain = [moment(smallestXData).toDate(), moment(largestXData).toDate()];
    xScale =
        xScale ||
        scaleTime()
            .domain(xDomain)
            .range([margin, width - margin]);

    yScale = useMemo(
        () =>
            yScale ||
            scaleLinear()
                .domain(yDomain)
                .range([margin, height - margin]),
        [yScale, yDomain, margin, height]
    );

    const config = {
        data,
        xScale,
        yScale,
        formatDate,
        formatMessage,
        dimensions: {
            margin,
            height: height - margin,
            width: width - margin,
            widthWithoutMargin: width,
            heightWithoutMargin: height,
        },
    };

    return <GraphContext.Provider value={config}>{children}</GraphContext.Provider>;
};

export default GraphDataProvider;
