import _ from 'lodash';

import { getSignal } from '../api';

export const SortDirection = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
};
const DEFAULT_VALUE_EXTRACTOR = value => {
    if (typeof value === 'string') {
        return value.toLowerCase() || '';
    }

    if (_.isNil(value)) {
        return -1 * Infinity;
    }
    return value;
};

// Those aren't signals but need to be sorted on. By either the vehicle name or the full name.
// could be a property of each column
export const findValueExtractor = propertyName => {
    switch (propertyName) {
        case 'vehicle':
        case 'vehicles':
        case 'vehicleNames':
            return vehicles => (vehicles || []).map(vehicle => _.get(vehicle, 'name', '-').toLocaleLowerCase());
        case 'drivers':
        case 'explicitDrivers':
            return drivers =>
                (drivers || []).map(driver =>
                    `${_.get(driver, 'lastName', '-')}, ${_.get(driver, 'firstName', '-')}, ${_.get(
                        driver,
                        'displayName',
                        '-'
                    )}`.toLocaleLowerCase()
                );
        case 'end':
        case 'start':
        case 'tripStartEnd':
            return val => new Date(val);
        case 'system':
            return vehicles => (vehicles || []).map(vehicle => _.get(vehicle, 'system', '').toLowerCase());
        default:
            return DEFAULT_VALUE_EXTRACTOR;
    }
};

// There is a special case where driver and vehicles aren't a signals, but we still need to sort on them.
const getPropertyValue = (element, property, defaultValue) => {
    if (!element) {
        return element;
    }

    return getSignal(element, property, defaultValue);
};

export function sortByProperty(
    array,
    property,
    sortDirection = SortDirection.ASCENDING,
    valueExtractor = DEFAULT_VALUE_EXTRACTOR
) {
    const isPropertyBeingSortedIsArray = _.isArray(getPropertyValue(array[0], property, null));

    if (isPropertyBeingSortedIsArray) {
        return _.orderBy(
            array,
            [
                element => getPropertyValue(element, property, []).length,
                element => valueExtractor(getPropertyValue(element, property, {})),
            ],
            [sortDirection, sortDirection]
        );
    }

    if (property === 'start') {
        return _.orderBy(
            array,
            [
                element => valueExtractor(getPropertyValue(element, property, null)),
                element => valueExtractor(getPropertyValue(element, 'end', null)),
            ],
            [sortDirection, sortDirection]
        );
    }

    return _.orderBy(array, element => valueExtractor(getPropertyValue(element, property, null)), sortDirection);
}
