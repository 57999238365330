import PropTypes from 'prop-types';

import { SingleDriver } from '../../components/DriverLabel';
import Label from '../../components/Label';
import { DriverPropType } from '../../utils/commonPropTypes';

const ClickableDriverList = ({ onClick, drivers }) => {
    return (
        <div className="text-size-18 ellipsis-1">
            <ul className="padding-0" style={{ listStyle: 'none' }} data-test="clickable-drivers">
                {drivers.map(driver => (
                    <li
                        className="hover-text-color-highlight cursor-pointer"
                        data-test="driver"
                        key={driver.id}
                        onClick={() => onClick(driver)}
                    >
                        <Label id="drivers" icon="rioglyph-driver">
                            <SingleDriver driver={driver.drivers[0]} />
                        </Label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

ClickableDriverList.propTypes = {
    onClick: PropTypes.func.isRequired,
    drivers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            drivers: PropTypes.arrayOf(DriverPropType),
        })
    ).isRequired,
};

export default ClickableDriverList;
