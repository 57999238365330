import _ from 'lodash';

import { Column } from '../../columns/createColumn';
import { totalRatingWithoutCC } from '../../columns/percentage';
import { getColumns } from '../../utils/columns';
import { tableColumns } from './columns';
import { UseCaseKey } from './driverAnalysisReducer';

const overviewColumns = [
    'drivers',
    'vehicles',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'drivingConsumption',
    'mileage',
    'averageWeight',
    'fuelEfficiency',
];
const overviewSummaryColumns = ['totalRating', 'drivingConsumption', 'mileage', 'averageWeight', 'fuelEfficiency'];

const ratingColumns = [
    'drivers',
    'vehicles',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRating',
    'overspeedRating',
];
const ratingSummaryColumns = [
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRating',
    'overspeedRating',
];

export type UseCaseConfig = {
    key: string;
    columns: Column[];
    summaryColumns: Column[];
};

export const useCaseConfig: UseCaseConfig[] = [
    {
        key: 'overview',
        columns: getColumns(overviewColumns, tableColumns),
        summaryColumns: getColumns(overviewSummaryColumns),
    },
    {
        key: 'ratings',
        columns: getColumns(ratingColumns, tableColumns),
        summaryColumns: getColumns(ratingSummaryColumns),
    },
];

type Settings = { includeCruiseControlInRating: boolean };
export const getUseCaseConfigForKey = (
    useCaseKey: UseCaseKey,
    { includeCruiseControlInRating }: Settings = {
        includeCruiseControlInRating: true,
    }
): UseCaseConfig | undefined => {
    const config = _.find(useCaseConfig, ['key', useCaseKey]);

    if (config) {
        return {
            ...config,
            summaryColumns: config.summaryColumns.map(column =>
                !includeCruiseControlInRating && column.key === 'totalRating' ? totalRatingWithoutCC : column
            ),
            columns: config.columns.map(column =>
                !includeCruiseControlInRating && column.key === 'totalRating' ? totalRatingWithoutCC : column
            ),
        };
    }

    return;
};
