import { ReactNode } from 'react';

import { getSignal } from '../api';

export type Column = {
    key: string;
    unit?: string;
    dataField: string;
    labelId: string;
    valueExtractor: (entity: any) => any;
    formatter: (value: any, data?: any) => ReactNode;
    dataSort: boolean;
    icon?: string;
    width?: string;
};

type ColumnTemplate = Omit<Column, 'dataSort' | 'valueExtractor'> &
    Partial<Pick<Column, 'dataSort' | 'valueExtractor'>>;

export default function createColumn({
    key,
    unit,
    dataField,
    labelId,
    valueExtractor,
    formatter,
    dataSort,
    icon,
    width,
}: ColumnTemplate): Column {
    return {
        key,
        unit,
        dataField,
        labelId,
        valueExtractor: valueExtractor || ((entity: string) => getSignal(entity, dataField, undefined)),
        formatter,
        dataSort: dataSort === undefined ? true : dataSort,
        icon,
        width,
    };
}
