import _ from 'lodash';
const isVeryEasy = (rating: number) => rating >= 0 && rating < 20.0;
const isEasy = (rating: number) => rating >= 20 && rating < 40.0;
const isMedium = (rating: number) => rating >= 40 && rating < 60.0;
const isHard = (rating: number) => rating >= 60 && rating < 80.0;
const isVeryHard = (rating: number) => rating >= 80 && rating <= 100.0;

const ratingToIndex = (rating: number | null) => {
    if (!_.isNumber(rating)) {
        return -1;
    }
    if (isVeryEasy(rating)) {
        return 0;
    } else if (isEasy(rating)) {
        return 1;
    } else if (isMedium(rating)) {
        return 2;
    } else if (isHard(rating)) {
        return 3;
    } else if (isVeryHard(rating)) {
        return 4;
    }
    return -1;
};

export default ratingToIndex;
