import cn from 'classnames';

import { getSignal } from '../../../api';
import { DateRange, HydratedEntity } from '../../../types';
import { extractDriverName, extractVehicleName } from '../../../utils/stringFormatters';
import { TEMPLATE_DRIVER_REQUEST, TEMPLATE_OPCON_REQUEST, TEMPLATE_VEHICLE_OPERATION_IN_DAYS } from '../queries';
import usePdfGeneration from './usePdfGeneration';

export default function DriverPDFDownloader({ entity, dateRange }: { entity: HydratedEntity; dateRange: DateRange }) {
    const driverIds = getSignal(entity, 'driverIds', []);
    const vehicleIds = getSignal(entity, 'vehicleIds', []);
    const isTopLevelWithMoreThan1Vehicle = vehicleIds.length > 1;
    const vehicleIdsWithLevelAwareness = isTopLevelWithMoreThan1Vehicle ? [] : vehicleIds;
    const performanceQuery = TEMPLATE_DRIVER_REQUEST({
        ...dateRange,
        driverIds,
        vehicleIds: vehicleIdsWithLevelAwareness,
        segmentBy: 'driver',
    });

    const opConQuery = TEMPLATE_OPCON_REQUEST({
        ...dateRange,
        vehicleIds: vehicleIdsWithLevelAwareness,
        driverIds,
    });

    const operationalDaysQuery = TEMPLATE_VEHICLE_OPERATION_IN_DAYS({
        ...dateRange,
        vehicleIds: vehicleIdsWithLevelAwareness,
        driverIds,
    });

    const identifier = `details-${extractDriverName(entity.drivers[0])}`;
    const fileSuffix = entity.vehicles.map(extractVehicleName).join('-');
    const { triggerDownload, hasPendingDownload } = usePdfGeneration({
        dateRange,
        // We pass along the entity to avoid having to request data twice
        entity,
        performanceQuery,
        operationalDaysQuery,
        opConQuery,
        identifier,
        fileSuffix,
        isDriver: true,
    });

    return (
        <button
            type="button"
            data-test="driverDetailDownload"
            className={cn('btn btn-muted btn-icon-only', { 'btn-loading': hasPendingDownload })}
            onClick={triggerDownload}
        >
            <span className={cn('rioglyph ', { 'rioglyph-download': !hasPendingDownload })} aria-hidden="true" />
        </button>
    );
}
