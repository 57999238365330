import { createAction } from '@reduxjs/toolkit';

import { DateRange, OpconQueryPayload, PerformQueryPayload } from '../../types';

export type Payload = {
    aggregationQuery: PerformQueryPayload;
    summaryQuery: PerformQueryPayload;
    opConQuery: OpconQueryPayload;
    dateRange: DateRange;
    useCase: string;
    id: string;
    retry?: boolean;
    columnSettings: { filteredColumnNames: string[]; columnOrder: string[] };
    sortBy: { key: string; order: string };
};

export const requestPrint = createAction<Payload>('requestPrint');
