import { Collapse, DataTab, DataTabs } from '@rio-cloud/rio-uikit';
import React from 'react';

import ErrorBoundary from '../ErrorBoundry';
import Title from './Title';
import { Section } from './types';

function Content({
    activeTab,
    entities,
    sections,
    isExpanded = true,
    setActiveTab,
    isLoading = false,
    children,
}: {
    activeTab: string | null;
    entities: unknown[];
    sections: Section[];
    isLoading?: boolean;
    isExpanded: boolean;
    setActiveTab: (tabKey: string) => void;
    children: React.ReactNode;
}) {
    const activeSection = sections.find(s => s.key === activeTab);
    if (!(entities.length && activeSection)) {
        return null;
    }

    return (
        <ErrorBoundary>
            <DataTabs
                activeKey={isExpanded && !isLoading ? activeTab! : 'no-tab'}
                onSelectTab={setActiveTab}
                bordered={false}
                disableTransition={true}
                tabContentClassName="padding-left-0 padding-right-0 padding-bottom-0 padding-top-0"
                className={!isExpanded ? 'pointer-events-none' : ''}
                commonTabContent={
                    <Collapse in={isExpanded} mountOnEnter={true} unmountOnExit={true}>
                        <div>{children}</div>
                    </Collapse>
                }
            >
                {sections.map((s, index: number) => (
                    <DataTab
                        key={`${s.key}-${index}`}
                        tabKey={s.key}
                        title={<Title text={s.title} value={s.tabValue} />}
                    />
                ))}
            </DataTabs>
        </ErrorBoundary>
    );
}

export default Content;
