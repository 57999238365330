import { Tooltip } from '@rio-cloud/rio-uikit';
import React from 'react';

import OverlayTriggerWithInjectedIntl from './OverlayTriggerWithInjectedIntl';

interface ConditionalTooltipI {
    children: JSX.Element;
    getTooltipChildren: (preset?: any) => () => JSX.Element;
    enabled: boolean;
}

const ConditionalTooltip: React.FC<ConditionalTooltipI> = ({
    children,
    enabled,
    getTooltipChildren,
}: ConditionalTooltipI) => {
    if (!enabled) {
        return children;
    }
    return (
        <OverlayTriggerWithInjectedIntl
            placement="top"
            overlay={
                <Tooltip id="tooltip" className="top">
                    {getTooltipChildren()}
                </Tooltip>
            }
        >
            {children}
        </OverlayTriggerWithInjectedIntl>
    );
    // without span, see https://github.com/react-bootstrap/react-bootstrap/issues/2208
};

export default ConditionalTooltip;
