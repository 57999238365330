import { Select as RioSelect } from '@rio-cloud/rio-uikit';
import cn from 'classnames';
import { ReactElement } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

enum InputStatus {
    error,
    touched,
    success,
}

type Option = { id: string; label: string; selected: boolean };
type Props<Form> = {
    errorMessage?: ReactElement;
    status?: InputStatus;
    options: Omit<Option, 'selected'>[];
    disabled?: boolean;
} & Omit<ControllerProps<Form>, 'render'>;

export default function Select<Form>(props: Props<Form>) {
    const { status = InputStatus.success, errorMessage = 'error', options, name, disabled = false, ...rest } = props;
    return (
        <Controller
            name={name}
            {...rest}
            render={({ field }) => (
                <div className={cn({ 'has-error': status === InputStatus.error })} data-test={`create-form-${name}`}>
                    <RioSelect
                        disabled={disabled}
                        options={options.map(option => ({ ...option, selected: option.id === field.value }))}
                        onChange={(element: any) => field.onChange(element.id)}
                    />

                    {status === InputStatus.error && (
                        <span className="help-block">
                            <span>{errorMessage}</span>
                        </span>
                    )}
                </div>
            )}
        />
    );
}
