import cn from 'classnames';
import React, { ReactNode } from 'react';

const COLUMN_WIDTH = 4;

const Box: React.FC<{ rows: Array<Array<ReactNode>>; hasBorder?: boolean }> = ({ rows, hasBorder = true }) => (
    <div
        className={cn(
            'padding-top-20 padding-bottom-20',
            hasBorder &&
                'border-style-solid border-top-0 border-left-0 border-right-0 border-color-lighter border-width-1'
        )}
    >
        <div className="row">
            <div className="col-md-12">
                {rows.map((row: Array<ReactNode>, idx: number) => (
                    <div className="row" key={idx}>
                        {row.map((content: ReactNode, innerIndex: number) => {
                            const isLastRow = row.length - 1 === innerIndex;
                            const remainingColumns = 12 - (row.length - 1) * COLUMN_WIDTH;
                            return (
                                <div
                                    className={cn(isLastRow ? `col-md-${remainingColumns}` : `col-md-${COLUMN_WIDTH}`)}
                                    key={innerIndex}
                                >
                                    {content}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default Box;
