import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { useContext, useEffect, useRef } from 'react';

import { ANIMATION_DURATION, WITH_DATA_COLOR, WITHOUT_DATA_COLOR } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { hasValue } from './utils';

export default function Line() {
    const { xScale: x, yScale: y, data } = useContext(GraphContext);
    const group = useRef(null);

    useEffect(() => {
        if (group && group.current) {
            const withDataLineGenerator = line()
                .defined(d => hasValue(d))
                .x(d => x(d.x))
                .y(d => y(d.y));
            const withoutDataLineGenerator = line()
                .x(d => x(d.x))
                .y(d => (hasValue(d) ? y(d.y) : y(y.domain()[1])));

            const withDataPath = select(group.current).select('.with-data');
            const withoutDataPath = select(group.current).select('.without-data');

            withDataPath
                .datum(data)
                .transition()
                .ease(easeLinear)
                .duration(ANIMATION_DURATION)
                .attr('d', withDataLineGenerator);

            withoutDataPath
                .datum(data)
                .transition()
                .ease(easeLinear)
                .duration(ANIMATION_DURATION)
                .attr('d', withoutDataLineGenerator);
        }
    }, [data, x, y]);

    return (
        <g ref={group} className="line">
            <path fill="none" className="without-data" stroke={WITHOUT_DATA_COLOR} strokeWidth="4" />
            <path fill="none" className="with-data" stroke={WITH_DATA_COLOR} strokeWidth="4" />
        </g>
    );
}
