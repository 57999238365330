import release4Image from '../static/release-4.0.0.png';

const releaseNotes = {
    '4.0.0': {
        date: '2021-05-05T00:00:00',
        content: [
            <div key="releaseNotes.4.0.0">
                <p>
                    In the sidebar you can now find details of the vehicle for MAN vehicles with active Perform booking
                    in the tab of the same name:
                </p>
                <ul>
                    <li>
                        Among other things, you will find information on engine power, transmission type, rear axle
                        ratio, emission class, fuel tank
                    </li>
                    <li>The display can only be made for one selected vehicle</li>
                    <li>The information is available in both the vehicle and driver views</li>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <li>Please note that some information may not be available (display "-")</li>
                </ul>
                <p></p>
                <img
                    src={release4Image}
                    width={640}
                    className="border-style-solid border-width-1 border-color-lighter display-inline-block"
                />
            </div>,
        ],
    },
};

export default releaseNotes;
