/* eslint-disable import/no-unused-modules */

import { get } from 'lodash';
import getOr from 'lodash/fp/getOr';

export const getUserProfile = getOr(null, 'login.userProfile');
export const getAccount = getOr(null, 'login.userProfile.account');

export const isKoreanTenant = state => {
    return get(state, 'login.userProfile.tenant', '').includes('rio-southkorea');
};

export const hasUserSessionEverExpired = state => get(state, 'login.hasUserSessionEverExpired');

export const isUserSessionExpired = state => get(state, 'login.userSessionExpired');
