import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Label: React.FC<{ icon?: string; id?: string; color?: string; children?: any }> = ({
    icon,
    id,
    color = 'dark',
    children,
}) => {
    return (
        <div className="display-flex align-items-center">
            <span className={cn('rioglyph text-size-20 margin-right-5', icon, 'text-color-' + color)} />
            {children ? children : <FormattedMessage id={id} />}
        </div>
    );
};

export default Label;
