import _ from 'lodash';
import { createSelector } from 'reselect';

import { VEHICLE_ROUTE } from '../../constants/routes';
import { entitiesByKey, getDrivers, getVehicles, mapEntities } from '../../data/selectors';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { sidebarData } from '../../features/ui/reducer';
import { DEFAULT_USE_CASE_CONFIG } from './reducer';
import { defaultUseCaseConfig, getUseCaseConfigForKey } from './useCaseConfig';

const vehicleSidebarData = state => sidebarData(state, VEHICLE_ROUTE);

export const entitySidePanelSelection = createSelector(
    [vehicleSidebarData, entitiesByKey, getVehicles, getDrivers, getPerformVehicles],
    (uiSidebarData, dataEntitiesByKey, vehicles, drivers, performVehicles) => {
        const mapEntityByKey = key => mapEntities(dataEntitiesByKey[key], vehicles, drivers, performVehicles);

        const key = _.get(uiSidebarData, 'id');
        const childKeys = _.get(uiSidebarData, 'childrenIds', []);

        const general = mapEntityByKey(key);
        const children = childKeys.map(id => mapEntityByKey(id));

        return { ...general, children };
    }
);

export const getUseCaseSetting = (state, key) => {
    return _.get(state, ['vehicleAnalysis', 'useCaseSettings', key], DEFAULT_USE_CASE_CONFIG);
};

export const getUseCaseConfig = () => defaultUseCaseConfig;

export const getSelectedUseCase = state => {
    return _.get(state, 'vehicleAnalysis.selectedUseCase', 'overview');
};

export const getSelectedUseCaseConfig = createSelector(
    [getSelectedUseCase, getUseCaseConfig],
    (selectedUseCase, useCaseConfig) => getUseCaseConfigForKey(selectedUseCase, useCaseConfig)
);

export const getSortedBy = state => {
    return _.get(state, 'vehicleAnalysis.sortedBy');
};

export const getOpenRows = state => {
    return _.get(state, 'vehicleAnalysis.openRows');
};

export const getActiveSummaryTab = state => {
    return _.get(state, 'vehicleAnalysis.activeSummaryTab');
};
