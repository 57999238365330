import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getSignal } from '../../api';
import ErrorBoundary from '../../components/ErrorBoundry';
import PerformUpSell from '../../components/PerformUpSell';
import TabbableContent from '../../components/TabbableContent';
import { ForesightedDrivingByVehicle } from '../../features/foresightedDriving/ForesightedDriving';
import Total from '../../features/foresightedDriving/Total';
import Elevation from '../../features/opcon/Elevation';
import OperationalRange from '../../features/opcon/OperationalRange';
import OperationConditionRating from '../../features/opcon/OperationConditionRating';
import TrafficJams from '../../features/opcon/TrafficJams';
import { getFeatureToggles } from '../../features/permissions/selectors';
import VehicleSpecification from '../../features/vehicleSpecification/VehicleSpecification';

export const SidepanelTabs = ({ dateRange, entity, level, hasPerformVehicle }) => {
    const vehicles = getSignal(entity, 'vehicles', []);
    const vehicleIds = getSignal(entity, 'vehicleIds', []);
    const driverIds = level === 1 ? [] : getSignal(entity, 'driverIds', []);
    const mileageFromPerformance = getSignal(entity, 'mileage', 0);

    // The opcon expects null to be passed when driverId was null;
    let driverIdsWithUnknownDriverMarkedAsNull = level !== 1 && driverIds.length === 0 ? [null] : driverIds;

    const hasMaxOneDriver = driverIds.length <= 1;
    const upsell = <PerformUpSell vehicles={vehicles} />;
    const props = {
        dateRange,
        vehicleIds,
        driverIds: driverIdsWithUnknownDriverMarkedAsNull,
        mileageFromPerformance,
        canSeeRoute: true,
        isRouteEnabled: level === 1,
    };

    const tabs = [
        {
            testTag: 'map',
            title: <FormattedMessage id="map" />,
            content: hasPerformVehicle ? <OperationalRange {...props} /> : upsell,
            disabled: !hasPerformVehicle,
        },
        {
            testTag: 'traffic',
            title: <FormattedMessage id="traffic" />,
            content: hasPerformVehicle ? <TrafficJams {...props} /> : upsell,
            disabled: !hasPerformVehicle,
        },
        {
            testTag: 'heightProfile',
            title: <FormattedMessage id="heightProfile" />,
            content: hasPerformVehicle ? <Elevation {...props} /> : upsell,
            disabled: !hasPerformVehicle,
        },
        {
            testTag: 'vehspec',
            title: <FormattedMessage id="vehicleSpecification" />,
            content: hasPerformVehicle ? (
                <VehicleSpecification dateRange={dateRange} vehicleIds={vehicleIds} />
            ) : (
                upsell
            ),
            disabled: !hasPerformVehicle,
        },
        ...(hasMaxOneDriver
            ? [
                  {
                      testTag: 'driving',
                      title: <FormattedMessage id="sidepanel.foresightedDriving" />,
                      content: hasPerformVehicle ? (
                          <ErrorBoundary>
                              <ForesightedDrivingByVehicle
                                  start={dateRange.start}
                                  end={dateRange.end}
                                  vehicleIds={vehicleIds}
                                  driverIds={driverIdsWithUnknownDriverMarkedAsNull}
                              >
                                  {({ entity }) => (
                                      <div className="display-flex equal-height">
                                          <div style={{ flex: 1 }} className="margin-right-15">
                                              <OperationConditionRating {...props} />
                                          </div>
                                          <div style={{ flex: 1 }}>
                                              <Total entity={entity} />
                                          </div>
                                      </div>
                                  )}
                              </ForesightedDrivingByVehicle>
                          </ErrorBoundary>
                      ) : (
                          upsell
                      ),
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
    ];

    return <TabbableContent tabs={tabs} />;
};

export function mapStateToProps(state) {
    return { featureToggles: getFeatureToggles(state) };
}

export default connect(mapStateToProps)(SidepanelTabs);
