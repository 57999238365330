import { FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';
import ErrorBoundary from '../../components/ErrorBoundry';
import TabbableContent from '../../components/TabbableContent';
import { ForesightedDrivingByDriver } from '../../features/foresightedDriving/ForesightedDriving';
import Total from '../../features/foresightedDriving/Total';
import { EntityType } from '../../features/foresightedDriving/types';
import Elevation from '../../features/opcon/Elevation';
import OperationalRange from '../../features/opcon/OperationalRange';
import OperationConditionRating from '../../features/opcon/OperationConditionRating';
import TrafficJams from '../../features/opcon/TrafficJams';
import VehicleSpecification from '../../features/vehicleSpecification/VehicleSpecification';
import { DateRange } from '../../types';

export const DriverSidebarTabs: React.FC<{
    dateRange: DateRange;
    entity: any;
    allowedVehicles: string[];
}> = ({ dateRange, entity, allowedVehicles }) => {
    const vehicleIds = getSignal(entity, 'vehicleIds', []);
    const driverIds = getSignal(entity, 'driverIds', []);
    const mileage = getSignal(entity, 'mileage', 0);
    const isTopLevelWithMoreThan1Vehicle = vehicleIds.length > 1;
    const vehicleIdsWithLevelAwareness = isTopLevelWithMoreThan1Vehicle ? [] : vehicleIds;
    const props = {
        dateRange,
        vehicleIds: vehicleIdsWithLevelAwareness,
        driverIds,
        mileageFromPerformance: mileage,
        canSeeRoute: false,
        isRouteEnabled: false,
    };
    const tabs = [
        {
            testTag: 'driving',
            title: <FormattedMessage id="sidepanel.foresightedDriving" />,
            content: (
                <ErrorBoundary>
                    <ForesightedDrivingByDriver
                        start={dateRange.start}
                        end={dateRange.end}
                        vehicleIds={vehicleIds}
                        driverIds={driverIds}
                        allowedVehicles={allowedVehicles}
                    >
                        {({ entity }: { entity: EntityType }) => (
                            <div className="display-flex equal-height">
                                <div style={{ flex: 1 }} className="margin-right-15">
                                    <OperationConditionRating {...props} />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Total entity={entity} />
                                </div>
                            </div>
                        )}
                    </ForesightedDrivingByDriver>
                </ErrorBoundary>
            ),
        },
        {
            testTag: 'vehspec',
            title: <FormattedMessage id="vehicleSpecification" />,
            content: <VehicleSpecification {...props} vehicleIds={vehicleIds} />,
        },
        {
            testTag: 'map',
            title: <FormattedMessage id="map" />,
            content: <OperationalRange {...props} />,
        },
        {
            testTag: 'traffic',
            title: <FormattedMessage id="traffic" />,
            content: <TrafficJams {...props} />,
        },
        {
            testTag: 'heightProfile',
            title: <FormattedMessage id="heightProfile" />,
            content: <Elevation {...props} />,
        },
    ];

    return <TabbableContent tabs={tabs} />;
};

export default DriverSidebarTabs;
