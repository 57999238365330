import { compose } from 'redux';

import { KILOMETERS_PER_HOUR } from '../constants/units';
import { formatOneDigitNumber, weightAverageByMileage, withUnit } from '../utils/formatters';
import createColumn from './createColumn';

export const speedSignal = {
    unit: KILOMETERS_PER_HOUR,
    displayedUnit: KILOMETERS_PER_HOUR,
    format: compose(withUnit(KILOMETERS_PER_HOUR), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const averageSpeed = createColumn({
    key: 'averageSpeed',
    dataField: 'averageSpeed',
    labelId: 'averageSpeed',
    formatter: speedSignal.format,
    unit: speedSignal.unit,
});
