import { FormattedMessage } from 'react-intl';

const tooltips = ['rating.very_easy', 'rating.easy', 'rating.medium', 'rating.hard', 'rating.very_hard'].map(key => (
    <FormattedMessage key={key} id={key} />
));

const IndexToWording = ({ index }: { index: number }) => {
    return tooltips[index] || <FormattedMessage id="noData" />;
};

export default IndexToWording;
