import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ValidUnit } from '../constants/units';

export default function WithUnit({ children, unit }: { children: React.ReactNode; unit: ValidUnit }): JSX.Element {
    return (
        <React.Fragment>
            {children} <FormattedMessage id={`unit.${unit}`} />
        </React.Fragment>
    );
}
