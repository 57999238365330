import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getSignal } from '../../api';
import isBelowMileageThreshold from './isBelowMileageThreshold';

export const getOpconAccuracyWarning = (row: unknown): JSX.Element | null => {
    const opconReport = getSignal(row, 'operatingConditionResponse', {});
    const totalMileage = getSignal(row, 'mileage', 0);

    // facets
    const routeTypes = _.get(opconReport, 'route.value.evaluatedMileage', 0);
    const topology = _.get(opconReport, 'topology.value.evaluatedMileage', 0);
    const traffic = _.get(opconReport, 'traffic.value.evaluatedMileage', 0);
    const weight = _.get(opconReport, 'weight.value.evaluatedMileage', totalMileage);
    const range = _.get(opconReport, 'range.value.evaluatedMileage', 0);

    // if one facet has evaluated less than 75% of the performance service
    // show warning
    const needsWarning =
        isBelowMileageThreshold(routeTypes, totalMileage) ||
        isBelowMileageThreshold(topology, totalMileage) ||
        isBelowMileageThreshold(range, totalMileage) ||
        isBelowMileageThreshold(traffic, totalMileage) ||
        isBelowMileageThreshold(weight, totalMileage);

    return needsWarning ? <OpconAccuracyWarning key="inaccuracyOperationConditionWarnings" /> : null;
};

function OpconAccuracyWarning(): JSX.Element {
    return (
        <React.Fragment>
            <dt data-test="opcon-warning">
                <FormattedMessage id="inaccuracyOperationConditionWarning" />
            </dt>
            <dd>
                <FormattedMessage id="inaccuracyOperationConditionWarningDetail" />
            </dd>
        </React.Fragment>
    );
}

export default OpconAccuracyWarning;
