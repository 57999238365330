import _ from 'lodash';
import moment from 'moment';
import React, { ReactElement, useMemo } from 'react';

import { createSignal, getSignal } from '../../api';
import useOpconData from '../../data/hooks/useOpconData';
import usePerformData from '../../data/hooks/usePerformData';
import { Loadable as LoadableDataStructure } from '../../data/loadable';
import { DateRange, HydratedEntity } from '../../types';
import { ISODateToMomentDate } from '../../utils/ISODateToMomentDateObject';
import { DRIVER_REQUEST_ATTRIBUTES } from './queries';

const removeEmptyEntity = (entity: HydratedEntity) => {
    const start = getSignal(entity, 'start', undefined);
    return ISODateToMomentDate(start).isValid();
};

const mergeParentsWithChildren = (parents: HydratedEntity[], children: HydratedEntity[]) => {
    const childrenAsMap = _.groupBy(children, child => getSignal(child, 'driverIds', []));
    return parents.map(parent => {
        const driverIdsForParent = getSignal(parent, 'driverIds', []);
        const childrenForParent = childrenAsMap[driverIdsForParent] || [];
        return {
            ...parent,
            level: 1,
            childrenCount: childrenForParent.length,
            children: childrenForParent.filter(removeEmptyEntity).map(child => ({
                ...child,
                level: 2,
            })),
        };
    });
};

const transformOpConToDriverMap = (getKey: { (element: HydratedEntity): string }) => (data: HydratedEntity[]) =>
    data.reduce((map, element) => map.set(getKey(element), element.signals), new Map());

const mergeOpConWithAggregationResponses = (getKey: { (element: HydratedEntity): string }) => (
    entities: HydratedEntity[],
    opCon = new Map()
) =>
    entities.filter(removeEmptyEntity).map(entity => {
        const { signals, ...rest } = entity;
        const key = getKey(entity);
        const opConSignals = opCon.get(key) || {};
        return {
            ...rest,
            signals: {
                ...signals,
                operatingConditionResponse: createSignal(opConSignals),
                operationCondition: _.get(opConSignals, 'score'),
            },
        };
    });

const getKeyForParent = (element: HydratedEntity) =>
    JSON.stringify([...element.drivers.map(driver => _.get(driver, 'driverId'))]);

const getKeyForChildren = (element: HydratedEntity) =>
    JSON.stringify([
        ...element.vehicles.map(vehicle => _.get(vehicle, 'vehicleId')),
        ...element.drivers.map(driver => _.get(driver, 'driverId')),
    ]);

export default function DriverContentLoader({
    dateRange = {
        start: moment()
            .subtract(1, 'week')
            .toDate(),
        end: moment().toDate(),
    },
    selectedDriverIds,
    vehicles,
    children,
}: {
    dateRange: DateRange;
    selectedDriverIds: string[];
    vehicles: string[];
    children: ReactElement;
}) {
    const loadableEntities = usePerformData(DRIVER_REQUEST_ATTRIBUTES, {
        variables: { ...dateRange, driverIds: selectedDriverIds, vehicleIds: vehicles, segmentBy: 'driver' },
    });

    const opCon = useOpconData({
        dateRange,
        vehicleIds: vehicles,
        driverIds: selectedDriverIds,
        groupBy: ['driver-id'],
    });

    const loadableChildren = usePerformData(DRIVER_REQUEST_ATTRIBUTES, {
        variables: {
            ...dateRange,
            driverIds: selectedDriverIds,
            vehicleIds: vehicles,
            segmentBy: 'driver_and_vehicle',
        },
    });

    const opConChildren = useOpconData({
        dateRange,
        vehicleIds: vehicles,
        driverIds: selectedDriverIds,
        groupBy: ['driver-id', 'asset-id'],
    });

    const parentsWithChildren = useMemo(() => {
        const opConMap = LoadableDataStructure.map(opCon, transformOpConToDriverMap(getKeyForParent));
        const opConChildrenMap = LoadableDataStructure.map(opConChildren, transformOpConToDriverMap(getKeyForChildren));

        const entitiesWithOpcon = LoadableDataStructure.combine(
            mergeOpConWithAggregationResponses(getKeyForParent),
            loadableEntities,
            opConMap
        );
        const childrenWithOpcon = LoadableDataStructure.combine(
            mergeOpConWithAggregationResponses(getKeyForChildren),
            loadableChildren,
            opConChildrenMap
        );

        return LoadableDataStructure.combine(mergeParentsWithChildren, entitiesWithOpcon, childrenWithOpcon);
    }, [loadableChildren, loadableEntities, opCon, opConChildren]);

    return React.cloneElement(children, { parentsWithChildren });
}
