import { Settings } from '../reducer';

const migrateKeys = (settings: Settings, oldSettingsVersion: number) => {
    if (oldSettingsVersion < 1) {
        const driverAnalysisSortBy = settings.driverAnalysisSortBy;
        if (driverAnalysisSortBy && driverAnalysisSortBy['key'] === 'vehicle') {
            driverAnalysisSortBy['key'] = 'vehicles';
        }

        const driverAnalysisColumnOrder = settings.driverAnalysisColumnOrder;
        if (driverAnalysisColumnOrder) {
            Object.keys(driverAnalysisColumnOrder).forEach(function(useCaseKey) {
                const useCaseColumnOrder = driverAnalysisColumnOrder[useCaseKey];
                if (Array.isArray(useCaseColumnOrder)) {
                    const idx = useCaseColumnOrder.indexOf('vehicle');
                    if (idx >= 0) {
                        useCaseColumnOrder[idx] = 'vehicles';
                    }
                }
            });
        }
    }

    return settings;
};

export default migrateKeys;
