import { Spinner, Tooltip } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import always from 'lodash/fp/always';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

import { getSignal } from '../../api';
import OverlayTriggerWithInjectedIntl from '../../components/OverlayTriggerWithInjectedIntl';
import usePerformData from '../../data/hooks/usePerformData';
import { Loadable, LoadableEntityPropType } from '../../data/loadable';
import { PADDED_DATE_FORMAT } from '../../utils/paddedDateFormat';
import { VEHICLE_OPERATION_IN_DAYS_ATTRIBUTES } from './queries';

interface Props {
    vehicles: string[];
    drivers: string[];
    start: Date;
    end: Date;
}

const transformDataEntryToLiElement = (dataEntry: { dataEntry: { start: string } }) => {
    const startValue = getSignal(dataEntry, 'start', undefined);
    return (
        <li key={startValue} className="padding-0 margin-0">
            {moment(startValue).isValid() ? (
                <FormattedDate value={startValue} {...(PADDED_DATE_FORMAT as { format?: string })} />
            ) : (
                '-'
            )}
        </li>
    );
};

const alwaysSpinner = always(<Spinner />);
const createStringFraction = (total: number) => (list = []) => `${list.length || 0}/${total}`;

const getTotalDays = (start: Date, end: Date) => {
    const startDate = moment(start).startOf('day');
    const endDate = moment(end).endOf('day');
    return endDate.diff(startDate, 'days') + 1;
};

const OperationDays = ({ loadableData, total }: { loadableData: any; total: number }) => {
    const tooltip = (
        <Tooltip id="operational-days-information" width="auto">
            <ul style={{ listStyle: 'none' }} className="padding-0 margin-0">
                {Loadable.cata(
                    loadableData,
                    (d: any) => d.map(transformDataEntryToLiElement),
                    always(null),
                    alwaysSpinner,
                    always(null)
                )}
            </ul>
        </Tooltip>
    );

    return (
        <span className="display-flex align-items-center" data-test="operationalDays">
            {Loadable.cata(loadableData, createStringFraction(total), always(null), alwaysSpinner, always(null))}
            <OverlayTriggerWithInjectedIntl placement="top" overlay={tooltip}>
                <span className="rioglyph rioglyph-info-sign text-color-info" />
            </OverlayTriggerWithInjectedIntl>
        </span>
    );
};

OperationDays.propTypes = {
    total: PropTypes.node.isRequired,
    loadableData: LoadableEntityPropType.isRequired,
};

const SelfFetchingOperationDays = ({ vehicles, start, end, drivers = [] }: Props) => {
    const loadableDataWithInvalidDataPoints = usePerformData(VEHICLE_OPERATION_IN_DAYS_ATTRIBUTES, {
        variables: { start, end, vehicleIds: vehicles, segmentBy: 'interval', intervalType: 'day' },
    });

    const loadableDataWithoutInvalidDates = Loadable.map(loadableDataWithInvalidDataPoints, data =>
        data
            .filter((d: any) => moment(getSignal(d, 'start', undefined), moment.ISO_8601).isValid())
            .filter((d: any) =>
                drivers.length ? _.intersection(getSignal(d, 'driverIds', []), drivers).length > 0 : true
            )
    );

    const totalDays = getTotalDays(start, end);
    return <OperationDays total={totalDays} loadableData={loadableDataWithoutInvalidDates} />;
};

export default SelfFetchingOperationDays;
