import { DateRangePicker as RioRangePicker } from '@rio-cloud/rio-uikit';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import ConditionalTooltip from '../../components/ConditionalTooltip';
import { CURRENT_MONTH, CURRENT_WEEK } from '../../utils/dateRangePresets';
import { getLocale } from '../lang/selectors';

// eslint-disable-next-line react/display-name
const getTooltipContent = preset => () => {
    if ([CURRENT_WEEK, CURRENT_MONTH].includes(preset.key) && !moment(preset.startValue).isBefore(preset.endValue)) {
        return <FormattedMessage id={'presetDisabledBecauseNoData'} />;
    }
    return <FormattedMessage id={'featureNotIncludedInEssentials'} />;
};

export const DateRangePicker = ({
    onSetDateRange,
    dateRange = {
        from: moment()
            .subtract(1, 'week')
            .toDate(),
        to: moment().toDate(),
    },
    dateRangeConfig,
    locale,
}) => {
    const textProps = {
        textDefault: <FormattedMessage id={'dateRange.placeholder'} />,
        textCustom: <FormattedMessage id={'dateRange.custom'} />,
        textLastCustomRange: <FormattedMessage id={'dateRange.lastCustomRange'} />,
        textFrom: <FormattedMessage id={'dateRange.from'} />,
        textTo: <FormattedMessage id={'dateRange.to'} />,
        textApply: <FormattedMessage id={'apply'} />,
        textCancel: <FormattedMessage id={'cancel'} />,
    };

    // rendered presets
    const customPresets = dateRangeConfig.presets.map(preset => ({
        ...preset,
        text: (
            <ConditionalTooltip enabled={preset.disabled} getTooltipChildren={getTooltipContent(preset)}>
                <span>
                    <FormattedMessage id={preset.translationKey} />
                </span>
            </ConditionalTooltip>
        ),
    }));

    return (
        <RioRangePicker
            locale={locale}
            onRangeChange={(start, end) => onSetDateRange(start.toDate(), end.toDate())}
            startValue={dateRange.from}
            endValue={dateRange.to}
            minValue={dateRangeConfig.min}
            maxValue={dateRangeConfig.max}
            customPresets={customPresets}
            customRangeOnly={dateRangeConfig.customRangeOnly}
            hasTimePicker={dateRangeConfig.hasTimePicker}
            {...textProps}
        />
    );
};

const dateRangePreset = PropTypes.shape({
    key: PropTypes.string.isRequired,
    startValue: PropTypes.instanceOf(Date).isRequired,
    endValue: PropTypes.instanceOf(Date).isRequired,
});

const dateRangeConfig = PropTypes.shape({
    min: PropTypes.object,
    max: PropTypes.object,
    hasTimePicker: PropTypes.bool,
    presets: PropTypes.arrayOf(dateRangePreset).isRequired,
    customRangeOnly: PropTypes.bool,
});

DateRangePicker.propTypes = {
    onSetDateRange: PropTypes.func.isRequired,
    dateRangeConfig: dateRangeConfig.isRequired,
};

export default connect(state => ({
    locale: getLocale(state),
}))(DateRangePicker);
