// This is the runtime configuration being used for development
// against a local instance of an `authorization-server`. Note
// that the redirect only works if you run the `webpack-dev-server`
// on http://localhost:8081.

export const localAuthConfig = {
    backend: {
        USERADMIN_SERVICE: `${window.location.origin}/user-management`,
        HERE_TOKEN_HANDLING: `${window.location.origin}/map/configurations`,
        MENU_URL: 'https://menu.rio.cloud',
        TAGS_SERVICE: `${window.location.origin}/tags`,
        ASSETS_SERVICE: `${window.location.origin}/assets`,
        DRIVERS_SERVICE: `${window.location.origin}/drivers`,
        SETTINGS_SERVICE: `${window.location.origin}/settings`,
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.local',
    login: {
        authority: `https://localhost:8443`,
        clientId: 'test-client',
        mockAuthorization: false, //true,
        preventRedirect: false,
    },
    logoutUri: `https://localhost:8443/logout`,
};
