import getOr from 'lodash/fp/getOr';

import { authorizeFetch } from '../../fetch';
import { configureReporting } from '../../setup/errorReporting';
import { changeLocale, languageDataFetched } from './actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './selectors';

const { captureException } = configureReporting(window, process.env);

const MODULE_NAME = `web-performance-v2`;

const sendError = exception => {
    captureException(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = imported => getOr(imported, 'default', imported);

export const configureFetchPreferredLanguage = storage => (userSettingsBackend, fetch = window.fetch) => {
    const get = authorizeFetch(storage, fetch);
    return get(`${userSettingsBackend}/user/settings/language`)
        .then(response => {
            // eslint-disable-next-line no-magic-numbers
            if (!response.ok || response.status === 401) {
                throw new Error(`Fetching user language failed.`);
            }
            return response.json();
        })
        .catch(error => {
            sendError(error);
            return error;
        });
};

const getMomentLocaleData = locale => {
    const sanitizedLocale = locale.toLowerCase().replace('_', '-');
    // We try to make our best guess what the locale might be named in moment.
    // eg. if `de-DE` doesn't work we try just the first part `de`
    // using Promise.all caused problems with jest.
    import(/* webpackChunkName: "moment/locale/[request]" */ `moment/locale/${sanitizedLocale.split('-')[0]}.js`).catch(
        () => {
            import(/* webpackChunkName: "moment/locale/[request]" */ `moment/locale/${sanitizedLocale}.js`).catch(
                captureException
            );
        }
    );
};

const getLanguageData = locale =>
    import(/* webpackChunkName: "[request]" */ `./translations/performanceAnalysis_${locale}.json`)
        .then(normalizeDynamicImport)
        .catch(error => {
            sendError(error);
            return error;
        });

export const configureFetchLanguageData = (
    store,
    fetchLanguageData = getLanguageData,
    fetchMomentLocaleData = getMomentLocaleData,
    getSupportedLocale = defaultGetSupportedLocale
) => locale => {
    if (!locale) {
        return Promise.reject(new Error('no locale'));
    }

    store.dispatch(changeLocale(locale));
    const supportedLocale = getSupportedLocale(store.getState());
    // We ignore the promise here, either it loads or we default to english.
    fetchMomentLocaleData(locale);
    return fetchLanguageData(supportedLocale)
        .then(languageData => {
            store.dispatch(languageDataFetched({ locale: supportedLocale, languageData }));
        })
        .catch(error => {
            sendError(error);
            return error;
        });
};
