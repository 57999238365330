import './polyfills';

import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';

import { env } from './env';
import { configureFetchLanguageData } from './features/lang/services';
import { mockOAuth, retrieveInitialState, setupOAuth } from './features/login/login';
import configureFetchLegacyPermissions from './features/permissions/api';
import { accessToken as accessTokenStorage } from './features/tokenHandling/accessToken';
import configureFetchHereSettings from './features/tokenHandling/hereToken';
import main, { trace } from './main';
import { history, store } from './setup/store';

if (env.shouldSendMetrics) {
    const options = { dsn: env.runtimeConfig.sentryToken, release: `performance-${process.env.COMMIT}` };
    if (env.isRunningOnProd) {
        options.environment = 'production';
    }
    Sentry.init(options);
}

const oauthBehavior = settings => {
    const isMocking = env.featureToggles.mockedToken;
    const promise = isMocking || env.runtimeConfig.login.mockAuthorization ? mockOAuth(settings) : setupOAuth(settings);

    return promise.then(() => {
        const { initialRoute } = retrieveInitialState();

        trace('initialRoute lookup', initialRoute);
        if (initialRoute) {
            trace(`history.replace("/${initialRoute}")`);
            history.replace(`/${initialRoute}`);
        }

        return Promise.resolve();
    });
};

main({
    accessTokenStorage,
    configureFetchHereSettings,
    configureFetchLanguageData,
    configureFetchLegacyPermissions,
    document,
    oauthBehavior,
    render,
    store,
});
