import { env } from '../../env';

const { HERE_TOKEN_HANDLING } = (env.runtimeConfig as Record<string, any>).backend;

const configureFetchHereSettings = (token: string, fetch = window.fetch) => () =>
    fetch(HERE_TOKEN_HANDLING, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            throw new Error('"hereSettings" call failed');
        })
        .then(json => (json.items || [])[0]);

export default configureFetchHereSettings;
