import moment from 'moment';

import { DateRange } from '../types';

const inclusiveDateDiff = (start: Date, end: Date, unit: moment.unitOfTime.Diff) => moment(end).diff(start, unit) + 1;
export const dateRangeSegmentedByStep = ({
    dateRange: { start, end },
    step,
}: {
    dateRange: DateRange;
    step: moment.unitOfTime.Diff;
}) => {
    const difference = Math.abs(inclusiveDateDiff(start, end, step));
    return Array(difference)
        .fill(moment(start).startOf(step))
        .map((rawDate, offset) =>
            moment(rawDate)
                .add(offset, step)
                .toDate()
        );
};
