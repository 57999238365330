import { StatusBar } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import { IntlProvider, useIntl } from 'react-intl';

import { ValidUnit } from '../constants/units';
import { getRatingCSSColor } from '../utils/rating';
import WithUnit from './WithUnit';

export default function SlimRating({ value }: { value?: number }) {
    const intl = useIntl();
    if (!_.isNumber(value)) {
        return (
            <div className="text-size-12 text-bold text-center">
                <span className="text-size-200pct">{'-'}</span>
            </div>
        );
    }
    const ratingColor = getRatingCSSColor(value);
    const roundedValue = Math.round(value);
    return (
        <div className="width-100pct display-inline-block">
            <StatusBar
                label={{
                    value: <WithUnit unit={ValidUnit.PERCENTAGE}>{roundedValue}</WithUnit>,
                    alignment: 'top',
                    color: ratingColor.color,
                }}
                progress={[
                    {
                        percentage: roundedValue,
                        color: ratingColor.background,
                        tooltip: (
                            <IntlProvider locale={intl.locale} messages={intl.messages}>
                                <span>
                                    <WithUnit unit={ValidUnit.PERCENTAGE}>{roundedValue}</WithUnit>
                                </span>
                            </IntlProvider>
                        ),
                    },
                ]}
            />
        </div>
    );
}
