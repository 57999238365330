import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import cn from 'classnames';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

interface Tabs {
    testTag?: string;
    title: React.ReactNode;
    disabled?: boolean;
    content: React.ReactNode;
}

interface TabbableContentI {
    tabs: Tabs[];
}

const TabbableContent: React.FC<TabbableContentI> = ({ tabs = [] }) => {
    const [currentContentBlock, switchContent] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (document && document.documentElement && !document.documentElement.classList.contains('ua-desktop')) {
            setIsMobile(true);
        }
    }, []);

    const options = tabs.map((tab, idx) => ({
        id: idx.toString(),
        selected: currentContentBlock === idx,
        label: tab.title,
    }));

    let selection = (
        <div className="border-color-light">
            <Select
                onChange={(item: { id: string }) => {
                    switchContent(parseInt(item.id));
                }}
                options={options}
            />
        </div>
    );
    if (!isMobile) {
        selection = (
            <ul className="nav nav-pills nav-justified nav-justified nav-justified-word-wrap border-color-light">
                {tabs.map((tab, idx) => (
                    <li
                        data-test={tab.testTag}
                        key={idx}
                        className={cn('max-width-25pct', {
                            active: currentContentBlock === idx && tab.disabled !== true,
                            disabled: tab.disabled === true,
                        })}
                    >
                        <div
                            role="tab"
                            className="word-break"
                            data-cy="tabbable-content"
                            onClick={() => switchContent(idx)}
                        >
                            {tab.title}
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <div>
            {selection}
            <div className="padding-top-15">{get(tabs, `${currentContentBlock}.content`)}</div>
        </div>
    );
};

export default TabbableContent;
