export const FORESIGHTED_REQUEST_ATTRIBUTES = [
    'acceleration_pedal_count_with_cc',
    'acceleration_pedal_distance_with_cc',
    'acceleration_pedal_rating',
    'acceleration_pedal_time_with_cc',
    'active_coasting_count',
    'active_coasting_distance',
    'active_coasting_time',
    'braking_rating',
    'coasting_cc_count',
    'coasting_cc_distance',
    'coasting_cc_time',
    'coasting_rating',
    'cruise_control_consumption_in_potential',
    'cruise_control_distance_in_potential',
    'cruise_control_potential_distance',
    'cruise_control_rating',
    'cruise_control_time_in_potential',
    'driver_ids',
    'emergency_brake_count',
    'foresighted_driving_percentage',
    'foresighted_driving_percentage_without_cc',
    'kickdown_count',
    'kickdown_distance',
    'kickdown_time',
    'motor_brake_count',
    'motor_brake_distance',
    'motor_brake_time',
    'no_cruise_control_consumption_in_potential',
    'no_cruise_control_distance_in_potential',
    'no_cruise_control_time_in_potential',
    'overspeed_distance',
    'overspeed_rating',
    'retarder_count',
    'retarder_distance',
    'retarder_time',
    'service_brake_count',
    'service_brake_distance',
    'service_brake_time',
    'vehicle_ids',
];
