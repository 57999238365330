import Tree from '@rio-cloud/rio-uikit/lib/es/Tree';
import { TreeItem } from '@rio-cloud/rio-uikit/lib/types';
import cn from 'classnames';
import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { Controller, ControllerProps } from 'react-hook-form';

import { Driver, Vehicle, VehicleGroup } from '../../../../api';

enum InputStatus {
    error,
    touched,
    success,
}

type Props<Form> = {
    errorMessage: ReactElement;
    status: InputStatus;
    items: Vehicle[] | Driver[];
    groups: VehicleGroup[];
    searchPlaceholder: string;
    disabled?: boolean;
} & Omit<ControllerProps<Form>, 'render'>;

export default function TreeSelect<Form>(props: Props<Form>) {
    const { status, errorMessage, groups, items, searchPlaceholder, name, disabled, ...rest } = props;
    return (
        <Controller
            name={name}
            {...rest}
            render={({ field }) => (
                <div data-test={`create-form-${name}`}>
                    <div
                        className={cn('panel panel-default margin-0', {
                            'border-color-danger': status === InputStatus.error,
                        })}
                    >
                        <div className="panel-body padding-0 padding-top-5 overflow-hidden">
                            <Tree
                                groups={groups}
                                currentCategoryId=""
                                items={items as TreeItem[]}
                                expandedGroups={[]}
                                selectedGroups={(field.value as { groups: string[]; items: string[] })?.groups || []}
                                selectedItems={(field.value as { groups: string[]; items: string[] })?.items || []}
                                onSelectionChange={disabled ? () => undefined : field.onChange}
                                scrollHeight={300}
                                searchPlaceholder={searchPlaceholder}
                                hasMultiselect={!disabled}
                                hideSearch={disabled}
                            />
                        </div>
                    </div>

                    {status === InputStatus.error && (
                        <span className="has-error text-size-12 text-right">
                            <span className="help-block">
                                <span>{errorMessage}</span>
                            </span>
                        </span>
                    )}
                </div>
            )}
        />
    );
}

export function LoadingTree() {
    return (
        <ContentLoader
            className="display-block"
            speed={2}
            viewBox="0 0 300 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="300" height="300" />
        </ContentLoader>
    );
}
