import { EmptyState, Sidebar } from '@rio-cloud/rio-uikit';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { createSignal, getSignal } from '../../api';
import Box from '../../components/Box';
import Column from '../../components/Column';
import ConditionalTooltip from '../../components/ConditionalTooltip';
import OperatingPeriod from '../../components/OperatingPeriod';
import { VEHICLE_ROUTE } from '../../constants/routes';
import OperationDays from '../../features/operationalDays/OperationalDays';
import PDFDownloader from '../../features/pdf/detail/VehiclePDFDownloader';
import { getPerformVehicles } from '../../features/permissions/selectors';
import { hideSidebar, showSidebar, sidebarData } from '../../features/ui/reducer';
import { getActiveDateRange } from '../../features/ui/selectors';
import { getColumns } from '../../utils/columns';
import { datePropType } from '../../utils/commonPropTypes';
import convertToRows from '../../utils/convertToRows';
import { trackingAttributes } from '../../utils/googleTagManager';
import { extractVehicleName } from '../../utils/stringFormatters';
import { openRow } from './actions';
import { getTopColumns } from './columns';
import { entitySidePanelSelection } from './selectors';
import Tabs from './SidepanelTabs';
import { decorateWithWarnings } from './utils';

const isValidDate = date => date && moment(date).isValid();
const convertToRow = convertToRows(3);

export const FormattedOperationDays = ({ entity, dateRange, level }) => (
    <div className=" margin-bottom-5">
        <label className="margin-bottom-0 text-size-12">
            <FormattedMessage id="operationDays" />
        </label>
        <div className="text-size-18 ellipsis-1">
            <OperationDays
                // On the first level we also need to include the no driver card for operationDays
                // This feature is unique to the vehicle. On the driver side we don't have to worry about it,
                drivers={level !== 1 ? getSignal(entity, 'driverIds', []) : []}
                vehicles={getSignal(entity, 'vehicleIds', [])}
                start={dateRange.start}
                end={dateRange.end}
            />
        </div>
    </div>
);

export const Sidepanel = ({
    entity = { drivers: [], vehicles: [], roads: createSignal([]), speedLimits: createSignal([]) },
    level,
    dateRange,
    vehicleColumns = [],
    onClose,
    hasPerformVehicle,
    performVehicleIds,
    onDriverClick,
    openParent,
}) => {
    const onDriverNameClick = useCallback(
        driver => {
            // TODO: figure out why id needs to be an array!?
            onDriverClick({ id: driver.id, level: 2, dateRange });
            openParent(entity.id);
        },
        [onDriverClick, openParent, entity, dateRange]
    );

    if (!isValidDate(getSignal(entity, 'start', undefined)) || !isValidDate(getSignal(entity, 'end', undefined))) {
        return (
            <Sidebar fly onClose={onClose} position={Sidebar.RIGHT} width={640}>
                <div className="padding-20 padding-top-15">
                    <EmptyState
                        headline={<FormattedMessage id="noData" />}
                        message={<FormattedMessage id="noData.explanation" />}
                    />
                </div>
            </Sidebar>
        );
    }

    const vehicle = extractVehicleName(_.get(entity, 'vehicles[0]', {}));
    const entityWithWarnings = decorateWithWarnings(entity, performVehicleIds);

    const isDriverNameClickable = hasPerformVehicle && level === 1 && entity.children && entity.children.length > 1;
    const topColumns = getTopColumns(isDriverNameClickable, onDriverNameClick, hasPerformVehicle);

    const topRows = convertToRow(
        topColumns.map(column => <Column key={column.key} column={column} entity={entityWithWarnings} />)
    );

    let displayedVehicleColumns = vehicleColumns
        .map(column => <Column key={column.key} column={column} entity={entity} />)
        .concat([<FormattedOperationDays key="operationDays" level={level} entity={entity} dateRange={dateRange} />]);
    const vehicleRows = convertToRow(displayedVehicleColumns);

    return (
        <Sidebar
            fly
            title={
                <div className="padding-left-10 justify-content-between flex align-items-center">
                    <FormattedMessage
                        id="vehicleAnalysis.sidebar.title.oneVehicle"
                        values={{
                            vehicle,
                            start: <FormattedDate value={dateRange.start} />,
                            end: <FormattedDate value={dateRange.end} />,
                        }}
                    />
                </div>
            }
            onClose={onClose}
            position={Sidebar.RIGHT}
            width={640}
            bodyClassName={'overflow-y-scroll'}
            headerButtons={
                <ConditionalTooltip
                    enabled={!hasPerformVehicle}
                    getTooltipChildren={() => <FormattedMessage id={'featureNotIncludedInEssentials'} />}
                >
                    <div>
                        <PDFDownloader
                            entity={entity}
                            dateRange={dateRange}
                            level={level}
                            performVehicleIds={performVehicleIds}
                        />
                    </div>
                </ConditionalTooltip>
            }
        >
            <OperatingPeriod start={getSignal(entity, 'start', undefined)} end={getSignal(entity, 'end', undefined)} />
            <div
                className="padding-20 padding-top-15"
                data-test="VehicleSidePanel"
                {...trackingAttributes('visibility', 'performance, sidebar', 'open:vehicleSidebar')}
            >
                <Box rows={topRows} />

                <Box rows={vehicleRows} hasBorder={false} />
                <Tabs level={level} dateRange={dateRange} entity={entity} hasPerformVehicle={hasPerformVehicle} />
            </div>
        </Sidebar>
    );
};

Sidepanel.propTypes = {
    dateRange: PropTypes.shape({ start: datePropType, end: datePropType }),
};

const mapStateToProps = state => {
    const sideBarData = sidebarData(state, VEHICLE_ROUTE);
    const entityWithChildren = entitySidePanelSelection(state);

    const performVehicles = getPerformVehicles(state);
    const vehicleIds = entityWithChildren.vehicles.map(vehicle => vehicle.vehicleId);
    const hasPerformVehicle = _.intersection(vehicleIds, performVehicles).length !== 0;

    return {
        vehicleColumns: getColumns([
            'operatingFuelConsumption',
            'fuelConsumption',
            'distanceFuelConsumption',
            'drivingConsumption',
            'idlingConsumption',
            'fuelEfficiency',
            'co2Emission',
            'operatingTime',
            'drivingTime',
            'idlingTime',
            'mileage',
            'averageWeight',
            'averageSpeed',
        ]),
        entity: entityWithChildren,
        level: _.get(sideBarData, 'level'),
        dateRange: getActiveDateRange(state),
        hasPerformVehicle,
        performVehicleIds: performVehicles,
    };
};

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideSidebar()),
    onDriverClick: data => dispatch(showSidebar({ data, type: VEHICLE_ROUTE })),
    openParent: id => dispatch(openRow({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidepanel);
