import _ from 'lodash';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { configureReporting } from '../../setup/errorReporting';
import { loadSettingsSuccess as settingsLoaded } from '../settings/reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from '../settings/saga';
import {
    collapseTree,
    expandTree,
    setSelectedDriverGroups,
    setSelectedDrivers,
    setSelectedVehicleGroups,
    setSelectedVehicles,
    setShowEmptyGroups,
} from './actions';

const { captureException } = configureReporting(window, process.env);

export const TREE_SETTINGS = {
    SELECTED_VEHICLE_GROUPS: 'treeSelectedGroups',
    SELECTED_DRIVER_GROUPS: 'treeSelectedDriversGroups',
    SELECTED_VEHICLES: 'treeSelectedVehicles',
    SELECTED_DRIVERS: 'treeSelectedDrivers',
    SHOW_EMPTY_GROUPS: 'treeShowEmptyGroups',
};

export function* toggleTree() {
    yield delay(10);
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        const evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        yield call(() => window.dispatchEvent(evt));
    } else {
        yield call(() => window.dispatchEvent(new Event('resize')));
    }
}

export function* saveShowEmptyGroups({ payload }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SHOW_EMPTY_GROUPS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedDrivers({ payload }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVERS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedVehicles({ payload }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLES, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedVehicleGroups({ payload }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLE_GROUPS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedDriverGroups({ payload }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVER_GROUPS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

function* clearAllSettings() {
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLE_GROUPS, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVER_GROUPS, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLES, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVERS, []);
    yield saveShowEmptyGroups(TREE_SETTINGS.SHOW_EMPTY_GROUPS, false);
}

export function* restoreSavedSettings() {
    try {
        const selectedVehicleGroups = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_VEHICLE_GROUPS, null);
        const selectedDriverGroups = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_DRIVER_GROUPS, null);
        const selectedVehicles = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_VEHICLES, null);
        const selectedDrivers = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_DRIVERS, null);
        const showEmptyGroups = yield loadSettingsFromStorage(TREE_SETTINGS.SHOW_EMPTY_GROUPS, false);

        yield put(setShowEmptyGroups(showEmptyGroups));

        if (_.isArray(selectedDriverGroups)) {
            yield put(setSelectedDriverGroups(selectedDriverGroups));
        }

        if (_.isArray(selectedVehicleGroups)) {
            yield put(setSelectedVehicleGroups(selectedVehicleGroups));
        }

        if (_.isArray(selectedVehicles)) {
            yield put(setSelectedVehicles(selectedVehicles));
        }

        if (_.isArray(selectedDrivers)) {
            yield put(setSelectedDrivers(selectedDrivers));
        }
    } catch (e) {
        yield clearAllSettings();
        yield call(captureException, e);
    }
}

export function* root() {
    yield takeLatest([expandTree, collapseTree], toggleTree);
    yield takeLatest(setSelectedVehicleGroups, saveSelectedVehicleGroups);
    yield takeLatest(setSelectedDriverGroups, saveSelectedDriverGroups);
    yield takeLatest(setSelectedVehicles, saveSelectedVehicles);
    yield takeLatest(setSelectedDrivers, saveSelectedDrivers);
    yield takeLatest(setShowEmptyGroups, saveShowEmptyGroups);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}

const mergedTreeSagas = [root];

export default mergedTreeSagas;
