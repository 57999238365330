import cn from 'classnames';
import _ from 'lodash';

import { getSignal } from '../api';
import DriverLabel from '../components/DriverLabel';
import FromToRange from '../components/FromToRange';
import InfoOverlay from '../components/InfoOverlay';
import Rating from '../components/Rating';
import SkeletonLoader from '../components/SkeletonLoader';
import SlimRating from '../components/SlimRating';
import SystemLabel from '../components/SystemLabel';
import VehicleLabel from '../components/VehicleLabel';
import WithUnit from '../components/WithUnit';
import { ValidUnit } from '../constants/units';
import { Driver, HydratedEntity, Vehicle } from '../types';
import { formatDateTime } from '../utils/formatters';
import { getRatingCSSColor } from '../utils/rating';
import createColumn from './createColumn';

export const coloredTotalRating = (rating: number | undefined) => {
    return (
        <div className={cn(getRatingCSSColor(rating).color, 'text-size-12', 'text-bold', 'text-center')}>
            <span className="text-size-200pct">
                {_.isNumber(rating) ? <WithUnit unit={ValidUnit.PERCENTAGE}>{Math.round(rating)}</WithUnit> : '-'}
            </span>
        </div>
    );
};

export const coloredRatingBar = (rating: number | undefined) => {
    return <SlimRating value={rating} />;
};

export const vehiclesLength = (vehicles: Vehicle[]) => vehicles.length;
export const vehiclesNames = (vehicles: Vehicle[]) => <VehicleLabel vehicles={vehicles} explicit />;
export const driverNames = (drivers: Driver[]) => <DriverLabel drivers={drivers} />;
export const driverNamesList = (drivers: Driver[]) => <DriverLabel drivers={drivers} explicit />;
export const systemFormatter = (vehicles: Vehicle[]) => <SystemLabel vehicles={vehicles} />;
export const vehicleList = (vehicles: Vehicle[], data: any) => (
    <span className="flex align-center display-flex">
        <span className="flex-1-1 overflow-hidden flex">
            <VehicleLabel vehicles={vehicles} />
        </span>
        <InfoOverlay warnings={data.warnings} />
    </span>
);

export const fromToRangeFormatter = (_: Date, entity: HydratedEntity) => {
    const start = getSignal(entity, 'start', undefined);
    const end = getSignal(entity, 'end', undefined);
    return <FromToRange start={start} end={end} />;
};

export const ratingForColumn = (rating: number | undefined, column: any) => <Rating value={rating} column={column} />;

export const loadingFormatter = () => (
    <SkeletonLoader>
        <span>100 kmh</span>
    </SkeletonLoader>
);

export const explicitDrivers = createColumn({
    key: 'explicitDrivers',
    dataField: 'drivers',
    labelId: 'driver',
    formatter: driverNamesList,
});

export const drivers = createColumn({
    key: 'drivers',
    dataField: 'drivers',
    labelId: 'driver',
    formatter: driverNames,
    dataSort: true,
    width: 'width-25pct-sm width-200-lg',
});

export const vehicle = createColumn({
    key: 'vehicle',
    dataField: 'vehicles',
    labelId: 'vehicle',
    formatter: vehicleList,
    dataSort: true,
    width: 'width-25pct-sm width-200-lg',
});

export const vehicles = createColumn({
    key: 'vehicles',
    dataField: 'vehicles',
    labelId: 'vehicles',
    formatter: vehiclesLength,
    dataSort: true,
    width: 'width-25pct-sm width-200-lg',
});
export const vehicleNames = createColumn({
    key: 'vehicleNames',
    dataField: 'vehicles',
    labelId: 'vehicles',
    formatter: vehiclesNames,
    dataSort: true,
});

export const start = createColumn({
    key: 'start',
    dataField: 'start',
    labelId: 'dateRange.from',
    formatter: formatDateTime,
    dataSort: true,
});

export const end = createColumn({
    key: 'end',
    dataField: 'end',
    labelId: 'dateRange.to',
    formatter: formatDateTime,
    dataSort: true,
});

export const system = createColumn({
    key: 'system',
    dataField: 'vehicles',
    labelId: 'vehicle.system',
    formatter: systemFormatter,
    dataSort: true,
});

export const tripStartEnd = createColumn({
    key: 'tripStartEnd',
    dataField: 'start',
    labelId: 'tripStartEnd',
    formatter: fromToRangeFormatter,
    dataSort: true,
    width: 'width-25pct-sm width-200-lg',
});
