// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

export const stagingConfig = {
    backend: {
        AUTHENTICATION_SERVICE: 'https://auth.iam.rio.cloud/oauth',
        //CHAT_SERVICE: 'https://api.iam.ccp-prod.net/api/chatservice/1.0',
        //ETA_SERVICE: 'https://api.iam.ccp-prod.net/todo_ETA_SERVICE/1.0.0',
        ROUTE_HISTORY: 'https://api.asset-history.rio.cloud/historic-positions/assets',
        FLEETADMIN_SERVICE: 'https://api.iam.ccp-prod.net/api/fleetadmin',
        //GEOFENCE_SERVICE: 'https://api.iam.ccp-prod.net',
        //LEGAL_SERVICE: 'https://api.iam.ccp-prod.net/todo_LEGAL_SERVICE/1.0.0',
        //LOCATION_SERVICE: 'https://api.iam.ccp-prod.net',
        //MAINTENANCE_SERVICE: 'https://api.iam.ccp-prod.net/api/maintenanceservice/v1',
        //MAP_SERVICE: 'https://api.iam.ccp-prod.net',
        //MARKETPLACE_SERVICE: 'https://api.iam.ccp-prod.net/todo_MARKETPLACE_SERVICE/1.0.0',
        //NOTIFICATION_SERVICE: 'https://api.iam.ccp-prod.net/todo_NOTIFICATION_SERVICE/1.0.0',
        //ORDER_SERVICE: 'https://api.iam.ccp-prod.net/api/orderservice/1.0',
        //MAP_ACTIVATION_SERVICE: 'https://api.iam.ccp-prod.net',
        //DRIVERLICENCE_SERVICE: 'https://api.iam.ccp-prod.net/api/driverlicenseservice/1.0',
        PERFORMANCE_HISTORY_SERVICE: 'https://api.perform3.rio.cloud/api/performancehistory/v2',
        //PARKING_SERVICE: 'https://api.iam.ccp-prod.net/todo_PARKING_SERVICE/1.0.0',
        //PUSHNOTIFICATION_SERVICE: 'https://api.iam.ccp-prod.net/api/pushnotificationservice/1.0',
        USERADMIN_SERVICE: 'https://api.iam.ccp-prod.net/user-management',
        PERFORMANCE_MERGER: 'https://api.perform3.rio.cloud/performcache',
        CONNECTED_CODRIVER_SERVICE: 'https://api.perform3.rio.cloud',
        EXCEL_EXPORT_SERVICE: 'https://api.perform3.rio.cloud',
        OPCON: 'https://api.opcon.rio.cloud',
        HERE_TOKEN_HANDLING: 'https://api.map.rio.cloud/configurations',
        //TACHOGRAPH_SERVICES_SERVICE: 'https://api.iam.ccp-prod.net/FileArchive/v1/',
        HERE_PROXY: 'https://api.iam.ccp-prod.net/api/mapservice/v1/hereproxy',
        MENU_URL: 'https://menu.rio.cloud',
        TAGS_SERVICE: `https://api.tags.rio.cloud`,
        ASSETS_SERVICE: `https://api.assets.rio.cloud`,
        DRIVERS_SERVICE: `https://api.drivers.rio.cloud`,
        PDF_SERVICE: `https://api.perform3.rio.cloud/staging/pdf-export`,
        SETTINGS_SERVICE: `https://api.perform3.rio.cloud/settings`,
        VEHICLE_SPEC: 'https://api.vehspec.rio.cloud',
        REPORTS_SERVICE: `https://api.perform3.rio.cloud/reporting`,
    },
    homeRoute: 'https://home.rio.cloud',
    id: 'env.production',
    login: {
        clientId: '6770a314-a990-480b-ab39-17fdba2f2c8a',
        authority: `https://auth.iam.rio.cloud`,
        mockAuthorization: false,
        preventRedirect: false,
    },
    logoutUri: `https://auth.iam.rio.cloud/logout?redirect_uri=${window.location.origin}/redirect.html`,
};
