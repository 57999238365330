import { isRight, map } from 'fp-ts/Either';
import { pipe } from 'fp-ts/pipeable';
import { array, boolean, intersection, number, partial, string, type, TypeOf } from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import _ from 'lodash';
import moment from 'moment';

import { TBM3ReportEntityFactory } from '../helpers/transformer';
import { Entity, Road, Route } from '../models/types';
import { getRoadTypeLabel } from './roadTypes';

const OpconReportResponse = intersection([
    type({
        key: intersection([
            //TODO: add missing stuff here.
            partial({
                driver_id: string,
            }),
            partial({
                asset_id: string,
            }),
            partial({
                from: string,
            }),
        ]),
    }),
    partial({
        topology: type({
            evaluated_mileage: number,
            avg_altitude: number,
        }),
        traffic: type({
            evaluated_mileage: number,
        }),
        weight: type({
            evaluated_mileage: number,
        }),
        range: type({
            evaluated_mileage: number,
        }),
        route_type: type({
            evaluated_mileage: number,
            route_types: array(
                type({
                    classifiers: array(string),
                    urban: boolean,
                    mileage: number,
                })
            ),
        }),
    }),
]);

const OpconApiResponse = type({
    id: string,
    reports: array(OpconReportResponse),
});

export type OpConApiType = TypeOf<typeof OpconApiResponse>;

function nonNullable<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

type RawRoadType = {
    mileage: number;
    urban: boolean;
    classifiers: string[];
};

const transformRoads = (routeTypes: { route_types: RawRoadType[] }): Road[] => {
    const extractNumericRoadIdentifier = (a: string) => {
        return parseInt(a.replace(/[\D]+/g, ''));
    };
    return routeTypes.route_types
        .map(road => {
            const highestRoadType = _.head(_.orderBy(road.classifiers, extractNumericRoadIdentifier).reverse()) || '';
            return {
                mileage: road.mileage,
                urban: road.urban,
                highestRoadType,
                country: highestRoadType.replace(/[\d-]/g, ''),
                name: getRoadTypeLabel(highestRoadType),
            };
        })
        .sort(
            (a, b) => extractNumericRoadIdentifier(a.highestRoadType) - extractNumericRoadIdentifier(b.highestRoadType)
        );
};

function convertEvaluatedMileageToCamelCase<T extends { evaluated_mileage: number }>(
    input: T
): Omit<T, 'evaluated_mileage'> {
    const tmp: Omit<T, 'evaluated_mileage'> & { evaluatedMileage: number; evaluated_mileage?: number } = {
        evaluatedMileage: input.evaluated_mileage,
        ...input,
    };
    delete tmp.evaluated_mileage;
    return tmp;
}

export function decodeOpconRequest(rawResponse: OpConApiType): Entity[] {
    const either = pipe(
        OpconApiResponse.decode(rawResponse),
        map(decoded =>
            decoded.reports.map(report => {
                const {
                    route_type: rawRouteType,
                    traffic: rawTraffic,
                    topology: rawTopology,
                    weight: rawWeight,
                    range: rawRange,
                    ...rest
                } = report;
                const route = rawRouteType
                    ? {
                          evaluatedMileage: rawRouteType.evaluated_mileage,
                          roads: transformRoads(rawRouteType),
                      }
                    : undefined;

                const traffic = rawTraffic ? convertEvaluatedMileageToCamelCase(rawTraffic) : undefined;
                const topology = rawTopology ? convertEvaluatedMileageToCamelCase(rawTopology) : undefined;
                const weight = rawWeight ? convertEvaluatedMileageToCamelCase(rawWeight) : undefined;
                const range = rawRange ? convertEvaluatedMileageToCamelCase(rawRange) : undefined;

                const decodedOpconResponse: {
                    driver_ids: string[];
                    vehicle_ids: string[];
                    key?: unknown;
                    route_type?: unknown;
                    start?: Date;
                    route?: Route;
                } = {
                    ...rest,
                    driver_ids: [report.key.driver_id].filter(nonNullable),
                    vehicle_ids: [report.key.asset_id].filter(nonNullable),
                    ...(report.key.from ? { start: moment(report.key.from, moment.ISO_8601).toDate() } : {}),
                    ...(route ? { route } : {}),
                    ...(topology ? { topology } : {}),
                    ...(traffic ? { traffic } : {}),
                    ...(weight ? { weight } : {}),
                    ...(range ? { range } : {}),
                };

                delete decodedOpconResponse.key;

                const transformFieldName = (value: unknown, key: string) => _.camelCase(key);

                return TBM3ReportEntityFactory(
                    _.mapKeys(decodedOpconResponse, transformFieldName),
                    decodedOpconResponse,
                    decoded.id
                );
            })
        )
    );

    if (isRight(either)) {
        return either.right;
    }

    throw Error(`Decoding Opcon Response failed, ${PathReporter.report(either)}`);
}
