import { compose } from 'redux';

import { PERCENTAGE } from '../constants/units';
import { formatIntegerNumber, plainAverage, withUnit } from '../utils/formatters';
import createColumn from './createColumn';

export const percentageSignal = {
    unit: PERCENTAGE,
    displayedUnit: PERCENTAGE,
    format: compose(withUnit(PERCENTAGE), formatIntegerNumber),
    average: plainAverage,
};

export const averageSlopeDown = createColumn({
    key: 'averageSlopeDown',
    dataField: 'averageSlopeDown',
    labelId: 'averageSlopeDown',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    dataSort: true,
});
export const averageSlopeUp = createColumn({
    key: 'averageSlopeUp',
    dataField: 'averageSlopeUp',
    labelId: 'averageSlopeUp',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const foresightedDrivingPercentage = createColumn({
    key: 'foresightedDrivingPercentage',
    dataField: 'foresightedDrivingPercentage',
    labelId: 'foresightedDrivingPercentage',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const coastingRating = createColumn({
    key: 'coastingRating',
    dataField: 'coastingRating',
    labelId: 'discipline.coasting',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-coasting',
});

export const accelerationPedalRating = createColumn({
    key: 'accelerationPedalRating',
    dataField: 'accelerationPedalRating',
    labelId: 'discipline.accelerationPedal',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-kickdown',
});

export const brakingRating = createColumn({
    key: 'brakingRating',
    dataField: 'brakingRating',
    labelId: 'discipline.braking',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-brake',
});

export const cruiseControlRating = createColumn({
    key: 'cruiseControlRating',
    dataField: 'cruiseControlRating',
    labelId: 'discipline.cruiseControl',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-cruise-control',
});

export const overspeedRating = createColumn({
    key: 'overspeedRating',
    dataField: 'overspeedRating',
    labelId: 'discipline.overspeed',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-speed',
});

export const totalRating = createColumn({
    key: 'totalRating',
    dataField: 'foresightedDrivingPercentage',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const totalRatingWithoutCC = createColumn({
    key: 'totalRatingWithoutCC',
    dataField: 'foresightedDrivingPercentageWithoutCc',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});
